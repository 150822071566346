import IndexPage from "../components/IndexPage.vue";
import WelcomePage from "../components/WelcomePage.vue";
import Treatment from "../components/Treatment.vue";
import ServiceDelivery from "../components/ServiceDelivery.vue";
import Research from "../components/Research.vue";
import CSI from "../components/CombodiaSmileIntervention.vue";
import OHE from "../components/OralHealthEducation.vue";
import BulkUpload from "../components/Bulkupload.vue";
import PrintList from "../components/PrintList.vue";
import UserList from "../components/UserList.vue";
import Settings from "../components/Settings.vue";
import LoginPage from "../components/LoginPage.vue";
import Logout from "../components/Logout.vue";
import About from "../components/AboutPage.vue";
import Help from "../components/HelpPage.vue";
import PrivacyPolicy from "../components/PrivacyPolicyPage.vue";
import Screening from "../components/Screening.vue";
import StaticPage from "../components/StaticPage.vue";

//for clinic page
// import SignupPage from "../components/clinic_page/SignupPage.vue";
import SelectActivity from "../components/clinic_page/SelectActivity.vue";
import SelectLocation from "../components/clinic_page/SelectLocation.vue";
import AddEncounter from "../components/clinic_page/AddEncounter.vue";
import CallList from "../components/clinic_page/CallList.vue";
import ReCallList from "../components/clinic_page/RecallList.vue";
import PatientDetail from "../components/clinic_page/PatientDetail.vue";
import PatientSearch from "../components/components_clinic_page/PatientSearch.vue";

export default [
  { path: "/dashboard", name: "index", component: IndexPage },
  { path: "/welcome", name: "welcome", component: WelcomePage },
  { path: "/treatment", name: "treatment", component: Treatment },
  { path: "/research", name: "research", component: Research },
  {
    path: "/servicedelivery",
    name: "servicedelivery",
    component: ServiceDelivery,
  },
  { path: "/screening", name: "screening", component: Screening },
  { path: "/csi", name: "csi", component: CSI },
  { path: "/ohe", name: "ohe", component: OHE },
  { path: "/printlist", name: "printlist", component: PrintList },
  { path: "/userlist", name: "userlist", component: UserList },
  { path: "/bulkupload", name: "bulkupload", component: BulkUpload },
  { path: "/settings", name: "settings", component: Settings },
  { path: "/", name: "loginpage", component: LoginPage },
  { path: "/logout", name: "logout", component: Logout },
  { path: "/about", name: "about", component: About },
  { path: "/help", name: "help", component: Help },
  { path: "/privacypolicy", name: "privacypolicy", component: PrivacyPolicy },
  { path: "/page/:slug", name: "staticPage", component: StaticPage },
  //For clinic Page
  // {path: '/signup', name: 'signuppage', component: SignupPage},
  { path: "/clinic", name: "selectlocation", component: SelectLocation },
  {
    path: "/clinic/selectactivity",
    name: "selectactivity",
    component: SelectActivity,
  },
  {
    path: "/clinic/calllist/:id/encounters",
    name: "addencounter",
    component: AddEncounter,
  },
  // {path: '/clinic/calllist/encounters', name: 'addencounter', component: AddEncounter},
  { path: "/clinic/calllist", name: "calllist", component: CallList },
  { path: "/clinic/recalllist", name: "recalllist", component: ReCallList },
  {
    path: "/clinic/calllist/:id",
    name: "patientdetail",
    component: PatientDetail,
  },
  {
    path: "/search-patient",
    name: "patientsearch",
    component: PatientSearch,
  },
];
