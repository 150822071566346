<template>
  <div class="recall-list">
    <app-header></app-header>
    <div class="components">
      <shortcuttab></shortcuttab>
      <div class="add-encounter">
        <div class="row align-items-center justify-content-between">
          <div class="col-3">
            <h1>Add Encounter</h1>
          </div>
          <div class="col-7">
            <h1>Patient Name: {{ patients.full_name }}</h1>
          </div>
        </div>
        <!-- {{ patients.full_name }} -->
        <encountertab></encountertab>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import AppHeader from "../Header.vue";
import ShortcutTab from "../components_clinic_page/ShortcutTab.vue";
import EncounterTab from "../clinic_page/EncounterTab.vue";
import Footer from "../components_clinic_page/FooterClinicSection.vue";
import { mapState } from "vuex";

export default {
  name: "AddEncounter",
  components: {
    "app-header": AppHeader,
    shortcuttab: ShortcutTab,
    encountertab: EncounterTab,
    Footer,
  },
  data() {
    return {
      tabIndex: 1,
      value: "",
      modalIndex: 1,
      selected: "",
    };
  },
  computed: {
    ...mapState(["patients"]),
  },
};
</script>
<style lang="scss" scoped>
@import "src/css/main.scss";
</style>
