<template>
  <form class="history-form" @submit.prevent="saveHistory()">
    <!-- {{ history_screening_obj }} -->
    <div class="border-box">
      <label class="blood-disorder space-between">
        <span>Blood Disorder and Bleeding Problem</span>
        <input
          type="checkbox"
          v-model="medicalCondition.bloodDisorder"
          size="lg"
          :disabled="disabled"
        />
      </label>
      <label class="diabetes space-between">
        <span>Diabetes (Sugar)</span>
        <input
          type="checkbox"
          v-model="medicalCondition.diabetes"
          size="lg"
          :disabled="disabled"
        />
      </label>
      <label class="liver-problem space-between">
        <span>Liver Problem</span>
        <input
          type="checkbox"
          v-model="medicalCondition.liverProblem"
          size="lg"
          :disabled="disabled"
        />
      </label>
      <label class="fever space-between">
        <span>Rheumatic Fever</span>
        <input
          type="checkbox"
          v-model="medicalCondition.rheumaticFever"
          size="lg"
          :disabled="disabled"
        />
      </label>
      <label class="seizures space-between">
        <span>Seizures or Epilepsy</span>
        <input
          type="checkbox"
          v-model="medicalCondition.seizure"
          size="lg"
          :disabled="disabled"
        />
      </label>
      <label class="hepatitis space-between">
        <span>Hepatitis B or C</span>
        <input
          type="checkbox"
          v-model="medicalCondition.hepatitisB"
          size="lg"
          :disabled="disabled"
        />
      </label>
      <label class="hiv space-between">
        <span>HIV</span>
        <input
          type="checkbox"
          v-model="medicalCondition.HIV"
          size="lg"
          :disabled="disabled"
        />
      </label>
      <label class="high-bp space-between">
        <span>High BP</span>
        <input
          type="checkbox"
          v-model="medicalCondition.highBP"
          size="lg"
          :disabled="disabled"
          @change="medicalCondition.lowBP = false"
        />
      </label>
      <label class="low-bp space-between">
        <span>Low BP</span>
        <input
          type="checkbox"
          v-model="medicalCondition.lowBP"
          size="lg"
          :disabled="disabled"
          @change="medicalCondition.highBP = false"
        />
      </label>
      <label class="thyroid space-between">
        <span>Thyroid Disorder</span>
        <input
          type="checkbox"
          v-model="medicalCondition.thyroid"
          size="lg"
          :disabled="disabled"
        />
      </label>
      <label class="other-condition space-between">
        <span>Others</span>
        <input
          type="text"
          placeholder="Eg. Whooping Cough"
          v-model="medicalCondition.others"
          :disabled="disabled"
        />
      </label>
      <label class="no-condition space-between">
        <span>No Underlying Medical Condition</span>
        <input
          type="checkbox"
          v-model="medicalCondition.none"
          size="lg"
          @change="disableMedicalConditions"
        />
      </label>
      <!-- <input
        type="text"
        class="no-condition"
        :disabled="disabledNoMedicationDetails"
        v-model="medicalCondition.noMedicationDetails"
      />-->

      <span class="form-error" v-if="errors.medical_condition">{{
        errors.medical_condition
      }}</span>
    </div>
    <!-- {{history.medications}} -->
    <div class="border-box">
      <label class="medication floating-label">
        <span>Medications</span>
        <input
          type="text"
          v-model="medicationDetails"
          placeholder="Medications if any"
          :disabled="disabledMedicationDetails"
        />
      </label>
      <label class="no-condition space-between">
        <span>Not Taking Any Medicines</span>
        <input
          type="checkbox"
          size="lg"
          v-model="medicalCondition.medication"
          @change="disableMedication"
        />
      </label>
      <span class="form-error" v-if="errors.medication">{{
        errors.medication
      }}</span>
    </div>
    <div class="border-box">
      <label class="allergies floating-label">
        <span>Allergies</span>
        <input
          type="text"
          v-model="allergiesDetails"
          placeholder="Allergies if any"
          :disabled="disabledAllergies"
        />
      </label>
      <label class="no-condition space-between">
        <span>No Allergies</span>
        <input
          type="checkbox"
          size="lg"
          v-model="medicalCondition.allergies"
          @change="disableAllergies"
        />
      </label>
      <span class="form-error" v-if="errors.allergy">{{ errors.allergy }}</span>
      <label class="no-condition space-between">
        <span>FV Applied Under CSI Program</span>
        <input type="checkbox" size="lg" v-model="fvApplied" />
      </label>
    </div>

    <input type="hidden" v-model="state_update_value" />
    <div class="button">
      <b-button variant="outline-success" @click="cancelPatient"
        >Cancel</b-button
      >
      <b-button variant="outline-success" type="submit">Next</b-button>
    </div>
    <b-toast
      id="history-failed-toast"
      variant="danger"
      solid
      append-toast
      toaster="b-toaster-top-center"
    >
      <div slot="toast-title" class="d-flex flex-grow-1 align-items-baseline">
        <strong class="mr-auto">Error !</strong>
      </div>
      <p v-if="this.failed">
        {{ this.failed }}
      </p>
    </b-toast>
    <b-toast
      id="history-success-toast"
      variant="success"
      solid
      append-toast
      toaster="b-toaster-top-center"
    >
      <div slot="toast-title" class="d-flex flex-grow-1 align-items-baseline">
        <strong class="mr-auto">Success !</strong>
      </div>
      <p>Encounter Removed Successfully</p>
    </b-toast>
  </form>
</template>

<script>
import axios from "axios";
import { mapState, mapActions, mapMutations } from "vuex";

export default {
  name: "History",
  data() {
    return {
      encounter_id: this.$route.params.id,
      disabled: false,
      disabledMedication: false,
      disabledMedicationDetails: false,
      disabledAllergies: false,
      disabledNoMedicationDetails: true,
      medicalCondition: {
        bloodDisorder: null,
        diabetes: null,
        liverProblem: null,
        rheumaticFever: null,
        seizure: null,
        hepatitisB: null,
        HIV: null,
        highBP: null,
        lowBP: null,
        thyroid: null,
        others: "",
        none: null,
        medication: null,
        allergies: null,
        // noMedicationDetails: ""
      },
      allergiesDetails: "",
      medicationDetails: "",
      fvApplied: null,
      state_update_value: "",
      errors: [],
      failed: "",
    };
  },
  computed: {
    ...mapState([
      "history",
      "successmessage",
      "errormessage",
      "message",
      "patients",
      "history_screening_obj",
    ]),
    disableMedicalConditions() {
      if (this.medicalCondition.none == true) {
        this.disabled = true;
        // this.disabledMedication = true;
        this.medicalCondition.bloodDisorder = false;
        this.medicalCondition.diabetes = false;
        this.medicalCondition.liverProblem = false;
        this.medicalCondition.rheumaticFever = false;
        this.medicalCondition.seizure = false;
        this.medicalCondition.hepatitisB = false;
        this.medicalCondition.HIV = false;
        this.medicalCondition.highBP = false;
        this.medicalCondition.lowBP = false;
        this.medicalCondition.thyroid = false;
        this.medicalCondition.others = "";
        this.disabledNoMedicationDetails = false;
      } else {
        this.disabled = false;
      }
    },
    disableMedication() {
      if (this.medicalCondition.medication == true) {
        this.medicationDetails = "";
        this.disabledMedicationDetails = true;
      } else {
        this.disabledMedicationDetails = false;
        // this.medicalCondition.medicationDetails = "";
      }
    },
    disableAllergies() {
      if (this.medicalCondition.allergies == true) {
        this.disabledAllergies = true;
        this.allergiesDetails = "";
      } else {
        // this.medicalCondition.allergiesDetails = "";
        this.disabledAllergies = false;
      }
    },
  },
  watch: {
    history: function() {
      if (Object.keys(this.history).length !== 0) {
        this.medicalCondition.bloodDisorder = this.history.blood_disorder;
        this.medicalCondition.diabetes = this.history.diabetes;
        this.medicalCondition.liverProblem = this.history.liver_problem;
        this.medicalCondition.rheumaticFever = this.history.rheumatic_fever;
        this.medicalCondition.seizure = this.history.seizuers_or_epilepsy;
        this.medicalCondition.hepatitisB = this.history.hepatitis_b_or_c;
        this.medicalCondition.HIV = this.history.hiv;
        this.medicalCondition.highBP = this.history.high_blood_pressure;
        this.medicalCondition.lowBP = this.history.low_blood_pressure;
        this.medicalCondition.thyroid = this.history.thyroid_disorder;
        this.medicalCondition.none = this.history.no_underlying_medical_condition;
        this.medicalCondition.medication = this.history.not_taking_any_medicines;
        this.medicalCondition.allergies = this.history.no_allergies;
        this.allergiesDetails = this.history.allergies;
        this.medicalCondition.others =
          this.history.other == "N/a" ? "" : this.history.other;

        this.medicationDetails = this.history.medications;
        this.fvApplied = this.history.FV_Applied_under_CSI_program;
      } else {
        this.medicalCondition.bloodDisorder = null;
        this.medicalCondition.diabetes = null;
        this.medicalCondition.liverProblem = null;
        this.medicalCondition.rheumaticFever = null;
        this.medicalCondition.seizure = null;
        this.medicalCondition.hepatitisB = null;
        this.medicalCondition.HIV = null;
        this.medicalCondition.highBP = null;
        this.medicalCondition.lowBP = null;
        this.medicalCondition.thyroid = null;
        this.medicalCondition.none = null;
        this.medicalCondition.medication = null;
        this.medicalCondition.allergies = null;
        // this.medicalCondition.allergiesDetails = "";
        this.medicalCondition.others = "";
        // this.medicalCondition.medicationDetails = "";
        this.fvApplied = null;
      }
    },
  },
  created() {
    this.listEncounterHistory(this.encounter_id);
    // console.log(this.patients.id)
    let formdata = {
      patientId: this.patients.id,
      encounterId: this.encounter_id,
    };
    this.getNewEncounter(formdata);
  },
  methods: {
    ...mapActions(["listEncounterHistory", "removeEncounterReason"]),
    ...mapMutations(["setHistoryScreening"]),
    getNewEncounter(patient_obj) {
      axios
        .get(
          `https://blcp.dentalhubproject.org/api/v1/patients/${patient_obj.patientId}/encounters/${patient_obj.encounterId}`
        )
        .then((response) => {
          if (response.data.encounter_type !== "Checkup / Screening") {
            // Get OLD DATA OF HISTORY AND SCRENNING
            axios
              .get(
                "https://blcp.dentalhubproject.org/api/v1/historyscreening/" +
                  patient_obj.patientId,
                {}
              )
              .then((response) => {
                if (response.status == 200) {
                  this.setHistoryScreening(response.data);
                  let history_screening = response.data;
                  this.medicalCondition.bloodDisorder =
                    history_screening.history.blood_disorder;
                  this.medicalCondition.diabetes =
                    history_screening.history.diabetes;
                  this.medicalCondition.liverProblem =
                    history_screening.history.liver_problem;
                  this.medicalCondition.rheumaticFever =
                    history_screening.history.rheumatic_fever;
                  this.medicalCondition.seizure =
                    history_screening.history.seizuers_or_epilepsy;
                  this.medicalCondition.hepatitisB =
                    history_screening.history.hepatitis_b_or_c;
                  this.medicalCondition.HIV = history_screening.history.hiv;
                  this.medicalCondition.highBP =
                    history_screening.history.high_blood_pressure;
                  this.medicalCondition.lowBP =
                    history_screening.history.low_blood_pressure;
                  this.medicalCondition.thyroid =
                    history_screening.history.thyroid_disorder;
                  this.medicalCondition.none =
                    history_screening.history.no_underlying_medical_condition;
                  this.medicalCondition.medication =
                    history_screening.history.not_taking_any_medicines;
                  this.medicalCondition.allergies =
                    history_screening.history.no_allergies;
                  this.allergiesDetails = history_screening.history.allergies;
                  this.medicalCondition.others =
                    history_screening.history.other == "N/a"
                      ? ""
                      : history_screening.history.other;
                  this.medicationDetails =
                    history_screening.history.medications;
                  this.fvApplied =
                    history_screening.history.FV_Applied_under_CSI_program;
                }
              });
          }
        });
    },
    saveHistory() {
      this.errors = [];
      if (
        this.medicalCondition.bloodDisorder != true &&
        this.medicalCondition.diabetes != true &&
        this.medicalCondition.liverProblem != true &&
        this.medicalCondition.rheumaticFever != true &&
        this.medicalCondition.seizure != true &&
        this.medicalCondition.hepatitisB != true &&
        this.medicalCondition.HIV != true &&
        this.medicalCondition.highBP != true &&
        this.medicalCondition.lowBP != true &&
        this.medicalCondition.thyroid != true &&
        this.medicalCondition.lowBP != true &&
        this.medicalCondition.others == "" &&
        this.medicalCondition.none != true &&
        this.medicalCondition.text != false
      ) {
        this.errors["medical_condition"] =
          "Please confirm if you have underlying medical conditions or not!";
      } else if (
        this.medicalCondition.medication != true &&
        this.medicationDetails == ""
      ) {
        this.errors["medication"] =
          "Please confirm if you are taking any medications or not!";
      } else if (
        this.allergiesDetails == "" &&
        this.medicalCondition.allergies != true
      ) {
        this.errors["allergy"] = "Please confirm if you have allergies or not!";
      } else {
        this.success = "Patient Added successfully.";
        this.$store.state.disabledScreening = false;
        this.$store.state.disabledHistory = true;
        let formDataObject = {
          blood_disorder: !this.medicalCondition.bloodDisorder ? false : true,
          diabetes: !this.medicalCondition.diabetes ? false : true,
          liver_problem: !this.medicalCondition.liverProblem ? false : true,
          rheumatic_fever: !this.medicalCondition.rheumaticFever ? false : true,
          seizuers_or_epilepsy: !this.medicalCondition.seizure ? false : true,
          hepatitis_b_or_c: !this.medicalCondition.hepatitisB ? false : true,
          hiv: !this.medicalCondition.HIV ? false : true,
          no_allergies: !this.medicalCondition.allergies ? false : true,
          allergies:
            this.allergiesDetails == "" ? "N/a" : this.allergiesDetails,
          other:
            this.medicalCondition.others == ""
              ? "N/a"
              : this.medicalCondition.others,
          medications:
            this.medicationDetails == "" ? "N/a" : this.medicationDetails,
          no_underlying_medical_condition: !this.medicalCondition.none
            ? false
            : true,
          not_taking_any_medicines: !this.medicalCondition.medication
            ? false
            : true,
          high_blood_pressure: !this.medicalCondition.highBP ? false : true,
          low_blood_pressure: !this.medicalCondition.lowBP ? false : true,
          thyroid_disorder: !this.medicalCondition.thyroid ? false : true,
          FV_Applied_under_CSI_program: !this.fvApplied ? false : true,
          history_status: "",
          encounter_id: this.encounter_id,
        };
        if (this.history_screening_obj.length != 0) {
          if (
            formDataObject.liver_problem ==
              this.history_screening_obj.history.liver_problem &&
            formDataObject.diabetes ==
              this.history_screening_obj.history.diabetes &&
            formDataObject.blood_disorder ==
              this.history_screening_obj.history.blood_disorder &&
            formDataObject.rheumatic_fever ==
              this.history_screening_obj.history.rheumatic_fever &&
            formDataObject.seizuers_or_epilepsy ==
              this.history_screening_obj.history.seizuers_or_epilepsy &&
            formDataObject.hepatitis_b_or_c ==
              this.history_screening_obj.history.hepatitis_b_or_c &&
            formDataObject.hiv == this.history_screening_obj.history.hiv &&
            formDataObject.no_allergies ==
              this.history_screening_obj.history.no_allergies &&
            formDataObject.allergies ==
              this.history_screening_obj.history.allergies &&
            formDataObject.other == this.history_screening_obj.history.other &&
            formDataObject.medications ==
              this.history_screening_obj.history.medications &&
            formDataObject.no_underlying_medical_condition ==
              this.history_screening_obj.history
                .no_underlying_medical_condition &&
            formDataObject.not_taking_any_medicines ==
              this.history_screening_obj.history.not_taking_any_medicines &&
            formDataObject.high_blood_pressure ==
              this.history_screening_obj.history.high_blood_pressure &&
            formDataObject.low_blood_pressure ==
              this.history_screening_obj.history.low_blood_pressure &&
            formDataObject.thyroid_disorder ==
              this.history_screening_obj.history.thyroid_disorder &&
            formDataObject.FV_Applied_under_CSI_program ==
              this.history_screening_obj.history.FV_Applied_under_CSI_program
          ) {
            formDataObject.history_status = "Copied";
          } else {
            formDataObject.history_status = "Fresh";
          }
        } else {
          formDataObject.history_status = "Fresh";
        }
        if (Object.keys(this.history).length !== 0) {
          this.$store.dispatch("updateHistory", formDataObject).then(() => {
            if (this.successmessage == "success") {
              this.success = "History saved successfully.";
              this.$store.state.tabIndex++;
            } else if (this.errormessage == "errormessage") {
              this.failed = "Failed to update history";
              this.$bvToast.show(failed - toast);
            }
          });
        } else {
          this.$store.dispatch("saveHistory", formDataObject).then(() => {
            if (this.successmessage == "success") {
              this.$store.state.tabIndex++;
            } else if (this.errormessage == "errormessage") {
              this.errors["saveHistoryError"] = "Failed to add history";
              console.log(JSON.stringify(this.message));
            }
          });
        }
      }
    },
    cancelPatient() {
      if (Object.keys(this.history).length !== 0) {
        setTimeout(() => {
          this.$store.state.disableTab = false;
          this.$router.push({
            name: "patientdetail",
            params: { id: this.patients.id },
          });
          window.scrollTo(0, 0);
        }, 1000);
      } else {
        this.$store
          .dispatch("removeEncounterReason", this.encounter_id)
          .then(() => {
            if (this.successmessage == "success") {
              this.$bvToast.show("history-success-toast");
              setTimeout(() => {
                this.$store.state.disableTab = false;
                this.$router.push({
                  name: "patientdetail",
                  params: { id: this.patients.id },
                });
                window.scrollTo(0, 0);
              }, 1000);
            } else if (this.errormessage == "errormessage") {
              this.failed = "Failed to canceled";
              this.$bvToast.show("history-failed-toast");
              this.$router.push({
                name: "patientdetail",
                params: { id: this.patients.id },
              });
              window.scrollTo(0, 0);
              console.log(JSON.stringify(this.message));
            }
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/css/main.scss";
</style>
