<template>
  <div class="container-bg-white mb-5">
    <div class="wrapper">
      <div class="col-12 p-0">
        <div class="data-list">
          <h3 class="pb-3">Patient List</h3>
          <div class="d-grid mb-4">
            <b-input-group-prepend>
              <b-button disabled variant="success" class="search-button"
                ><i class="fas fa-search"></i
              ></b-button>
            </b-input-group-prepend>
            <b-form-input
              v-model="filter"
              placeholder="Search for Users"
              v-stream:keyup.native="filter"
            ></b-form-input>
            <b-input-group-append>
              <b-button
                variant="danger"
                :disabled="!filter"
                @click="filter = ''"
                class="search-button"
                >Clear</b-button
              >
            </b-input-group-append>
          </div>
          <div>
            <b-table
              id="patients-table"
              show-empty
              :items="filteredPatients"
              :fields="fields"
              bordered
              :filter="filter"
              @filtered="onFiltered"
              responsive
              hover
              :current-page="currentPage"
              :per-page="perPage"
              :busy="isBusy"
            >
              <template v-slot:table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner
                    class="align-middle"
                    type="grow"
                    style="width: 5rem; height: 5rem"
                  ></b-spinner>
                </div>
              </template>
              <template v-slot>
                <div class="text-center text-primary my-2">
                  <h3>{{ this.message }}</h3>
                </div>
              </template>
              <template v-slot:cell(serial_number)="data">
                {{ data.index + 1 + "." }}
              </template>

              <template v-slot:cell(full_name)="row">
                {{ row.item.full_name }}
              </template>
              <template v-slot:cell(school)="row">
                {{ row.item.school }}
              </template>

              <template v-slot:cell(actions)="row">
                <b-button
                  variant="success"
                  centered
                  @click="getuserdetail(row.item.id)"
                  class=""
                  >View Detail</b-button
                >
              </template>
            </b-table>
            <div class="row my-3">
              <div class="col-12">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="perPage"
                  aria-controls="patients-table"
                  pills
                ></b-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
const axios = require("axios");
export default {
  name: "PatientList",
  components: {},
  data() {
    return {
      searchString: "",
      activity_id: "",
      clinic_id: "",
      school_id: "",
      search_value: "",
      patientList: [],
      patientArray: [],
      filter: null,
      fields: [
        { key: "serial_number", label: "S.N." },
        { key: "full_name", label: "Full Name", sortable: true },
        { key: "school", label: "School Name" },
        { key: "actions", label: "Action" },
      ],
      perPage: 10,
      currentPage: 1,
      isBusy: true,
    };
  },

  computed: {
    ...mapState([
      "successmessage",
      "patients",
      "activity",
      "clinic",
      "errormessage",
      "message",
      "schools",
    ]),

    rows() {
      return this.patientArray.length;
    },

    filteredPatients: function() {
      const activities = JSON.parse(
        window.localStorage.getItem("activity_item")
      );

      const patient_array = [];
      this.patientArray = patient_array;
      if (activities.schoolId == "") {
        this.patientList.forEach((element) => {
          this.schools.forEach((school) => {
            if (school.id == element.school) {
              element.school = school.name;
            }
          });
          patient_array.push(element);
        });
      } else {
        this.patientList.forEach((element) => {
          if (element.school == activities.schoolId) {
            this.schools.forEach((school) => {
              if (school.id == element.school) {
                element.school = school.name;
              }
            });
            patient_array.push(element);
          }
        });
      }
      // this.isBusy = false;

      return patient_array;
    },
  },

  watch: {
    patients: function() {
      if (Object.keys(this.patients).length !== 0) {
        this.patientList = this.patients;
        this.isBusy = false;
      } else {
        this.isBusy = false;
      }
    },
    schools: function() {
      if (Object.keys(this.patients).length !== 0) {
      } else {
        this.isBusy = false;
      }
    },
  },

  created() {
    const activities = JSON.parse(window.localStorage.getItem("activity_item"));

    axios
      .get("https://blcp.dentalhubproject.org/api/v1/clinic", {})
      .then((response) => {
        response.data.forEach((element) => {
          if (element.name == activities.location) {
            this.clinic_id = element.id;
          }
        });
        // console.log(this.clinic_id);
        this.listAllPatients(this.clinic_id);
      });
    // this.searchPatients();
    // this.filterSchool();
  },
  methods: {
    ...mapActions([
      "listAllPatients",
      "listActivity",
      "listSchools",
      "listClinic",
    ]),

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getuserdetail(id) {
      this.$router.push({ name: "patientdetail", params: { id: id } });
    },
  },
};
</script>
<style lang="scss" scoped>
.patient-name {
  h4 {
    font-family: "Open sans";
    font-weight: 600;
    color: #000;
  }
  span {
    font-weight: 600;
    color: #000;
    // font-size:  18px;
  }
}

.d-grid {
  display: grid;
  grid-template-columns: 50px auto 50px;
}

@import "src/css/main.scss";
</style>
