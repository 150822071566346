<template>
  <form action class="peridontal-form" @submit.prevent="savePeriodontal">
    <div class="column-1">
      <div class="row-1">
        <div class="number">
          <input
            type="number"
            class="peridontal-number-input"
            v-model="peridontal1714"
            max="5"
            min="0"
            @input="displayTreatment()"
          />
        </div>
        <div class="interval">17-14</div>
      </div>
      <div class="row-1">
        <div class="number">
          <input
            type="number"
            class="peridontal-number-input"
            v-model="peridontal1323"
            max="5"
            min="0"
            @input="displayTreatment()"
          />
        </div>
        <div class="interval">13-23</div>
      </div>
      <div class="row-1">
        <div class="number">
          <input
            type="number"
            class="peridontal-number-input"
            v-model="peridontal2427"
            max="5"
            min="0"
            @input="displayTreatment()"
          />
        </div>
        <div class="interval">24-27</div>
      </div>
      <div class="row-1">
        <div class="number">
          <input
            type="number"
            class="peridontal-number-input"
            v-model="peridontal4744"
            max="5"
            min="0"
            @input="displayTreatment()"
          />
        </div>
        <div class="interval">47-44</div>
      </div>
      <div class="row-1">
        <div class="number">
          <input
            type="number"
            class="peridontal-number-input"
            v-model="peridontal4333"
            max="5"
            min="0"
            @input="displayTreatment()"
          />
        </div>
        <div class="interval">43-33</div>
      </div>
      <div class="row-1">
        <div class="number">
          <input
            type="number"
            class="peridontal-number-input"
            v-model="peridontal3437"
            max="5"
            min="0"
            @input="displayTreatment()"
          />
        </div>
        <div class="interval">34-37</div>
      </div>
    </div>
    <div class="boarder-box">
      <label class="other space-between direction-column">
        <span class="other-treatment">Diagnosis</span>
        <input type="text" placeholder v-model="diagnosis" />
      </label>
    </div>
    <div class="border-box" v-if="GreaterThan">
      <div class="title">
        <p>Treatment</p>
      </div>

      <label class="hand-scaling space-between">
        <span>Hand Scaling</span>
        <input
          type="checkbox"
          size="lg"
          v-model="peridontalTreatment.handScaling"
        />
      </label>
      <!-- <label class="full-mouth space-between">
        <span>Full Mouth</span>
        <input
          type="checkbox"
          size="lg"
          v-model="peridontalTreatment.fullMouth"
        />
      </label>-->
      <label class="ultrasonic-scaling space-between">
        <span>Ultrasonic Scaling</span>
        <input
          type="checkbox"
          size="lg"
          v-model="peridontalTreatment.ultrasonicScaling"
        />
      </label>
      <label class="other space-between direction-column">
        <span class="other-treatment">Others</span>
        <input
          type="text"
          placeholder="Eg. Laser Treatment"
          v-model="peridontalTreatment.others"
        />
      </label>
    </div>
    <label class="comments medication">
      <p>Notes</p>
      <input type="text" placeholder="Notes if any" v-model="peridontalNotes" />
    </label>
    <div class="button">
      <b-button variant="outline-success" @click="backToScreening"
        >Back</b-button
      >
      <b-button variant="outline-success" type="submit">Next</b-button>
    </div>
  </form>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "Peridontal",
  data() {
    return {
      encounter_id: this.$route.params.id,
      peridontal1714: 0,
      peridontal1323: 0,
      peridontal2427: 0,
      peridontal4744: 0,
      peridontal4333: 0,
      peridontal3437: 0,
      diagnosis: "",
      peridontalTreatment: {
        handScaling: false,
        fullMouth: false,
        ultrasonicScaling: false,
        others: "",
      },
      peridontalNotes: "",
      // periodontal: "",
      GreaterThan: false,
    };
  },
  computed: {
    ...mapState(["periodontal", "successmessage", "errormessage", "message"]),
  },
  watch: {
    periodontal: function () {
      if (Object.keys(this.periodontal).length !== 0) {
        this.peridontal1714 = this.periodontal.teeth_17_14;
        this.peridontal1323 = this.periodontal.teeth_13_23;
        this.peridontal2427 = this.periodontal.teeth_24_27;
        this.peridontal4744 = this.periodontal.teeth_47_44;
        this.peridontal4333 = this.periodontal.teeth_43_33;
        this.peridontal3437 = this.periodontal.teeth_34_37;
        this.diagnosis =
          this.periodontal.diagnosis != "N/a" ? this.periodontal.diagnosis : "";
        this.peridontalTreatment.handScaling = this.periodontal.hand_scaling;
        this.peridontalTreatment.fullMouth = this.periodontal.full_mouth;
        this.peridontalTreatment.ultrasonicScaling =
          this.periodontal.ultrasonic_scaling;
        this.peridontalTreatment.others =
          this.periodontal.other != "N/a" ? this.periodontal.other : "N/a";
        this.peridontalNotes =
          this.periodontal.notes != "N/a" ? this.periodontal.notes : "N/a";
        // this.displayTreatment();
        if (
          this.peridontal1714 >= 3 ||
          this.peridontal1323 >= 3 ||
          this.peridontal2427 >= 3 ||
          this.peridontal4744 >= 3 ||
          this.peridontal4333 >= 3 ||
          this.peridontal3437 >= 3
        ) {
          this.GreaterThan = true;
        }
      } else {
        this.peridontal1714 = 0;
        this.peridontal1323 = 0;
        this.peridontal2427 = 0;
        this.peridontal4744 = 0;
        this.peridontal4333 = 0;
        this.peridontal3437 = 0;
        this.diagnosis = "";
        this.peridontalTreatment.handScaling = false;
        this.peridontalTreatment.fullMouth = false;
        this.peridontalTreatment.ultrasonicScaling = false;
        this.peridontalTreatment.others = "";
        this.peridontalNotes = "";
      }
    },
  },
  created() {
    this.listEncounterPeriodontal(this.encounter_id);
  },
  methods: {
    ...mapActions(["listEncounterPeriodontal"]),
    backToScreening() {
      this.$store.state.tabIndex--;
      this.$store.state.disabledScreening = false;
      this.$store.state.disabledPeriodontal = true;
    },
    savePeriodontal() {
      this.$store.state.disabledPeriodontal = true;
      this.$store.state.disabledDiagnostic = false;
      let formDataObject = {
        teeth_17_14: this.peridontal1714 > 0 ? this.peridontal1714 : 0,
        teeth_13_23: this.peridontal1323 > 0 ? this.peridontal1323 : 0,
        teeth_24_27: this.peridontal2427 > 0 ? this.peridontal2427 : 0,
        teeth_47_44: this.peridontal4744 > 0 ? this.peridontal4744 : 0,
        teeth_43_33: this.peridontal4333 > 0 ? this.peridontal4333 : 0,
        teeth_34_37: this.peridontal3437 > 0 ? this.peridontal3437 : 0,
        diagnosis: this.diagnosis != "" ? this.diagnosis : "N/a",
        hand_scaling: !this.peridontalTreatment.handScaling ? false : true,
        full_mouth: !this.peridontalTreatment.fullMouth ? false : true,
        ultrasonic_scaling: !this.peridontalTreatment.ultrasonicScaling
          ? false
          : true,
        other:
          this.peridontalTreatment.others != ""
            ? this.peridontalTreatment.others
            : "N/a",
        notes: this.peridontalNotes != "" ? this.peridontalNotes : "N/a",
        encounter_id: this.encounter_id,
      };
      // console.log(formDataObject);
      if (Object.keys(this.periodontal).length !== 0) {
        this.$store.dispatch("updatePeriodontal", formDataObject).then(() => {
          if (this.successmessage == "success") {
            // alert("Periodotal saved successfully.")
            this.success = "Periodotal updated succsessfully.";
            this.$store.state.tabIndex++;
          } else if (this.errormessage == "errormessage") {
            console.log(JSON.stringify(this.message));
          }
        });
      } else {
        this.$store.dispatch("savePeriodontal", formDataObject).then(() => {
          if (this.successmessage == "success") {
            // alert("Periodotal saved successfully.")
            this.success = "Periodotal saved successfully.";
            this.$store.state.tabIndex++;
          } else if (this.errormessage == "errormessage") {
            console.log(JSON.stringify(this.message));
          }
        });
      }
    },
    displayTreatment() {
      if (
        this.peridontal1714 >= 3 ||
        this.peridontal1323 >= 3 ||
        this.peridontal2427 >= 3 ||
        this.peridontal4744 >= 3 ||
        this.peridontal4333 >= 3 ||
        this.peridontal3437 >= 3
      ) {
        this.peridontalTreatment.handScaling = false;
        this.peridontalTreatment.ultrasonicScaling = false;
        this.peridontalTreatment.other = "N/a";
        this.GreaterThan = true;
      } else {
        this.GreaterThan = false;
      }
    },
  },
};
</script>

<style lang="scss">
input[type="number"] {
  &.peridontal-number-input {
    width: 60px;
    padding-left: 22px;
  }
}

.other-treatment {
  width: 50%;
}
</style>
<style lang="scss" scoped>
@import "src/css/main.scss";
</style>
