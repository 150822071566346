<template>
  <form class="screening-form" @submit.prevent="saveScreening">
    <div class="caries-risk md-height space-between direction-column">
      <span>Plaque</span>
      <div class="select-box">
        <select v-model="riskLevel" name id="risk-carrier">
          <option value="no plaque">no plaque</option>
          <option value="1/3 tooth">1/3 tooth</option>
          <option value="2/3 tooth">2/3 tooth</option>
          <option value="full tooth">full tooth</option>
        </select>
      </div>
    </div>
    <div class="primary-teeth md-height space-between direction-column">
      <span>No of Decayed Primary Teeth</span>
      <div class="input-box">
        <input type="number" min="0" max="20" v-model="decayedPrimary" />
      </div>
    </div>
    <div class="permanent-teeth md-height space-between direction-column">
      <span>No of Decayed Permanent Teeth</span>
      <div class="input-box">
        <input type="number" min="0" max="32" v-model="decayedPermanent" />
      </div>
      <!-- <div class="select-box">
        <select
          v-model="decayedPermanent"
          name="decayed teeth"
          id="decayed-teeth"
        >
          <option value="0">0</option>
          <option value="1">1</option>
          <option value="2">2</option>
        </select>
      </div>-->
    </div>
    <label class="cavity-molar space-between direction-column">
      <span>Cavity Permanent Molar or Premolar</span>
      <div class="check-box">
        <input type="checkbox" size="lg" v-model="molarOrPremolar" />
      </div>
    </label>
    <label class="cavity-canine space-between direction-column">
      <span>Cavity Permanent Canine or Incisor</span>
      <div class="check-box">
        <input type="checkbox" size="lg" v-model="canineOrIncisor" />
      </div>
    </label>
    <label class="reversible-pulpitis space-between direction-column">
      <span>Pain due to Reversible Pulpitis</span>
      <div class="check-box">
        <input type="checkbox" size="lg" v-model="reversiblePulpitisPain" />
      </div>
    </label>
    <label class="active-infection space-between direction-column">
      <span>Active Infection</span>
      <div class="check-box">
        <input type="checkbox" size="lg" v-model="activeInfection" />
      </div>
    </label>
    <label class="full-mouth space-between direction-column">
      <span>Full mouth SDF applied</span>
      <div class="check-box">
        <input type="checkbox" size="lg" v-model="fullMouthSdf" />
      </div>
    </label>
    <div class="how-often space-between direction-column">
      <span>How often in the last 3 months have you ?</span>
      <!-- <b-form-checkbox size="lg"></b-form-checkbox> -->
    </div>
    <div class="had-pain md-height space-between direction-column">
      <span>Had pain in the mouth or teeth</span>
      <div class="select-box">
        <select name="Pain" id="pain" v-model="lastThreeMonths.mouthTeethPain">
          <option value="never">Never</option>
          <option value="once or twice">Once or Twice</option>
          <option value="sometimes">Sometimes</option>
          <option value="frequently">Frequently</option>
          <option value="almost every day">Almost Every Day</option>
          <option value="do not know">Do Not Know</option>
        </select>
      </div>
    </div>
    <div class="had-stores md-height space-between direction-column">
      <span>Had sores in your mouth</span>
      <div class="select-box">
        <select name id="stores" v-model="lastThreeMonths.mouthStores">
          <option value="never">Never</option>
          <option value="once or twice">Once or Twice</option>
          <option value="sometimes">Sometimes</option>
          <option value="frequently">Frequently</option>
          <option value="almost every day">Almost Every Day</option>
          <option value="do not know">Do Not Know</option>
        </select>
      </div>
    </div>
    <div class="had-bad-breath md-height space-between direction-column">
      <span>Had bad breath</span>
      <div class="select-box">
        <select name id="bad-breath" v-model="lastThreeMonths.badBreath">
          <option value="never">Never</option>
          <option value="once or twice">Once or Twice</option>
          <option value="sometimes">Sometimes</option>
          <option value="frequently">Frequently</option>
          <option value="almost every day">Almost Every Day</option>
          <option value="do not know">Do Not Know</option>
        </select>
      </div>
    </div>
    <div class="had-food-stuck md-height space-between direction-column">
      <span>Had food stuck between your teeth</span>
      <div class="select-box">
        <select name id="food-stuck" v-model="lastThreeMonths.foodStuck">
          <option value="never">Never</option>
          <option value="once or twice">Once or Twice</option>
          <option value="sometimes">Sometimes</option>
          <option value="frequently">Frequently</option>
          <option value="almost every day">Almost Every Day</option>
          <option value="do not know">Do Not Know</option>
        </select>
      </div>
    </div>
    <label class="comments medication">
      <p>Comments</p>
      <input type="text" placeholder="Write your comments" v-model="comments" />
    </label>
    <div class="button">
      <b-button variant="outline-success" @click="backToHistory">Back</b-button>
      <b-button variant="outline-success" type="sumit">Save</b-button>
      <b-button variant="outline-success" @click="nextToPeriodontal"
        >Next</b-button
      >
    </div>
    <b-toast
      id="screening-success-toast"
      variant="success"
      solid
      append-toast
      toaster="b-toaster-top-center"
    >
      <div slot="toast-title" class="d-flex flex-grow-1 align-items-baseline">
        <strong class="mr-auto">Success !</strong>
      </div>
      <p>{{ this.success }}</p>
    </b-toast>
    <b-toast
      id="screening-error-toast"
      variant="danger"
      solid
      append-toast
      toaster="b-toaster-top-center"
    >
      <div slot="toast-title" class="d-flex flex-grow-1 align-items-baseline">
        <strong class="mr-auto">Error!</strong>
      </div>
      <p>{{ this.errormessage }}</p>
    </b-toast>
  </form>
</template>

<script>
import { mapState, mapActions } from "vuex";
import axios from "axios";

export default {
  name: "Screening",
  data() {
    return {
      encounter_id: this.$route.params.id,
      riskLevel: "no plaque",
      decayedPrimary: 0,
      decayedPermanent: 0,
      molarOrPremolar: false,
      canineOrIncisor: false,
      reversiblePulpitisPain: false,
      activeInfection: false,
      fullMouthSdf: false,
      lastThreeMonths: {
        mouthTeethPain: "never",
        mouthStores: "never",
        badBreath: "never",
        foodStuck: "never",
      },
      comments: "",
      screeningData: "",
      success: "",
      errormessage: "",
    };
  },
  computed: {
    ...mapState([
      "screening",
      "successmessage",
      "errormessage",
      "message",
      "patients",
      "history_screening_obj",
    ]),
  },
  watch: {
    screening: function() {
      if (Object.keys(this.screening).length !== 0) {
        this.riskLevel = this.screening.plaque_score;
        this.decayedPrimary = this.screening.decayed_primary_teeth;
        this.decayedPermanent = this.screening.decayed_permanent_teeth;
        this.molarOrPremolar = this.screening.cavity_permanent_molar_or_premolar;
        this.canineOrIncisor = this.screening.cavity_permanent_canine_or_incisor;
        this.reversiblePulpitisPain = this.screening.pain_due_to_reversible_pulpitis;
        this.activeInfection = this.screening.active_infection;
        this.fullMouthSdf = this.screening.full_mouth_sdf_applied;
        this.lastThreeMonths.mouthTeethPain = this.screening.had_pain_in_mouth_or_teeth;
        this.lastThreeMonths.mouthStores = this.screening.had_sores_in_your_mouth;
        this.lastThreeMonths.badBreath = this.screening.had_bad_breath;
        this.lastThreeMonths.foodStuck = this.screening.had_food_stuck_between_your_teeth;
        this.comments =
          this.screening.comments != "N/a" ? this.screening.comments : "";
      } else {
        this.riskLevel = "no plaque";
        this.decayedPrimary = 0;
        this.decayedPermanent = 0;
        this.molarOrPremolar = false;
        this.canineOrIncisor = false;
        this.reversiblePulpitisPain = false;
        this.activeInfection = false;
        this.fullMouthSdf = false;
        this.lastThreeMonths.mouthTeethPain = "never";
        this.lastThreeMonths.mouthStores = "never";
        this.lastThreeMonths.badBreath = "never";
        this.lastThreeMonths.foodStuck = "never";
        this.comments = "";
      }
    },
  },
  created() {
    this.listEncounterScreening(this.encounter_id);
    let formdata = {
      patientId: this.patients.id,
      encounterId: this.encounter_id,
    };
    this.getNewEncounter(formdata);
  },
  methods: {
    ...mapActions(["listEncounterScreening"]),
    getNewEncounter(patient_obj) {
      axios
        .get(
          `https://blcp.dentalhubproject.org/api/v1/patients/${patient_obj.patientId}/encounters/${patient_obj.encounterId}`
        )
        .then((response) => {
          if (response.data.encounter_type !== "Checkup / Screening") {
            //GET OLD HISTORY AND SCREENING DATA
            axios
              .get(
                "https://blcp.dentalhubproject.org/api/v1/historyscreening/" +
                  patient_obj.patientId,
                {}
              )
              .then((response) => {
                console.log(response.data);
                if (response.status == 200) {
                  let history_screening = response.data;

                  this.riskLevel = history_screening.screening.plaque_score;
                  this.decayedPrimary =
                    history_screening.screening.decayed_primary_teeth;
                  this.decayedPermanent =
                    history_screening.screening.decayed_permanent_teeth;
                  this.molarOrPremolar =
                    history_screening.screening.cavity_permanent_molar_or_premolar;
                  this.canineOrIncisor =
                    history_screening.screening.cavity_permanent_canine_or_incisor;
                  this.reversiblePulpitisPain =
                    history_screening.screening.pain_due_to_reversible_pulpitis;
                  this.activeInfection =
                    history_screening.screening.active_infection;
                  this.fullMouthSdf =
                    history_screening.screening.full_mouth_sdf_applied;
                  this.lastThreeMonths.mouthTeethPain =
                    history_screening.screening.had_pain_in_mouth_or_teeth;
                  this.lastThreeMonths.mouthStores =
                    history_screening.screening.had_sores_in_your_mouth;
                  this.lastThreeMonths.badBreath =
                    history_screening.screening.had_bad_breath;
                  this.lastThreeMonths.foodStuck =
                    history_screening.screening.had_food_stuck_between_your_teeth;
                  this.comments =
                    history_screening.screening.comments != "N/a"
                      ? history_screening.screening.comments
                      : "";
                }
              });
          }
        });
    },
    backToHistory() {
      this.$store.state.tabIndex--;
      this.$store.state.disabledHistory = false;
      this.$store.state.disabledScreening = true;
    },
    nextToPeriodontal() {
      this.$store.state.tabIndex++;
      this.$store.state.disabledScreening = true;
      this.$store.state.disabledPeriodontal = false;
      let formDataObject = {
        plaque_score: this.riskLevel,
        decayed_primary_teeth: this.decayedPrimary,
        decayed_permanent_teeth: this.decayedPermanent,
        cavity_permanent_molar_or_premolar: !this.molarOrPremolar
          ? false
          : true,
        cavity_permanent_canine_or_incisor: !this.canineOrIncisor
          ? false
          : true,
        pain_due_to_reversible_pulpitis: !this.reversiblePulpitisPain
          ? false
          : true,
        active_infection: !this.activeInfection ? false : true,
        full_mouth_sdf_applied: !this.fullMouthSdf ? false : true,
        had_pain_in_mouth_or_teeth: this.lastThreeMonths.mouthTeethPain,
        had_sores_in_your_mouth: this.lastThreeMonths.mouthStores,
        had_bad_breath: this.lastThreeMonths.badBreath,
        had_food_stuck_between_your_teeth: this.lastThreeMonths.foodStuck,
        comments: this.comments != "" ? this.comments : "N/a",
        history_status: "",
        encounter_id: this.encounter_id,
      };

      if (this.history_screening_obj.length != 0) {
        if (
          formDataObject.plaque_score ==
            this.history_screening_obj.screening.plaque_score &&
          formDataObject.decayed_primary_teeth ==
            this.history_screening_obj.screening.decayed_primary_teeth &&
          formDataObject.decayed_permanent_teeth ==
            this.history_screening_obj.screening.decayed_permanent_teeth &&
          formDataObject.cavity_permanent_molar_or_premolar ==
            this.history_screening_obj.screening
              .cavity_permanent_molar_or_premolar &&
          formDataObject.cavity_permanent_canine_or_incisor ==
            this.history_screening_obj.screening
              .cavity_permanent_canine_or_incisor &&
          formDataObject.pain_due_to_reversible_pulpitis ==
            this.history_screening_obj.screening
              .pain_due_to_reversible_pulpitis &&
          formDataObject.active_infection ==
            this.history_screening_obj.screening.active_infection &&
          formDataObject.full_mouth_sdf_applied ==
            this.history_screening_obj.screening.full_mouth_sdf_applied &&
          formDataObject.had_pain_in_mouth_or_teeth ==
            this.history_screening_obj.screening.had_pain_in_mouth_or_teeth &&
          formDataObject.had_sores_in_your_mouth ==
            this.history_screening_obj.screening.had_sores_in_your_mouth &&
          formDataObject.had_bad_breath ==
            this.history_screening_obj.screening.had_bad_breath &&
          formDataObject.had_food_stuck_between_your_teeth ==
            this.history_screening_obj.screening
              .had_food_stuck_between_your_teeth &&
          formDataObject.comments ==
            this.history_screening_obj.screening.comments
        ) {
          formDataObject.screening_status = "Copied";
        } else {
          formDataObject.screening_status = "Fresh";
        }
      } else {
        formDataObject.screening_status = "Fresh";
      }

      if (Object.keys(this.screening).length !== 0) {
        this.$store.dispatch("updateScreening", formDataObject).then(() => {
          if (this.successmessage == "success") {
            this.$store.state.disableTab = false;
            this.success = "History and Screening updated succsessfully.";
            this.$bvToast.show("screening-success-toast");
            // setTimeout(() => {
            //   this.$router.push({
            //     name: "patientdetail",
            //     params: { id: this.patients.id },
            //   });
            // }, 1500);
          } else if (this.errormessage == "errormessage") {
            this.$bvToast.show("screening-error-toast");
            console.log(JSON.stringify(this.message));
          }
        });
      } else {
        this.$store.dispatch("saveScreening", formDataObject).then(() => {
          if (this.successmessage == "success") {
            this.success = "History and Screening saved successfully.";
            this.$bvToast.show("screening-success-toast");
            this.$store.state.disableTab = false;
            // setTimeout(() => {
            //   this.$router.push({
            //     name: "patientdetail",
            //     params: { id: this.patients.id },
            //   });
            // }, 1500);
          } else if (this.errormessage == "errormessage") {
            this.$bvToast.show("screening-error-toast");
            console.log(JSON.stringify(this.message));
          }
        });
      }
    },
    saveScreening() {
      this.errors = [];
      // this.$store.state.disabledScreening = true;
      // this.$store.state.disabledHistory = false;
      let formDataObject = {
        plaque_score: this.riskLevel,
        decayed_primary_teeth: this.decayedPrimary,
        decayed_permanent_teeth: this.decayedPermanent,
        cavity_permanent_molar_or_premolar: !this.molarOrPremolar
          ? false
          : true,
        cavity_permanent_canine_or_incisor: !this.canineOrIncisor
          ? false
          : true,
        pain_due_to_reversible_pulpitis: !this.reversiblePulpitisPain
          ? false
          : true,
        active_infection: !this.activeInfection ? false : true,
        full_mouth_sdf_applied: !this.fullMouthSdf ? false : true,
        had_pain_in_mouth_or_teeth: this.lastThreeMonths.mouthTeethPain,
        had_sores_in_your_mouth: this.lastThreeMonths.mouthStores,
        had_bad_breath: this.lastThreeMonths.badBreath,
        had_food_stuck_between_your_teeth: this.lastThreeMonths.foodStuck,
        comments: this.comments != "" ? this.comments : "N/a",
        history_status: "",
        encounter_id: this.encounter_id,
      };

      if (this.history_screening_obj.length != 0) {
        if (
          formDataObject.plaque_score ==
            this.history_screening_obj.screening.plaque_score &&
          formDataObject.decayed_primary_teeth ==
            this.history_screening_obj.screening.decayed_primary_teeth &&
          formDataObject.decayed_permanent_teeth ==
            this.history_screening_obj.screening.decayed_permanent_teeth &&
          formDataObject.cavity_permanent_molar_or_premolar ==
            this.history_screening_obj.screening
              .cavity_permanent_molar_or_premolar &&
          formDataObject.cavity_permanent_canine_or_incisor ==
            this.history_screening_obj.screening
              .cavity_permanent_canine_or_incisor &&
          formDataObject.pain_due_to_reversible_pulpitis ==
            this.history_screening_obj.screening
              .pain_due_to_reversible_pulpitis &&
          formDataObject.active_infection ==
            this.history_screening_obj.screening.active_infection &&
          formDataObject.full_mouth_sdf_applied ==
            this.history_screening_obj.screening.full_mouth_sdf_applied &&
          formDataObject.had_pain_in_mouth_or_teeth ==
            this.history_screening_obj.screening.had_pain_in_mouth_or_teeth &&
          formDataObject.had_sores_in_your_mouth ==
            this.history_screening_obj.screening.had_sores_in_your_mouth &&
          formDataObject.had_bad_breath ==
            this.history_screening_obj.screening.had_bad_breath &&
          formDataObject.had_food_stuck_between_your_teeth ==
            this.history_screening_obj.screening
              .had_food_stuck_between_your_teeth &&
          formDataObject.comments ==
            this.history_screening_obj.screening.comments
        ) {
          formDataObject.history_status = "Copied";
        } else {
          formDataObject.history_status = "Fresh";
        }
      } else {
        formDataObject.history_status = "Fresh";
      }
      if (Object.keys(this.screening).length !== 0) {
        this.$store.dispatch("updateScreening", formDataObject).then(() => {
          if (this.successmessage == "success") {
            this.success = "History and Screening updated succsessfully.";
            this.$bvToast.show("screening-success-toast");

            setTimeout(() => {
              this.$router.push({
                name: "patientdetail",
                params: { id: this.patients.id },
              });
              this.$store.state.disabledScreening = true;
              this.$store.state.disabledHistory = false;
              this.$store.state.disableTab = false;
              window.scrollTo(0, 0);
            }, 1500);
            this.$store.state.tabIndex = 1;
          } else if (this.errormessage == "errormessage") {
            this.$bvToast.show("screening-error-toast");
            console.log(JSON.stringify(this.message));
          }
        });
      } else {
        this.$store.dispatch("saveScreening", formDataObject).then(() => {
          if (this.successmessage == "success") {
            this.success = "History and Screening saved successfully.";
            this.$bvToast.show("screening-success-toast");
            setTimeout(() => {
              this.$router.push({
                name: "patientdetail",
                params: { id: this.patients.id },
              });
              this.$store.state.disabledScreening = true;
              this.$store.state.disabledHistory = false;
              this.$store.state.disableTab = false;
              window.scrollTo(0, 0);
            }, 1500);
            this.$store.state.tabIndex = 1;
          } else if (this.errormessage == "errormessage") {
            this.$bvToast.show("screening-error-toast");
            setTimeout(() => {
              this.$router.push({
                name: "patientdetail",
                params: { id: this.patients.id },
              });
              this.$store.state.disabledScreening = true;
              this.$store.state.disabledHistory = false;
              this.$store.state.disableTab = false;
              window.scrollTo(0, 0);
            }, 1500);
            console.log(JSON.stringify(this.message));
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/css/main.scss";
</style>
