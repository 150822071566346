export default {
  title: "** Dental Hub **",
  loginvisualization: "",
  token: window.localStorage.getItem("token"),
  location_item: window.localStorage.getItem("location_item"),
  activity_item: window.localStorage.getItem("activity_item"),
  activity_area_item: window.localStorage.getItem("activity_area_item"),
  new_treatment_id: window.localStorage.getItem("treatment_id"),
  users: [],
  loginLabel: [],
  loginData: [],
  successmessage: "",
  deletemessage: [],
  bulk_upload_response: "",
  errormessage: "",
  message: "",
  duplicate_message: [],
  profile: "",
  geography: [],
  adminusername: "",
  overviewbargraph_obj: [],
  home_table_obj: [],
  treatment_by_type_obj: [],
  treatment_by_basic_obj: [],
  treatment_by_treatment_obj: [],
  screening_achievement_vs_target_obj: [],
  treatment_progress_screened_obj: [],
  // screening_achievement_obj: [],
  all_location_obj: [],
  progress_obj: [],
  treatment_location_basic_obj: [],
  treatment_location_treatment_obj: [],
  combodia_smile_intervention: [],
  csi_basic_obj: [],
  ohe_table_basic_obj: [],
  csi_obj: [],
  ohe_obj: [],
  loginvisualization1: "",
  carepattern_obj: [],
  preventive_obj: [],
  intervention_obj: [],
  oral_health_obj: [],
  sample_file_obj: [],
  csi_list_obj: [],
  ohe_list_obj: [],
  // Clinic
  locations: [],
  addresses: [],
  alladdresses: [],
  province: [],
  schools: [],
  activityareas: [],
  selectedLocation: "",
  selectedActivity: "",
  clinic_activities: [],
  patients: [],
  recall_list: [],
  encounters: [],
  encounter: [],
  new_encounter: [],
  returndate_obj: [],
  teeth_obj: [],
  clinic: "",
  activity: "",
  search_patient: [],
  encounterId: "",
  history_screening_obj: [],
  // Local Store
  tabIndex: 1,
  history: [],
  screening: [],
  periodontal: [],
  diagnostic: [],
  treatment: [],
  history_screening: {},
  referral: [],
  disabledHistory: false,
  disabledScreening: true,
  disabledPeriodontal: true,
  disabledDiagnostic: true,
  disabledTreatment: true,
  disabledReferral: true,
  disableTab: false,
  // Users
  users_obj: [],
  role_obj: [],
  // printlist
  print_referral: [],
  print_recall: [],
  static_page_obj: [],
};
