<template>
  <b-modal
    id="add-patient-modal"
    size="lg"
    header-text-size="large"
    title="Create New Patient"
    hide-footer
    no-close-on-backdrop
  >
    <form @submit.prevent="addPatient" method="post">
      <div class="name-field">
        <div class="first-name">
          <div class="floating-label">
            <input
              type="text"
              class="is-invalid"
              placeholder="First Name"
              v-model="first_name"
              id="first-name"
            />
            <span v-if="errors.first_name" class="form-error">{{
              errors.first_name
            }}</span>
            <label for="first-name">First Name</label>
            <div class="text-danger"></div>
          </div>
        </div>
        <div class="mid-name">
          <div class="floating-label">
            <input
              type="text"
              placeholder="Middle Name"
              v-model="middle_name"
              name
              id="mid-name"
            />
            <label for="mid-name">Mid Name</label>
          </div>
        </div>
        <div class="last-name">
          <div class="floating-label">
            <input
              type="text"
              name
              class="is-invalid"
              placeholder="Last Name"
              v-model="last_name"
              id="last-name"
            />
            <span v-if="errors.last_name" class="form-error">{{
              errors.last_name
            }}</span>
            <label for="last-name">Last Name</label>
            <div class="text-danger"></div>
          </div>
        </div>
        <div class="invalid-feedback">Please enter the name</div>
      </div>
      <div class="name-field">
        <div class="first-name">
          <div class="floating-label">
            <input
              type="text"
              class="is-invalid"
              placeholder="First Name Khmer"
              v-model="first_name_khmer"
              id="first-name-khmer"
            />

            <label for="first-name-khmer">First Name Khmer</label>
            <div class="text-danger"></div>
          </div>
        </div>
        <div class="mid-name">
          <div class="floating-label">
            <input
              type="text"
              placeholder="Mid Name Khmer"
              v-model="middle_name_khmer"
              name
              id="mid-name-khmer"
            />
            <label for="mid-name-khmer">Mid Name Khmer</label>
          </div>
        </div>
        <div class="last-name">
          <div class="floating-label">
            <input
              type="text"
              name
              class="is-invalid"
              placeholder="Last Name Khmer"
              v-model="last_name_khmer"
              id="last-name-khmer"
            />
            <!-- <span v-if="errors.last_name" class="form-error">{{
              errors.last_name
            }}</span>-->
            <label for="last-name-khmer">Last Name Khmer</label>
            <div class="text-danger"></div>
          </div>
        </div>
      </div>
      <div class="gender-field">
        <label>Gender</label>
        <div class="gender-inputs">
          <div class="male-gender">
            <label class="space-between">
              <span>Male</span>
              <input
                type="radio"
                v-model="gender"
                value="Male"
                id="male-radio-button"
              />
            </label>
          </div>
          <div class="female-gender">
            <label class="space-between">
              <span>Female</span>
              <input
                type="radio"
                v-model="gender"
                value="Female"
                id="female-radio-button"
              />
            </label>
          </div>
          <div class="female-gender">
            <label class="space-between">
              <span>Others</span>
              <input
                type="radio"
                v-model="gender"
                value="Other"
                id="other-radio-button"
              />
            </label>
          </div>
        </div>
        <span v-if="errors.gender" class="form-error">{{ errors.gender }}</span>
      </div>
      <div class="dob-field">
        <label>Date of Birth</label>
        <div class="dob-wrapper">
          <div class="month">
            <div class="select">
              <select v-model="dob.month" @change="selected">
                <option value>Month</option>
                <option
                  v-for="(month, index) in months"
                  :key="index"
                  :value="month"
                  >{{ month.month }}</option
                >
              </select>
            </div>
            <span class="form-error" v-if="errors.dob_month">
              {{ errors.dob_month }}
            </span>
          </div>

          <div class="day">
            <div class="select">
              <select v-model="dob.day" @change="minTwoDigits(dob.day)">
                <option value>Day</option>
                <option
                  v-for="day in daysInMonth(monthIndex, dob.year)"
                  :key="day.id"
                  :value="day"
                  >{{ day }}</option
                >
              </select>
            </div>
            <span class="form-error" v-if="errors.dob_day">
              {{ errors.dob_day }}
            </span>
          </div>

          <div class="year">
            <div class="select">
              <select v-model="dob.year">
                <option value selected>Year</option>
                <option v-for="year in years" :key="year.id" :value="year">{{
                  year
                }}</option>
              </select>
              <!-- <label for="year">Year</label> -->
            </div>
            <span class="form-error" v-if="errors.dob_year">
              {{ errors.dob_year }}
            </span>
          </div>
        </div>
      </div>
      <div class="row align-items-center justify-content-center my-4">
        <div class="col-12 col-lg-6 pr-0">
          <div class="phone-number">
            <div class="floating-label">
              <input
                type="number"
                id="phone-number"
                class="phone-number"
                placeholder="Phone Number"
                name
                v-model="phone"
              />
              <span v-if="errors.phone" class="form-error">{{
                errors.phone
              }}</span>
              <label for="phone-number">Phone Number</label>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-6">
          <div class="provience">
            <div class="select">
              <div class="floating-label">
                <select v-model="provinceData" id="provience">
                  <option value selected disabled>Select Province</option>
                  <option
                    v-for="(province, index) in filteredProvinceList"
                    :key="index"
                    :value="province.id"
                    >{{ province.name }}</option
                  >
                </select>
                <label for="provience">Provience</label>
              </div>
              <span v-if="errors.provinceError" class="form-error">{{
                errors.provinceError
              }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="row align-items-center justify-content-center my-4">
        <div class="col-12 col-lg-6 pr-0">
          <div class="select">
            <div class="floating-label">
              <select
                v-model="districtData"
                :disabled="disabledDistrict"
                id="district"
              >
                <option value disabled>Select District</option>
                <option
                  v-for="(district, index) in filterDistrict"
                  :key="index"
                  :value="district.id"
                  >{{ district.name }}</option
                >
              </select>
              <label alt="district">District</label>
            </div>
            <span v-if="errors.district" class="form-error">{{
              errors.district
            }}</span>
          </div>
        </div>
        <div class="col-12 col-lg-6">
          <div class="community">
            <div class="select">
              <div class="floating-label">
                <select
                  v-model="communeData"
                  :disabled="disabledCommune"
                  id="commune"
                >
                  <option value disabled>Select Commune</option>
                  <option
                    v-for="(commune, index) in filterCommune"
                    :key="index"
                    :value="commune.id"
                    >{{ commune.name }}</option
                  >
                </select>
                <label for="commune">Commune</label>
              </div>
            </div>
            <span v-if="errors.communeError" class="form-error">{{
              errors.communeError
            }}</span>
          </div>
        </div>
      </div>
      <div class="row align-items-center justify-content-center my-4">
        <div class="col-12 col-lg-6 pr-0">
          <div class="phone-number">
            <div class="floating-label">
              <input
                type="text"
                id="village"
                class="phone-number"
                placeholder="Enter Your Village"
                name
                v-model="village"
              />
              <!-- <span v-if="errors.village" class="form-error">{{
                errors.village
              }}</span>-->
              <label for="village">Enter Your Village</label>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-6">
          <div class="community">
            <div class="select">
              <div class="floating-label">
                <select
                  v-model="schoolData"
                  :disabled="disabledSchool"
                  id="school"
                  @change="selectedSchool(schoolData)"
                >
                  <option value disabled>Select School</option>
                  <option
                    v-for="(school, index) in filterSchool"
                    :key="index"
                    :value="school.id"
                    >{{ school.name }}</option
                  >
                </select>
                <label for="school">Select School</label>
              </div>
            </div>
          </div>
          <span v-if="errors.schoolError" class="form-error">{{
            errors.schoolError
          }}</span>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="community">
            <div class="select">
              <div class="floating-label">
                <select v-model="grade">
                  <option value selected disabled>Select Grade</option>
                  <option value="KG">KG</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="PPS">PPS</option>
                </select>
                <label for="school">Select Grade</label>
              </div>
            </div>
          </div>

          <span v-if="errors.gradeError" class="form-error">{{
            errors.gradeError
          }}</span>
        </div>
      </div>
      <div class="save-button">
        <b-button type="submit" variant="success">Save Patient</b-button>
      </div>
      <span v-if="success.success_message" class="form-success">{{
        success.success_message
      }}</span>
    </form>
  </b-modal>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import moment from "moment";

export default {
  name: "ModalPatient",
  data() {
    return {
      activity_id: "",
      clinic_id: "",
      disabledDistrict: false,
      disabledCommune: false,
      disabledSchool: false,
      first_name: "",
      middle_name: "",
      last_name: "",
      first_name_khmer: "",
      middle_name_khmer: "",
      last_name_khmer: "",
      gender: "",
      monthIndex: "",
      grade: "",
      months: [
        {
          month: "January",
          index: 1,
        },
        {
          month: "February",
          index: 2,
        },
        {
          month: "March",
          index: 3,
        },
        {
          month: "April",
          index: 4,
        },
        {
          month: "May",
          index: 5,
        },
        {
          month: "June",
          index: 6,
        },
        {
          month: "July",
          index: 7,
        },
        {
          month: "August",
          index: 8,
        },
        {
          month: "September",
          index: 9,
        },
        {
          month: "October",
          index: 10,
        },
        {
          month: "November",
          index: 11,
        },
        {
          month: "December",
          index: 12,
        },
      ],
      dob: {
        day: "",
        month: "",
        year: "",
      },
      formattedDay: "",
      phone: "",
      districtData: "",
      provinceData: "",
      schoolData: "",
      communeData: "",
      filteredProvince: "",
      selectedDistrictData: [],
      selectedSchoolData: [],
      village: "",
      education: "",
      latitude: "20.32",
      longitude: "34.23",
      recall_time: "",
      recall_date: "",
      errors: [],
      success: [],
      location_item: JSON.parse(localStorage.getItem("location_item")),
      activity_item: JSON.parse(localStorage.getItem("activity_item")),

      // activity_name: activity,
    };
  },
  computed: {
    ...mapState([
      "successmessage",
      "alladdresses",
      "schools",
      "province",
      "errormessage",
      "message",
      "activity",
      "clinic",
    ]),
    years() {
      const year = new Date().getFullYear();
      return Array.from(
        {
          length: 110,
        },
        (value, index) => year - index
      );
    },
    filteredProvinceList: function() {
      // console.log(this.provinceData);
      let filteredProvinceList = [];
      this.province.forEach((element) => {
        if (this.location_item.location != "MDC") {
          this.location_item.province.forEach((item) => {
            if (element.id == item) {
              filteredProvinceList.push(element);
              this.provinceData = element.id;
            }
          });
        } else {
          this.location_item.province.forEach((item) => {
            if (element.id == item) {
              filteredProvinceList.push(element);
            }
          });
        }
      });
      return filteredProvinceList;
    },
    filterDistrict: function() {
      let filteredDistrictList = [];
      if (this.activity_item.schoolId == "") {
        this.alladdresses.forEach((element) => {
          if (element.id == this.provinceData) {
            filteredDistrictList = element.districts;
            this.selectedDistrictData = element.districts;
          }
        });
        return filteredDistrictList;
      } else {
        this.schools.forEach((element) => {
          if (this.activity_item.schoolId == element.id) {
            this.alladdresses.forEach((item) => {
              if (item.id == this.provinceData) {
                item.districts.forEach((district) => {
                  if (district.id == element.district) {
                    filteredDistrictList.push(district);
                    this.districtData = district.id;
                  }
                });
              }
            });
          }
        });
        return filteredDistrictList;
      }
    },
    filterCommune: function() {
      let filteredCommuneList = [];
      if (this.activity_item.schoolId == "") {
        this.selectedDistrictData.forEach((element) => {
          if (element.id == this.districtData) {
            filteredCommuneList = element.communes;
          }
        });
        return filteredCommuneList;
      } else {
        this.schools.forEach((element) => {
          if (this.activity_item.schoolId == element.id) {
            this.alladdresses.forEach((item) => {
              if (item.id == this.provinceData) {
                item.districts.forEach((district) => {
                  if (district.id == element.district) {
                    district.communes.forEach((commune) => {
                      if (commune.id == element.commune) {
                        filteredCommuneList.push(commune);
                        this.communeData = commune.id;
                      }
                    });
                  }
                });
              }
            });
          }
        });
        return filteredCommuneList;
      }
    },
    filterSchool: function() {
      let selectedSchool = [];
      if (this.location_item.location != "MDC") {
        this.schools.forEach((element) => {
          if (this.activity_item.schoolId == "") {
            if (
              element.school_type == "target" &&
              element.province == this.location_item.province
            ) {
              selectedSchool.push(element);
            }
          } else if (this.activity_item.schoolId == element.id) {
            selectedSchool.push(element);
            this.schoolData = element.id;
            this.village = element.village;
            this.phone = element.phone;
          }
        });
      } else {
        this.schools.forEach((element) => {
          // Condition When user select the routine clinic as activity
          if (this.activity_item.schoolId == "") {
            this.location_item.province.forEach((province) => {
              if (
                element.school_type != "target" &&
                element.province == province
              ) {
                selectedSchool.push(element);
              }
            });
          }
          // Condition When user select the particular school as activity
          else if (this.activity_item.schoolId == element.id) {
            this.location_item.province.forEach((province) => {
              if (
                element.school_type != "target" &&
                element.province == province
              ) {
                selectedSchool.push(element);
                this.village = element.village;
                this.districtData = element.district;
                this.communeData = element.commune;
                this.provinceData = element.province;
                this.phone = element.phone;
                this.schoolData = element.id;
              }
            });
          }
        });
      }
      return selectedSchool;
    },
  },
  created() {
    this.listAllAddresses();
    this.listSchools();
    this.listProvince();
    this.listActivity();
    this.listClinic();
  },
  methods: {
    ...mapActions([
      "listAllAddresses",
      "listSchools",
      "listProvince",
      "listActivity",
      "listClinic",
    ]),
    ...mapMutations(["setAllAddress", "setSchool", "setProvince"]),

    selected() {
      let monthIndex = this.months.indexOf(this.dob.month) + 1;
      console.log(monthIndex.toString().length);
      if (monthIndex.toString().length < 2) {
        this.monthIndex = "0" + monthIndex;
      } else {
        this.monthIndex = monthIndex;
      }
    },

    minTwoDigits(value) {
      if (value.toString().length < 2) {
        this.formattedDay = "0" + value;
      } else {
        this.formattedDay = value;
      }
    },

    daysInMonth(month, year) {
      return new Date(year, month, 0).getDate();
    },

    addPatient() {
      let activities = JSON.parse(window.localStorage.getItem("activity_item"));
      this.activity.forEach((element) => {
        if (element.name == activities.activity) {
          this.activity_id = element.id;
        }
      });
      this.clinic.forEach((element) => {
        if (element.name == activities.location) {
          this.clinic_id = element.id;
        }
      });
      var date = moment().toDate();
      var currentDate = moment(date).format("YYYY-MM-DD");
      this.errors = [];
      this.success = [];
      if (this.first_name == "") {
        this.errors["first_name"] = "First name is required.";
      } else if (this.last_name == "") {
        this.errors["last_name"] = "Last name is required.";
      } else if (this.gender == "") {
        this.errors["gender"] = "Please Select Gender";
      } else if (this.dob.month == "") {
        this.errors["dob_month"] = "Select month.";
      } else if (this.dob.day == "") {
        this.errors["dob_day"] = "Select day.";
      } else if (this.dob.year == "") {
        this.errors["dob_year"] = "Select year.";
      } else if (this.provinceName == "") {
        this.errors["provinceError"] = "Select Province";
      } else if (this.districtName == "") {
        this.errors["district"] = "Select District";
      } else if (this.commune == "") {
        this.errors["communeError"] = "Select Commune";
        // } else if (this.school == "") {
        //   this.errors["schoolError"] = "Select School";
      } else if (this.grade == "") {
        this.errors["gradeError"] = "Select Grade";
      } else {
        let formData = new FormData();
        formData.append("activity", this.activity_id);
        formData.append("area", "");
        formData.append("first_name", this.first_name);
        formData.append("middle_name", this.middle_name);
        formData.append("last_name", this.last_name);
        formData.append("first_name_khmer", this.first_name_khmer);
        formData.append("middle_name_khmer", this.middle_name_khmer);
        formData.append("last_name_khmer", this.last_name_khmer);
        formData.append("gender", this.gender);
        formData.append(
          "dob",
          this.dob.year + "-" + this.monthIndex + "-" + this.formattedDay
        );
        formData.append("phone", this.phone);
        formData.append("school", this.schoolData);
        formData.append("province", this.provinceData);
        formData.append("district", this.districtData);
        formData.append("commune", this.communeData);
        formData.append("village", this.village);
        formData.append("clinic", this.clinic_id);
        formData.append("grade", this.grade);
        formData.append("education", this.education);
        formData.append(
          "geography",
          window.localStorage.getItem("location_item")
        );
        formData.append("created_at", currentDate);
        formData.append("recall_time", this.recall_time);
        formData.append("recall_date", this.recall_date);
        formData.append("latitude", this.latitude);
        formData.append("longitude", this.longitude);
        formData.append("other_school", "");
        formData.append("status", "Active");
        // console.log(form)

        // for (var pair of formData.entries()) {
        //   console.log(pair[0] + ", " + pair[1]);
        // }

        this.$store.dispatch("addPatient", formData).then(() => {
          if (this.successmessage == "success") {
            this.success["success_message"] = "Patient Added successfully.";
            setTimeout(function() {
              location.reload();
            }, 1500);
          } else if (this.errormessage == "errormessage") {
            this.errors["addPatientError"] = "Failed to add Patient";
            //console.log(this.errormessage);
          }
        });
      }
    },

    selectedSchool(schoolData) {
      console.log(schoolData);
      this.schools.forEach((element) => {
        if (element.id == schoolData) {
          this.village = element.village;
          this.districtData = element.district;
          this.communeData = element.commune;
          this.provinceData = element.province;
          this.phone = element.phone;
        }
      });
    },
  },
};
</script>

<style lang="scss">
.form-error {
  color: #e23f3f;
}

.form-success {
  color: #1fd01f;
}
.phone-number {
  -moz-appearance: textfield;
  &input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
</style>
<style lang="scss" scoped>
@import "src/css/main.scss";
</style>
