<template>
  <div id="app">
    <app-header></app-header>
    <div class="contents">
      <div class="row">
        <div class="col-12">
          <h1>Research</h1>
        </div>
      </div>
      <!-- Filter data Section -->
      <div class="row mt-4 text-center">
        <div class="col-12">
          <div class="card shadow">
            <h3 class="mb-4">5 Filter Data</h3>
            <div class="row mt-5">
              <div class="col-6">
                <h3 class="my-4">Time Frame 1</h3>
                <div class="row">
                  <div class="col-lg-6 col-sm-12 mb-4">
                    <h6>Select Start Date:</h6>
                    <b-input v-model="start_date" type="date" />
                  </div>
                  <div class="col-lg-6 col-sm-12 mb-4">
                    <h6>Select End Date:</h6>
                    <b-input v-model="today_date" type="date" />
                  </div>
                </div>
              </div>
              <div class="col-6">
                <h3 class="my-4">Time Frame 1</h3>
                <div class="row">
                  <div class="col-lg-6 col-sm-12 mb-4">
                    <h6>Select Start Date:</h6>
                    <b-input v-model="start_date" type="date" />
                  </div>
                  <div class="col-lg-6 col-sm-12 mb-4">
                    <h6>Select End Date:</h6>
                    <b-input v-model="today_date" type="date" />
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-3 col-sm-12 mb-4">
                <h6>Location:</h6>
                <multiselect
                  v-model="location_filter_data"
                  :options="options_location_filter_data"
                  :multiple="false"
                  :preserve-search="true"
                  placeholder="Select Location"
                  label="name"
                  track-by="name"
                  open-direction="bottom"
                  :preselect-first="true"
                >
                </multiselect>
              </div>
              <div class="col-lg-3 col-sm-12 mb-4">
                <h6>Select age:</h6>
                <multiselect
                  v-model="select_age_filter_data"
                  :options="options_age_filter_data"
                  :multiple="false"
                  :preserve-search="true"
                  placeholder="Select age"
                  label="name"
                  track-by="name"
                  open-direction="bottom"
                  :preselect-first="false"
                >
                </multiselect>
              </div>
              <div class="col-lg-6 col-sm-12">
                <h6>Select Activities:</h6>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="checkbox_filter_data"
                    :options="checkbox_filter_data_options"
                    switches
                    size="lg"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-2 col-sm-12 ml-auto">
                <b-button
                  variant="custom"
                  block
                  class="mb-4"
                  @click="filterDataTable"
                  >Submit</b-button
                >
              </div>
            </div>
            <div class="row pr-4 mt-5">
              <small class="ml-auto"
                ><a href=""
                  ><i class="fas fa-file-export mr-1"></i>Export Now</a
                ></small
              >
            </div>
          </div>
        </div>
      </div>
      <!-- Filter data Section -->

      <!--Summary Epidemiological Table -->
      <div class="row mt-4 text-center">
        <div class="col-12">
          <div class="card shadow">
            <h3 class="mb-4">
              5.1 Changes in disease considering two cross-sections
            </h3>
            <div class="row justify-content-center">
              <div class="col-lg-12">
                <b-table
                  id="epidemiological-table"
                  show-empty
                  :items="crosssectionsItems"
                  :fields="crosssectionsData"
                  responsive
                  hover
                  bordered
                >
                </b-table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Summary Epidemiological Table -->
      <!--Summary Epidemiological Bargraph -->
      <div class="row mt-4 text-center">
        <div class="col-12">
          <div class="card shadow">
            <h3 class="mb-4">5.2.1 Effect size for changes in oral health</h3>
            <div class="row justify-content-center">
              <div class="col-lg-12">
                <Visualization :tag="effectsizeoralhealthdata"></Visualization>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--Summary Epidemiological Bargraph -->

      <!--Summary Screeing Table -->
      <div class="row mt-4 text-center">
        <div class="col-12">
          <div class="card shadow">
            <h3 class="mb-4">5.2.2 Effect size for changes in oral health</h3>
            <div class="row justify-content-center">
              <div class="col-lg-12">
                <h4 class="mb-4">Basic Data</h4>
                <b-table
                  id="epidemiological-table"
                  show-empty
                  :items="oralhealthItems"
                  :fields="oralhealthData"
                  responsive
                  hover
                  bordered
                >
                </b-table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Summary Screening Table -->
      <!-- Form validation Error message for 5 Filter Table Data  -->
      <b-toast
        id="error-research"
        variant="warning"
        solid
        append-toast
        toaster="b-toaster-bottom-full"
      >
        <div slot="toast-title" class="d-flex flex-grow-1 align-items-baseline">
          <strong class="mr-auto">Required error</strong>
        </div>
        <div v-if="errors.location_filter_data">
          <p>{{ errors.location_filter_data }}</p>
        </div>
        <div v-if="errors.select_age_filter_data">
          <p>{{ errors.select_age_filter_data }}</p>
        </div>

        <div v-if="errors.checkbox_filter_data">
          <p>{{ errors.checkbox_filter_data }}</p>
        </div>
      </b-toast>
      <!-- Form validation Error message for 5 Filter Table Data  -->
    </div>
    <app-footer></app-footer>
  </div>
</template>


<script>
import { mapState, mapActions } from "vuex";
import Visualization from "./Visualization";
import AppHeader from "./Header.vue";
import Appfooter from "./Footer.vue";
// import userChart from "../js/userchart.js";
// import locationChart from "../js/locationchart.js";
// import years from "../js/year_array.js";

// const axios = require('axios');
export default {
  name: "screening",
  components: {
    // "AuthenticationForm": AuthenticationForm
    "app-header": AppHeader,
    Visualization: Visualization,
    "app-footer": Appfooter,
  },

  data() {
    return {
      start_date: "",
      today_date: "",
      effectsizeoralhealthdata: "effectsizeoralhealthdata",
      errors: [],
      location_filter_data: [],
      select_age_filter_data: [],
      options_location_filter_data: [
        { name: "CDC", language: null },
        { name: "MDC", language: null},
      ],
      options_age_filter_data: [
        { name: "Child < 13Y", language: null },
        { name: "Child ≥13Y", language: null },
        { name: "3Y", language: null },
        { name: "6Y", language: null },
        { name: "12Y", language: null },
        { name: "15Y", language: null },
      ],
      checkbox_filter_data: [],
      checkbox_filter_data_options: [
        {
          text: "All",
          value: "all",
        },
        {
          text: "RoutineClinic",
          value: "routine",
        },
        {
          text: "School",
          value: "school",
        },
        {
          text: "Other",
          value: "other",
        },
      ],
      crosssectionsData: [
        { key: "type", label: "", tdClass: "font-weight-bold text-left" },
        { key: "timewindowone", label: "Time Window 1" },
        { key: "timewindowtwo", label: "Time Window 2" },
        { key: "realdifference", label: "Real difference" },
        { key: "effectsizevalue", label: "Effect size value" },
        { key: "effectsizedescription", label: "Effect size description" },
        { key: "p_value", label: "P-value for related samples" },
      ],
      crosssectionsItems: [
        {
          type: "Referred to clinic",
          timewindowone: "",
          timewindowtwo: "",
          realdifference: "",
          effectsizevalue: "",
          effectsizedescription: "",
          p_value: "3",
        },
        {
          type: "Number of decayed primary teeth",
          timewindowone: "",
          timewindowtwo: "",
          realdifference: "",
          effectsizevalue: "",
          effectsizedescription: "",
          p_value: "3",
        },
        {
          type: "Number of decayed permanent teeth",
          timewindowone: "",
          timewindowtwo: "",
          realdifference: "",
          effectsizevalue: "",
          effectsizedescription: "",
          p_value: "3",
        },
        {
          type: "Mean symptoms score/16",
          timewindowone: "",
          timewindowtwo: "",
          realdifference: "",
          effectsizevalue: "",
          effectsizedescription: "",
          p_value: "3",
        },
        {
          type: "cavity permanent molar or premolar",
          timewindowone: "",
          timewindowtwo: "",
          realdifference: "",
          effectsizevalue: "",
          effectsizedescription: "",
          p_value: "3",
        },
        {
          type: "cavity permanent anterior",
          timewindowone: "",
          timewindowtwo: "",
          realdifference: "",
          effectsizevalue: "",
          effectsizedescription: "",
          p_value: "3",
        },
        {
          type: "cavity permanent anterior",
          timewindowone: "",
          timewindowtwo: "",
          realdifference: "",
          effectsizevalue: "",
          effectsizedescription: "",
          p_value: "3",
        },
        {
          type: "Active infection",
          timewindowone: "",
          timewindowtwo: "",
          realdifference: "",
          effectsizevalue: "",
          effectsizedescription: "",
          p_value: "3",
        },
        {
          type: "Presence of oral symptoms",
          timewindowone: "",
          timewindowtwo: "",
          realdifference: "",
          effectsizevalue: "",
          effectsizedescription: "",
          p_value: "3",
        },
        {
          type: "Totals",
          timewindowone: "",
          timewindowtwo: "",
          realdifference: "",
          effectsizevalue: "",
          effectsizedescription: "",
          p_value: "3",
        },
      ],
      oralhealthData: [
        // { key: "type", label: "", tdClass: "font-weight-bold text-left" },
        { key: "reffered_to_clinic", label: "Referred to clinic" },
        {
          key: "decayedprimaryteeth",
          label: "Number of decayed primary teeth",
        },
        {
          key: "decayed_permanet_teeth",
          label: "Number of decayed permanent teeth",
        },
        { key: "mean_symptom", label: "Mean symptoms score/16" },
        { key: "activeinfection", label: "Cavity permanent anterior" },
        { key: "active_infection", label: "Active infection" },
        { key: "oral_symptoms", label: "Presence of Oral symptoms" },
      ],
      oralhealthItems: [
        {
          type: "",
          totalscreening: "20,000",
          anycaries: "",
          cavityfreepd: "",
          symptomfree: "",
          activeinfection: "",
        },
      ],
    };
  },

  computed: {
    ...mapState([
      "errormessage",
      "successmessage",
      "message",
      "returndate_obj",
      "treatment_tablebasicdata_obj",
      "treatmentstrategicdata_obj",
      "geography",
      "activities_obj",
    ]),
  },

  methods: {
    ...mapActions([
      "listReturnDate",
      "listTreatmentStrategicData",
      "listTreatmentTableBasicData",
      "listGeography",
      "listActivitie",
    ]),
    filterDataTable() {
      this.errors = [];
      if (this.location_filter_data == "") {
        this.errors["location_filter_data"] =
          "Selection of location is required.";
        this.$bvToast.show("error-research");
      }

      if (this.select_age_filter_data == "") {
        this.errors["select_age_filter_data"] = "Selection of Age is required.";
        this.$bvToast.show("error-research");
      }

      if (this.checkbox_filter_data == "") {
        this.errors["checkbox_filter_data"] =
          "Selection of Activities is required.";
        this.$bvToast.show("error-research");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../css/encounter.scss";
</style>
