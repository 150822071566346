<template>
  <b-modal
    id="edit-patient-modal"
    size="lg"
    header-text-size="large"
    title="Edit Patient"
    hide-footer
  >
    <form @submit.prevent="editPatient(id)" method="post">
      <div class="name-field">
        <div class="first-name">
          <div class="floating-label">
            <input
              type="text"
              class="is-invalid"
              placeholder="First Name"
              v-model="patientFormData.first_name"
              id="first-name"
            />
            <span v-if="errors.first_name" class="form-error">
              {{ errors.first_name }}
            </span>
            <label for="first-name">First Name</label>
            <div class="text-danger"></div>
          </div>
        </div>
        <div class="mid-name">
          <div class="floating-label">
            <input
              type="text"
              placeholder="Middle Name"
              v-model="patientFormData.middle_name"
              name
              id="mid-name"
            />
            <label for="mid-name">Middle Name</label>
          </div>
        </div>
        <div class="last-name">
          <div class="floating-label">
            <input
              type="text"
              name
              class="is-invalid"
              placeholder="Last Name"
              v-model="patientFormData.last_name"
              id="last-name"
            />
            <span v-if="errors.last_name" class="form-error">
              {{ errors.last_name }}
            </span>
            <label for="last-name">Last Name</label>
            <div class="text-danger"></div>
          </div>
        </div>
        <div class="invalid-feedback">Please enter the name</div>
      </div>
      <div class="name-field">
        <div class="first-name">
          <div class="floating-label">
            <input
              type="text"
              class="is-invalid"
              placeholder="First Name Khmer"
              v-model="patientFormData.first_name_khmer"
              id="first-name-khmer"
            />

            <label for="first-name-khmer">First Name Khmer</label>
            <div class="text-danger"></div>
          </div>
        </div>
        <div class="mid-name">
          <div class="floating-label">
            <input
              type="text"
              placeholder="Mid Name Khmer"
              v-model="patientFormData.middle_name_khmer"
              name
              id="mid-name-khmer"
            />
            <label for="mid-name-khmer">Mid Name Khmer</label>
          </div>
        </div>
        <div class="last-name">
          <div class="floating-label">
            <input
              type="text"
              name
              class="is-invalid"
              placeholder="Last Name Khmer"
              v-model="patientFormData.last_name_khmer"
              id="last-name-khmer"
            />
            <label for="last-name-khmer">Last Name Khmer</label>
            <div class="text-danger"></div>
          </div>
        </div>
      </div>
      <div class="gender-field">
        <label>Gender</label>
        <div class="gender-inputs">
          <div class="male-gender">
            <label for="male-gender">Male</label>
            <b-form-radio
              v-model="patientFormData.gender"
              value="Male"
              id="male-gender"
            ></b-form-radio>
          </div>
          <div class="female-gender">
            <label for="female-gender">Female</label>
            <b-form-radio
              v-model="patientFormData.gender"
              value="Female"
              id="female-gender"
            ></b-form-radio>
          </div>
          <div class="female-gender">
            <label for="female-gender">Other</label>
            <b-form-radio
              v-model="patientFormData.gender"
              value="Other"
              id="other-gender"
            ></b-form-radio>
          </div>
        </div>
      </div>
      <div class="dob-field">
        <span>Date of Birth</span>
        <div class="dob-wrapper">
          <div class="month">
            <div class="select">
              <select v-model="dob.month" @change="selected">
                <option value disabled selected>Month</option>
                <option
                  v-for="(month, index) in months"
                  :key="index"
                  :value="index + 1"
                >
                  {{ month.month }}
                </option>
              </select>
            </div>
            <span class="form-error" v-if="errors.dob_month">{{
              errors.dob_month
            }}</span>
          </div>
          <div class="day">
            <div class="select">
              <select v-model="dob.day" @change="minTwoDigits(dob.day)">
                <option value disabled selected>Day</option>
                <option
                  v-for="day in daysInMonth(monthIndex, dob.year)"
                  :key="day.id"
                  :value="day"
                >
                  {{ day }}
                </option>
              </select>
            </div>
            <span class="form-error" v-if="errors.dob_day">{{
              errors.dob_day
            }}</span>
          </div>

          <div class="year">
            <div class="select">
              <select v-model="dob.year">
                <option value disabled selected>Year</option>
                <option v-for="year in years" :key="year.id" :value="year">
                  {{ year }}
                </option>
              </select>
            </div>
            <span class="form-error" v-if="errors.dob_year">{{
              errors.dob_year
            }}</span>
          </div>
        </div>
      </div>
      <div class="row align-items-center justify-content-center my-4">
        <div class="col-12 col-lg-6 pr-0">
          <div class="phone-number">
            <div class="floating-label">
              <input
                type="text"
                id="phone-number"
                class="phone-number"
                placeholder="Phone Number"
                name
                v-model="patientFormData.phone"
              />
              <span v-if="errors.phone" class="form-error">
                {{ errors.phone }}
              </span>
              <label for="phone-number">Phone Number</label>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-6">
          <div class="provience">
            <div class="select">
              <div class="floating-label">
                <select v-model="provinceData" id="provience">
                  <option
                    v-for="(province, index) in filteredProvinceList"
                    :key="index"
                    :value="province.id"
                  >
                    {{ province.name }}
                  </option>
                </select>
                <label for="provience">Provience</label>
              </div>
              <span v-if="errors.provinceError" class="form-error">
                {{ errors.provinceError }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="row align-items-center justify-content-center my-4">
        <div class="col-12 col-lg-6 pr-0">
          <div class="select">
            <div class="floating-label">
              <select
                v-model="districtData"
                :disabled="disabledDistrict"
                id="district"
              >
                <option value disabled>Select District</option>
                <option
                  v-for="(district, index) in filterDistrict"
                  :key="index"
                  :value="district.id"
                >
                  {{ district.name }}
                </option>
              </select>
              <label alt="district">District</label>
            </div>
            <span v-if="errors.district" class="form-error">
              {{ errors.district }}
            </span>
          </div>
        </div>
        <div class="col-12 col-lg-6">
          <div class="community">
            <div class="select">
              <div class="floating-label">
                <select
                  v-model="communeData"
                  :disabled="disabledCommune"
                  id="commune"
                >
                  <option value disabled>Select Commune</option>
                  <option
                    v-for="(commune, index) in filterCommune"
                    :key="index"
                    :value="commune.id"
                  >
                    {{ commune.name }}
                  </option>
                </select>
                <label for="commune">Commune</label>
              </div>
            </div>
            <span v-if="errors.communeError" class="form-error">
              {{ errors.communeError }}
            </span>
          </div>
        </div>
      </div>
      <div class="row align-items-center justify-content-center my-4">
        <div class="col-12 col-lg-6 pr-0">
          <div class="phone-number">
            <div class="floating-label">
              <input
                type="text"
                id="village"
                class="phone-number"
                placeholder="Enter Your Village"
                name
                v-model="patientFormData.village"
              />
              <label for="village">Enter Your Village</label>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-6">
          <div class="community">
            <div class="select">
              <div class="floating-label">
                <select
                  v-model="schoolData"
                  :disabled="disabledSchool"
                  id="school"
                  @change="selectedSchool(schoolData)"
                >
                  <option value disabled>Select School</option>
                  <option
                    v-for="(school, index) in filterSchool"
                    :key="index"
                    :value="school.id"
                  >
                    {{ school.name }}
                  </option>
                </select>
                <label for="school">Select School</label>
              </div>
            </div>
          </div>
          <span v-if="errors.schoolError" class="form-error">
            {{ errors.schoolError }}
          </span>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="community">
            <div class="select">
              <div class="floating-label">
                <select v-model="patientFormData.grade">
                  <option value selected disabled>Select Grade</option>
                  <option value="KG">KG</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="PPS">PPS</option>
                </select>
                <label for="school">Select Grade</label>
              </div>
            </div>
          </div>
          <span v-if="errors.gradeError" class="form-error">
            {{ errors.gradeError }}
          </span>
        </div>
      </div>
      <div class="save-button">
        <b-button type="submit" variant="success">Update Patient</b-button>
      </div>
      <span v-if="success" class="form-success">{{ success }}</span>
    </form>
  </b-modal>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import moment from "moment";

export default {
  name: "ModalPatient",
  props: ["patientFormData"],
  data() {
    return {
      disabledDistrict: false,
      disabledCommune: false,
      first_name: "",
      middle_name: "",
      last_name: "",
      first_name_khmer: "",
      middle_name_khmer: "",
      last_name_khmer: "",
      monthIndex: "",
      gender: "",
      months: [
        {
          month: "January",
          index: 1,
        },
        {
          month: "February",
          index: 2,
        },
        {
          month: "March",
          index: 3,
        },
        {
          month: "April",
          index: 4,
        },
        {
          month: "May",
          index: 5,
        },
        {
          month: "June",
          index: 6,
        },
        {
          month: "July",
          index: 7,
        },
        {
          month: "August",
          index: 8,
        },
        {
          month: "September",
          index: 9,
        },
        {
          month: "October",
          index: 10,
        },
        {
          month: "November",
          index: 11,
        },
        {
          month: "December",
          index: 12,
        },
      ],
      dob: {
        day: "",
        month: "",
        year: "",
      },
      formattedDay: "",
      phone: "",
      districtData: "",
      provinceData: "",
      schoolData: "",
      communeData: "",
      filteredProvince: "",
      selectedDistrictData: [],
      selectedSchoolData: [],
      education: "",
      disabledSchool: false,
      latitude: "20.32",
      longitude: "34.23",
      errors: [],
      success: "",
      id: this.$route.params.id,
      activity_id: "",
      clinic_id: "",
      location_item: JSON.parse(localStorage.getItem("location_item")),
      activity_item: JSON.parse(localStorage.getItem("activity_item")),
    };
  },
  computed: {
    ...mapState([
      "successmessage",
      "alladdresses",
      "schools",
      "profile",
      "errormessage",
      "message",
      "province",
      "clinic",
      "activity",
    ]),

    years() {
      const year = new Date().getFullYear();
      return Array.from(
        {
          length: 110,
        },
        (value, index) => year - index
      );
    },
    filteredProvinceList: function() {
      // console.log(this.provinceData);
      let filteredProvinceList = [];
      this.province.forEach((element) => {
        if (this.location_item.location != "MDC") {
          this.location_item.province.forEach((item) => {
            if (element.id == item) {
              filteredProvinceList.push(element);
              this.provinceData = element.id;
              this.provinceData = this.patientFormData.province;
            }
          });
        } else {
          this.location_item.province.forEach((item) => {
            if (element.id == item) {
              filteredProvinceList.push(element);
              this.provinceData = element.id;
              this.provinceData = this.patientFormData.province;
            }
          });
        }
      });
      return filteredProvinceList;
    },
    filterDistrict: function() {
      let filteredDistrictList = [];
      if (this.activity_item.schoolId == "") {
        this.alladdresses.forEach((element) => {
          if (element.id == this.provinceData) {
            filteredDistrictList = element.districts;
            this.selectedDistrictData = element.districts;
          }
        });
        return filteredDistrictList;
      } else {
        this.schools.forEach((element) => {
          if (this.activity_item.schoolId == element.id) {
            this.alladdresses.forEach((item) => {
              if (item.id == this.provinceData) {
                item.districts.forEach((district) => {
                  if (district.id == element.district) {
                    filteredDistrictList.push(district);
                    this.districtData = district.id;
                  }
                });
              }
            });
          }
        });
        return filteredDistrictList;
      }
    },
    filterCommune: function() {
      let filteredCommuneList = [];
      if (this.activity_item.schoolId == "") {
        this.selectedDistrictData.forEach((element) => {
          if (element.id == this.districtData) {
            filteredCommuneList = element.communes;
          }
        });
        return filteredCommuneList;
      } else {
        this.schools.forEach((element) => {
          if (this.activity_item.schoolId == element.id) {
            this.alladdresses.forEach((item) => {
              if (item.id == this.provinceData) {
                item.districts.forEach((district) => {
                  if (district.id == element.district) {
                    district.communes.forEach((commune) => {
                      if (commune.id == element.commune) {
                        filteredCommuneList.push(commune);
                        this.communeData = commune.id;
                      }
                    });
                  }
                });
              }
            });
          }
        });
        return filteredCommuneList;
      }
    },
    filterSchool: function() {
      let selectedSchool = [];
      if (this.location_item.location != "MDC") {
        this.schools.forEach((element) => {
          if (this.activity_item.schoolId == "") {
            if (
              element.school_type == "target" &&
              element.province == this.provinceData
            ) {
              selectedSchool.push(element);
              this.schoolData = this.patientFormData.school;
              // this.village = element.village;
              // this.phone = element.phone;
            }
          } else if (this.activity_item.schoolId == element.id) {
            selectedSchool.push(element);
            this.schoolData = element.id;
            this.patientFormData.village = element.village;
            this.patientFormData.phone = element.phone;
            this.schoolData = this.patientFormData.school;
          }
        });
      } else {
        this.schools.forEach((element) => {
          if (this.activity_item.schoolId == "") {
            this.location_item.province.forEach((province) => {
              if (
                element.school_type != "target" &&
                element.province == province
              ) {
                selectedSchool.push(element);
                this.schoolData = this.patientFormData.school;
              }
            });
          } else if (this.activity_item.schoolId == element.id) {
            this.location_item.province.forEach((province) => {
              if (
                element.school_type != "target" &&
                element.province == province
              ) {
                selectedSchool.push(element);
                // selectedSchool.push(element);
                // this.schoolData = element.id;
                this.patientFormData.village = element.village;
                this.patientFormData.phone = element.phone;
                this.schoolData = this.patientFormData.school;
              }
            });
          }
        });
      }

      return selectedSchool;
    },
  },
  created() {
    this.listAllAddresses();
    this.listSchools();
    this.listProfile();
    this.listProvince();
    this.listActivity();
    this.listClinic();
    this.patientFormData.province
      ? (this.selectedProvinceData = this.province.find(
          (evt) => evt.id == this.patientFormData.province
        ))
      : "";
  },
  // beforeUpdate() {
  // },
  watch: {
    schools: function() {
      this.districtData = this.patientFormData.district;

      this.communeData = this.patientFormData.commune;

      //calculate day
      let day = this.patientFormData.dob.split("-")[2];
      if (day > 9) {
        this.dob.day = day;
      } else if (day < 10) {
        this.dob.day = day.slice(1);
      }
      //calculate month
      let month = this.patientFormData.dob.split("-")[1];
      if (month > 9) {
        this.dob.month = month;
      } else if (month < 10) {
        this.dob.month = month.slice(1);
      }
      //calculate year
      this.dob.year = this.patientFormData.dob.split("-")[0];
    },
  },
  methods: {
    ...mapActions([
      "listAllAddresses",
      "listSchools",
      "listProfile",
      "listProvince",
      "listActivity",
      "listClinic",
    ]),
    ...mapMutations([
      "setAllAddress",
      "setSchool",
      "setProfile",
      "setProvince",
    ]),
    selected() {
      let monthIndex = this.months.indexOf(this.dob.month) + 1;
      if (monthIndex.toString().length < 2) {
        this.monthIndex = "0" + monthIndex;
      }
    },
    selectedSchool(schoolData) {
      this.schools.forEach((element) => {
        if (element.id == schoolData) {
          // console.log(element);
          this.patientFormData.village = element.village;
          this.districtData = element.district;
          this.communeData = element.commune;
          this.provinceData = element.province;
          this.patientFormData.phone = element.phone;
        }
      });
    },

    minTwoDigits(value) {
      if (value.toString().length < 2) {
        this.formattedDay = "0" + value;
      } else {
        this.formattedDay = value;
      }
    },

    daysInMonth(month, year) {
      return new Date(year, month, 0).getDate();
    },

    editPatient() {
      // console.log(this.patientFormData);
      let activities = JSON.parse(window.localStorage.getItem("activity_item"));
      this.activity.forEach((element) => {
        if (element.name == activities.activity) {
          this.activity_id = element.id;
        }
      });
      this.clinic.forEach((element) => {
        if (element.name == activities.location) {
          this.clinic_id = element.id;
        }
      });
      var date = moment().toDate();
      var currentDate = moment(date).format("YYYY-MM-DD");
      this.errors = [];
      if (this.patientFormData.first_name == "") {
        this.errors["first_name"] = "First name is required.";
      } else if (this.patientFormData.last_name == "") {
        this.errors["last_name"] = "Last name is required.";
      } else if (this.patientFormData.gender == "") {
        this.errors["gender"] = "Select Gender.";
      } else if (this.dob.month == "") {
        this.errors["dob_month"] = "Select month.";
      } else if (this.dob.day == "") {
        this.errors["dob_day"] = "Select day.";
      } else if (this.dob.year == "") {
        this.errors["dob_year"] = "Select year.";
      } else if (this.patientFormData.phone == "") {
        this.errors["phone"] = "Phone Number is rquired.";
      } else if (this.provinceData == "") {
        this.errors["province"] = "Province is required.";
      } else if (this.districtData == "") {
        this.errors["district"] = "District is required.";
      } else if (this.communeData == "") {
        this.errors["communeError"] = "Select Commune";
      } else if (this.schoolData == "") {
        this.errors["schoolError"] = "Select School";
      } else if (this.patientFormData.grade == "") {
        this.errors["gradeError"] = "Select Grade";
      } else {
        let patientEditObject = {
          activity: this.activity_id,
          clinic: this.clinic_id,
          first_name: this.patientFormData.first_name,
          middle_name: this.patientFormData.middle_name,
          last_name: this.patientFormData.last_name,
          first_name_khmer: this.patientFormData.first_name_khmer,
          middle_name_khmer: this.patientFormData.middle_name_khmer,
          last_name_khmer: this.patientFormData.last_name_khmer,
          gender: this.patientFormData.gender,
          dob: this.dob.year + "-" + this.dob.month + "-" + this.dob.day,
          phone: this.patientFormData.phone,
          grade: this.patientFormData.grade,
          education: this.patientFormData.education,
          school: this.schoolData,
          province: this.provinceData,
          district: this.districtData,
          commune: this.communeData,
          geography: window.localStorage.getItem("location_item"),
          created_at: currentDate,
          area: "",
          recall_time: this.patientFormData.recall_time,
          recall_date: this.patientFormData.recall_date,
          latitude: this.patientFormData.latitude,
          longitude: this.patientFormData.longitude,
          updated_by: this.profile.id,
          updated_at: this.patientFormData.updated_at,
          other_school: "school",
          village: this.patientFormData.village,
          status: "Active",
          id: this.id,
        };
        // console.log(patientEditObject);
        this.$store.dispatch("updatePatient", patientEditObject).then(() => {
          if (this.successmessage == "success") {
            this.success = "Patient Updated successfully.";
            setTimeout(function() {
              location.reload();
            }, 1500);
          } else if (this.errormessage == "errormessage") {
            this.errors["editPatientError"] = "Failed to update Patient";
            //console.log(JSON.stringify(this.message));
          }
        });
      }
    },
  },
};
</script>

<style lang="scss">
.form-error {
  color: #e23f3f;
}

.form-success {
  color: #1fd01f;
}
.phone-number {
  -moz-appearance: textfield;
  &input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
</style>
<style lang="scss" scoped>
@import "src/css/main.scss";
</style>
