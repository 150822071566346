<template>
<div id="app">

  <div class="contents">
    <div class="row mb-4">


      <div class="col-md-4 col-12 pt-4 d-flex align-content-center flex-wrap">
        <LoginForm></LoginForm>
      </div>


      <div class="col-md-8 col-12">
        <div class="row mt-4" data-aos="fade-left" data-aos-duration="3200">
          <div class="col-12">
            <div class="card shadow static-card">
              <h1 class="mb-4">Privacy Policy of Dental Hub BLCP</h1>
              <p>
                <a href="#" target="_blank">Dental Hub BLCP</a> and <a href="https://abhiyantrik.com">Abhiyantrik Technology Pvt. Ltd.</a> built the Dental Hub app as
                an Open Source app. This SERVICE is provided by
                Abhiyantrik Technology Pvt. Ltd. at no cost and is intended for
                use as is.
              </p>
              <p>
                This page is used to inform visitors regarding
                our policies with the collection, use, and
                disclosure of Personal Information if anyone decided to use
                our Service.
              </p>
              <p>
                If you choose to use our Service, then you agree
                to the collection and use of information in relation to this
                policy. The Personal Information that we collect is
                used for providing and improving the Service.
                We will not use or share your
                information with anyone except as described in this Privacy
                Policy.
              </p>
              <p>
                The terms used in this Privacy Policy have the same meanings
                as in our Terms and Conditions, which is accessible at
                Dental Hub unless otherwise defined in this Privacy
                Policy.
              </p>
              <h5>Information Collection and Use</h5>
              <p>
                For a better experience, while using our Service,
                we may require you to provide us with certain
                personally identifiable information, including but not limited to Name, Age, Phone Number, Date of Birth, Location. The
                information that we request will be
                retained by us and used as described in this privacy policy.
              </p>
              <p>
                The app does use third party services that may collect
                information used to identify you.
              </p>
              <div>
                <p>
                  Link to privacy policy of third party service providers
                  used by the app
                </p>
                <ul>
                  <li><a href="https://www.google.com/policies/privacy/" target="_blank">Google Play Services</a></li>
                  <li><a href="https://firebase.google.com/policies/analytics" target="_blank">Firebase Analytics</a></li>
                </ul>
              </div>
              <h5>Log Data</h5>
              <p>
                We want to inform you that whenever
                you use our Service, in a case of an error in the
                app we collect data and information (through third
                party products) on your phone called Log Data. This Log Data
                may include information such as your device Internet
                Protocol (“IP”) address, device name, operating system
                version, the configuration of the app when utilizing
                our Service, the time and date of your use of the
                Service, and other statistics.
              </p>
              <h5>Cookies</h5>
              <p>
                Cookies are files with a small amount of data that are
                commonly used as anonymous unique identifiers. These are
                sent to your browser from the websites that you visit and
                are stored on your device's internal memory.
              </p>
              <p>
                This Service does not use these “cookies” explicitly.
                However, the app may use third party code and libraries that
                use “cookies” to collect information and improve their
                services. You have the option to either accept or refuse
                these cookies and know when a cookie is being sent to your
                device. If you choose to refuse our cookies, you may not be
                able to use some portions of this Service.
              </p>
              <h5>Service Providers</h5>
              <p>
                We may employ third-party companies
                and individuals due to the following reasons:
              </p>
              <ul><li>To facilitate our Service;</li> <li>To provide the Service on our behalf;</li> <li>To perform Service-related services; or</li> <li>To assist us in analyzing how our Service is used.</li></ul>
              <p>
                We want to inform users of this
                Service that these third parties have access to your
                Personal Information. The reason is to perform the tasks
                assigned to them on our behalf. However, they are obligated
                not to disclose or use the information for any other
                purpose.
              </p>
              <h5>Security</h5>
              <p>
                We value your trust in providing us
                your Personal Information, thus we are striving to use
                commercially acceptable means of protecting it. But remember
                that no method of transmission over the internet, or method
                of electronic storage is 100% secure and reliable, and
                we cannot guarantee its absolute security.
              </p>
              <h5>Links to Other Sites</h5>
              <p>
                This Service may contain links to other sites. If you click
                on a third-party link, you will be directed to that site.
                Note that these external sites are not operated by
                us. Therefore, we strongly advise you to
                review the Privacy Policy of these websites.
                We have no control over and assume no
                responsibility for the content, privacy policies, or
                practices of any third-party sites or services.
              </p>
              <h5>Children’s Privacy</h5>
              <p>
                The Dental Hub application and Web Dashboards are not intended for use by children under 13 years old. Therefore we do not knowingly collect personally identifiable information from users under age 13. In the case we discover that a child under 13 has logged in to Dental Hub and provided us with personal user information, we immediately delete this from our servers. If you are a parent or guardian and you are aware that your child has provided us with personal information by logging in to the Dental Hub application or Web Dashboard, please contact us so that we will be able to do necessary actions. Please note that while we do not store user data on children under 13, adult users of the app see patients of any age, collect all relevant patient data, and enter it into the Dental Hub where it is stored in our database for health record keeping purposes.
              </p>
              <h5>Changes to This Privacy Policy</h5>
              <p>
                We may update our Privacy Policy from
                time to time. Thus, you are advised to review this page
                periodically for any changes. We will
                notify you of any changes by posting the new Privacy Policy
                on this page. These changes are effective immediately after
                they are posted on this page.
              </p>
              <h5>Contact Us</h5>
              <p>
                If you have any questions or suggestions about
                our Privacy Policy, do not hesitate to contact
                us at <a href="mailto:info@abhiyantrik.com">info@abhiyantrik.com</a>.
              </p>
              <p>
                This privacy policy page is created by
                <a href="https://abhiyantrik.com/" target="_blank">Abhiyantrik Technology</a> and is effective from 20th of August 2019.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { mapState,mapMutations } from 'vuex';
import LoginForm from './LoginForm';

const axios = require('axios');
export default {
  name: "PrivacyPolicy",
  components:{
    'LoginForm': LoginForm,
  },

  data() {
    return {

    }
  }
};
</script>

<style lang="scss" scoped>
  @import "../css/loginpage.scss";

</style>
