export default {
  //For Tokenization
  setToken(state, token) {
    state.token = token;
  },

  setProfile(state, profile) {
    state.profile = profile;
  },

  setLocationItem(state, location_item) {
    state.location_item = location_item;
  },

  setUsers(state, users) {
    state.users = users;
  },

  setEncounterId(state, encounterId) {
    state.encounterId = encounterId;
  },

  setMessage(state, message) {
    state.message = message;
  },

  setSuccessMessage(state, successmessage) {
    state.successmessage = successmessage;
  },

  setDeleteMessage(state, deletemessage) {
    state.deletemessage = deletemessage;
  },
  setBulkUploadResponse(state, bulk_upload_response) {
    state.bulk_upload_response = bulk_upload_response;
  },

  setErrorMessage(state, errormessage) {
    state.errormessage = errormessage;
  },

  //For loginvisualization Page
  setLoginVisualization(state, loginvisualization) {
    state.loginvisualization = loginvisualization;
  },
  setLoginVisualization1(state, loginvisualization1) {
    state.loginvisualization1 = loginvisualization1;
  },

  //For Home page graph and Table
  setHomePageBarGraph(state, overviewbargraph_obj) {
    state.overviewbargraph_obj = overviewbargraph_obj;
  },

  setHomeTable(state, home_table_obj) {
    state.home_table_obj = home_table_obj;
  },

  //For Treatment by Type page graph and Table
  setTreatmentByTypeBarGraph(state, treatment_by_type_obj) {
    state.treatment_by_type_obj = treatment_by_type_obj;
  },

  //For Raw Treatment Basic graph and Table
  setTreatmentByTreatmentBasic(state, treatment_by_basic_obj) {
    state.treatment_by_basic_obj = treatment_by_basic_obj;
  },

  //For Raw Treatment Treatment graph and Table
  setTreatmentByTreatmentTreatment(state, treatment_by_treatment_obj) {
    state.treatment_by_treatment_obj = treatment_by_treatment_obj;
  },

  setScreeningAchievementTargetBarGraph(
    state,
    screening_achievement_vs_target_obj
  ) {
    state.screening_achievement_vs_target_obj = screening_achievement_vs_target_obj;
  },

  // setScreeningAchievementTargetTable(state, screening_achievement_obj) {
  //   state.screening_achievement_obj = screening_achievement_obj;
  // },

  setTreatmentProgressScreenedGraph(state, treatment_progress_screened_obj) {
    state.treatment_progress_screened_obj = treatment_progress_screened_obj;
  },

  // setTreatmentProgressScreenTable(state, treatment_progress_screen_table_obj) {
  //   state.treatment_progress_screen_table_obj = treatment_progress_screen_table_obj;
  // },

  setAllLocation(state, all_location_obj) {
    state.all_location_obj = all_location_obj;
  },

  // setProgressOfReferral(state, progress_obj) {
  //   state.progress_obj = progress_obj;
  // },

  // 2.6 Raw Treatment data by Location object
  setTreatmentLocationBasicTable(state, treatment_location_basic_obj) {
    state.treatment_location_basic_obj = treatment_location_basic_obj;
  },

  setTreatmentLocationTreatmentTable(state, treatment_location_treatment_obj) {
    state.treatment_location_treatment_obj = treatment_location_treatment_obj;
  },

  setCsiTable(state, combodia_smile_intervention) {
    state.combodia_smile_intervention = combodia_smile_intervention;
  },

  setCsiBasic(state, csi_basic_obj) {
    state.csi_basic_obj = csi_basic_obj;
  },

  setOheTableBasic(state, ohe_table_basic_obj) {
    state.ohe_table_basic_obj = ohe_table_basic_obj;
  },

  setCsiListData(state, csi_list_obj) {
    state.csi_list_obj = csi_list_obj;
  },

  //CombodiaSmileIntervention
  setCsiGraph(state, csi_obj) {
    state.csi_obj = csi_obj;
  },

  setOheGraph(state, ohe_obj) {
    state.ohe_obj = ohe_obj;
  },

  setHistoryScreening(state, history_screening_obj) {
    state.history_screening_obj = history_screening_obj;
  },

  // //3.1.1 Summary of Epideomiological Data
  // setEpidemiologicalSumGraph(state, epidemiological_sum_obj) {
  //   state.epidemiological_sum_obj = epidemiological_sum_obj;
  // },

  // setEarlyIntervention(state, earlyintervention_obj) {
  //   state.earlyintervention_obj = earlyintervention_obj;
  // },

  // //4.1.1 Care patterns
  // setCarePatterns(state, carepattern_obj) {
  //   state.carepattern_obj = carepattern_obj;
  // },

  // setPreventiveSurgical(state, preventive_obj) {
  //   state.preventive_obj = preventive_obj;
  // },

  // setInterventionExtraction(state, intervention_obj) {
  //   state.intervention_obj = intervention_obj;
  // },

  setSetFile(state, sample_file_obj) {
    state.sample_file_obj = sample_file_obj;
  },

  //5.2.1 Effect size for changes in oral health

  setOralHealth(state, oral_health_obj) {
    state.oral_health_obj = oral_health_obj;
  },

  setDuplicateMessage(state, duplicate_message) {
    state.duplicate_message = duplicate_message;
  },

  // Clinic
  setLocation(state, locations) {
    state.locations = locations;
  },

  setSchool(state, schools) {
    state.schools = schools;
  },

  setActivity(state, activity) {
    state.activity = activity;
  },

  setClinic(state, clinic) {
    state.clinic = clinic;
  },

  setActivityArea(state, activityareas) {
    state.activityareas = activityareas;
  },

  setPatient(state, patients) {
    state.patients = patients;
  },

  setSearchPatientList(state, search_patient) {
    state.search_patient = search_patient;
  },

  setRecall(state, recall_list) {
    state.recall_list = recall_list;
  },

  setEncounters(state, encounters) {
    state.encounters = encounters;
  },
  setEncounter(state, encounter) {
    state.new_encounter = encounter;
  },

  setAddress(state, addresses) {
    state.addresses = addresses;
  },
  setAllAddress(state, alladdresses) {
    state.alladdresses = alladdresses;
  },
  setProvince(state, province) {
    state.province = province;
  },
  setEncounterHistoryAndScreening(state, history_screening) {
    state.history_screening = history_screening;
  },

  setEncounterHistory(state, history) {
    state.history = history;
  },

  setEncounterScreening(state, screening) {
    state.screening = screening;
  },

  setEncounterPeriodontal(state, periodontal) {
    state.periodontal = periodontal;
  },

  setEncounterDiagnostic(state, diagnostic) {
    state.diagnostic = diagnostic;
  },

  setEncounterTreatment(state, treatment) {
    state.treatment = treatment;
  },

  setEncounterReferral(state, referral) {
    state.referral = referral;
  },

  setReturnDate(state, returndate_obj) {
    state.returndate_obj = returndate_obj;
  },

  setTeeth(state, teeth_obj) {
    state.teeth_obj = teeth_obj;
  },
  // Users
  setUsersList(state, users_obj) {
    state.users_obj = users_obj;
  },

  setRole(state, role_obj) {
    state.role_obj = role_obj;
  },

  // Print
  setPrintReferral(state, print_referral) {
    state.print_referral = print_referral;
  },

  setPrintRecall(state, print_recall) {
    state.print_recall = print_recall;
  },

  setStaticPageList(state, static_page_obj) {
    state.static_page_obj = static_page_obj;
  },
};
