<template>
  <div id="app">
    <div class="contents">
      <div class="row mb-4">
        <div class="col-md-4 col-12 d-flex align-content-center flex-wrap">
          <LoginForm></LoginForm>
        </div>

        <div class="col-md-8 col-12">
          <div class="row mt-4">
            <div
              class="col-md-4 col-12"
              data-aos="fade-down"
              data-aos-duration="3200"
            >
              <div class="card shadow">
                <p class="counter">
                  <!-- {{this.loginvisualization1.locationChart.data.treatment_this_year}} -->
                  <ICountUp
                    :delay="delay"
                    :endVal="treatment_obj"
                    :options="options"
                  />
                </p>
                <small class="counter-label"> Treatments this year </small>
              </div>
            </div>

            <div
              class="col-md-4 col-12 pl-0"
              data-aos="fade-down"
              data-aos-duration="3200"
            >
              <div class="card shadow">
                <p class="counter">
                  <ICountUp
                    :delay="delay"
                    :endVal="cavities_obj"
                    :options="options"
                  />
                </p>
                <small class="counter-label">
                  Cavities restored this year
                </small>
              </div>
            </div>

            <div
              class="col-md-4 col-12 pl-0"
              data-aos="fade-down"
              data-aos-duration="3200"
            >
              <div class="card shadow">
                <p class="counter">
                  <ICountUp
                    :delay="delay"
                    :endVal="contacted_obj"
                    :options="options"
                  />
                </p>
                <small class="counter-label">
                  Contacts this year
                </small>
              </div>
            </div>
          </div>

          <div class="row mt-4">
            <div class="col-12 text-center">
              <div
                class="card shadow"
                data-aos="fade-left"
                data-aos-duration="3200"
              >
                <div class="row justify-content-center mb-3">
                  <h1>Number of Encounters</h1>
                </div>
                <div class="row justify-content-center">
                  <div class="col-8">
                    <LoginVisualization :tag="lch"></LoginVisualization>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

import LoginForm from "./LoginForm";
import LoginVisualization from "./LoginPageVisualization";
import locationChart from "../js/locationchart.js";

// const axios = require('axios');

export default {
  name: "LoginPage",
  components: {
    LoginForm: LoginForm,
    LoginVisualization: LoginVisualization,
  },

  computed: {
    ...mapState(["loginvisualization1"]),
  },
  created() {
    this.listLoginVisualization1();
  },

  watch: {
    loginvisualization1: function() {
      if (this.loginvisualization1.locationChart) {
        this.treatment_obj = this.loginvisualization1.locationChart.data.treatment_this_year;
        this.contacted_obj = this.loginvisualization1.locationChart.data.people_contacted_this_year;
        this.cavities_obj = this.loginvisualization1.locationChart.data.cavities_restored_this_year;
        this.endVal = [
          this.loginvisualization1.locationChart.data.treatment_this_year,
          this.loginvisualization1.locationChart.data
            .cavities_restored_this_year,
          this.loginvisualization1.locationChart.data
            .people_contacted_this_year,
        ];
      }
    },
  },

  data() {
    return {
      delay: 100,
      treatment_obj: "",
      cavities_obj: "",
      contacted_obj: "",
      endVal: [],
      options: {
        useEasing: true,
        useGrouping: true,
        separator: ",",
        decimal: ".",
        prefix: "",
        suffix: "",
      },

      locationChart: locationChart,
      lch: "lch",
      type: "pie",
    };
  },
  methods: {
    ...mapActions(["listLoginVisualization1"]),
  },
};
</script>

<style lang="scss" scoped>
@import "../css/loginpage.scss";
</style>
