<template>
  <div>
    <b-tabs id="tab-link" justified v-model="$store.state.tabIndex">
      <b-tab title="History" active :disabled="this.$store.state.disabledHistory">
        <History  />
      </b-tab>
      <b-tab title="Screening" :disabled="this.$store.state.disabledScreening">
        <Screening  />
      </b-tab>
      <b-tab title="Periodontal" :disabled="this.$store.state.disabledPeriodontal">
        <Peridontal />
      </b-tab>
      <b-tab title="Diagnostic" :disabled="this.$store.state.disabledDiagnostic">
        <Diagnostic />
      </b-tab>
      <b-tab title="Treatment" :disabled="this.$store.state.disabledTreatment">
        <Treatment />
      </b-tab>
      <b-tab title="Referral" :disabled="this.$store.state.disabledReferral">
        <Referral />
      </b-tab>
    </b-tabs>
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from "vuex";
import History from "../components_clinic_page/History.vue";
import Screening from "../components_clinic_page/Screening.vue";
import Peridontal from "../components_clinic_page/Peridontal.vue";
import Diagnostic from "../components_clinic_page/Diagnostic.vue";
import Treatment from "../components_clinic_page/Treatment.vue";
import Referral from "../components_clinic_page/Referral.vue";

export default {
  name: "AddEncounter",
  components: {
    History,
    Screening,
    Peridontal,
    Diagnostic,
    Treatment,
    Referral
    // Footer
  },
  data() {
    return {
      ariaSelected: false,
    };
  },
};
</script>
<style lang="scss" scoped>
.hiddden {
  display: none;
}
@import "src/css/main.scss";
</style>
