<template>
  <div class="footer-fix">
    <div
      class="footer-wrap"
      :class="{ backgroundRed: backgroundred, backgroundBlue: backgroundblue }"
    >
      <div>
        <h4 class="mx-2">{{ capitalizeFirstLetter(profile.full_name) }}</h4>
      </div>
      <div>
        <h4 class="mx-2">{{ this.location_item.name }}</h4>
      </div>
      <div>
        <h4 class="mx-2">{{ this.activity_item.activity }}</h4>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  data() {
    return {
      location_item: JSON.parse(localStorage.getItem("location_item")),
      activity_item: JSON.parse(localStorage.getItem("activity_item")),
      backgroundblue: false,
      backgroundred: false,
    };
  },

  computed: {
    ...mapState(["profile"]),
  },

  mounted() {
    if (this.activity_item.activity == "Routine Clinic") {
      this.backgroundblue = true;
    } else if (this.activity_item.activity == "School") {
      this.backgroundred = true;
    }
  },
  created() {
    this.listProfile();
  },
  methods: {
    ...mapActions(["listProfile"]),
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
  },
};
</script>

<style lang="scss" scoped>
.footer-fix {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: #fff;
  // margin-top:10px;
  z-index: 99;
}
.footer-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 0px;
  h4,
  h6 {
    color: #fff;
  }
}
.backgroundRed {
  background: #f64138;
}

.backgroundBlue {
  background: #4688f1;
}
@import "../../css/footer";
</style>
