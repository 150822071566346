<template>
  <div class="select-location">
    <app-header></app-header>
    <div class="form">
      <form class="location">
        <p>
          <img src="../../assets/clinic/images/location-icon.svg" alt="" />
          Select Location
        </p>
        <div class="location-first">
          <div class="select">
            <select id="location" v-model="location_select">
              <option value disabled>Location First</option>
              <option
                v-for="(location, index) in locations"
                :key="index"
                :value="location.id"
              >
                {{ location.name }}
              </option>
            </select>
            <span
              class="text-danger float-right"
              v-if="errors.location_select"
              >{{ errors.location_select }}</span
            >
          </div>
          <!-- <label alt="Location First" placeholder="Location First"></label> -->
        </div>

        <div class="button">
          <b-button @click="saveLocation()" class="bg-blue">Next</b-button>
          <!-- <router-link to="/clinic/selectactivity"
            ><b-button @click="saveLocation(location.id)" class="bg-blue">Next</b-button></router-link
          > -->
        </div>
      </form>
    </div>
    <input type="hidden" v-model="state_change_update_text" />
  </div>
</template>
<script>
import AppHeader from "../Header.vue";
import { mapState, mapActions, mapMutations } from "vuex";
// import { required} from 'vuelidate/lib/validators';

export default {
  name: "SelectLocation",
  components: {
    "app-header": AppHeader,
  },
  data() {
    return {
      selected: null,
      location: null,
      location_select: "",
      errors: [],
      options: [
        { value: "A", text: "Option A (from options prop)" },
        { value: "B", text: "Option B (from options prop)" },
      ],
      state_change_update_text: "",
    };
  },
  computed: {
    ...mapState([
      "profile",
      "successmessage",
      "locations",
      "selectedLocation",
      "errormessage",
      "message",
    ]),
    userrole: function() {
      let profileDetail = this.profile;
      let userrole =
        profileDetail && profileDetail.role ? profileDetail.role : "Admin User";
      return userrole;
    },
  },
  created() {
    this.listLocations();
    this.listProfile();
  },
  watch() {},
  methods: {
    ...mapActions(["listLocations", "listProfile"]),
    ...mapMutations(["setLocationItem"]),
    saveLocation() {
      // console.log(this.location_select)
      if (this.location_select == "") {
        this.state_change_update_text = Date.now();
        this.errors["location_select"] = "Location is required.";
      } else {
        let thisLocationValue = null;
        thisLocationValue = this.locations.find((row) => {
          return row.id == this.location_select;
        });

        if (thisLocationValue && thisLocationValue.id) {
          localStorage.setItem(
            "location_item",
            JSON.stringify(thisLocationValue)
          );
          this.setLocationItem(JSON.stringify(thisLocationValue));
          this.$router.replace("/clinic/selectactivity");
        } else {
          this.state_change_update_text = Date.now();
          this.errors["location_select"] = "Incorrect Location value selected.";
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "src/css/main.scss";
</style>
