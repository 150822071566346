<template>
  <div id="app">
    <app-header></app-header>
    <div class="contents">
      <div class="row">
        <div class="col-12">
          <h1>Service Delivery Data</h1>
        </div>
      </div>
      <!-- Filter data Section -->
      <div class="row mt-4 text-center">
        <div class="col-12">
          <div class="card shadow">
            <h3 class="my-4">4 Filter Data</h3>

            <div class="row mt-4">
              <div class="col-lg-4 col-sm-12 mb-4">
                <h6>Select Start Date:</h6>
                <b-input v-model="start_date" type="date" />
              </div>

              <div class="col-lg-4 col-sm-12 mb-4">
                <h6>Select End Date:</h6>
                <b-input v-model="today_date" type="date" />
              </div>

              <div class="col-lg-4 col-sm-12 mb-4">
                <h6>Location:</h6>
                <multiselect
                  v-model="location_filter_data"
                  :options="location_filter_data_options"
                  :multiple="true"
                  :preserve-search="true"
                  placeholder="Select Location"
                  label="name"
                  track-by="name"
                  open-direction="bottom"
                  :preselect-first="true"
                >
                </multiselect>
              </div>
            </div>

            <div class="row justify-content-center">
              <div class="col-lg-5 col-sm-12 ml-auto">
                <h6 class="mb-4">Select Patient Group :</h6>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="checkbox_filter_data"
                    :options="checkbox_filter_data_options"
                    switches
                    size="lg"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>

              <!-- <div class="col-lg-5 col-sm-12">
                <h6>Select Activities:</h6>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="checkbox_selected"
                    :options="checkbox_options"
                    switches
                    size="md"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div> -->

              <div class="col-lg-2 col-sm-12 ml-auto">
                <b-button variant="custom" block class="" @click="filterDataTable">Submit</b-button>
              </div>
            </div>
            <div class="row pr-4 mt-5">
              <small class="ml-auto"
                ><a href=""
                  ><i class="fas fa-file-export mr-1"></i>Export Now</a
                ></small
              >
            </div>
          </div>
        </div>
      </div>
      <!-- Filter data Section -->

      <!--Summary Epidemiological Bargraph -->
      <div class="row mt-4 text-center">
        <div class="col-12">
          <div class="card shadow">
            <h3 class="mb-4">4.1.1 Care Patterns</h3>
            <div class="row justify-content-center">
              <div class="col-lg-11">
                <Visualization :tag="carepatternsgraph"></Visualization>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--Summary Epidemiological Bargraph -->

      <!--Summary Epidemiological Table -->
      <div class="row mt-4 text-center">
        <div class="col-12">
          <div class="card shadow">
            <h3 class="mb-4">4.1.2 Care Patterns</h3>
            <div class="row justify-content-center">
              <div class="col-lg-12">
                <b-table
                  id="carepatterns-table"
                  show-empty
                  :items="carePatternsItems"
                  :fields="carePatternsData"
                  responsive
                  hover
                  bordered
                >
                </b-table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Summary Epidemiological Table -->
      <!--Summary Screeing Table -->
      <div class="row mt-4 text-center">
        <div class="col-lg-6">
          <div class="card shadow">
            <h3 class="mb-4">
              4.2.1 Preventive treatment vs surgical treatment
            </h3>
            <Visualization :tag="treatmentsurgicalgraph"></Visualization>
          </div>
        </div>
        <div class="col-lg-6 card shadow">
          <h3 class="mb-4">4.2.2 Preventive treatment vs surgical treatment</h3>
          <div class="row justify-content-center">
            <div class="col-lg-10">
              <b-table
                id="epidemiological-table"
                show-empty
                :items="treatmentSurgicalItems"
                :fields="treatmentSurgicalData"
                responsive
                hover
                bordered
              >
              </b-table>
            </div>
          </div>
        </div>
      </div>
      <!-- Summary Screening Table -->
      <div class="row mt-4 text-center">
        <div class="col-lg-6">
          <div class="card shadow">
            <h3 class="mb-4">4.3.1 Early intervention vs extraction</h3>
            <Visualization :tag="interventionextractiongraph"></Visualization>
          </div>
        </div>
        <div class="col-lg-6 card shadow">
          <h3 class="mb-4">4.3.2 Early intervention vs extraction</h3>
          <div class="row justify-content-center">
            <div class="col-lg-10">
              <b-table
                id="epidemiological-table"
                show-empty
                :items="interventionExtractionItems"
                :fields="interventionExtractionData"
                responsive
                hover
                bordered
              >
              </b-table>
            </div>
          </div>
        </div>
      </div>
      <!-- Form validation Error message for 2 Filter Table Data  -->
      <b-toast
        id="error-serviceDelivery"
        variant="warning"
        solid
        append-toast
        toaster="b-toaster-bottom-full"
      >
        <div slot="toast-title" class="d-flex flex-grow-1 align-items-baseline">
          <strong class="mr-auto">Required error</strong>
        </div>
        <div v-if="errors.location_filter_data">
          <p>{{ errors.location_filter_data }}</p>
        </div>

        <div v-if="errors.checkbox_filter_data">
          <p>{{ errors.checkbox_filter_data }}</p>
        </div>
      </b-toast>
      <!-- Form validation Error message for 2 Filter Table Data  -->
    </div>
    <app-footer></app-footer>
  </div>
</template>


<script>
import { mapState, mapActions } from "vuex";
import Visualization from "./Visualization";
import AppHeader from "./Header.vue";
import Appfooter from "./Footer.vue";
// import userChart from "../js/userchart.js";
// import locationChart from "../js/locationchart.js";
// import years from "../js/year_array.js";

// const axios = require('axios');
export default {
  name: "servicedelivery",
  components: {
    // "AuthenticationForm": AuthenticationForm
    "app-header": AppHeader,
    Visualization: Visualization,
    "app-footer": Appfooter,
  },

  data() {
    return {
      start_date: "",
      today_date: "",
      errors: [],
      location_filter_data: [],
      location_filter_data_options: [{ name: "All location", language: null }],
      checkbox_filter_data: [],
      checkbox_filter_data_options: [
        {text:'All',value:'all'},
        {text:'Non-Target',value:'non_target'},
        {text:'Target',value:'target'}
      ],
      carepatternsgraph: "carepatternsgraph",
      treatmentsurgicalgraph: "treatmentsurgicalgraph",
      interventionextractiongraph: "interventionextractiongraph",
      carePatternsData: [
        { key: "type", label: "", tdClass: "font-weight-bold" },
        { key: "totalscreened", label: "Total Screened" },
        { key: "anteriorrootfilling", label: "Anterior root fillings" },
        { key: "childrengettingsealants", label: "Children Getting sealants" },
        {
          key: "childgettingsealants",
          label: "6-8 years old getting sealants",
        },
        {
          key: "childrengettingextraction",
          label: "Children getting one or more extraction",
        },
        {
          key: "childrengettingextraction",
          label: "Children getting one or more extraction",
        },
        {
          key: "childrengettingextractioninpermanentmolar",
          label: "Children getting extraction in permanent molar",
        },
      ],
      carePatternsItems: [
        {
          type: "Number",
          totalscreened: "20,000",
          anteriorrootfilling: "",
          childrengettingsealants: "",
          childgettingsealants: "",
          childrengettingextraction: "",
          childrengettingextractioninpermanentmolar: "",
        },
        {
          type: "Percentage",
          totalscreened: "20,000",
          anteriorrootfilling: "",
          childrengettingsealants: "",
          childgettingsealants: "",
          childrengettingextraction: "",
          childrengettingextractioninpermanentmolar: "",
        },
      ],
      treatmentSurgicalData: [
        { key: "type", label: "", tdClass: "font-weight-bold text-left" },
        { key: "number", label: "Number" },
        { key: "percentage", label: "Percentage" },
      ],
      treatmentSurgicalItems: [
        {
          type: "Preventive Treatment",
          number: "16,566",
          percentage: "31.1%",
        },
        {
          type: "Surgical Treatment",
          number: "36,701",
          percentage: "68.9%",
        },
      ],
      interventionExtractionData: [
        { key: "type", label: "", tdClass: "font-weight-bold text-left" },
        { key: "number", label: "Number" },
        { key: "percentage", label: "Percentage" },
      ],
      interventionExtractionItems: [
        {
          type: "Early Intervention",
          number: "12,500",
          percentage: "31.1%",
        },
        {
          type: "Extraction",
          number: "40,767",
          percentage: "68.9%",
        },
      ],
    };
  },

  computed: {
    ...mapState([
      "errormessage",
      "successmessage",
      "message",
      "returndate_obj",
      "treatment_tablebasicdata_obj",
      "treatmentstrategicdata_obj",
      "geography",
      "activities_obj",
    ]),
  },

  methods: {
    ...mapActions([
      "listReturnDate",
      "listTreatmentStrategicData",
      "listTreatmentTableBasicData",
      "listGeography",
      "listActivitie",
    ]),
    filterDataTable() {
      this.errors = [];
      if (this.location_filter_data == "") {
        this.errors["location_filter_data"] =
          "Selection of location is required.";
        this.$bvToast.show("error-serviceDelivery");
      }

     if (this.checkbox_filter_data == "") {
        this.errors["checkbox_filter_data"] =
          "Selection of Patient is required.";
        this.$bvToast.show("error-serviceDelivery");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../css/encounter.scss";
</style>
