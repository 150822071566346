<template>
  <div id="app">
    <app-header></app-header>
    <div class="contents">
      <div class="row">
        <div class="col-12">
          <h1>Treatment</h1>
        </div>
      </div>
      <!-- Filter data Section -->
      <div class="row mt-4 text-center">
        <div class="col-12">
          <div class="card shadow">
            <h3 class="mb-4">2 Filter Table Data</h3>
            <div class="row">
              <div class="col-lg-4 col-sm-12 mb-4">
                <h6>Select Start Date: {{ start_date }}</h6>
                <b-input type="date" v-model="start_date" />
              </div>

              <div class="col-lg-4 col-sm-12 mb-4">
                <h6>Select End Date: {{ end_date }}</h6>
                <b-input type="date" v-model="end_date" />
              </div>

              <div class="col-lg-4 col-sm-12 mb-4">
                <h6>Location:</h6>
                <multiselect v-model="location_filter_data_table2" :options="options_location_filter_data_table2"
                  :multiple="true" :preserve-search="true" placeholder="Select Location" label="name" track-by="name"
                  open-direction="bottom" id="locatinoSelector" :preselect-first="true">
                </multiselect>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-10 col-sm-12">
                <h6 class="my-3">Select Activities:</h6>
                <b-form-group>
                  <b-form-checkbox-group v-model="filter_data_table_checkbox"
                    :options="filter_data_table_checkbox_options" switches size="lg"></b-form-checkbox-group>
                </b-form-group>
              </div>

              <div class="col-lg-2 col-sm-12">
                <!-- <h6>Click Here:</h6> -->
                <b-button variant="custom" block class="mb-4" @click="filterDataTable2">Submit</b-button>
              </div>
            </div>
            <!-- <div class="row pr-4 mt-5">
              <small class="ml-auto"
                ><a href=""
                  ><i class="fas fa-file-export mr-1"></i>Export Now</a
                ></small
              >
            </div> -->
          </div>
        </div>
      </div>
      <!-- Filter data Section -->
      <!-- Treatment By Type Graph Section -->
      <div class="row mt-4 text-center">
        <div class="col-12">
          <div class="card shadow">
            <h3 class="mb-3">
              2.1.1 {{ this.treatment_location }}: Treatment By Type
            </h3>
            <!-- <h3 class="mb-3" v-else>2.1.1 All Location : Treatment By Type</h3> -->
            <div class="row justify-content-center">
              <div class="col-lg-11">
                <Visualization :tag="TreatmentByType"></Visualization>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Treatment By Type Graph Section -->
      <!-- Treatment By Type Table Section -->
      <!-- a{{treatmentByTypeItems}}b -->
      <div class="row mt-4 text-center">
        <div class="col-12">
          <div class="card shadow">
            <h3 class="mb-3">
              2.1.2 {{ this.treatment_location }}: Treatment By Type
            </h3>
            <!-- <h3 class="mb-3" v-else>2.1.2 All Location : Treatment By Type</h3> -->
            <b-table id="user-table" show-empty :items="treatmentByTypeItems" :fields="Treatment_By_Type" responsive hover
              :busy="isBusy" bordered>
              <!-- <template #cell(number)="data">
              {{data}}
            </template> -->
              <template v-slot:table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle" type="grow" style="width: 5rem; height: 5rem"></b-spinner>
                </div>
              </template>
            </b-table>
            <div class="row pr-4 mt-2 mb-2">
              <small class="ml-auto">
                <vue-excel-xlsx :data="treatmentByTypeItems" :columns="Treatment_By_Type"
                  :filename="'2.1.2 Treatment By Type'" :sheetname="' 2.1.2 Treatment By Type'" class="export-btn">
                  <a><i class="fas fa-file-export mr-1"></i>Export Now</a>
                </vue-excel-xlsx>
              </small>
            </div>
          </div>
        </div>
      </div>
      <!-- Treatment By Type Table Section -->
      <!-- Raw Treatment Data(Target Children Only) -->
      <div class="row mt-4 text-center">
        <div class="col-12">
          <div class="card shadow">
            <h3 class="mb-3">
              2.2 {{ treatment_location }}: Raw Treatment Data
            </h3>
            <h4 class="my-5">Basic Data</h4>
            <b-table id="user-table" show-empty :items="Raw_Treatment_Basic_Items" :fields="Raw_Treatment_Basic_Data"
              responsive hover :busy="isBusy" bordered>
              <template v-slot:table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle" type="grow" style="width: 5rem; height: 5rem"></b-spinner>
                </div>
              </template>
            </b-table>
            <div class="row pr-4 mt-2 mb-2">
              <small class="ml-auto">
                <vue-excel-xlsx :data="Raw_Treatment_Basic_Items" :columns="Raw_Treatment_Basic_Data"
                  :filename="'2.2 Basic Data'" :sheetname="'2.2 Basic Data'" class="export-btn">
                  <a><i class="fas fa-file-export mr-1"></i>Export Now</a>
                </vue-excel-xlsx>
              </small>
            </div>

            <h4 class="my-5">Treatments</h4>

            <b-table id="user-table" show-empty :items="Raw_Treatment_Treatment_Items"
              :fields="Raw_Treatment_Treatment_Data" responsive hover :busy="isBusy" bordered>
              <template v-slot:table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle" type="grow" style="width: 5rem; height: 5rem"></b-spinner>
                </div>
              </template>
            </b-table>
            <div class="row pr-4 mt-2 mb-2">
              <small class="ml-auto">
                <vue-excel-xlsx :data="Raw_Treatment_Treatment_Items" :columns="Raw_Treatment_Treatment_Data"
                  :filename="'2.2 Treatment Data'" :sheetname="'2.2 Treatment Data'" class="export-btn">
                  <a><i class="fas fa-file-export mr-1"></i>Export Now</a>
                </vue-excel-xlsx>
              </small>
            </div>
          </div>
        </div>
      </div>
      <!-- Raw Treatment Data(Target Children Only) -->
      <!-- Screening Achievement vs Target -->
      <div class="row mt-4">
        <div class="col-6 text-center">
          <div class="card shadow">
            <h3 class="mb-3">
              2.3.1 {{ this.treatment_location_not_mdc }}: Screening Achievement
              (KG - Grade 4)
            </h3>
            <div class="row justify-content-center">
              <div class="col-6">
                <Visualization :tag="ScreeningAchievement"></Visualization>
              </div>
            </div>
          </div>
        </div>

        <div class="col-6 text-center">
          <div class="shadow card py-5">
            <h3 class="">
              2.3.2 {{ this.treatment_location_not_mdc }}: Screening Achievement
              (KG - Grade 4)
            </h3>
            <div class="row justify-content-center py-3 mt-3">
              <div class="col-12">
                <b-table id="combodia-smile-table" show-empty :items="Screening_achievement_Items"
                  :fields="Screening_achievement_Data" responsive hover :busy="isBusy" bordered class="">
                  <template v-slot:table-busy>
                    <div class="text-center text-primary my-2">
                      <b-spinner class="align-middle" type="grow"></b-spinner>
                    </div>
                  </template>
                </b-table>
              </div>
            </div>
            <div class="row pr-4 my-2 pb-2">
              <small class="ml-auto">
                <vue-excel-xlsx :data="Screening_achievement_Items" :columns="Screening_achievement_Data"
                  :filename="' 2.3.2 Screening Achievement'" :sheetname="'2.3.2 Screening Achievement'"
                  class="export-btn">
                  <a><i class="fas fa-file-export mr-1"></i>Export Now</a>
                </vue-excel-xlsx>
              </small>
            </div>
          </div>
        </div>
      </div>
      <!-- Screening Achievement vs Target -->
      <!-- Treatment progress of Screened -->
      <div class="row mt-4">
        <div class="col-6 text-center">
          <div class="card shadow">
            <h3 class="mb-3">
              2.4.1 {{ this.treatment_location_not_mdc }}: Treatment Progress
              (KG - Grade 4)
            </h3>
            <div class="row justify-content-center my-4">
              <div class="col-lg-9">
                <Visualization :tag="TreatmentScreened"></Visualization>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6 text-center">
          <div class="card shadow">
            <h3>
              2.4.2 {{ this.treatment_location_not_mdc }}: Treatment Progress
              (KG - Grade 4)
            </h3>
            <div class="row justify-content-center py-4">
              <div class="col-12">
                <b-table id="combodia-smile-table" show-empty :items="Treatment_progress_Items"
                  :fields="Treatment_progress_Data" responsive hover :busy="isBusy" bordered>
                  <template v-slot:table-busy>
                    <div class="text-center text-primary my-2">
                      <b-spinner class="align-middle" type="grow"></b-spinner>
                    </div>
                  </template>
                </b-table>
              </div>
            </div>
            <div class="row pr-4 pb-2">
              <small class="ml-auto">
                <vue-excel-xlsx :data="Treatment_progress_Items" :columns="Treatment_progress_Data"
                  :filename="'2.4.2 Treatment Progress'" :sheetname="'2.4.2 Treatment Progress'" class="export-btn">
                  <a><i class="fas fa-file-export mr-1"></i>Export Now</a>
                </vue-excel-xlsx>
              </small>
            </div>
          </div>
        </div>
      </div>
      <!-- Treatment progress of Screened -->
      <!-- All Locations: Progress of referral to clinics -->
      <div class="row mt-4">
        <div class="col-6 text-center">
          <div class="card shadow">
            <h3 class="mb-3">
              2.5.1 {{ this.treatment_location_not_mdc }}: Progress Of Referral
              (KG - Grade 4)
            </h3>
            <!-- <h3 class="mb-3" v-else>
              2.5.1 All Locations: Progress Of Referral To Clinics
            </h3> -->
            <div class="row justify-content-center py-4">
              <div class="col-lg-9">
                <Visualization :tag="AllLocation"></Visualization>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6 text-center">
          <div class="card shadow">
            <h3>
              2.5.2 {{ this.treatment_location_not_mdc }}: Progress Of Referral
              (KG - Grade 4)
            </h3>
            <!-- <h3 v-else>
              2.5.2 All Locations: Progress Of Referral To Clinics
            </h3> -->
            <div class="row justify-content-center p-4">
              <div class="col-12">
                <b-table class="mb-3" id="combodia-smile-table" show-empty :items="All_location_Items"
                  :fields="All_location_Data" responsive hover :busy="isBusy" bordered>
                  <template v-slot:table-busy>
                    <div class="text-center text-primary my-2">
                      <b-spinner class="align-middle" type="grow"></b-spinner>
                    </div>
                  </template>
                </b-table>
              </div>
            </div>
            <div class="row pr-4 pb-2">
              <small class="ml-auto">
                <vue-excel-xlsx :data="All_location_Items" :columns="All_location_Data"
                  :filename="'2.5.2 Progress of Referral'" :sheetname="'2.5.2 Progress of Referral'" class="export-btn">
                  <a><i class="fas fa-file-export mr-1"></i>Export Now</a>
                </vue-excel-xlsx>
              </small>
            </div>
          </div>
        </div>
      </div>
      <!-- All Locations: Progress of referral to clinics -->
      <!-- Raw Treatment Data by location(Both Target and  Non-Target) -->
      <div class="row mt-4 text-center">
        <div class="col-12">
          <div class="card shadow">
            <h3 class="mb-3">
              2.6 {{ treatment_location }}: Raw Treatment Data By Location
            </h3>
            <h4 class="my-5">Basic Data</h4>
            <b-table id="user-table" show-empty :items="Raw_Treatment_Location_Basic_Items"
              :fields="Raw_Treatment_Location_Basic_Data" responsive hover :busy="isBusy" bordered>
              <template v-slot:table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle" type="grow" style="width: 5rem; height: 5rem"></b-spinner>
                </div>
              </template>
            </b-table>
            <div class="row pr-4 pb-2">
              <small class="ml-auto">
                <vue-excel-xlsx :data="Raw_Treatment_Location_Basic_Items" :columns="Raw_Treatment_Location_Basic_Data"
                  :filename="'2.6 Basic Data'" :sheetname="'2.6 Basic Data'" class="export-btn">
                  <a><i class="fas fa-file-export mr-1"></i>Export Now</a>
                </vue-excel-xlsx>
              </small>
            </div>
            <h4 class="my-5">Treatments</h4>
            <b-table id="user-table" show-empty :items="Raw_Treatment_Location_Treatment_Items"
              :fields="Raw_Treatment_Location_Treatment_Data" responsive hover :busy="isBusy" bordered>
              <template v-slot:table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle" type="grow" style="width: 5rem; height: 5rem"></b-spinner>
                </div>
              </template>
            </b-table>
            <div class="row pr-4 pb-2">
              <small class="ml-auto">
                <vue-excel-xlsx :data="Raw_Treatment_Location_Treatment_Items"
                  :columns="Raw_Treatment_Location_Treatment_Data" :filename="'2.6 Treatment Data '"
                  :sheetname="'2.6 Treatment Data'" class="export-btn">
                  <a><i class="fas fa-file-export mr-1"></i>Export Now</a>
                </vue-excel-xlsx>
              </small>
            </div>
          </div>
        </div>
      </div>
      <!-- Raw Treatment Data by location(Both Target and  Non-Target) -->
      <!-- Combodia Smile Intervention Filter Table Starts here -->
      <div class="row mt-4 text-center">
        <div class="col-lg-12 col-sm-12">
          <div class="card shadow">
            <h3 class="mb-3">2.7.1 Cambodia Smile Intervention (CSI)</h3>
            <div class="row mt-5 justify-content-around">
              <div class="col-lg-3 col-sm-12 mb-4">
                <h6>Select Year</h6>
                <b-form-select v-model="selected_year_csi" :options="years" placeholder="Enter Year">
                  <template v-slot:first>
                    <b-form-select-option :value="null" disabled>-- Select Year --</b-form-select-option>
                  </template>
                </b-form-select>
              </div>
              <div class="col-lg-3 mb-4">
                <h6>Select Months From</h6>
                <b-form-select v-model="months_from_csi" :options="options_month_from_csi" value-field="name"
                  text-field="name">
                  <template v-slot:first>
                    <b-form-select-option :value="null" disabled>-- Select Month --</b-form-select-option>
                  </template>
                </b-form-select>
              </div>
              <div class="col-lg-3 mb-4"></div>
            </div>
            <div class="row mb-5 justify-content-center">
              <div class="col-lg-4">
                <span>Please select the range of three month (Quarter)</span>
              </div>
            </div>
            <div class="row mb-5 justify-content-end">
              <div class="col-lg-4 col-sm-12 mb-4"></div>
              <div class="col-lg-3"></div>
              <div class="col-lg-3 col-sm-12">
                <b-button variant="custom" block class="mb-4" @click="filterDataCsi">Submit</b-button>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-lg-10">
                <Visualization :tag="CombodiaSmileInterventiongraph"></Visualization>
                <div v-show="spinner" class="text-center mt-5">
                  <b-spinner variant="primary" type="grow" label="Loading Visualization"
                    style="width: 5rem; height: 5rem"></b-spinner>
                </div>
              </div>
            </div>
            <!-- <div class="row pr-4 mt-5">
              <small class="ml-auto"
                ><a href=""
                  ><i class="fas fa-file-export mr-1"></i>Export Now</a
                ></small
              >
            </div> -->
          </div>
        </div>
      </div>
      <!-- Combodia Smile Intervention Filter graph Ends here -->
      <!-- Combodia smile Intervention Table Section -->
      <div class="row mt-4 text-center">
        <div class="col-12">
          <div class="card shadow">
            <h3 class="my-4">2.7.2 Combodia Smile Intervention(CSI)</h3>

            <div class="row justify-content-center mt-3">
              <div class="col-lg-11">
                <b-table id="combodia-smile-table" show-empty :items="Combodia_smile_intervention_Items"
                  :fields="Combodia_smile_intervention_Data" responsive hover :busy="isBusy" bordered>
                  <template v-slot:table-busy>
                    <div class="text-center text-primary my-2">
                      <b-spinner class="align-middle" type="grow" style="width: 5rem; height: 5rem"></b-spinner>
                    </div>
                  </template>
                </b-table>
              </div>
            </div>
            <div class="row pr-4 mt-2 mb-2">
              <small class="ml-auto">
                <vue-excel-xlsx :data="Combodia_smile_intervention_Items" :columns="Combodia_smile_intervention_Data"
                  :filename="'2.7.2 CSI Data '" :sheetname="'2.7.2 CSI Data'" class="export-btn">
                  <a><i class="fas fa-file-export mr-1"></i>Export Now</a>
                </vue-excel-xlsx>
              </small>
            </div>
          </div>
        </div>
      </div>
      <!--  Combodia smile Intervention Filter Table Section -->
      <!-- Combodia smile Intervention Table Section -->
      <div class="row mt-4 text-center">
        <div class="col-12">
          <div class="card shadow">
            <h3 class="my-4">
              2.8 Cambodia Smile Intervention (CSI) Basic Data
            </h3>
            <div class="row justify-content-center mt-3">
              <div class="col-lg-12">
                <b-table id="combodia-smile-table" show-empty :items="Combodia_Smile_Intervention_Location_Items"
                  :fields="Combodia_Smile_Intervention_Location_Data" responsive hover :busy="isBusy"
                  :current-page="currentPage" :per-page="perPage" bordered @filtered="onFiltered" :filter="filter">
                  <template v-slot:table-busy>
                    <div class="text-center text-primary my-2">
                      <b-spinner class="align-middle" type="grow" style="width: 5rem; height: 5rem"></b-spinner>
                    </div>
                  </template>
                </b-table>
                <div class="row my-3">
                  <div class="col-12">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"
                      aria-controls="combodia-smile-table" pills></b-pagination>
                  </div>
                </div>
                <div class="row pr-4 mt-2 mb-2">
                  <small class="ml-auto">
                    <vue-excel-xlsx :data="Combodia_Smile_Intervention_Location_Items"
                      :columns="Combodia_Smile_Intervention_Location_Data" :filename="'2.8 CSI Data'"
                      :sheetname="'2.8 CSI Data'" class="export-btn">
                      <a><i class="fas fa-file-export mr-1"></i>Export Now</a>
                    </vue-excel-xlsx>
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--  Combodia smile Intervention Table Section -->
      <!-- Children Received OHE graphs Starts here -->
      <div class="row mt-4 text-center">
        <div class="col-lg-12 col-sm-12">
          <div class="card shadow">
            <h3 class="my-3">2.9.1 Children received OHE by month</h3>
            <div class="row justify-content-around mt-3">
              <div class="col-lg-3 col-sm-12 mb-4">
                <h6>Select Year</h6>
                <b-form-select v-model="selected_year_ohe" :options="years" placeholder="Enter Year">
                  <template v-slot:first>
                    <b-form-select-option :value="null" disabled>-- Select Year --</b-form-select-option>
                  </template>
                </b-form-select>
              </div>
              <div class="col-lg-3 mb-4">
                <h6>Select Months From</h6>
                <b-form-select v-model="months_from_ohe" :options="options_month_from_ohe" value-field="name"
                  text-field="name">
                  <template v-slot:first>
                    <b-form-select-option :value="null" disabled>-- Select Month --</b-form-select-option>
                  </template>
                </b-form-select>
              </div>
              <div class="col-lg-3 col-sm-12 mb-4">
                <!-- <h6>Location</h6>
                <multiselect
                  v-model="location_ohe"
                  :options="options_location_ohe"
                  :multiple="true"
                  :preserve-search="true"
                  placeholder="Select Location"
                  label="name"
                  track-by="name"
                  open-direction="bottom"
                  :preselect-first="true"
                >
                </multiselect> -->
              </div>
            </div>
            <div class="row mb-5 justify-content-center">
              <div class="col-lg-4">
                <span>Please select the range of three month (Quarter)</span>
              </div>
            </div>
            <div class="row mb-5 justify-content-end">
              <div class="col-lg-3 col-sm-12">
                <b-button variant="custom" block class="mb-4" @click="filterDataOhe()">Submit</b-button>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-lg-11">
                <Visualization :tag="ChildrenOhegraph"></Visualization>
                <div v-show="spinner" class="text-center mt-5">
                  <b-spinner variant="primary" type="grow" label="Loading Visualization"
                    style="width: 5rem; height: 5rem"></b-spinner>
                </div>
              </div>
            </div>
            <!-- <div class="row pr-4">
              <small class="ml-auto"
                ><a href=""
                  ><i class="fas fa-file-export mr-1"></i>Export Now</a
                ></small
              >
            </div> -->
          </div>
        </div>
      </div>
      <!-- Children recieved OHE graphs Ends here -->
      <!-- Children recieved OHE Table Section -->
      <div class="row mt-4 text-center">
        <div class="col-12">
          <div class="card shadow">
            <h3 class="my-3">2.9.2 Children Received OHE by month</h3>

            <div class="row justify-content-center mt-4">
              <div class="col-lg-11">
                <b-table id="combodia-smile-table" show-empty :items="Children_Recieved_Ohe_Items"
                  :fields="Children_Recieved_Ohe_Data" responsive hover :busy="isBusy" bordered>
                </b-table>
              </div>
            </div>
            <div class="row pr-4 mt-2 mb-2">
              <small class="ml-auto">
                <vue-excel-xlsx :data="Children_Recieved_Ohe_Items" :columns="Children_Recieved_Ohe_Data"
                  :filename="'2.9.2 OHE Data'" :sheetname="'2.9.2 OHE Data'" class="export-btn">
                  <a><i class="fas fa-file-export mr-1"></i>Export Now</a>
                </vue-excel-xlsx>
              </small>
            </div>
          </div>
        </div>
      </div>
      <!-- Children recieved OHE Table Section -->
      <!-- Children recieved OHE Table Section -->
      <div class="row mt-4 text-center">
        <div class="col-12">
          <div class="card shadow">
            <h3 class="my-3">2.10 Oral Health Education Table</h3>

            <div class="row justify-content-center mt-4">
              <div class="col-lg-12">
                <b-table id="combodia-smile-table" show-empty :items="Oral_Health_Education_Table_Items"
                  :fields="Oral_Health_Education_Table_Data" responsive hover bordered @filtered="onFilteredOhe"
                  :filter="filter" :current-page="currentPageOhe" :per-page="perPageOhe" :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc">
                  <template v-slot:table-busy>
                    <div class="text-center text-primary my-2">
                      <b-spinner class="align-middle" type="grow" style="width: 5rem; height: 5rem"></b-spinner>
                    </div>
                  </template>
                </b-table>
              </div>
            </div>
            <div class="row my-3">
              <div class="col-12">
                <b-pagination v-model="currentPageOhe" :total-rows="rowsOhe" :per-page="perPageOhe"
                  aria-controls="combodia-smile-table" pills></b-pagination>
              </div>
            </div>
            <div class="row pr-4 mt-2 mb-2">
              <small class="ml-auto">
                <vue-excel-xlsx :data="Oral_Health_Education_Table_Items" :columns="Oral_Health_Education_Table_Data"
                  :filename="'2.10 OHE Data'" :sheetname="'2.10 OHE Data'" class="export-btn">
                  <a><i class="fas fa-file-export mr-1"></i>Export Now</a>
                </vue-excel-xlsx>
              </small>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="row mt-4 text-center" else>
        <h3 class="my-3">Can't Show the Table</h3>
      </div> -->
      <!-- Children recieved OHE Table Section -->
      <!-- Form validation Error message for 2 Filter Table Data  -->
      <b-toast id="error-toast" variant="warning" solid append-toast toaster="b-toaster-bottom-full">
        <div slot="toast-title" class="d-flex flex-grow-1 align-items-baseline">
          <strong class="mr-auto">Error!</strong>
        </div>

        <div v-if="errors.start_date">
          <p>{{ errors.start_date }}</p>
        </div>

        <div v-if="errors.end_date">
          <p>{{ errors.end_date }}</p>
        </div>

        <div v-if="errors.location_filter_data_table2">
          <p>{{ errors.location_filter_data_table2 }}</p>
        </div>

        <div v-if="errors.filter_data_table_checkbox">
          <p>{{ errors.filter_data_table_checkbox }}</p>
        </div>
        <div v-if="errors.message">
          <p>{{ errors.message }}</p>
        </div>
      </b-toast>
      <!-- Form validation Error message for 2 Filter Table Data  -->

      <!-- Form validation Error message for 2.7.1 Cambodia Smile Intervention (CSI)-->
      <b-toast id="error-csi" variant="warning" solid append-toast toaster="b-toaster-bottom-full">
        <div slot="toast-title" class="d-flex flex-grow-1 align-items-baseline">
          <strong class="mr-auto">Error!</strong>
        </div>
        <div v-if="errorsCsi.selected_year_csi">
          <p>{{ errorsCsi.selected_year_csi }}</p>
        </div>
        <div v-else-if="errorsCsi.months_from_csi">
          <p>{{ errorsCsi.months_from_csi }}</p>
        </div>
        <div v-else-if="errorsCsi.location_csi">
          <p>{{ errorsCsi.location_csi }}</p>
        </div>
      </b-toast>
      <!-- Form validation Error message for  2.7.1 Cambodia Smile Intervention (CSI)-->
      <!-- Form validation Error message for 2.9.1 Children received OHE by month-->
      <b-toast id="error-ohe" variant="warning" solid append-toast toaster="b-toaster-bottom-full">
        <div slot="toast-title" class="d-flex flex-grow-1 align-items-baseline">
          <strong class="mr-auto">Error!</strong>
        </div>
        <div v-if="errorsOhe.selected_year_ohe">
          <p>{{ errorsOhe.selected_year_ohe }}</p>
        </div>
        <div v-else-if="errorsOhe.months_from_ohe">
          <p>{{ errorsOhe.months_from_ohe }}</p>
        </div>
        <div v-else-if="errorsOhe.location_ohe">
          <p>{{ errorsOhe.location_ohe }}</p>
        </div>
      </b-toast>
      <!-- Form validation Error message for 2.9.1 Children received OHE by month-->
      <b-toast id="success-toast" variant="custom-success" solid append-toast toaster="b-toaster-bottom-full">
        <div slot="toast-title" class="d-flex flex-grow-1 align-items-baseline">
          <strong class="mr-auto">Success!</strong>
        </div>
        Data is Successfully Filtered
      </b-toast>
    </div>
    <app-footer></app-footer>
  </div>
</template>

<script>
// const moment = require('moment')
import { mapState, mapActions } from "vuex";
import AppHeader from "./Header.vue";
import Visualization from "./Visualization";
import Appfooter from "./Footer.vue";
import moment from "moment";
moment().format();

// const axios = require('axios');
export default {
  name: "Treatment",
  components: {
    // "AuthenticationForm": AuthenticationForm
    "app-header": AppHeader,
    Visualization: Visualization,
    "app-footer": Appfooter,
  },
  computed: {
    ...mapState([
      "errormessage",
      "successmessage",
      "message",
      "treatment_by_type_obj",
      "treatment_by_basic_obj",
      "treatment_by_treatment_obj",
      "screening_achievement_vs_target_obj",
      "treatment_progress_screened_obj",
      "all_location_obj",
      "treatment_location_basic_obj",
      "treatment_location_treatment_obj",
      "combodia_smile_intervention",
      "csi_basic_obj",
      "csi_obj",
      "ohe_table_basic_obj",
      "ohe_obj",
    ]),
    years() {
      const year = new Date().getFullYear();
      return Array.from(
        {
          length: 110,
        },
        (value, index) => year - index
      );
    },
    rows() {
      return this.patientArray.length;
    },
    rowsOhe() {
      return this.oheArray.length;
    },
    treatmentByTypeItems: function () {
      // this.isBusy = true
      let returnObjectData = [];
      if (
        this.$store.state.treatment_by_type_obj &&
        this.$store.state.treatment_by_type_obj.locationTable
      ) {
        this.$store.state.treatment_by_type_obj.locationTable.data.forEach(
          function (rec) {
            returnObjectData.push({
              TOTAL: rec[0],
              PERIDONTAL: rec[1],
              SDF: rec[2],
              FV: rec[3],
              SEAL: rec[4],
              FILLING: rec[5],
              ART: rec[6],
              EXTRACTION: rec[7],
              CROWN: rec[8],
              RCT: rec[9],
              UNTR: rec[10],
            });
            // returnObjectData.push();
          }
        );
      } // end if

      return returnObjectData;
    },
    Raw_Treatment_Basic_Items: function () {
      let returnObjectData = [];
      if (
        this.$store.state.treatment_by_basic_obj &&
        this.$store.state.treatment_by_basic_obj.locationTable
      ) {
        this.$store.state.treatment_by_basic_obj.locationTable.data.forEach(
          function (rec) {
            returnObjectData.push({
              TYPE: rec[0],
              MALE: rec[1],
              FEMALE: rec[2],
              "CHILD < 13Y": rec[3],
              "CHILD ≥ 13": rec[4],
              TARGET: rec[5],
              "NON TARGET": rec[6],
            });
          }
        );
      } //  end if

      return returnObjectData;
    },
    Raw_Treatment_Treatment_Items: function () {
      let returnObjcectData = [];
      if (
        this.$store.state.treatment_by_treatment_obj &&
        this.$store.state.treatment_by_treatment_obj.locationTable
      ) {
        this.$store.state.treatment_by_treatment_obj.locationTable.datatable.forEach(
          function (rec) {
            returnObjcectData.push({
              TYPE: rec[0],
              MALE: rec[1],
              FEMALE: rec[2],
              "CHILD < 13Y": rec[3],
              "CHILD ≥ 13": rec[4],
              TARGET: rec[5],
              "NON TARGET": rec[6],
            });
          }
        );
      } // end if

      return returnObjcectData;
    },
    Screening_achievement_Items: function () {
      let returnObjectData = [];
      if (
        this.$store.state.screening_achievement_vs_target_obj &&
        this.$store.state.screening_achievement_vs_target_obj.locationTable
      ) {
        this.$store.state.screening_achievement_vs_target_obj.locationTable.datatable.forEach(
          function (rec) {
            returnObjectData.push({
              TYPE: rec[0],
              NUMBER: rec[1],
              PERCENTAGE: rec[2] + "%",
            });
          }
        );
      } // end if

      return returnObjectData;
    },
    Treatment_progress_Items: function () {
      let returnObjectData = [];
      if (
        this.$store.state.treatment_progress_screened_obj &&
        this.$store.state.treatment_progress_screened_obj.locationTable
      ) {
        this.$store.state.treatment_progress_screened_obj.locationTable.datatable.forEach(
          function (rec) {
            returnObjectData.push({
              TYPE: rec[0],
              NUMBER: rec[1],
              PERCENTAGE: rec[2] + "%",
            });
          }
        );
      } // end if
      return returnObjectData;
    },
    All_location_Items: function () {
      let returnObjectData = [];
      if (
        this.$store.state.all_location_obj &&
        this.$store.state.all_location_obj.locationTable
      ) {
        this.$store.state.all_location_obj.locationTable.datatable.forEach(
          function (rec) {
            returnObjectData.push({
              TYPE: rec[0],
              NUMBER: rec[1],
              PERCENTAGE: rec[2] + "%",
            });
          }
        );
      } // end if
      return returnObjectData;
    },
    Raw_Treatment_Location_Basic_Items: function () {
      let returnObjectData = [];
      if (
        this.$store.state.treatment_location_basic_obj &&
        this.$store.state.treatment_location_basic_obj.locationTable
      ) {
        this.$store.state.treatment_location_basic_obj.locationTable.datatable.forEach(
          function (rec) {
            returnObjectData.push({
              TYPE: rec[0],
              CDC: rec[1],
              KYC: rec[2],
              TDC: rec[3],
              MDC: rec[4],
              DENTIST: rec[5],
              "DENTIST ASSISTANT": rec[6],
              TARGET: rec[7],
              "NON TARGET": rec[8],
            });
          }
        );
      } // end if

      return returnObjectData;
    },
    Raw_Treatment_Location_Treatment_Items: function () {
      let returnObjectData = [];
      if (
        this.$store.state.treatment_location_treatment_obj &&
        this.$store.state.treatment_location_treatment_obj.locationTable
      ) {
        this.$store.state.treatment_location_treatment_obj.locationTable.datatable.forEach(
          function (rec) {
            returnObjectData.push({
              TYPE: rec[0],
              CDC: rec[1],
              KYC: rec[2],
              TDC: rec[3],
              MDC: rec[4],
              DENTIST: rec[5],
              "DENTIST ASSISTANT": rec[6],
              TARGET: rec[7],
              "NON TARGET": rec[8],
            });
          }
        );
      } // end if

      return returnObjectData;
    },
    Combodia_smile_intervention_Items: function () {
      let returnObjcectData = [];
      if (this.$store.state.csi_obj && this.csi_obj.locationTable) {
        this.$store.state.csi_obj.locationTable.datatable.forEach(function (
          rec
        ) {
          returnObjcectData.push({
            MONTH: rec[0],
            TOTAL: rec[1],
            CDC: rec[2],
            KYC: rec[3],
            TDC: rec[4],
            // color:"black"
          });
        });
      } // end if

      return returnObjcectData;
    },

    Combodia_Smile_Intervention_Location_Items: function () {
      let returnObjectData = [];
      if (this.$store.state.csi_basic_obj) {
        this.$store.state.csi_basic_obj.data.forEach(function (rec) {
          returnObjectData.push({
            "S.N": rec.id,
            LOCATION: rec.location,
            "COMMUNITY HEALTH CENTER": rec.community_health_center,
            YEAR: rec.year,
            MONTH: rec.month,
            TOTAL: rec.total,
          });
        });
      } // end if
      this.patientArray = returnObjectData;
      return returnObjectData;
    },

    Children_Recieved_Ohe_Items: function () {
      let returnObjcectData = [];
      if (this.$store.state.ohe_obj && this.ohe_obj.locationTable) {
        this.$store.state.ohe_obj.locationTable.datatable.forEach(function (
          rec
        ) {
          returnObjcectData.push({
            MONTH: rec[0],
            TOTAL: rec[1],
            CDC: rec[2],
            KYC: rec[3],
            TDC: rec[4],
            MDC: rec[5],
          });
        });
      } // end if

      return returnObjcectData;
    },

    Oral_Health_Education_Table_Items: function () {
      let returnObjectData = [];
      // let location = "";
      if (this.$store.state.ohe_table_basic_obj) {
        this.$store.state.ohe_table_basic_obj.data.forEach(function (rec) {
          returnObjectData.push({
            LOCATION: rec.location,
            SCHOOL: rec.school,
            YEAR: rec.year,
            MONTH: rec.month,
            GRADE: rec.grade,
            "STUDENTS RECEIVED OHE": rec.students_received_tb_by_tp,
            "STUDENT BRUSHING TEETH REGULARLY":
              rec.students_brushing_teeth_regular,
            "% REGULAR TOOTH BRUSHING": rec.percent_regular_tooth_brushing,
            COMMENTS: rec.comments,
          });
        });
      }
      this.oheArray = returnObjectData;
      return returnObjectData;
      // .sort(function(a, b) {
      //   console.log(a);
      // });
    },
  },
  data() {
    return {
      TreatmentByType: "treatmentByType",
      ScreeningAchievement: "screeningAchievement",
      TreatmentScreened: "treatmentScreened",
      AllLocation: "allLocation",
      CombodiaSmileInterventiongraph: "combodiaSmile",
      ChildrenOhegraph: "childrenOhe",
      isBusy: false,
      spinner: false,
      startDate: "",
      treatment_location: "",
      perPage: 10,
      perPageOhe: 5,
      currentPage: 1,
      currentPageOhe: 1,
      filter: null,
      endDate: new Date().toISOString().substr(0, 10),
      //For Error Messages
      errors: [],
      errorsCsi: [],
      errorsOhe: [],
      // message: "",

      //For 2 Filter Data Table: []
      start_date: "",
      end_date: "",
      location_filter_data_table2: [],
      filter_data_table_checkbox: ["all"],
      options_location_filter_data_table2: [
        { key: "all", name: "All Location" },
        { key: 1, name: "CDC" },
        { key: 2, name: "KYC" },
        { key: 4, name: "MDC" },
        { key: 3, name: "TDC" },
      ],
      filter_data_table_checkbox_options: [
        {
          text: "All",
          value: "all",
        },
        {
          text: "RoutineClinic",
          value: 1,
        },
        {
          text: "School",
          value: 2,
        },
        {
          text: "Other",
          value: 3,
        },
      ],
      //For Smile Combodia Intervention
      location_csi: [],
      selected_year_csi: null,
      months_from_csi: null,
      months_to_csi: [],
      options_year_csi: [{ name: "2019", language: null }],
      options_month_from_csi: [
        {
          name: "January",
          index: 1,
        },
        {
          name: "February",
          index: 2,
        },
        {
          name: "March",
          index: 3,
        },
        {
          name: "April",
          index: 4,
        },
        {
          name: "May",
          index: 5,
        },
        {
          name: "June",
          index: 6,
        },
        {
          name: "July",
          index: 7,
        },
        {
          name: "August",
          index: 8,
        },
        {
          name: "September",
          index: 9,
        },
        {
          name: "October",
          index: 10,
        },
        {
          name: "November",
          index: 11,
        },
        {
          name: "December",
          index: 12,
        },
      ],
      options_month_to_csi: [{ name: "Mar", language: null }],
      options_location_csi: [
        { key: "all", name: "All Location" },
        { key: 1, name: "CDC" },
        { key: 2, name: "KYC" },
        { key: 4, name: "MDC" },
        { key: 3, name: "TDC" },
      ],
      //For Children Ohe Card
      location_ohe: [],
      selected_year_ohe: null,
      months_from_ohe: null,
      months_to_ohe: [],
      options_year_ohe: [{ name: "2019", language: null }],
      options_month_from_ohe: [
        {
          name: "January",
          index: 1,
        },
        {
          name: "February",
          index: 2,
        },
        {
          name: "March",
          index: 3,
        },
        {
          name: "April",
          index: 4,
        },
        {
          name: "May",
          index: 5,
        },
        {
          name: "June",
          index: 6,
        },
        {
          name: "July",
          index: 7,
        },
        {
          name: "August",
          index: 8,
        },
        {
          name: "September",
          index: 9,
        },
        {
          name: "October",
          index: 10,
        },
        {
          name: "November",
          index: 11,
        },
        {
          name: "December",
          index: 12,
        },
      ],
      options_month_to_ohe: [{ name: "Mar", language: null }],
      options_location_ohe: [
        { key: "all", name: "All Location" },
        { key: 1, name: "CDC" },
        { key: 2, name: "KYC" },
        { key: 4, name: "MDC" },
        { key: 3, name: "TDC" },
      ],
      // fig 2.1.2 Table data
      Treatment_By_Type: [
        { field: "TOTAL", label: "TOTAL", tdClass: "font-weight-bold" },
        { field: "PERIDONTAL", label: "PERIDONTAL" },
        { field: "SDF", label: "SDF" },
        { field: "FV", label: "FV" },
        { field: "SEAL", label: "SEAL" },
        { field: "FILLING", label: "FILLING" },
        { field: "ART", label: "ART" },
        { field: "EXTRACTION", label: "EXTRACTION" },
        { field: "CROWN", label: "CROWN" },
        { field: "RCT", label: "RCT" },
        { field: "UNTR", label: "UNTR" },
      ],

      // fig 2.2 Raw Treatment Data Table
      Raw_Treatment_Basic_Data: [
        { field: "TYPE", label: "" },
        { field: "MALE", label: "MALE" },
        { field: "FEMALE", label: "FEMALE" },
        { field: "CHILD < 13Y", label: "CHILD < 13Y" },
        { field: "CHILD ≥ 13", label: "CHILD ≥ 13" },
        { field: "TARGET", label: "TARGET" },
        { field: "NON TARGET", label: "NON TARGET" },
      ],

      // Raw_Treatment_Basic_Items: [],
      Raw_Treatment_Treatment_Data: [
        { field: "TYPE", label: "" },
        { field: "MALE", label: "MALE" },
        { field: "FEMALE", label: "FEMALE" },
        { field: "CHILD < 13Y", label: "CHILD < 13Y" },
        { field: "CHILD ≥ 13", label: "CHILD ≥ 13" },
        { field: "TARGET", label: "TARGET" },
        { field: "NON TARGET", label: "NON TARGET" },
      ],
      // Raw_Treatment_Treatment_Items: [],

      //fig 2.3.1 Screening Achievement vs Target table data
      Screening_achievement_Data: [
        { field: "TYPE", label: "", tdClass: "font-weight-bold" },
        { field: "NUMBER", label: "NUMBER" },
        { field: "PERCENTAGE", label: "PERCENTAGE" },
      ],

      // Screening_achievement_Items: [],

      //fig 2.4.1 Treatment progress of Screened table data
      Treatment_progress_Data: [
        { field: "TYPE", label: "", tdClass: "font-weight-bold" },
        { field: "NUMBER", label: "NUMBER" },
        { field: "PERCENTAGE", label: "PERCENTAGE" },
        // { key: "treatment_completed", label: "dasd" },
      ],

      // Treatment_progress_Items: [],

      //fig 2.5.1 All Locations: Progress of referral to clinics table data

      All_location_Data: [
        { field: "TYPE", label: "", tdClass: "font-weight-bold" },
        { field: "NUMBER", label: "NUMBER" },
        { field: "PERCENTAGE", label: "PERCENTAGE" },
      ],

      // All_location_Items: [
      // ],

      //fig 2.6 Raw Treatment Data by location basic data
      Raw_Treatment_Location_Basic_Data: [
        { field: "TYPE", label: "TYPE", tdClass: "font-weight-bold" },
        { field: "CDC", label: "CDC" },
        { field: "KYC", label: "KYC" },
        { field: "TDC", label: "TDC" },
        { field: "MDC", label: "MDC" },
        { field: "DENTIST", label: "DENTIST" },
        { field: "DENTIST ASSISTANT", label: "DENTIST ASSISTANT" },
        { field: "TARGET", label: "TARGET" },
        { field: "NON TARGET", label: "NON TARGET" },
      ],

      // Raw_Treatment_Location_Basic_Items: [],

      //fig 2.6 Raw Treatment Data by location  Treatments
      Raw_Treatment_Location_Treatment_Data: [
        { field: "TYPE", label: "TYPE", tdClass: "font-weight-bold" },
        { field: "CDC", label: "CDC" },
        { field: "KYC", label: "KYC" },
        { field: "TDC", label: "TDC" },
        { field: "MDC", label: "MDC" },
        { field: "DENTIST", label: "DENTIST" },
        { field: "DENTIST ASSISTANT", label: "DENTIST ASSISTANT" },
        { field: "TARGET", label: "TARGET" },
        { field: "NON TARGET", label: "NON TARGET" },
      ],

      // Raw_Treatment_Location_Treatment_Items: [],

      // fig 2.7.1 Cambodia Smile Intervention (CSI) data table
      Combodia_smile_intervention_Data: [
        { field: "MONTH", label: "MONTH", tdClass: "font-weight-bold" },
        { field: "TOTAL", label: "TOTAL" },
        { field: "CDC", label: "CDC" },
        { field: "KYC", label: "KYC" },
        { field: "TDC", label: "TDC" },
      ],

      // fig 2.8 Cambodia Smile Intervention (CSI) Location table
      Combodia_Smile_Intervention_Location_Data: [
        { field: "S.N", label: "S.N", tdClass: "font-weight-bold" },
        {
          field: "LOCATION",
          label: "LOCATION",
          tdClass: "font-weight-bold",
          sortable: true,
        },
        { field: "COMMUNITY HEALTH CENTER", label: "COMMUNITY HEALTH CENTER" },
        { field: "YEAR", label: "YEAR", sortable: true },
        { field: "MONTH", label: "MONTH", sortable: true },
        { field: "TOTAL", label: "TOTAL", tdClass: "font-weight-bold" },
      ],
      // Combodia_Smile_Intervention_Location_Items: [],
      //2.9.1 Children received OHE by month
      Children_Recieved_Ohe_Data: [
        { field: "MONTH", label: "MONTH", tdClass: "font-weight-bold" },
        { field: "TOTAL", label: "TOTAL" },
        { field: "CDC", label: "CDC" },
        { field: "KYC", label: "KYC" },
        { field: "TDC", label: "TDC" },
        { field: "MDC", label: "MDC" },
      ],

      // fig 2.10 Oral Health Education Table
      Oral_Health_Education_Table_Data: [
        // { key: "type", label: "S.N", tdClass: "font-weight-bold" },
        {
          field: "MONTH",
          label: "MONTH",
        },
        {
          field: "LOCATION",
          label: "LOCATION",
          tdClass: "font-weight-bold",
        },
        { field: "SCHOOL", label: "SCHOOL" },
        { field: "YEAR", label: "YEAR" },

        {
          field: "GRADE",
          label: "GRADE",
        },
        {
          field: "STUDENTS RECEIVED TB/TP",
          label: "STUDENTS RECEIVED TB/TP",
          tdClass: "font-weight-bold",
        },
        {
          field: "STUDENT BRUSHING TEETH REGULARLY",
          label: "STUDENT BRUSHING TEETH REGULARLY",
        },
        {
          field: "% REGULAR TOOTH BRUSHING",
          label: "% REGULAR TOOTH BRUSHING",
        },
        { field: "COMMENTS", label: "COMMENTS" },
      ],
      patientArray: [],
      oheArray: [],
      treatment_location: "All Location",
      treatment_location_not_mdc: "All Location",
      sortBy: "LOCATION",
      sortDesc: false,
    };
  },
  created() {
    this.listCsiBasic();
    this.listOheTableBasic();
    this.listTreatmentLocationBasicTable();
    this.listTreatmentLocationTreatmentTable();
    // this.listTreatmentLocationLocationTable();
    this.listTreatmentByTypeBarGraph();
    this.listTreatmentByTreatmentBasic();
    this.listTreatmentByTreatmentTreatment();
    // this.listScreeningAchievementTargetBarGraph();
    this.listTreatmentProgressScreenedTable();
    this.listProgressOfReferralTable();
    this.listCsiTable();
  },
  methods: {
    ...mapActions([
      "listTreatmentByTypeBarGraph",
      "listTreatmentByTreatmentBasic",
      "listTreatmentByTreatmentTreatment",
      // "listScreeningAchievementTargetTable",
      // "listTreatmentProgressScreenedTable",
      "listTreatmentLocationBasicTable",
      // "listTreatmentLocationLocationTable",
      "listProgressOfReferralTable",
      "listTreatmentLocationTreatmentTable",
      "listCsiTable",
      "listOheTableBasic",
      "listCsiBasic",
    ]),
    callCsiTable() {
      this.listCsiBasic();
    },
    currentDateTime() {
      let currentDate = new Date();
      let currentYear = currentDate.getFullYear();
      let currentMonth = currentDate.getMonth() + 1;
      let currentDay = currentDate.getDate();
      currentMonth =
        currentMonth.toString().length <= 1 ? "0" + currentMonth : currentMonth;
      currentDay =
        currentDay.toString().length <= 1 ? "0" + currentDay : currentDay;
      let today = currentMonth + "/" + currentDay + "/" + currentYear;
      // const date = current.getFullYear()+'-'+(current.getMonth()+1)+'-'+current.getDate();
      // const time = current.getHours() + ":" + current.getMinutes() + ":" + current.getSeconds();
      // const dateTime = date +' & '+ time;
      return today;
    },
    dispatchAction(actionName) {
      switch (actionName.key) {
        case "all":
          //window.alert('You just dispatched "all" action!')
          break;
      }
    },
    filterDataTable2() {
      this.errors = [];
      //Error message of the filter data table 1
      if (this.start_date == "" || this.start_date == null) {
        this.errors["start_date"] = "Start date is required.";
        this.$bvToast.show("error-toast");
      } else if (this.end_date == "" || this.end_date == null) {
        this.errors["end_date"] = "End date is required.";
        this.$bvToast.show("error-toast");
      } else if (
        this.location_filter_data_table2 == "" ||
        this.location_filter_data_table2 == null
      ) {
        this.errors["location_filter_data_table2"] =
          "Selection of the location required.";
        this.$bvToast.show("error-toast");
      } else if (
        this.filter_data_table_checkbox == "" ||
        this.filter_data_table_checkbox == null
      ) {
        this.errors["filter_data_table_checkbox"] =
          "Selection of the activities is required.";
        this.$bvToast.show("error-toast");
      } else {
        var clinic_id = [];
        var clinic_name_not_mdc = [];
        var clinic_name = [];
        this.location_filter_data_table2.forEach(function (location_id) {
          clinic_id.push(location_id.key);
          clinic_name.push(location_id.name);
          // clinic_name_not_mdc.push(location_id.name);
          if (location_id.name != "MDC") {
            clinic_name_not_mdc.push(location_id.name);
          }
        });
        this.treatment_location_not_mdc = clinic_name_not_mdc.join(", ");
        this.treatment_location = clinic_name.join(", ");
        if (clinic_id.includes("all")) {
          clinic_id = [1, 2, 3, 4];
          this.treatment_location = "All Locations";
          this.treatmen_location_not_mdc = "All Locations";
        }
        // console.log(this.treatment_location);

        if (this.filter_data_table_checkbox.includes("all")) {
          this.filter_data_table_checkbox = [1, 2, 3];
        }

        let formData = {
          start_date: this.start_date,
          end_date: this.end_date,
          clinic: clinic_id,
          activity: this.filter_data_table_checkbox,
        };

        // for postTreatmentByTypeBarGraph
        this.$store
          .dispatch("postTreatmentByTypeBarGraph", formData)
          .then(() => {
            if (this.successmessage == "success") {
              this.$bvToast.show("success-toast");

              // this.$bvToast.show("error-toast");
              //alert("Error1")
            } else {
              this.errors["message"] = this.errormessage;
              this.$bvToast.show("error-toast");
            }
          });

        // for postTreatmentByTreatmentBasic
        this.$store
          .dispatch("postTreatmentByTreatmentBasic", formData)
          .then(() => {
            if (this.successmessage == "success") {
              this.$bvToast.show("success-toast");
              // this.$bvToast.show("error-toast");
              //alert("Error1")
            } else {
              this.errors["message"] = this.errormessage;
              this.$bvToast.show("error-toast");
            }
          });

        // for postTreatmentByTreatmentTreatment
        this.$store
          .dispatch("postTreatmentByTreatmentTreatment", formData)
          .then(() => {
            if (this.successmessage == "success") {
              this.$bvToast.show("success-toast");
              // this.$bvToast.show("error-toast");
              //alert("Error1")
            } else {
              this.errors["message"] = this.errormessage;
              this.$bvToast.show("error-toast");
            }
          });

        // for postScreeningAchievementTargetTable
        this.$store
          .dispatch("postScreeningAchievementTargetTable", formData)
          .then(() => {
            if (this.successmessage == "success") {
              this.$bvToast.show("success-toast");
              // this.$bvToast.show("error-toast");
              //alert("Error1")
            } else {
              this.errors["message"] = this.errormessage;
              this.$bvToast.show("error-toast");
            }
          });
        // for postTreatmentProgressScreenedTable
        this.$store
          .dispatch("postTreatmentProgressScreenedTable", formData)
          .then(() => {
            if (this.successmessage == "success") {
              this.$bvToast.show("success-toast");
              // this.$bvToast.show("error-toast");
              //alert("Error1")
            } else {
              this.errors["message"] = this.errormessage;
              this.$bvToast.show("error-toast");
            }
          });
        // for postAllLocationViewTable
        this.$store.dispatch("postAllLocationViewTable", formData).then(() => {
          if (this.successmessage == "success") {
            this.$bvToast.show("success-toast");
            // this.$bvToast.show("error-toast");
            //alert("Error1")
          } else {
            this.errors["message"] = this.errormessage;
            this.$bvToast.show("error-toast");
          }
        });

        // for postTreatmentLocationBasicTable
        this.$store
          .dispatch("postTreatmentLocationBasicTable", formData)
          .then(() => {
            if (this.successmessage == "success") {
              this.$bvToast.show("success-toast");
              // this.$bvToast.show("error-toast");
              //alert("Error1")
            } else {
              this.errors["message"] = this.errormessage;
              this.$bvToast.show("error-toast");
            }
          });

        // for postTreatmentLocationTreatmentTable
        this.$store
          .dispatch("postTreatmentLocationTreatmentTable", formData)
          .then(() => {
            if (this.successmessage == "success") {
              this.$bvToast.show("success-toast");
              // this.$bvToast.show("error-toast");
              //alert("Error1")
            } else {
              this.errors["message"] = this.errormessage;
              this.$bvToast.show("error-toast");
            }
          });

        // for (var pair of formData.entries()) {
        //   console.log(pair[0] + ", " + pair[1]);
        // }
      }
    },
    filterDataCsi() {
      this.errorsCsi = [];
      //Error message 2.7.1 Cambodia Smile Intervention (CSI)
      if (this.selected_year_csi == null) {
        this.errorsCsi["selected_year_csi"] = "Please Select the Year";
        this.$bvToast.show("error-csi");
      } else if (this.months_from_csi == null) {
        this.errorsCsi["months_from_csi"] = "Please Select Month From";
        this.$bvToast.show("error-csi");
      }
      // else if (this.location_csi == "") {
      // //   this.errorsCsi["location_csi"] = "Please Select the Location";
      // //   this.$bvToast.show("error-csi");
      // }
      else {
        var clinic_id = [];
        this.location_csi.forEach(function (location_id) {
          clinic_id.push(location_id.key);
        });

        if (clinic_id.includes("all")) {
          clinic_id = [1, 2, 3, 4];
        }

        let formData = {
          start_month: this.months_from_csi,
          year: this.selected_year_csi,
          // clinic: clinic_id,
        };

        // for postCsiTable
        this.$store.dispatch("postCsiTable", formData).then(() => {
          if (this.successmessage == "success") {
            this.$bvToast.show("success-toast");
            // this.$bvToast.show("error-toast");
            //alert("Error1")
          } else {
            this.errors["message"] = this.errormessage;
            this.$bvToast.show("error-csi");
          }
        });
        this.$store.dispatch("postCsiTableBasics", formData).then(() => {
          if (this.successmessage == "success") {
            this.$bvToast.show("success-toast");
            // this.$bvToast.show("error-toast");
            //alert("Error1")
          } else {
            this.errors["message"] = this.errormessage;
            this.$bvToast.show("error-csi");
          }
        });
      }
    },
    filterDataOhe() {
      this.errorsOhe = [];
      //Error message 2.7.1 Cambodia Smile Intervention (CSI)
      if (this.selected_year_ohe == null) {
        this.errorsOhe["selected_year_ohe"] = "Please Select the Year";
        this.$bvToast.show("error-ohe");
      } else if (this.months_from_ohe == null) {
        this.errorsOhe["months_from_ohe"] = "Please Select Month From";
        this.$bvToast.show("error-ohe");
      }
      // else if (this.location_ohe == "") {
      //   this.errorsOhe["location_ohe"] = "Please Select the Location";
      //   this.$bvToast.show("error-csi");
      // }
      else {
        var clinic_id = [];
        this.location_ohe.forEach(function (location_id) {
          // console.log(location_id);
          clinic_id.push(location_id.key);
        });

        if (clinic_id.includes("all")) {
          clinic_id = [1, 2, 3, 4];
        }

        let formData = {
          start_month: this.months_from_ohe,
          year: this.selected_year_ohe,
          // clinic: clinic_id,
        };
        // console.log(formData);

        // for postChildrenOheViewBarGraph
        this.$store
          .dispatch("postChildrenOheViewBarGraph", formData)
          .then(() => {
            if (this.successmessage == "success") {
              this.$bvToast.show("success-toast");
              // this.$bvToast.show("error-toast");
              //alert("Error1")
            } else {
              this.errors["message"] = this.errormessage;
              this.$bvToast.show("error-ohe");
            }
          });
        this.$store.dispatch("postOheTableBasics", formData).then(() => {
          if (this.successmessage == "success") {
            this.$bvToast.show("success-toast");
            // this.$bvToast.show("error-toast");
            //alert("Error1")
          } else {
            this.errors["message"] = this.errormessage;
            this.$bvToast.show("error-ohe");
          }
        });
      }
    },
    setMyDateToToday() {
      this.startDate = new Date();
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalItems = filteredItems.length;
      this.currentPage = 1;
    },
    onFilteredOhe(filteredItemsOhe) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalItemsOhe = filteredItemsOhe.length;
      this.currentPageOhe = 1;
    },
  },
  mounted() {
    /**
     * Set  Current Date , Start date and End Dates
     * By using Date Function
     */
    let currentDate = new Date().toISOString().substring(0, 10);
    this.end_date = currentDate;
    let startDate = new Date(Date.now() - 86400000 * 90)
      .toISOString()
      .substring(0, 10);
    this.start_date = startDate;
  },
};
</script>

<style lang="scss" scoped>
@import "../css/index.scss";

.export-btn {
  background: #fff;
  border: none;
  color: blue;
}

.box {
  margin: 10px 10px;
  width: 25px;
  height: 25px;
}
</style>
