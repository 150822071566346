<template>
   <div id="app">
      <div class="contents">
         <div class="row justify-content-center align-item-center card">
            <div class="row mt-4 justify-content-center align-item-center">
               <div class="col-6 text-center">
                  <h1> Welcome to Dentalhub
                  </h1>
                  <p class="mt-3">Dental Hub records the patient profile, treatment provided, their history of
                     treatment, and recall
                     status. It will help and guide dentists through a progression of interactions with patients and its
                     overall intent. This app is will help automate differential diagnosis, provide treatment guidance,
                     contain detailed medical history</p>
               </div>
            </div>
            <div class="row">
               <div class="col-12 ">
                  <div class="row justify-content-center align-item-center mt-3">
                     <div class="col-4 submit-button text-center" v-if="userrole != 'Clinic Officer'">
                        <b-button block variant="custom" type="submit" @click="routeToClinic">Go to Clinic
                           Section</b-button>
                     </div>
                     <div class="col-4 submit-button text-center" v-if="userrole == 'Clinic Officer'">
                        <b-button block variant="custom" type="submit" @click="routeToDashboard">Go to Dashboard
                           Section</b-button>
                     </div>
                  </div>

               </div>
            </div>
         </div>
      </div>

   </div>

</template>
<script>
import { mapState, mapActions } from "vuex";

export default {
   name: "WelcomePage",
   computed: {
      ...mapState(["profile"]),
      userrole: function () {
         let profileDetail = this.profile;
         let userrole =
            profileDetail && profileDetail.role ? profileDetail.role : "Admin User";

         return userrole;
      },
   },
   created() {
      this.listProfile();
   },
   methods: {
      ...mapActions(["listProfile"]),

      routeToClinic() {
         this.$router.replace("/clinic");
      },
      routeToDashboard() {
         this.$router.replace("/dashboard");
      }
   },
};
</script>
<style lang="scss" scoped>
@import "../css/index.scss";
</style>
