<template>
  <div class="patient-detail">
    <app-header></app-header>
    <div class="components">
      <!-- {{ profile }}
      {{ role_obj }} -->
      <ShortcutTab></ShortcutTab>
      <div class="container-bg-white">
        <div class="wrapper">
          <div class="add-new-patient space-between">
            <h1>Patient Details</h1>
          </div>
          <div class="border-box">
            <div class="space">
              <div class="edit">
                <!-- <h4>Please click on the labels to Edit</h4> -->
                <div class="edit-pencil hover" v-b-modal.edit-patient-modal>
                  <label class="fa fa-pencil-alt"></label>
                </div>
                <!-- <label for="name" class="fas fa-edit"></label> -->
              </div>
              <div class="input-detail">
                <label class="d-flex justify-content-between align-items-center">
                  <span>Name</span>
                  <input type="text" v-model="patients.full_name" id="name" disabled />
                </label>
                <label class="d-flex justify-content-between align-items-center">
                  <span>Gender</span>
                  <input type="text" v-model="patients.gender" disabled />
                </label>
                <label v-if="patients.phone && patients.phone.length > 0"
                  class="d-flex justify-content-between align-items-center">
                  <span>Phone Number</span>
                  <input type="string" v-model="patients.phone" disabled />
                </label>
                <label v-else class="d-flex justify-content-between align-items-center">
                  <span>Phone Number</span>
                  <input type="string" v-model="not_provided" disabled />
                </label>
                <label class="d-flex justify-content-between align-items-center">
                  <span>Age</span>
                  <div class="w-50 px-2 py-2 span">
                    <span class="m-0">{{ PatientAge }} Year ({{ patients.dob }})</span>
                  </div>
                </label>
                <label class="d-flex justify-content-between align-items-center">
                  <span>Address</span>
                  <div class="w-50 px-2 py-2 span">
                    <span class="m-0">{{ Adress.district }},</span>
                    <span class="m-0">{{ Adress.commune }},</span>
                    <span class="m-0">{{ Adress.province }}</span>
                  </div>
                  <!-- <input type="text" v-model="(address.district + address.province}}" disabled /> -->
                </label>
                <label class="d-flex justify-content-between align-items-center">
                  <span>School</span>
                  <input type="text" v-model="schoolName" disabled />
                </label>

                <!-- <label class="d-flex justify-content-between">
                  <span>District</span>
                  <input type="text" v-model="patients.district" disabled />
                </label>-->
                <!-- <label class="d-flex justify-content-between">
                  <span>Ward</span>
                  <input type="text" v-model="patients.ward" disabled />
                </label>-->
                <!-- <label class="d-flex justify-content-between">
                  <span>Recall Time</span>
                  <input
                    type="text"
                    v-model="patients.recall_time"
                    disabled
                  />
                </label>-->
                <label class="d-flex justify-content-between align-items-center">
                  <span>Grade</span>
                  <input type="text" v-model="patients.grade" disabled />
                </label>
              </div>
            </div>
          </div>
          <div class="add-new-encounter">
            <h1>Encounters</h1>
            <button class="add-button" @click="addEncounter(patients.id)">
              <i class="fa fa-plus"></i>Add new Encounter
              <!-- </router-link> -->
            </button>
          </div>

          <div class="patient-info-wrapper">
            <div class="patient-info" v-for="encounter in listEncounters" :key="encounter.id">
              <div class="patient-name">
                <span class="text-bold">{{ encounter.encounter_type }}</span>
                <span>{{ getUser(encounter.author) }}</span>
                <br />
              </div>
              <div class="editable">
                <div class="icon space-between">
                  <span v-b-modal.report-modal @click="encounterDetails(encounter.id)" class="hover">
                    <i class="fa fa-eye"></i>
                  </span>
                  <span @click="editEncounter(encounter.id)" v-if="checkDate(encounter.created_at)" class="hover">
                    <i class="fa fa-pencil-alt"></i>
                  </span>
                </div>
                <span>Created Date</span>
                <span>{{ encounter.created_at.slice(0, 10) }}</span>
                <!-- <span>Created Date</span>
                <span>{{ encounter.date.slice(0, 10) }}</span> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <!-- Reason for encounter -->
      <b-modal id="encounter-reason-modal" hide-footer class="encounter-reason" centered title="Reason For Encounter">
        <b-form>
          <b-form-group class="patient-detail-radios">
            <label class="row pl-5">
              <input type="radio" v-model="encounterReason" name="some-radios" value="Checkup / Screening" />
              <span>Checkup/Screening</span>
            </label>
            <label class="row pl-5">
              <input type="radio" v-model="encounterReason" name="some-radios" value="Relief of pain" />
              <span>Relief of Pain</span>
            </label>
            <label class="row pl-5">
              <input type="radio" v-model="encounterReason" name="some-radios" value="Continuation of treatment plan" />
              <span>Continuation of Treatment Plan</span>
            </label>
            <label class="row pl-5">
              <input type="radio" v-model="encounterReason" name="some-radios" value="Other Problem" />
              <span>Other Problem</span>
            </label>
          </b-form-group>
          <!-- <span class="form-error" v-if="errors.addEncounterReasonError">
            {{ errors.addEncounterReasonError }}
          </span> -->
          <!-- <span class="form-success" v-if="success">{{ success }}</span> -->
          <div class="continue-button">
            <!-- <router-link to="/clinic/addencounter"> -->
            <b-button @click="openConfirmSave" class="continue" variant="outline-success">Continue</b-button>
            <!-- </router-link> -->
          </div>
        </b-form>
      </b-modal>
      <b-modal id="save-modal" hide-footer centered title="Are you sure you want to save this encounter?">
        <p>Selected Reason for the encounter is</p>
        <h4>{{ this.encounterReason }}</h4>
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <div class="row justify-content-end mt-5">
          <b-button size="lg" class="mx-2" variant="success" @click="addEncounterReason">
            Confirm
          </b-button>
          <b-button size="lg" class="mx-2" variant="danger" @click="cancel()">
            Change Encounter Reason
          </b-button>
        </div>
      </b-modal>
      <EditPatientModal :patientFormData="patients"></EditPatientModal>
      <ReportModal :tag="encounter_item"></ReportModal>
    </div>
    <Footer />
    <b-toast id="success-toast" variant="custom-success" solid append-toast toaster="b-toaster-top-center">
      <div slot="toast-title" class="d-flex flex-grow-1 align-items-baseline">
        <strong class="mr-auto">Success!</strong>
      </div>
      Encounter added successfully
    </b-toast>
    <b-toast id="encounter-error-toast" variant="danger" solid append-toast toaster="b-toaster-top-center">
      <div slot="toast-title" class="d-flex flex-grow-1 align-items-baseline">
        <strong class="mr-auto">Encounter Could not be Created</strong>
      </div>
      <p></p>
      <p v-if="this.empty_school">{{ this.empty_school }}</p>
    </b-toast>
  </div>
</template>
<script>
import AppHeader from "../Header.vue";
import ShortcutTab from "../components_clinic_page/ShortcutTab.vue";
import EditPatientModal from "../components_clinic_page/EditPatientModal.vue";
import { mapState, mapActions } from "vuex";
import ReportModal from "../components_clinic_page/ReportModal.vue";
import Footer from "../components_clinic_page/FooterClinicSection.vue";
import moment from "moment";
export default {
  name: "ViewDetail",
  components: {
    "app-header": AppHeader,
    ShortcutTab,
    ReportModal,
    EditPatientModal,
    Footer,
  },
  data() {
    return {
      CheckDate: new Date().toISOString().split("T")[0],
      encounterReason: "",
      patient_encounter_id: "",
      id: this.$route.params.id,
      errors: [],
      encounter_item: [],
      success: "",
      pk: "",
      pl: "",
      clicked: false,
      response_encounter_id: "",
      activity_id: "",
      clinic_id: "",
      address: {
        province: "",
        district: "",
        commune: "",
      },
      not_provided: "Not Provided",
      empty_school: "",
    };
  },

  computed: {
    ...mapState([
      "successmessage",
      "encounters",
      "patients",
      "errormessage",
      "message",
      "alladdresses",
      "activity",
      "schools",
      "users_obj",
      "clinic",
      "encounterId",
      "profile",
      "role_obj"
    ]),

    listEncounters() {
      // console.log(this.encounters[0].created_at);
      let encounters = this.encounters.sort((a, b) => {
        return new Date(b.created_at) - new Date(a.created_at);
      });
      // console.log(encounters);
      return encounters.filter((i) => i.patient == this.id);
    },
    Adress: function () {
      let address = this.alladdresses;
      let patient = this.patients;
      let AdressData = {
        province: "",
        district: "",
        commune: "",
      };
      address.forEach((provinceElement) => {
        if (provinceElement.id == patient.province) {
          AdressData.province = provinceElement.name;
          provinceElement.districts.forEach((districtElement) => {
            if (districtElement.id == this.patients.district) {
              AdressData.district = districtElement.name;
              districtElement.communes.forEach((communeElement) => {
                if (communeElement.id == this.patients.commune) {
                  AdressData.commune = communeElement.name;
                }
              });
            }
          });
          // this.province.communes.forEach(communeElement => {
          //   if (this.communeElement.id == this.patients.commune) {
          //     this.address.commune = communeElement.name;
          //   }
          // });
        }
      });
      // console.log(AdressData);
      return AdressData;
    },
    PatientAge: function () {
      let patient = this.patients;
      let today = new Date();
      let birthDate = new Date(patient.dob);
      let age = today.getFullYear() - birthDate.getFullYear();
      let m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    },
    schoolName: function () {
      let school = this.schools;
      let patient = this.patients;
      let schoolName = "";
      school.forEach((schoolElement) => {
        if (schoolElement.id == patient.school) {
          schoolName = schoolElement.name;
        }
      });
      return schoolName;
    },
  },
  watch: {
    schools: function () {
      if (Object.keys(this.schools).length !== 0) {
        let from = new Date().toISOString().split("T")[0];
        let calendar = new Date();
        calendar.setDate(calendar.getDate() + 180);
        let to = calendar.toISOString().split("T")[0];
        this.schools.forEach((school) => {
          if (element.recall_date >= from && element.recall_date <= to) {
            this.array.push(element);
          }
        });
      }
    },
  },
  created() {
    this.patientDetails(this.id);
    this.listPatientEncounters(this.id);
    this.listAllAddresses();
    this.listActivity();
    this.listSchools();
    this.listUsers();
    this.listClinic();
    this.listRole();
  },

  methods: {
    ...mapActions([
      "patientDetails",
      "listPatientEncounters",
      "listSchools",
      "listAllAddresses",
      "listActivity",
      "listUsers",
      "listClinic",
      "listRole",
    ]),

    checkDate(date) {
      const diffInMs = new Date(this.CheckDate) - new Date(date);
      const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
      console.log(diffInDays);
      // let role_user = [];
      // this.users_obj.forEach((user) => {
      //   this.role_obj.forEach((roles) => {
      //     if (user.role == roles.id) {
      //       role_user.push(roles.name)
      //     }
      //   });
      // });
      // role_user.forEach((name) => {
      //   if (name == 'System Admin' && diffInDays < 3 ) {
      //     console.log('pass');
      //     // if (diffInDays < 3) {
      //     //   return true;
      //     // } else {
      //     //   return false;
      //     // }
      //   }
      // });
      // console.log(this.profile.first_name);
      if (diffInDays < 3) {
        return true;
      } else if (this.profile.role == 'System Admin') {
        return true;
      }
      else {
        return false;
      }
      // if (this.CheckDate < this.encounters.updated_at) {
      //   return true;
      // } else {
      //   return false;
      // }
    },

    cancel() {
      this.$bvModal.hide("save-modal");
      this.$bvModal.show("modal-center");
    },

    openConfirmSave() {
      this.$bvModal.hide("modal-center");
      this.$bvModal.show("save-modal");
    },
    getUser(author) {
      let creator = author;
      this.users_obj.forEach((element) => {
        if (element.id == author) {
          creator = element.full_name;
        }
      });
      return creator;
    },
    encounterDetails(encounter_id) {
      // console.log(encounter_id);
      this.encounter_item = this.encounters.find((evt) => evt.id == encounter_id);
      this.$refs["report-modal"].show();
      // console.log(this.encounter_item);
    },

    editEncounter(encounter_id) {
      this.$router.replace(`/clinic/calllist/${encounter_id}/encounters`);
      this.$store.state.disableTab = true;
      window.scrollTo(0, 0);
    },

    addEncounterReason() {
      // console.log(this.encounters);
      let activities = JSON.parse(window.localStorage.getItem("activity_item"));
      this.activity.forEach((element) => {
        if (element.name == activities.activity) {
          this.activity_id = element.id;
        }
      });
      this.clinic.forEach((element) => {
        if (element.name == activities.location) {
          this.clinic_id = element.id;
        }
      });
      var date = moment().toDate();
      var currentDate = moment(date).format("YYYY-MM-DD HH:mm:ss");
      this.errors = [];
      if (this.encounterReason == "") {
        this.errors["empty_reason"] = "Please select a reason.";
      } else {
        let formData = new FormData();
        formData.append("activity", this.activity_id);
        formData.append("clinic", this.clinic_id);
        formData.append("geography", window.localStorage.getItem("location_item"));
        formData.append("created_at", currentDate);
        formData.append("area", "");
        formData.append("encounter_type", this.encounterReason);
        formData.append("id", this.patient_encounter_id);
        // console.log(formData);
        this.$store.dispatch("addEncounterReason", formData).then(() => {
          // console.log(response.id);
          if (this.successmessage == "success") {
            this.$bvToast.show("success-toast");
            this.$store.state.disableTab = true;
            // this.response_encounter_id = response.data.id;
            setTimeout(() => {
              this.$router.replace({
                name: "addencounter",
                params: { id: this.encounterId },
              });
              // window.location.reload();
              // this.$router.replace(
              //   `/clinic/calllist/${this.encounterId}/encounters`
              // );
            }, 500);
            window.scrollTo(0, 0);

            // this.success = "Encounter Added successfully.";
            // console.log(this.response_encounter_id);
            // this.$router.push({name:"/clinic/calllist/"+ encounterId + "/encounters");
          } else {
            this.$bvToast.show("encounter-error-toast");
            // this.errors["addEncounterReasonError"] =
            //   "Failed to add New Encounter";
          }
        });
      }
    },
    addEncounter(patient_id) {
      if (this.schoolName !== "") {
        this.patient_encounter_id = patient_id;
        this.$bvModal.show("encounter-reason-modal");
      } else {
        this.empty_school = "Patient school not found";
        this.$bvToast.show("encounter-error-toast");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "src/css/main.scss";

p {
  font-size: 18px;
  margin-bottom: 0px;
}

.hover:hover {
  cursor: pointer;
  border-radius: 50%;
  background-color: #d0cbcb;
  color: rgb(206, 203, 203);
}

.input-detail {

  input[type="text"],
  input[type="string"],
  input[type="date"] {
    font-size: 17px;
    border: none;
    // border: 1px solid #f5f5f5;
    height: 40px;
    width: 50%;
    padding: 5px 10px;
    border-radius: 8px;
    background-color: #ffffff;

    &:focus {
      // border: 1px solid #e0d7d7;
      // color: #fff;
      outline: none;
    }
  }

  span {
    color: rgb(112, 112, 112);
  }

  .span {
    color: -internal-light-dark(rgb(84, 84, 84), rgb(170, 170, 170));
    font-size: 17px;
  }
}

.clicked {
  border: 1px solid #e4e4e4;
}

.edit {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .edit-pencil {
    cursor: pointer;

    label {
      height: 28px;
      width: 28px;
      padding: 5px 5px;
      color: #000;
      cursor: pointer;
    }

    // background: #ffffff;
    height: 28px;
    width: 28px;
    border-radius: 20px;
    border: 1px solid #dadce0;
  }
}

h4 {
  color: rgb(102, 100, 100);
}
</style>
