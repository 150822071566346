<template>
  <div class="select-activity">
    <app-header></app-header>
    <div class="select-activity-form">
      <div class="form-heading">
        <img src="../../assets/clinic/images/icon.svg" alt="" />
        <span class="activity-span">Select activity</span>
      </div>
      <form class="activity-form">
        <span
          class="float-right"
          style="color: red; font-size: 14px"
          v-if="errors && errors.activity_error"
        >
          {{ errors.activity_error }}
        </span>
        <label class="activity-label">
          <input
            ref="radio_routine"
            type="radio"
            v-model="radio"
            :disabled="disabledClinic"
            value="Routine Clinic"
            @change="disableSchoolOther"
          />
          <div class="routine-clinic-span">
            <span> Routine Clinic</span>
          </div>
        </label>
        <div v-show="radio_routine" class="school">
          <select
            :disabled="disabledSchoolSelect"
            v-model="select_clinic_school"
          >
            <option value disabled>Select School</option>
            <option
              v-for="(school, index) in locationSchoolList"
              :key="index"
              :value="school.id"
            >
              {{ school.name }}
            </option>
          </select>
        </div>
        <label class="activity-label">
          <input
            ref="radio_school"
            type="radio"
            v-model="radio"
            :disabled="disabledSchool"
            value="School"
            @change="disableClinicOther"
          />
          <div class="routine-clinic-span">
            <span>Select School</span>
          </div>
        </label>
        <div v-show="radio_school" class="school">
          <select :disabled="disabledSchoolSelect" v-model="select_school">
            <option value disabled>Select School</option>
            <option
              v-for="(school, index) in locationSchoolList"
              :key="index"
              :value="school.id"
            >
              {{ school.name }}
            </option>
          </select>
        </div>

        <label class="activity-label">
          <input
            ref="radio_other"
            type="radio"
            v-model="radio"
            value="Other"
            :disabled="disabledOther"
            @change="disableClinicSchool"
          />
          <div class="routine-clinic-span">
            <span>Others</span>
          </div>
        </label>
        <div v-show="radio_other" class="school">
          <select :disabled="disabledOtherSelect" v-model="select_other">
            <option value disabled>Select Others</option>
            <option
              v-for="(other, index) in getOtherList"
              :key="index"
              :value="other.id"
            >
              {{ other.area }}
            </option>
          </select>
        </div>
        <div class="button">
          <b-button class="bg-blue" @click="addActivityArea()">Next</b-button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import AppHeader from "../Header.vue";
import { mapState, mapActions } from "vuex";

export default {
  name: "SelectActivity",
  components: {
    "app-header": AppHeader,
  },
  computed: {
    ...mapState(["schools", "activityareas", "successmessage"]),
    locationSchoolList() {
      let allSchools = this.schools;
      let locationSchools = null;
      if (allSchools && allSchools.length > 0) {
        if (this.location_item.location != "MDC") {
          locationSchools = allSchools.filter((row) => {
            return (
              row.province == this.location_item.province &&
              row.school_type == "target"
            );
          });
        } else {
          locationSchools = allSchools.filter((row) => {
            return row.school_type == "non target";
          });
        }
      }
      return locationSchools;
    },
    getOtherList: function() {
      let mainObj = this.activityareas;
      let otherObj = null;

      if (mainObj && mainObj.length > 0) {
        otherObj = mainObj.filter((row) => {
          return row.activity_id == "Other";
        });
      }

      return otherObj;
    },
  },
  data() {
    return {
      selected: null,
      select_activity: null,
      select_school: "",
      select_clinic_school: "",
      select_other: "",
      area: "",
      available: true,
      disabledClinic: false,
      disabledSchool: false,
      disableSchoolSelect: false,
      disabledSchoolSelect: false,
      disabledOther: false,
      disabledOtherSelect: false,
      enableOther: true,
      location_item: JSON.parse(localStorage.getItem("location_item")),
      radio_school: false,
      radio_other: false,
      radio_routine: false,
      // radio_clinic: false,
      radio: "",
      school_obj: [
        { name: "School1", SchoolId: "101" },
        { name: "School2", SchoolId: "101" },
      ],
      school: [
        { text: "Option A (from options prop)", value: "A" },
        { text: "Option B (from options prop)", value: "B" },
      ],
      other_obj: [
        { name: "Other1", OtherId: "102" },
        { name: "Other2", OtherlId: "102" },
      ],
      other: [
        { text: "Option A (from options prop)", value: "A" },
        { text: "Option B (from options prop)", value: "B" },
      ],
      activities: [
        {
          value: "A",
          text: "Option A (from options prop)",
        },
        {
          value: "B",
          text: "Option B (from options prop)",
        },
      ],
      // radio_routine: true,
      errors: [],
    };
  },
  created() {
    this.listSchools();
    this.listActivityArea();
  },
  mounted() {
    if (this.location_item == null) {
      this.$router.replace({
        name: "selectlocation",
      });
    }
    //
    document.getElementsByClassName(".list_box").hide;
  },
  methods: {
    ...mapActions(["listSchools", "listActivityArea"]),

    disableSchoolOther() {
      this.radio_school = false;
      this.radio_other = false;
      this.radio_routine = true;
    },

    disableClinicOther() {
      this.radio_other = false;
      this.radio_school = true;
      this.radio_routine = false;
    },

    disableClinicSchool() {
      this.radio_school = false;
      this.radio_other = true;
      this.radio_routine = false;
    },

    addActivityArea() {
      this.$store.state.disableTab = false;
      this.errors = [];
      if (this.radio == "") {
        this.errors["activity_error"] = "Activity required";
      } else if (this.radio_routine && this.select_clinic_school == "") {
        this.errors["activity_error"] = "Please select school first";
      } else if (this.radio_school && this.select_school == "") {
        this.errors["activity_error"] = "Please choose school.";
      } else if (this.radio_other && this.select_other == "") {
        this.errors["activity_error"] = "Please choose other.";
      } else {
        // check school item name and other item name
        let firstValue = "";
        let secondValue = "";
        let activtyType = "";
        //console.log("select_other", this.select_other)
        if (this.radio_routine && this.select_clinic_school != "") {
          activtyType = "routine clinic";
          firstValue = this.select_clinic_school;
          let allData = this.schools.find((row) => {
            return row.id == firstValue;
          });
          secondValue = allData.id;
        } else if (this.radio_school && this.select_school != "") {
          activtyType = "school";
          firstValue = this.select_school;
          let allData = this.schools.find((row) => {
            return row.id == firstValue;
          });
          secondValue = allData.id;
        } else if (this.radio_other && this.select_other != "") {
          activtyType = "other";
          firstValue = this.select_other;
          let allData = this.activityareas.find((row) => {
            return row.id == firstValue;
          });
          secondValue = allData.area;
        } else {
          activtyType = "clinic";
          firstValue = "";
          secondValue = "";
        }

        // creatae acivity item obj
        let activityObject = {
          location: this.location_item.location,
          activity: this.radio,
          type: activtyType,
          first: firstValue,
          schoolId: secondValue,
        };

        // store activity item locally
        localStorage.setItem("activity_item", JSON.stringify(activityObject));
        // this.setActivityItem(JSON.stringify(activityObject));
        if (activtyType == "clinic") {
          this.$router.replace("/clinic/calllist");
        } else if (activtyType == "school") {
          this.$router.replace("/clinic/calllist");
        } else {
          this.$router.replace("/clinic/calllist");
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "src/css/main.scss";
</style>
