<template>
  <b-modal
    id="report-modal"
    ref="report-modal"
    size="lg"
    title="View Encounter"
    header-text-size="large"
    hide-footer
    v-if="tag && tag.history"
  >
    <div class="modal-wrapper">
      <div class="d-flex direction-column space-between align-items-center">
        <p>Activity</p>
        <p v-if="tag.activity == 1">Clinic</p>
        <p v-if="tag.activity == 2">School</p>
      </div>
      <div class="space-between direction-column">
        <p>Location</p>
        <span v-if="tag.clinic == 1">CDC</span>
        <span v-if="tag.clinic == 2">KYC</span>
        <span v-if="tag.clinic == 3">TDC</span>
        <span v-if="tag.clinic == 4">MDC</span>
      </div>
      <div class="space-between direction-column">
        <p>Encounter Type</p>
        <p>{{ tag.encounter_type }}</p>
      </div>

      <!-- History Area -->
      <div class="border-box">
        <h4>History</h4>
        <div v-if="tag.history == null">
          <div
            class="underlying-medical-condition space-between direction-column"
          >
            <span>No history has been added.</span>
          </div>
        </div>
        <!-- <div
          v-if="
            tag.history != null &&
              tag.history.no_underlying_medical_condition == true
          "
        >
          <div
            class="underlying-medical-condition space-between direction-column"
          >
            <span>No underlying medical condition</span>
            <span>{{
              tag.history.no_underlying_medical_condition ? "Yes" : "No"
            }}</span>
          </div>
        </div> -->
        <div>
          <div
            class="underlying-medical-condition space-between direction-column"
          >
            <span>Blood Disorder and Bleeding Problem</span>
            <span>{{ tag.history.blood_disorder ? "Yes" : "No" }}</span>
          </div>
          <div
            class="underlying-medical-condition space-between direction-column"
          >
            <span>Diabetes (Sugar)</span>
            <span>{{ tag.history.diabetes ? "Yes" : "No" }}</span>
          </div>
          <div
            class="underlying-medical-condition space-between direction-column"
          >
            <span>Liver Problem</span>
            <span>{{ tag.history.liver_problem ? "Yes" : "No" }}</span>
          </div>
          <div
            class="underlying-medical-condition space-between direction-column"
          >
            <span>Rheumatic Fever</span>
            <span>{{ tag.history.rheumatic_fever ? "Yes" : "No" }}</span>
          </div>
          <div
            class="underlying-medical-condition space-between direction-column"
          >
            <span>Seizures or Epilepsy</span>
            <span>{{ tag.history.seizuers_or_epilepsy ? "Yes" : "No" }}</span>
          </div>
          <div
            class="underlying-medical-condition space-between direction-column"
          >
            <span>Hepatitis B or C</span>
            <span>{{ tag.history.hepatitis_b_or_c ? "Yes" : "No" }}</span>
          </div>
          <div
            class="underlying-medical-condition space-between direction-column"
          >
            <span>HIV</span>
            <span>{{ tag.history.hiv ? "Yes" : "No" }}</span>
          </div>
          <div
            class="underlying-medical-condition space-between direction-column"
            v-if="
              tag.history.high_blood_pressure || tag.history.low_blood_pressure
            "
          >
            <span>{{
              tag.history.high_blood_pressure ? "High BP" : "Low BP"
            }}</span>
            <span>
              {{
                tag.history.high_blood_pressure
                  ? "Yes"
                  : tag.history.low_blood_pressure
                  ? "Yes"
                  : "No"
              }}
            </span>
          </div>
          <div
            class="underlying-medical-condition space-between direction-column"
          >
            <span>Thyroid Disorder</span>
            <span>{{ tag.history.thyroid_disorder ? "Yes" : "No" }}</span>
          </div>
          <div
            class="underlying-medical-condition space-between direction-column"
          >
            <span>Others</span>
            <span>{{
              tag.history.other != "N/a" ? tag.history.other : ""
            }}</span>
          </div>
        </div>
        <div
          class="underlying-medical-condition space-between direction-column"
          v-if="tag.history.no_underlying_medical_condition == true"
        >
          <span>No Underlying Medical Condition</span>
          <span>Yes</span>
        </div>
        <div
          class="underlying-medical-condition space-between direction-column"
          v-if="tag.history.no_underlying_medical_condition == false"
        >
          <span>No Underlying Medical Condition</span>
          <span>No</span>
        </div>
        <div
          class="not-taken-medication space-between direction-column"
          v-if="tag.history != null && tag.history.not_taking_any_medicines"
        >
          <span>Not Taking Any Medicines</span>
          <span>Yes</span>
        </div>
        <div class="not-taken-medication space-between direction-column" v-else>
          <span>Medications</span>
          <span>{{ tag.history.medications }}</span>
        </div>

        <div
          class="no-allergies space-between direction-column"
          v-if="tag.history != null && tag.history.no_allergies"
        >
          <span>No Allergies</span>
          <span>Yes</span>
        </div>
        <div class="no-allergies space-between direction-column" v-else>
          <span>Allergies</span>
          <span>{{ tag.history.allergies }}</span>
        </div>

        <div
          class="no-allergies space-between direction-column"
          v-if="tag.history.FV_Applied_under_CSI_program == true"
        >
          <span>FV Applied Under CSI Program</span>
          <span>
            Yes
            <!-- {{ tag.history.FV_Applied_under_CSI_program ? "Yes" : "No" }} -->
          </span>
        </div>
        <div class="no-allergies space-between direction-column" v-else>
          <span>FV Applied Under CSI Program</span>
          <span>
            No
          </span>
        </div>
      </div>

      <!-- Screening Area -->
      <div class="border-box">
        <h4>Screening</h4>
        <div v-if="tag.screening == null">
          <div class="space-between direction-column">
            <span>No Screening has been added.</span>
          </div>
        </div>
        <div v-if="tag.screening != null">
          <div class="space-between direction-column">
            <span>Plaque</span>
            <span>{{ tag.screening.plaque_score }}</span>
          </div>
          <div
            class="space-between direction-column"
            v-if="tag.screening.decayed_primary_teeth == ''"
          >
            <span>No. of Decayed Primary Teeth</span>
            <span>0</span>
          </div>
          <div
            class="space-between direction-column"
            v-else-if="tag.screening.decayed_primary_teeth != ''"
          >
            <span>No. of Decayed Primary Teeth</span>
            <span>{{ tag.screening.decayed_primary_teeth }}</span>
          </div>
          <div
            class="space-between direction-column"
            v-if="tag.screening.decayed_permanent_teeth == ''"
          >
            <span>No. of Decayed Permanent Teeth</span>
            <span>0</span>
          </div>
          <div
            class="space-between direction-column"
            v-else-if="tag.screening.decayed_permanent_teeth != ''"
          >
            <span>No. of Decayed Permanent Teeth</span>
            <span>{{ tag.screening.decayed_permanent_teeth }}</span>
          </div>
          <div class="space-between direction-column">
            <span>Cavity Permanent Molar or Premolar</span>
            <span>
              {{
                tag.screening.cavity_permanent_molar_or_premolar ? "Yes" : "No"
              }}
            </span>
          </div>
          <div class="space-between direction-column">
            <span>Cavity Permanent Canine or Incisor</span>
            <span>
              {{
                tag.screening.cavity_permanent_canine_or_incisor ? "Yes" : "No"
              }}
            </span>
          </div>
          <div class="space-between direction-column">
            <span>Pain Due to reversible pulpitis</span>
            <span>{{
              tag.screening.pain_due_to_reversible_pulpitis ? "Yes" : "No"
            }}</span>
          </div>
          <div class="space-between direction-column">
            <span>Active Infection</span>
            <span>{{ tag.screening.active_infection ? "Yes" : "No" }}</span>
          </div>
          <div class="space-between direction-column">
            <span>Full mouth SDF applied</span>
            <span>{{
              tag.screening.full_mouth_sdf_applied ? "Yes" : "No"
            }}</span>
          </div>
          <h6 class="space-between direction-column">
            How often in the last 3 months have you
          </h6>
          <div class="space-between direction-column">
            <span>Had pain in the mounth or teeth</span>
            <span>
              {{
                capitalizeFirstLetter(tag.screening.had_pain_in_mouth_or_teeth)
              }}
            </span>
          </div>
          <div class="space-between direction-column">
            <span>Had stores in your mouth</span>
            <span>{{
              capitalizeFirstLetter(tag.screening.had_sores_in_your_mouth)
            }}</span>
          </div>
          <div class="space-between direction-column">
            <span>Had bad breath</span>
            <span>{{
              capitalizeFirstLetter(tag.screening.had_bad_breath)
            }}</span>
          </div>
          <div class="space-between direction-column">
            <span>Had food stuck between your teeth</span>
            <span>
              {{
                capitalizeFirstLetter(
                  tag.screening.had_food_stuck_between_your_teeth
                )
              }}
            </span>
          </div>
          <div v-if="tag.screening.comments != ''">
            <div class="space-between d-flex direction-column">
              <h6>Comment</h6>
            </div>
            <div class="space-between d-flex direction-column">
              {{ capitalizeFirstLetter(tag.screening.comments) }}
            </div>
          </div>
        </div>
      </div>
      <!-- Perodontal Area -->
      <div class="border-box">
        <h4>Periodontal</h4>
        <div v-if="tag.periodontal != null">
          <div class="column-1">
            <div class="row-1">
              <div class="number">{{ tag.periodontal.teeth_17_14 }}</div>
              <div class="interval">17-14</div>
            </div>
            <div class="row-1">
              <div class="number">{{ tag.periodontal.teeth_13_23 }}</div>
              <div class="interval">13-23</div>
            </div>
            <div class="row-1">
              <div class="number">{{ tag.periodontal.teeth_24_27 }}</div>
              <div class="interval">24-27</div>
            </div>
            <div class="row-1">
              <div class="number">{{ tag.periodontal.teeth_47_44 }}</div>
              <div class="interval">47-44</div>
            </div>
            <div class="row-1">
              <div class="number">{{ tag.periodontal.teeth_43_33 }}</div>
              <div class="interval">43-33</div>
            </div>
            <div class="row-1">
              <div class="number">{{ tag.periodontal.teeth_34_37 }}</div>
              <div class="interval">34-37</div>
            </div>
          </div>

          <div
            class="space-between direction-column"
            v-if="tag.periodontal.diagnosis != ''"
          >
            <span>Diagnosis</span>
            <span>{{ capitalizeFirstLetter(tag.periodontal.diagnosis) }}</span>
          </div>

          <h6>Treatment</h6>
          <div class="space-between direction-column">
            <span>Hand Scaling</span>
            <span>{{ tag.periodontal.hand_scaling ? "Yes" : "No" }}</span>
          </div>
          <div class="space-between direction-column">
            <span>Ultrasonic Scaling</span>
            <span>{{ tag.periodontal.ultrasonic_scaling ? "Yes" : "No" }}</span>
          </div>
          <div class="space-between direction-column">
            <span>Others</span>
            <span>{{
              tag.periodontal.other ? tag.periodontal.other : "N/a"
            }}</span>
          </div>
          <div v-if="tag.periodontal.notes != ''">
            <div class="space-between d-flex direction-column">
              <h4>Notes</h4>
            </div>
            <div class="space-between d-flex direction-column">
              {{ capitalizeFirstLetter(tag.periodontal.notes) }}
            </div>
          </div>
        </div>
        <div v-else>
          <div class="space-between direction-column">
            <span>No periodontal has been added.</span>
          </div>
        </div>
      </div>
      <!-- Diagnostic Area -->
      <div class="border-box">
        <h4>Diagnostic</h4>

        <div v-if="tag.diagnostic != null">
          <div class="space-between">
            <span>PBW</span>
            <span>{{ tag.diagnostic.PBW ? "Yes" : "No" }}</span>
          </div>
          <div class="space-between">
            <span>OPG</span>
            <span>{{ tag.diagnostic.OPG ? "Yes" : "No" }}</span>
          </div>
          <div class="space-between">
            <span>PA</span>
            <span>{{ tag.diagnostic.PA ? "Yes" : "No" }}</span>
          </div>
          <div class="space-between">
            <span>Occlusal View</span>
            <span>{{ tag.diagnostic.occlusal_view ? "Yes" : "No" }}</span>
          </div>
          <div class="diagonistic-finding">
            <h4>Diagnostic finding on X-Ray</h4>
            <div
              class="diagnostic-image grid-column"
              v-if="
                tag.diagnostic.xraygallery &&
                  tag.diagnostic.xraygallery.length > 0
              "
            >
              <div
                v-for="(xRayImg, index) in tag.diagnostic.xraygallery"
                :key="index"
              >
                <a :href="xRayImg.image" target="_blank">
                  <img
                    :src="xRayImg.image"
                    height="150"
                    width
                    :alt="xRayImg.image_title"
                  />
                  <p v-html="xRayImg.image_title"></p>
                </a>
              </div>
            </div>
          </div>
          <h4>Oral Mucosal Lesions</h4>
          <div class="oral-mucosal">
            <h6>Section of the mouth</h6>
            <div>
              {{ capitalizeFirstLetter(tag.diagnostic.section_of_mouth) }}
            </div>
          </div>
          <div>
            <h6>Description of lesion</h6>
            <div>
              {{ capitalizeFirstLetter(tag.diagnostic.description_of_lesion) }}
            </div>
          </div>
          <div>
            <h6>Other Special Test</h6>
            <div>
              {{ capitalizeFirstLetter(tag.diagnostic.other_special_test) }}
            </div>
          </div>
        </div>
        <div v-else>
          <div class="space-between direction-column">
            <span>No diagnostic has been added.</span>
          </div>
        </div>
      </div>
      <!-- Treatment Area -->
      <div class="border-box">
        <h4>Treatment</h4>
        <div v-if="tag.treatment != null">
          <div class="treatment-report">
            <div class="header-buttons">
              <b-button pill class="sdf sdf-button">SDF</b-button>
              <b-button pill class="seal-button seal">SEAL</b-button>
              <b-button pill class="restoration-button restoration"
                >FILLING</b-button
              >
              <b-button pill class="art-button art">ART</b-button>
              <b-button pill class="exo-button exo">EXO</b-button>
              <b-button pill class="crown-button crowns">CROWN</b-button>
              <b-button pill class="root-button root">ROOT</b-button>
              <b-button pill class="untr-button untr">UNTR</b-button>
            </div>
            <div class="treatment-body--wrapper">
              <div class="right-indicator same-style">
                <span class="number">10</span>
                <span class="text">R</span>
                <span class="number">40</span>
              </div>
              <div class="left-indicator same-style">
                <span class="number">20</span>
                <span class="text">L</span>
                <span class="number">30</span>
              </div>
              <div class="middle-indicator">
                <button class="pills">00</button>
              </div>
              <div class="indicator--header">01</div>
              <div class="teeth-number-selection">
                <div class="buttons-group-one">
                  <div class="top-button-group">
                    <div class="top-button">
                      <div class="horizontal-line"></div>
                      <div class="round-buttons justify-content-end">
                        <div v-for="(teeth, index) in teeth_11_18" :key="index">
                          <button
                            type="button"
                            class="round"
                            :id="teeth.id"
                            :ref="teeth.id"
                            @click="showContentObject(teeth.id)"
                          >
                            {{ teeth.id + 10 }}
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="bottom-button">
                      <div class="round-buttons justify-content-end">
                        <div v-for="(teeth, index) in teeth_51_55" :key="index">
                          <button
                            type="button"
                            class="round"
                            :id="teeth.id"
                            @click="showContentObject(teeth.id)"
                          >
                            {{ teeth.id + 18 }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="bottom-button-group">
                    <div class="top-button">
                      <div class="round-buttons justify-content-end">
                        <div v-for="(teeth, index) in teeth_81_85" :key="index">
                          <button
                            type="button"
                            class="round"
                            :id="teeth.id"
                            @click="showContentObject(teeth.id)"
                          >
                            {{ teeth.id + 33 }}
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="bottom-button">
                      <div class="round-buttons justify-content-end">
                        <div
                          v-for="(teeth, index) in this.teeth_41_48"
                          :key="index"
                        >
                          <button
                            type="button"
                            class="round"
                            :id="teeth.id"
                            @click="showContentObject(teeth.id)"
                          >
                            {{ teeth.id + 16 }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="buttons-group-two">
                  <div class="top-button-group">
                    <div class="top-button">
                      <div class="round-buttons justify-content-start">
                        <div v-for="(teeth, index) in teeth_21_28" :key="index">
                          <button
                            type="button"
                            class="round"
                            :id="teeth.id"
                            @click="showContentObject(teeth.id)"
                          >
                            {{ teeth.id + 12 }}
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="bottom-button">
                      <div class="round-buttons justify-content-start">
                        <div v-for="(teeth, index) in teeth_61_65" :key="index">
                          <button
                            class="round"
                            type="button"
                            :id="teeth.id"
                            @click="showContentObject(teeth.id)"
                          >
                            {{ teeth.id + 23 }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="bottom-button-group">
                    <div class="top-button">
                      <div class="round-buttons justify-content-start">
                        <div v-for="(teeth, index) in teeth_71_75" :key="index">
                          <button
                            type="button"
                            class="round"
                            :id="teeth.id"
                            @click="showContentObject(teeth.id)"
                          >
                            {{ teeth.id + 28 }}
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="bottom-button">
                      <div class="round-buttons justify-content-start">
                        <div v-for="(teeth, index) in teeth_31_38" :key="index">
                          <button
                            type="button"
                            class="round"
                            :id="teeth.id"
                            @click="showContentObject(teeth.id)"
                          >
                            {{ teeth.id + 14 }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="indicator--footer">02</div>
            </div>
            <div class="row mb-2">
              <div class="col-12">
                <span class="form-error" v-if="errors.message">{{
                  errors.message
                }}</span>
              </div>
            </div>
            <div class="row justify-content-end">
              <div class="col-4">
                <button class="btn btn-primary" @click="AddColor()">
                  View Selected Teeth
                </button>
              </div>
            </div>
            <div class="treatment--footer">
              <div class="space-between direction-column">
                <span>SDF Whole Mouth</span>
                <span>{{ tag.treatment.sdf_whole_mouth ? "Yes" : "No" }}</span>
              </div>
              <div class="space-between direction-column">
                <span>FV Applied</span>
                <span>{{ tag.treatment.fv_applied ? "Yes" : "No" }}</span>
              </div>
              <div class="space-between direction-column">
                <span>OHE Given</span>
                <span>{{ tag.treatment.OHE_given ? "Yes" : "No" }}</span>
              </div>
              <div class="space-between direction-column">
                <span>Treatment Plan Complete</span>
                <span>{{
                  tag.treatment.treatment_plan_complete ? "Yes" : "No"
                }}</span>
              </div>
              <div class="note" v-if="tag.treatment.notes != ''">
                <p>Note</p>
                <div>
                  <span>{{ capitalizeFirstLetter(tag.treatment.notes) }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="space-between direction-column">
            <span>No Treatment has been added.</span>
          </div>
        </div>
      </div>

      <!-- Referral Area -->
      <div class="border-box">
        <h4>Referral</h4>
        <div v-if="tag.referral != null">
          <div>
            <div class="space-between direction-column">
              <span>No Referral</span>
              <span>{{ tag.referral.no_referral ? "Yes" : "No" }}</span>
            </div>
          </div>
          <div>
            <div class="space-between direction-column">
              <span>Routine Clinic</span>
              <span>{{
                tag.referral.clinic
                  ? this.patients.recall_date + "/" + this.patients.recall_time
                  : "No"
              }}</span>
            </div>

            <div class="space-between direction-column">
              <span>Hygienist</span>
              <span>{{ tag.referral.hygienist ? "Yes" : "No" }}</span>
            </div>

            <div class="space-between direction-column">
              <span>Dentist</span>
              <span>{{ tag.referral.dentist ? "Yes" : "No" }}</span>
            </div>

            <div class="space-between direction-column">
              <span>General Physician</span>
              <span>{{ tag.referral.general_physician ? "Yes" : "No" }}</span>
            </div>

            <div class="space-between direction-column">
              <span>Others</span>
              <span>
                {{
                  tag.referral.other != "N/a"
                    ? capitalizeFirstLetter(tag.referral.other)
                    : ""
                }}
              </span>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="space-between direction-column">
            <span>No referral has been added.</span>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      id="show-restoration-detail-modal"
      hide-footer
      centered
      title="Treatment Type : RESTORATION "
    >
      <!-- Emulate built in modal footer ok and cancel button actions -->
      <div class="underlying-medical-condition space-between direction-column">
        <span>Composite</span>
        <span>{{ this.restoration.composite }}</span>
      </div>
      <div class="underlying-medical-condition space-between direction-column">
        <span>GIC</span>
        <span>{{ this.restoration.GIC }}</span>
      </div>
      <div class="underlying-medical-condition space-between direction-column">
        <span>Mesial</span>
        <span>{{ this.restoration.mesial }}</span>
      </div>
      <div class="underlying-medical-condition space-between direction-column">
        <span>Occlusal</span>
        <span>{{ this.restoration.occlusal }}</span>
      </div>
      <div class="underlying-medical-condition space-between direction-column">
        <span>Distal</span>
        <span>{{ this.restoration.distal }}</span>
      </div>
      <div class="underlying-medical-condition space-between direction-column">
        <span>Buccal</span>
        <span>{{ this.restoration.buccal }}</span>
      </div>
      <div class="underlying-medical-condition space-between direction-column">
        <span>Palatal</span>
        <span>{{ this.restoration.palatal }}</span>
      </div>
      <div class="underlying-medical-condition space-between direction-column">
        <span>Detail</span>
        <span>{{
          this.restoration.detail == "" ? "N/a" : this.restoration.detail
        }}</span>
      </div>
    </b-modal>
    <b-modal
      id="show-crown-detail-modal"
      hide-footer
      centered
      title="Treatment Type : CROWN "
    >
      <!-- Emulate built in modal footer ok and cancel button actions -->
      <div class="underlying-medical-condition space-between direction-column">
        <span>Preparation</span>
        <span>{{ this.crown.preparation }}</span>
      </div>
      <div class="underlying-medical-condition space-between direction-column">
        <span>Cementation</span>
        <span>{{ this.crown.cementation }}</span>
      </div>
      <div class="underlying-medical-condition space-between direction-column">
        <span>Other</span>
        <span>{{ this.crown.other == "" ? "N/a" : this.crown.other }}</span>
      </div>
      <div class="underlying-medical-condition space-between direction-column">
        <span>CrownType</span>
        <span>{{ this.crown.crown_type }}</span>
      </div>
      <div class="underlying-medical-condition space-between direction-column">
        <span>Detail</span>
        <span>{{ this.crown.detail == "" ? "N/a" : this.crown.detail }}</span>
      </div>
    </b-modal>
    <b-modal
      id="show-root-detail-modal"
      hide-footer
      centered
      title="Treatment Type : ROOT "
    >
      <!-- Emulate built in modal footer ok and cancel button actions -->
      <div class="underlying-medical-condition space-between direction-column">
        <span>Pulpectomy</span>
        <span>{{ this.root.pulpectomy }}</span>
      </div>
      <div class="underlying-medical-condition space-between direction-column">
        <span>Pulpotomy</span>
        <span>{{ this.root.pulpotomy }}</span>
      </div>
      <div class="underlying-medical-condition space-between direction-column">
        <span>Dressing</span>
        <span>{{ this.root.dressing }}</span>
      </div>
      <div class="underlying-medical-condition space-between direction-column">
        <span>Root Preparation</span>
        <span>{{ this.root.root_preparation }}</span>
      </div>
      <div class="underlying-medical-condition space-between direction-column">
        <span>Root Filling</span>
        <span>{{ this.root.root_filling }}</span>
      </div>
      <div class="underlying-medical-condition space-between direction-column">
        <span>Detail</span>
        <span>{{ this.root.detail == "" ? "N/a" : this.root.detail }}</span>
      </div>
    </b-modal>
  </b-modal>
</template>
<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "ReportModal",
  props: ["tag"],
  data() {
    return {
      activity_name: "",
      backgroundSdf: false,
      backgroundSeal: false,
      backgroundRestoration: false,
      backgroundArt: false,
      backgroundExo: false,
      backgroundCrown: false,
      backgroundRoot: false,
      backgroundUntr: false,
      // showButton: true,
      errors: [],
      restoration: {},
      crown: {},
      root: {},
      // teeth_11_18: [],
      // teeth_51_55: [],
      // teeth_81_85: [],
      // teeth_41_48: [],
      // teeth_21_28: [],
      // teeth_61_65: [],
      // teeth_71_75: [],
      // teeth_31_38: [],
    };
  },

  computed: {
    ...mapState([
      "successmessage",
      "encounters",
      "errormessage",
      "teeth_obj",
      "message",
      "activity",
      "schools",
      "patients",
    ]),

    teeth_11_18: function() {
      let teethNumber = [];
      this.teeth_obj.forEach((teeth) => {
        if (teeth.id < 9) {
          teethNumber.push(teeth);
        }
      });
      // console.log(teethNumber);
      return teethNumber.reverse();
    },
    teeth_21_28: function() {
      let teethNumber = [];
      this.teeth_obj.forEach((teeth) => {
        if (teeth.id > 8 && teeth.id < 17) {
          teethNumber.push(teeth);
        }
      });
      return teethNumber;
    },
    teeth_31_38: function() {
      let teethNumber = [];
      this.teeth_obj.forEach((teeth) => {
        if (teeth.id > 16 && teeth.id < 25) {
          teethNumber.push(teeth);
        }
      });
      return teethNumber;
    },
    teeth_41_48: function() {
      let teethNumber = [];
      this.teeth_obj.forEach((teeth) => {
        if (teeth.id > 24 && teeth.id < 33) {
          teethNumber.push(teeth);
        }
      });
      return teethNumber.reverse();
    },
    teeth_51_55: function() {
      let teethNumber = [];
      this.teeth_obj.forEach((teeth) => {
        if (teeth.id > 32 && teeth.id < 38) {
          teethNumber.push(teeth);
        }
      });
      return teethNumber.reverse();
    },
    teeth_61_65: function() {
      let teethNumber = [];
      this.teeth_obj.forEach((teeth) => {
        if (teeth.id > 37 && teeth.id < 43) {
          teethNumber.push(teeth);
        }
      });
      return teethNumber;
    },
    teeth_71_75: function() {
      let teethNumber = [];
      this.teeth_obj.forEach((teeth) => {
        if (teeth.id > 42 && teeth.id < 48) {
          teethNumber.push(teeth);
        }
      });
      return teethNumber;
    },
    teeth_81_85: function() {
      let teethNumber = [];
      this.teeth_obj.forEach((teeth) => {
        if (teeth.id > 47 && teeth.id < 53) {
          teethNumber.push(teeth);
        }
      });
      return teethNumber.reverse();
    },
  },

  // watch: {
  //   tag: function() {
  //     this.$nextTick().then(() => this.AddColor());
  //   }
  // },

  created() {
    this.listActivity();
    let formData = new FormData();
    formData.append("id", "4850C5C2EFE9425E81410E6F58DA0C25");
    this.addEncounterReason(formData);
    this.listTeeth();
    this.listSchools();
  },

  methods: {
    ...mapActions([
      "addEncounterReason",
      "listActivity",
      "listTeeth",
      "listSchools",
    ]),
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    AddColor() {
      this.errors = [];
      if (this.tag.treatment !== null) {
        if (this.tag.treatment.treatment_info.length > 0) {
          this.teeth_obj.forEach((element) => {
            this.tag.treatment.treatment_info.forEach((treatment) => {
              if (element.id == treatment.teeth.id) {
                var elem = document.getElementById(element.id);
                if (treatment.treatment_types == "SDF") {
                  elem.classList.add("sdf");
                }
                if (treatment.treatment_types == "SEAL") {
                  elem.classList.add("seal");
                }
                if (treatment.treatment_types == "RESTORATION") {
                  elem.classList.add("restoration");
                }
                if (treatment.treatment_types == "EXO") {
                  elem.classList.add("exo");
                }
                if (treatment.treatment_types == "ART") {
                  elem.classList.add("art");
                }
                if (treatment.treatment_types == "CROWN") {
                  elem.classList.add("crowns");
                }
                if (treatment.treatment_types == "ROOT") {
                  elem.classList.add("root");
                }
                if (treatment.treatment_types == "UNTR") {
                  elem.classList.add("untr");
                }
              }
            });
          });
        } else {
          this.errors["message"] =
            "Could not find any treatment Info in this treatment of encounter";
          setTimeout(() => {
            this.errors = [];
          }, 1500);
        }
      }
    },
    showContentObject(id) {
      if (this.tag.treatment !== null) {
        if (this.tag.treatment.treatment_info.length > 0) {
          this.tag.treatment.treatment_info.forEach((treatmentInfo) => {
            if (treatmentInfo.teeth.id == id) {
              if (treatmentInfo.treatment_types == "RESTORATION") {
                this.restoration = treatmentInfo.content_object;
                this.$bvModal.show("show-restoration-detail-modal");
                console.log(treatmentInfo);
              } else if (treatmentInfo.treatment_types == "CROWN") {
                this.crown = treatmentInfo.content_object;

                this.$bvModal.show("show-crown-detail-modal");
                console.log(treatmentInfo);
              } else if (treatmentInfo.treatment_types == "ROOT") {
                this.root = treatmentInfo.content_object;

                this.$bvModal.show("show-root-detail-modal");
                console.log(treatmentInfo);
              }
            }
          });
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "src/css/main.scss";
</style>
