<template>
  <div id="app" class="app-content">
    <!-- <div v-if="spinner" class="text-center mt-5">
      <b-spinner
        variant="primary"
        type="grow"
        label="Loading Visualization"
        style="width: 5rem; height: 5rem"
      ></b-spinner>
    </div> -->
    <canvas :id="tag"></canvas>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
export default {
  name: "Visualization",
  props: ["tag"],
  components: {},
  data() {
    return {
      treatmentChart: null,
      screeningAchievementChart: null,
      treatmentScreenedChart: null,
      allLocationChart: null,
      combodiaSmileChart: null,
      childOheChart: null,
      // spinner: false,
    };
  },

  computed: {
    ...mapState([
      "overviewbargraph_obj",
      "treatment_by_type_obj",
      "screening_achievement_vs_target_obj",
      "treatment_progress_screened_obj",
      "all_location_obj",
      "csi_obj",
      "ohe_obj",
      "epidemiological_sum_obj",
      "carepattern_obj",
      "preventive_obj",
      "intervention_obj",
      "oral_health_obj",
      "treatment_basic_obj",
      "treatment_treatments_obj",
    ]),
  },

  created() {
    this.listHomePageBarGraph().then(() => {
      // this.spinner = true;
      this.createHomePageBarGraph();
      // this.spinner = false;
    });

    this.listTreatmentByTypeBarGraph().then(() => {
      this.createTreatmentByTypeBarGraph();
    });

    this.listScreeningAchievementTargetBarGraph().then(() => {
      this.createScreeningAchievementTargetBarGraph();
    });

    this.listTreatmentScreenedViewGraph().then(() => {
      this.createTreatmentScreenedViewGraph();
    });

    this.listAllLocationViewGraph().then(() => {
      this.createAllLocationViewGraph();
    });

    this.listCombodiaSmileInterventionViewBarGraph().then(() => {
      this.createCombodiaSmileInterventionViewBarGraph();
    });

    this.listChildrenOheViewBarGraph().then(() => {
      this.createChildrenOheViewBarGraph();
    });

    // this.postCsiTable().then(() => {
    //   this.createChildrenOheViewBarGraph();
    // });

    // this.listEpidemiologicalViewBarGraph().then(() => {
    //   this.createEpideomiologicalViewBarGraph();
    // });

    // this.listCarepatternsViewBarGraph().then(() => {
    //   this.createCarepatternsViewBarGraph();
    // });

    // this.listTreatmentsurgicalViewBarGraph().then(() => {
    //   this.createTreatmentsurgicalViewBarGraph();
    // });
    // this.listInterventionextractionViewBarGraph().then(() => {
    //   this.createInterventionextractionViewBarGraph();
    // });
    // this.listEffectsizeoralhealthViewBarGraph().then(() => {
    //   this.createEffectsizeoralhealthViewBarGraph();
    // });
    // this.listTreatmentBasicTable();
    // this.listTreatmentTreatmentsTable()
  },
  // Watch the value of the incoming in the state and initiating  destroying of first instance of the chart and create the second instance of the chart
  watch: {
    treatment_by_type_obj: function() {
      if (this.treatment_by_type_obj.locationChart.data) {
        this.destroyTreatmentChart();
        this.createTreatmentByTypeBarGraph();
      }
    },
    screening_achievement_vs_target_obj: function() {
      if (this.screening_achievement_vs_target_obj.locationChart.datachart) {
        this.destroyScreeningChart();
        this.createScreeningAchievementTargetBarGraph();
      }
    },
    treatment_progress_screened_obj: function() {
      if (this.treatment_progress_screened_obj.locationChart.data) {
        this.destroyTreatmentProgressChart();
        this.createTreatmentScreenedViewGraph();
      }
    },
    all_location_obj: function() {
      if (this.all_location_obj.locationChart.data) {
        this.destroyAllLocationChart();
        this.createAllLocationViewGraph();
      }
    },
    csi_obj: function() {
      if (this.csi_obj.locationChart.datachart) {
        this.destroyCombodiaSmileChart();
        this.createCombodiaSmileInterventionViewBarGraph();
      }
    },
    ohe_obj: function() {
      if (this.ohe_obj.locationChart.datachart) {
        this.destroyOralHealthChart();
        this.createChildrenOheViewBarGraph();
      }
    },
  },

  methods: {
    ...mapActions([
      "listHomePageBarGraph",
      "listTreatmentByTypeBarGraph",
      "listScreeningAchievementTargetBarGraph",
      "listTreatmentScreenedViewGraph",
      "listAllLocationViewGraph",
      "listCombodiaSmileInterventionViewBarGraph",
      "listChildrenOheViewBarGraph",
      "listEpidemiologicalViewBarGraph",
      "listCarepatternsViewBarGraph",
      "listTreatmentsurgicalViewBarGraph",
      "listInterventionextractionViewBarGraph",
      "listEffectsizeoralhealthViewBarGraph",
      "postCsiTable",
      //"listTreatmentBasicTable",
      //"listTreatmentTreatmentsTable",
    ]),
    ...mapMutations(["setCsiGraph"]),
    // Destroying the first Instance

    destroyTreatmentChart() {
      if (this.treatment_by_type_obj.locationChart.data) {
        this.treatmentChart.destroy();
      }
    },
    destroyScreeningChart() {
      if (this.screening_achievement_vs_target_obj.locationChart.data) {
        this.screeningAchievementChart.destroy();
      }
    },
    destroyTreatmentProgressChart() {
      if (this.treatment_progress_screened_obj.locationChart.data) {
        this.treatmentScreenedChart.destroy();
      }
    },
    destroyAllLocationChart() {
      if (this.all_location_obj.locationChart.data) {
        this.allLocationChart.destroy();
      }
    },
    destroyCombodiaSmileChart() {
      if (this.csi_obj.locationChart.data) {
        this.combodiaSmileChart.destroy();
      }
    },
    destroyOralHealthChart() {
      if (this.ohe_obj.locationChart.data) {
        this.childOheChart.destroy();
      }
    },

    // Creating the graph instance
    createHomePageBarGraph() {
      const ctx = document.getElementById("settingsgraph");
      const _ = new Chart(ctx, {
        type: "bar",
        data: {
          labels: ["Total", "CDC", "KYC", "TDC", "MDC"],
          // labels: this.overviewbargraph_obj.locationChart.labels,
          datasets: [
            {
              label: "No of Patient",
              borderColor: "#FF8AA3",
              borderWidth: 1,
              data: [
                this.overviewbargraph_obj.locationChart.datachart
                  .number_of_patients.total_patient,
                this.overviewbargraph_obj.locationChart.datachart
                  .number_of_patients.patient_cdc,
                this.overviewbargraph_obj.locationChart.datachart
                  .number_of_patients.patient_kyc,
                this.overviewbargraph_obj.locationChart.datachart
                  .number_of_patients.patient_tdc,
                this.overviewbargraph_obj.locationChart.datachart
                  .number_of_patients.patient_mdc,
              ],
              backgroundColor: "#FFB1C1",
            },
            {
              label: "No of Contacts",
              borderColor: "#4FAEEE",
              borderWidth: 1,
              data: [
                this.overviewbargraph_obj.locationChart.datachart
                  .number_of_contacts.total_contacts,
                this.overviewbargraph_obj.locationChart.datachart
                  .number_of_contacts.contacts_cdc,
                this.overviewbargraph_obj.locationChart.datachart
                  .number_of_contacts.contacts_kyc,
                this.overviewbargraph_obj.locationChart.datachart
                  .number_of_contacts.contacts_tdc,
                this.overviewbargraph_obj.locationChart.datachart
                  .number_of_contacts.contacts_mdc,
              ],
              backgroundColor: "#9AD0F5",
            },
            {
              label: "No of Treatments",
              borderColor: "#FFCD56",
              borderWidth: 1,
              data: [
                this.overviewbargraph_obj.locationChart.datachart
                  .number_of_treatments.total_treatment,
                this.overviewbargraph_obj.locationChart.datachart
                  .number_of_treatments.treatment_cdc,
                this.overviewbargraph_obj.locationChart.datachart
                  .number_of_treatments.treatment_kyc,
                this.overviewbargraph_obj.locationChart.datachart
                  .number_of_treatments.treatment_tdc,
                this.overviewbargraph_obj.locationChart.datachart
                  .number_of_treatments.treatment_mdc,
              ],
              backgroundColor: "#FFE6AA",
            },
            {
              label: "No of Restoration",
              borderColor: "#78D0D0",
              borderWidth: 1,
              data: [
                this.overviewbargraph_obj.locationChart.datachart
                  .number_of_restorations.total_restoration,
                this.overviewbargraph_obj.locationChart.datachart
                  .number_of_restorations.restoration_cdc,
                this.overviewbargraph_obj.locationChart.datachart
                  .number_of_restorations.restoration_kyc,

                this.overviewbargraph_obj.locationChart.datachart
                  .number_of_restorations.restoration_tdc,
                this.overviewbargraph_obj.locationChart.datachart
                  .number_of_restorations.restoration_mdc,
              ],
              backgroundColor: "#A5DFDF",
            },
          ],
        },
        options: {
          responsive: true,
          aspectRatio: 1,
          maintainAspectRatio: false,
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
        },
        //  data: this.overviewbargraph_obj.locationChart.data,
        // options: this.overviewbargraph_obj.locationChart.options,
      });
    },
    createTreatmentByTypeBarGraph() {
      var ctx = document.getElementById("treatmentByType");
      this.treatmentChart = new Chart(ctx, {
        type: "bar",
        data: {
          labels: [
            "TOTAL",
            "PERIDONTAL",
            "SDF",
            "FV",
            "SEAL",
            "FILLING",
            "ART",
            "EXTRACTION",
            "CROWN",
            "RCT",
            "UNTR",
          ],
          datasets: [
            {
              label: "",
              borderColor: "#FF8AA3",
              borderWidth: 1,
              data: [
                this.treatment_by_type_obj.locationChart.data.Total,
                this.treatment_by_type_obj.locationChart.data.Periodontol,
                this.treatment_by_type_obj.locationChart.data.SDF,
                this.treatment_by_type_obj.locationChart.data.FV,
                this.treatment_by_type_obj.locationChart.data.SEAL,
                this.treatment_by_type_obj.locationChart.data.FILLING,
                this.treatment_by_type_obj.locationChart.data.ART,
                this.treatment_by_type_obj.locationChart.data.Extraction,
                this.treatment_by_type_obj.locationChart.data.CROWN,
                this.treatment_by_type_obj.locationChart.data.RCT,
                this.treatment_by_type_obj.locationChart.data.Untreated,
              ],
              backgroundColor: [
                "#FFB1C1",
                "#9AD0F5",
                "#FFE6AA",
                "#A5DFDF",
                "#CCB2FF",
                "#E4E5E7",
                "#FFCF9F",
                "#707070",
                "#CCDFA5",
                "#87FBA2",
                "#E39AF5",
              ],
            },
          ],
        },
        options: {
          responsive: true,
          // tooltips: false,
          legend: {
            display: false,
          },
          aspectRatio: 1,
          maintainAspectRatio: false,
          // scales: {
          //   yAxes: [
          //   ],
          // },
        },
        //  data: this.overviewbargraphpost_obj.locationChart.data,
        //  options: this.overviewbargraphpost_obj.locationChart.options,
      });
    },
    createScreeningAchievementTargetBarGraph() {
      const ctx = document.getElementById("screeningAchievement");
      this.screeningAchievementChart = new Chart(ctx, {
        type: "bar",
        data: {
          labels: ["Not Yet Screened", "Screened"],
          datasets: [
            {
              label: "",
              borderColor: "#FF8AA3",
              barThickness: 60,
              maxBarThickness: 80,
              stack: "sensitivity",
              data: [
                this.screening_achievement_vs_target_obj.locationChart.datachart
                  .not_yet_screened,
                this.screening_achievement_vs_target_obj.locationChart.datachart
                  .screened,
              ],
              backgroundColor: ["#FFB1C1", "#9AD0F5"],
            },
          ],
        },
        options: {
          responsive: true,
          legend: {
            display: false,
          },
          aspectRatio: 1,
          maintainAspectRatio: false,
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
        },
        //  data: this.overviewbargraphpost_obj.locationChart.data,
        //  options: this.overviewbargraphpost_obj.locationChart.options,
      });
    },
    createTreatmentScreenedViewGraph() {
      const ctx = document.getElementById("treatmentScreened");
      this.treatmentScreenedChart = new Chart(ctx, {
        type: "bar",
        data: {
          labels: [
            //  this.treatment_progress_screened_obj.locationChart.label
            "No further Treatment",
            "Treatment Completed",
            "Treatment Not Completed",
            "Not Yet to Clinic",
          ],
          datasets: [
            {
              label: "",
              borderColor: "#FF8AA3",
              barThickness: 60,
              maxBarThickness: 80,
              stack: "sensitivity",
              data: [
                this.treatment_progress_screened_obj.locationChart.data
                  .no_further_treatment,
                this.treatment_progress_screened_obj.locationChart.data
                  .treatment_completed,
                this.treatment_progress_screened_obj.locationChart.data
                  .treatment_not_completed,
                this.treatment_progress_screened_obj.locationChart.data
                  .not_yet_to_clinic,
              ],
              backgroundColor: ["#FFB1C1", "#9AD0F5", "#FFE6AA", "#A5DFDF"],
            },
          ],
        },
        options: {
          responsive: true,
          legend: {
            display: false,
          },
          aspectRatio: 1,
          maintainAspectRatio: false,
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
        },
        //  data: this.overviewbargraphpost_obj.locationChart.data,
        //  options: this.overviewbargraphpost_obj.locationChart.options,
      });
    },
    createAllLocationViewGraph() {
      const ctx = document.getElementById("allLocation");
      this.allLocationChart = new Chart(ctx, {
        type: "bar",
        data: {
          labels: [
            "Referred to clinic",
            "Treatment Completed",
            "Treatment in Progress",
            "Not yet been to Clinic",
          ],
          datasets: [
            {
              label: "",
              borderColor: "#FF8AA3",
              barThickness: 60,
              maxBarThickness: 80,
              data: [
                this.all_location_obj.locationChart.data.referred_to_clinic,
                this.all_location_obj.locationChart.data.treatment_completed,
                this.all_location_obj.locationChart.data
                  .treatment_not_completed,
                this.all_location_obj.locationChart.data.not_yet_to_clinic,
              ],
              backgroundColor: ["#FFB1C1", "#9AD0F5", "#FFE6AA", "#A5DFDF"],
            },
          ],
        },
        options: {
          responsive: true,
          legend: {
            display: false,
          },
          aspectRatio: 1,
          maintainAspectRatio: false,
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
        },
        //  data: this.overviewbargraphpost_obj.locationChart.data,
        //  options: this.overviewbargraphpost_obj.locationChart.options,
      });
    },
    createCombodiaSmileInterventionViewBarGraph() {
      const ctx = document.getElementById("combodiaSmile");
      this.combodiaSmileChart = new Chart(ctx, {
        type: "bar",
        data: {
          datasets: [
            {
              label: "First Index",
              type: "bar",
              borderColor: "#FF8AA3",
              borderWidth: 1,
              data: [
                this.csi_obj.locationChart.datachart.first.total,
                this.csi_obj.locationChart.datachart.first.cdc,
                this.csi_obj.locationChart.datachart.first.kyc,
                this.csi_obj.locationChart.datachart.first.tdc,
                // this.csi_obj.locationChart.datachart.first.mdc,
              ],
              backgroundColor: "#FFB1C1",
            },
            {
              label: "Second Index",
              borderColor: "#4FAEEE",
              borderWidth: 1,
              // data: this.csi_obj.locationChart.datachart.CDC,
              data: [
                this.csi_obj.locationChart.datachart.second.total,
                this.csi_obj.locationChart.datachart.second.cdc,
                this.csi_obj.locationChart.datachart.second.kyc,
                this.csi_obj.locationChart.datachart.second.tdc,
                // this.ohe_obj.locationChart.datachart.second.mdc,
              ],
              backgroundColor: "#9AD0F5",
            },
            {
              label: "Third Index",
              borderColor: "#FFCD56",
              borderWidth: 1,
              // data: this.csi_obj.locationChart.datachart.KYC,
              data: [
                this.csi_obj.locationChart.datachart.third.total,
                this.csi_obj.locationChart.datachart.third.cdc,
                this.csi_obj.locationChart.datachart.third.kyc,
                this.csi_obj.locationChart.datachart.third.tdc,
                // this.csi_obj.locationChart.datachart.third.mdc,
              ],
              backgroundColor: "#FFE6AA",
            },
          ],
          labels: ["Total", "CDC", "KYC", "TDC"],
        },
        options: {
          responsive: true,
          legend: {
            display: false,
          },
          aspectRatio: 1,
          maintainAspectRatio: false,
          scales: {
            xAxes: {
              // stacked: true,
              ticks: {
                beginAtZero: true,
              },
            },
            yAxes: [
              {
                // stacked: true,
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
        },
        //  data: this.overviewbargraphpost_obj.locationChart.data,
        //  options: this.overviewbargraphpost_obj.locationChart.options,
      });
    },
    createChildrenOheViewBarGraph() {
      const ctx = document.getElementById("childrenOhe");
      this.childOheChart = new Chart(ctx, {
        type: "bar",
        data: {
          datasets: [
            {
              label: "",
              type: "bar",
              borderColor: "#FF8AA3",
              borderWidth: 1,
              data: [
                this.ohe_obj.locationChart.datachart.first.total,
                this.ohe_obj.locationChart.datachart.first.cdc,
                this.ohe_obj.locationChart.datachart.first.kyc,
                this.ohe_obj.locationChart.datachart.first.tdc,
                this.ohe_obj.locationChart.datachart.first.mdc,
              ],
              backgroundColor: "#FFB1C1",
            },
            {
              label: "",
              borderColor: "#4FAEEE",
              borderWidth: 1,
              data: [
                this.ohe_obj.locationChart.datachart.second.total,
                this.ohe_obj.locationChart.datachart.second.cdc,
                this.ohe_obj.locationChart.datachart.second.kyc,
                this.ohe_obj.locationChart.datachart.second.tdc,
                this.ohe_obj.locationChart.datachart.second.mdc,
              ],
              backgroundColor: "#9AD0F5",
            },
            {
              label: "",
              borderColor: "#FFCD56",
              borderWidth: 1,
              data: [
                this.ohe_obj.locationChart.datachart.third.total,
                this.ohe_obj.locationChart.datachart.third.cdc,
                this.ohe_obj.locationChart.datachart.third.kyc,
                this.ohe_obj.locationChart.datachart.third.tdc,
                this.ohe_obj.locationChart.datachart.third.mdc,
              ],
              backgroundColor: "#FFE6AA",
            },
          ],
          labels: ["Total", "CDC", "KYC", "TDC", "MDC"],
        },
        options: {
          responsive: true,
          legend: {
            display: false,
          },
          aspectRatio: 1,
          maintainAspectRatio: false,
          scales: {
            xAxes: {
              // stacked: true,
              ticks: {
                beginAtZero: true,
              },
            },
            yAxes: [
              {
                // stacked: true,
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
        },
        //  data: this.overviewbargraphpost_obj.locationChart.data,
        //  options: this.overviewbargraphpost_obj.locationChart.options,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../css/style.scss";
</style>
