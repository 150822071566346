<template>
  <div class="calllist-page">
    <app-header></app-header>
    <div class="components">
      <shortcuttab></shortcuttab>
      <div class="container-bg-white">
        <div class="wrapper">
          <div class="add-new-patient">
            <h1>Search Results</h1>
          </div>
          <!-- <form class="search-form" method="GET" action="/search">
            <i class="fa fa-search search"></i>
            <input placeholder="Search Patient" v-model="searchPatient" />
          </form> -->
          <div
            class="patient-info-wrapper"
            v-if="search_patient_list && search_patient_list.length > 0"
          >
            <div
              class="patient-info"
              v-for="(patient, index) in search_patient_list"
              :key="index"
            >
              <div class="patient-name">
                <span>{{ patient.full_name }}</span>
                <span>{{ patient.phone }}</span>
              </div>
              <div class="button">
                <router-link
                  :to="{ name: 'patientdetail', params: { id: patient.id } }"
                >
                  <b-button variant="outline-success">View Detail</b-button>
                </router-link>
              </div>
            </div>
          </div>
          <div class="patient-info-wrapper" v-else>
            <span>Patient not found.</span>
          </div>
        </div>
      </div>
      <div></div>
    </div>
    <!-- <modalpatient></modalpatient> -->
  </div>
</template>
<script>
import AppHeader from "../Header.vue";
import PatientList from "../components_clinic_page/PatientList.vue";
import ShortcutTab from "../components_clinic_page/ShortcutTab.vue";
import { mapState } from "vuex";

// import ModalPatient from '../components_clinic_page/AddPatientModal.vue';

export default {
  name: "CallList",
  components: {
    "app-header": AppHeader,
    "patient-list": PatientList,
    shortcuttab: ShortcutTab,
    // 'modalpatient': ModalPatient,
  },
  data() {
    return {
      radio: "",
      search_value: "",
      search_patient_list: [],
    };
  },
  computed: {
    ...mapState(["search_patient"]),
  },

  created() {
    this.search_value = this.$route.query.search.replace(/(<([^>]+)>)/ig, "");
    this.search_value = this.search_value == "" ? "a" : this.search_value;
    this.$store.dispatch("searchPatients", this.search_value).then(() => {
    this.search_patient_list = this.search_patient;
    });
  },
};
</script>
<style lang="scss" scoped>
@import "src/css/main.scss";
</style>
