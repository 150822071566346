<template>
  <div class="csi">
    <div id="app">
      <app-header></app-header>
      <div class="contents">
        <div class="row">
          <div class="col-12">
            <h1>Online data entry (CSI)</h1>
          </div>
        </div>
        <div class="mt-5">
          <b-tabs content-class="mt-5 mb-4" align="center">
            <b-tab title="Data Entry" active>
              <b-card-text>
                <div class="row mt-4">
                  <div class="col-12">
                    <div class="card shadow">
                      <div class="row pr-4 justify-content-center">
                        <div class="col-10">
                          <b-card
                            header=" 6.3.1 Cambodia Smile Intervention (CSI)"
                            bg-variant="white"
                            class="pl-0 pr-0 pt-0 header-text text-center"
                          >
                            <!-- <b-row   align-h="center" class="">
                              <div class="row " v-show ="errors.selectLocation">
                                <p class="ml-5">{{ errors.selectLocation }}</p>
                              </div></b-row
                            > -->
                            <b-row
                              align-h="space-between"
                              class="mb-4 ml-4 mt-3"
                            >
                              <div
                                class="col-lg-5 col-md-9 col-sm-12 pl-0 text-left"
                              >
                                <h4>Location</h4>
                              </div>
                              <div class="col-lg-6 col-md-6 col-sm-12 pl-0">
                                <b-form-select
                                  v-model="selectLocation"
                                  :options="optionsLocation"
                                  value-field="id"
                                  text-field="name"
                                  placeholder="Select Location"
                                >
                                  <template v-slot:first>
                                    <b-form-select-option :value="null" disabled
                                      >-- Select a Location
                                      --</b-form-select-option
                                    >
                                  </template>
                                </b-form-select>
                              </div>
                            </b-row>
                            <b-row align-h="space-between" class="mb-4 ml-4">
                              <div
                                class="col-lg-5 col-md-9 col-sm-12 pl-0 text-left"
                              >
                                <h4>Community Health Center</h4>
                              </div>
                              <div class="col-lg-6 col-md-6 col-sm-12 pl-0">
                                <b-input
                                  type="text"
                                  v-model="selectCommunity"
                                  placeholder="Enter Health Center"
                                ></b-input>
                              </div>
                            </b-row>
                            <b-row align-h="space-between" class="mb-4 ml-4">
                              <div
                                class="col-lg-5 col-md-9 col-sm-12 pl-0 text-left"
                              >
                                <h4>Year</h4>
                              </div>
                              <div class="col-lg-6 col-md-6 col-sm-12 pl-0">
                                <b-form-select
                                  v-model="selectYear"
                                  :options="years"
                                  placeholder="Enter Year"
                                >
                                  <template v-slot:first>
                                    <b-form-select-option :value="null" disabled
                                      >-- Select Year --</b-form-select-option
                                    >
                                  </template>
                                </b-form-select>
                              </div>
                            </b-row>
                            <b-row align-h="space-between" class="mb-4 ml-4">
                              <div
                                class="col-lg-5 col-md-9 col-sm-12 pl-0 text-left"
                              >
                                <h4>Month</h4>
                              </div>
                              <div class="col-lg-6 col-md-6 col-sm-12 pl-0">
                                <b-form-select
                                  v-model="selectMonth"
                                  :options="optionsMonth"
                                  value-field="month"
                                  text-field="month"
                                >
                                  <template v-slot:first>
                                    <b-form-select-option :value="null" disabled
                                      >-- Select Month --</b-form-select-option
                                    >
                                  </template>
                                </b-form-select>
                              </div>
                            </b-row>
                            <b-row align-h="flex-end" class="mb-4 ml-4">
                              <div
                                class="col-lg-5 col-md-9 col-sm-12 pl-0 text-left"
                              >
                                <h4>Total Number</h4>
                              </div>
                              <div class="col-lg-6 col-md-6 col-sm-12 pl-0">
                                <b-form-input
                                  onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.charCode == 46"
                                  v-model="selectTotal"
                                  placeholder="Enter Number"
                                ></b-form-input>
                              </div>
                            </b-row>
                            <b-row class="mb-4 ml-4 mr-5 justify-content-end">
                              <div class="col-lg-4 col-sm-12 mt-4 mr-3">
                                <b-button
                                  variant="custom"
                                  block
                                  class="mb-4"
                                  @click="submitForm"
                                  >Submit</b-button
                                >
                              </div>
                            </b-row>
                          </b-card>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </b-card-text>
            </b-tab>
            <b-tab title="Data List" content-class="mt-3">
              <b-card-text>
                <div class="row mt-4 mb-4">
                  <div class="col-12">
                    <div class="card shadow">
                      <h3 class="mb-3 text-center">
                        6.3.2 Cambodia Smile Intervention
                      </h3>
                      <div class="row pr-4 justify-content-center">
                        <div class="col-12">
                          <b-table
                            id="user-table"
                            show-empty
                            :items="combodia_smile_intervention_Items"
                            :fields="combodia_smile_intervention_Fields"
                            responsive
                            hover
                            :busy="isBusy"
                            bordered
                            primary-key="uniqueID"
                          >
                            <template v-slot:cell(location)="data">{{
                              locationValue(data.item.location)
                            }}</template>

                            <template v-slot:cell(actions)="data">
                              <b-button
                                variant="outline-warning"
                                centered
                                @click="openEditForm(data.item)"
                                class="mr-2"
                                >Edit</b-button
                              >
                              <b-button
                                @click="openDeleteForm(data.item)"
                                variant="danger"
                                >Delete</b-button
                              >
                            </template>

                            <template v-slot:table-busy>
                              <div class="text-center text-primary my-2">
                                <b-spinner
                                  class="align-middle"
                                  type="grow"
                                  style="width: 5rem; height: 5rem"
                                ></b-spinner>
                              </div>
                            </template>
                          </b-table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </b-card-text>
            </b-tab>
          </b-tabs>
        </div>
      </div>

      <!-- Form validation Error message for 5 Filter Table Data  -->
      <b-toast
        id="error-csi"
        variant="danger"
        solid
        append-toast
        toaster="b-toaster-bottom-full"
      >
        <div slot="toast-title" class="d-flex flex-grow-1 align-items-baseline">
          <strong class="mr-auto">Required error</strong>
        </div>
        <div v-if="errors.selectLocation">
          <p>{{ errors.selectLocation }}</p>
        </div>
        <div v-else-if="errors.selectCommunity">
          <p>{{ errors.selectCommunity }}</p>
        </div>
        <div v-else-if="errors.selectYear">
          <p>{{ errors.selectYear }}</p>
        </div>
        <div v-else-if="errors.selectMonth">
          <p>{{ errors.selectMonth }}</p>
        </div>
        <div v-else-if="errors.selectTotal">
          <p>{{ errors.selectTotal }}</p>
        </div>
        <div v-else-if="errors.error_message">
          <p>{{ errors.error_message }}</p>
        </div>
        <div v-else></div>
      </b-toast>
      <b-toast
        id="success-csi"
        variant="success"
        solid
        append-toast
        toaster="b-toaster-bottom-full"
      >
        <div slot="toast-title" class="d-flex flex-grow-1 align-items-baseline">
          <strong class="mr-auto">CSI Success</strong>
        </div>
        <div>
          <p>Cambodia Smile Intervention (CSI) Added Successfully!</p>
        </div>
      </b-toast>
      <b-toast
        id="update-success-csi"
        variant="success"
        solid
        append-toast
        toaster="b-toaster-bottom-full"
      >
        <div slot="toast-title" class="d-flex flex-grow-1 align-items-baseline">
          <strong class="mr-auto">CSI Updatae Success</strong>
        </div>
        <div>
          <p>Cambodia Smile Intervention (CSI) Data Updated Successfully!</p>
        </div>
      </b-toast>
      <b-toast
        id="delete-success-csi"
        variant="success"
        solid
        append-toast
        toaster="b-toaster-bottom-full"
      >
        <div slot="toast-title" class="d-flex flex-grow-1 align-items-baseline">
          <strong class="mr-auto">CSI Delete Success</strong>
        </div>
        <div>
          <p>Cambodia Smile Intervention (CSI) Data Deleted Successfully!</p>
        </div>
      </b-toast>
      <!-- Form validation Error message for 5 Filter Table Data  -->
      <b-modal id="edit-modal" centered title="Edit CSI Form">
        <b-form>
          <h4>Location</h4>
          <b-form-select
            v-model="single_csi_obj.location"
            :options="optionsLocation"
            value-field="id"
            text-field="name"
            placeholder="Select Location"
            class="mb-3 mt-3"
          >
            <template v-slot:first>
              <b-form-select-option :value="null" disabled
                >-- Select a Location --</b-form-select-option
              >
            </template>
          </b-form-select>

          <h4>Community Health Center</h4>
          <b-form-input
            v-model="single_csi_obj.community_health_center"
            type="text"
            class="mb-3 mt-3"
            placeholder="Enter community health center"
          ></b-form-input>

          <h4>Year</h4>
          <b-form-select
            v-model="single_csi_obj.year"
            :options="years"
            placeholder="Enter Year"
            class="mb-3 mt-3"
          >
            <template v-slot:first>
              <b-form-select-option :value="null" disabled
                >-- Select Year --</b-form-select-option
              >
            </template>
          </b-form-select>

          <h4>Month</h4>
          <b-form-select
            v-model="single_csi_obj.month"
            :options="optionsMonth"
            value-field="month"
            text-field="month"
            class="mb-3 mt-3"
          >
            <template v-slot:first>
              <b-form-select-option :value="null" disabled
                >-- Select Month --</b-form-select-option
              >
            </template>
          </b-form-select>
          
          <h4>Total Number</h4>
          <b-form-input
            onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.charCode == 46"
            v-model="single_csi_obj.total"
            placeholder="Enter Number"
            class="mb-3 mt-3"
          ></b-form-input>
        </b-form>
        <template #modal-footer="{ ok, cancel }">
          <!-- Emulate built in modal footer ok and cancel button actions -->
          <b-button size="sm" variant="success" @click="updateCSIData">
            Confirm
          </b-button>
          <b-button size="sm" variant="danger" @click="cancel()">
            Cancel
          </b-button>
        </template>
      </b-modal>

      <b-modal
        id="delete-modal"
        centered
        title="Are you sure you want to delete"
      >
        <template #modal-footer="{ ok, cancel}">
          <b-button size="sm" variant="success" @click="deleteCSIData"> Confirm </b-button>
          <b-button size="sm" variant="danger" @click="cancel()">
            Cancel
          </b-button>
        </template>
      </b-modal>
      <app-footer></app-footer>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import AppHeader from "./Header.vue";
import footer from "./Footer.vue";

export default {
  name: "Longitudinal",
  components: {
    "app-header": AppHeader,
    "app-footer": footer,
  },
  data() {
    return {
      errors: [],
      isBusy: false,
      selectLocation: null,
      selectCommunity: "",
      // optionsLocation: [
      //   { value: null, text: "Select Location" },
      //   { value: "cdc", text: "CDC" },
      //   { value: "tdc", text: "TDC" },
      // ],
      selectYear: null,
      optionsYear: [
        { value: null, text: "Select year" },
        { value: "a", text: "2019" },
        { value: "b", text: "2020" },
      ],
      selectMonth: null,
      // optionsMonth: [
      //   { value: null, text: "Select month" },
      //   { value: "a", text: "Jan" },
      //   { value: "b", text: "Feb" },
      // ],
      monthIndex: "",
      optionsMonth: [
        {
          month: "January",
          index: 1,
        },
        {
          month: "February",
          index: 2,
        },
        {
          month: "March",
          index: 3,
        },
        {
          month: "April",
          index: 4,
        },
        {
          month: "May",
          index: 5,
        },
        {
          month: "June",
          index: 6,
        },
        {
          month: "July",
          index: 7,
        },
        {
          month: "August",
          index: 8,
        },
        {
          month: "September",
          index: 9,
        },
        {
          month: "October",
          index: 10,
        },
        {
          month: "November",
          index: 11,
        },
        {
          month: "December",
          index: 12,
        },
      ],
      selectTotal: "",
      combodia_smile_intervention_Fields: [
        { key: "location", label: "Location", tdClass: "font-weight-bold" },
        { key: "community_health_center", label: "Community Health Centre" },
        { key: "year", label: "Year" },
        { key: "month", label: "Month" },
        { key: "total", label: "Total" },
        { key: "actions", label: "Action" },
      ],
      // combodia_smile_intervention_Items: [
      //   {
      //     location: "CDC",
      //     community_health_center: "Health Community Centre",
      //     year: "2020",
      //     month: "July",
      //     total: "322",
      //   },
      //   {
      //     location: "TDC",
      //     community_health_center: "Health Community Centre",
      //     year: "2020",
      //     month: "July",
      //     total: "322",
      //   },
      // ],
      single_csi_obj: []
    };
  },
  computed: {
    ...mapState([
      "locations",
      "csi_list_obj",
      "successmessage",
      "errormessage",
      "message",
    ]),
    optionsLocation: function () {
      return this.locations;
    },
    years() {
      const year = new Date().getFullYear();
      return Array.from(
        {
          length: 110,
        },
        (value, index) => year - index
      );
    },
    combodia_smile_intervention_Items: function () {
      let allData = this.csi_list_obj;
      let singleData = null;
      if (allData && allData.data && allData.data.length > 0) {
        singleData = allData.data;
      }
      return singleData;
    },
  },
  created() {
    this.listLocations();
    this.listCsiData();
  },
  methods: {
    ...mapActions(["listLocations", "listCsiData"]),
    locationValue(locId) {
      let allLocation = this.locations;
      let singleLocation = null;
      if (allLocation && allLocation.length > 0) {
        singleLocation = allLocation.find((row) => {
          return row.id == locId;
        });
      }
      return singleLocation.location;
    },
    submitForm() {
      this.errors = [];
      if (this.selectLocation == "" || this.selectLocation == null) {
        this.errors["selectLocation"] = "Selection of Location is required.";
        this.$bvToast.show("error-csi");
      } else if (this.selectCommunity == "") {
        this.errors["selectCommunity"] = "Health center is required.";
        this.$bvToast.show("error-csi");
      } else if (this.selectYear == "" || this.selectYear == null) {
        this.errors["selectYear"] = "Selection of Year is required.";
        this.$bvToast.show("error-csi");
      } else if (this.selectMonth == "" || this.selectMonth == null) {
        this.errors["selectMonth"] = "Selection of Month is required.";
        this.$bvToast.show("error-csi");
      } else if (this.selectTotal == "") {
        this.errors["selectTotal"] = "Total Number is required.";
        this.$bvToast.show("error-csi");
      } else {
        let formData = new FormData();
        formData.append("location", this.selectLocation);
        formData.append("community_health_center", this.selectCommunity);
        formData.append("year", this.selectYear);
        formData.append("month", this.selectMonth);
        formData.append("total_number", this.selectTotal);
        // for (var pair of formData.entries()) {
        //   console.log(pair[0]+ ', ' + pair[1]);
        // }
        this.$store
          .dispatch("postCsiData", formData)
          .then(() => {
            if (this.successmessage == "success") {
              this.$bvToast.show("success-csi");
              setTimeout(() => {
                location.reload();
              }, 1000);
            }
          })
          .catch((error) => {
            if (error) {
              this.errors["error_message"] = error.response;
              this.$bvToast.show("error-csi");
            }
          });
      }
    },
    openEditForm(csiObject){
      this.single_csi_obj = csiObject;

      this.selectLocation = this.single_csi_obj.location
      this.selectCommunity = this.single_csi_obj.community_health_center
      this.selectYear = this.single_csi_obj.year
      this.selectMonth = this.single_csi_obj.month
      this.selectTotal = this.single_csi_obj.total

      this.$bvModal.show('edit-modal');
    },
    openDeleteForm(csiObject){
      this.single_csi_obj = csiObject;

      this.$bvModal.show('delete-modal')
    },
    updateCSIData(){
      this.errors = [];
      if (this.single_csi_obj.location == "" || this.single_csi_obj.location == null) {
        this.errors["selectLocation"] = "Selection of Location is required.";
        this.$bvToast.show("error-csi");
      } else if (this.single_csi_obj.community_health_center == "") {
        this.errors["selectCommunity"] = "Health center is required.";
        this.$bvToast.show("error-csi");
      } else if (this.single_csi_obj.year == "" || this.single_csi_obj.year == null) {
        this.errors["selectYear"] = "Selection of Year is required.";
        this.$bvToast.show("error-csi");
      } else if (this.single_csi_obj.month == "" || this.single_csi_obj.month == null) {
        this.errors["selectMonth"] = "Selection of Month is required.";
        this.$bvToast.show("error-csi");
      } else if (this.single_csi_obj.total == "") {
        this.errors["selectTotal"] = "Total Number is required.";
        this.$bvToast.show("error-csi");
      } else {
        //
        let formData = {
          'location': this.single_csi_obj.location,
          'year': this.single_csi_obj.year,
          'community_health_center': this.single_csi_obj.community_health_center,
          'month': this.single_csi_obj.month,
          'total_number': this.single_csi_obj.total,
          'id': this.single_csi_obj.id,
        }

        this.$store
        .dispatch("updateCsiData", formData)
        .then(() => {
          if (this.successmessage == "success") {
            this.$bvToast.show("update-success-csi");
            setTimeout(() => {
              location.reload();
            }, 1000);
          }
        })
        .catch((error) => {
          if (error) {
            this.errors["error_message"] = error.response;
            this.$bvToast.show("error-csi");
          }
        });

      }
    },
    deleteCSIData(){
        let csiId = this.single_csi_obj.id
        this.$store
        .dispatch("removeCsiData", csiId)
        .then(() => {
          if (this.successmessage == "success") {
            this.$bvToast.show("delete-success-csi");
            setTimeout(() => {
              location.reload();
            }, 1000);
          }
        })
        .catch((error) => {
          if (error) {
            this.errors["error_message"] = error.response;
            this.$bvToast.show("error-csi");
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.csi {
  display: grid;
  height: 100vh;
  input,
  select {
    border: 1px solid #cccccc;
    border-radius: 8px;
  }
}

@import "../css/index.scss";
@import "../css/bulkupload.scss";
</style>
