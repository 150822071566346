<template>
  <div id="app">
    <app-header></app-header>
    <div class="contents">
      <div class="row">
        <div class="col-12">
          <h1>Home</h1>
        </div>
      </div>
      <span style="display: none;">{{ home_table_obj }}</span>
      <div class="row mt-4 text-center">
        <div class="col-lg-12 col-sm-12">
          <div class="card shadow">
            <h3 class="mb-3">1 At a glance this year</h3>

            <div class="row justify-content-center">
              <div class="col-lg-10">
                <Visualization :tag="settingsgraph"></Visualization>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-4 text-center table-area">
        <div class="col-12">
          <div class="card shadow">
            <h3 class="mb-3">1.1 At a glance this year</h3>
            <div class="row justify-content-center">
              <div class="col-lg-10">
                <b-table
                  id="treatments-table"
                  show-empty
                  :items="treatmentTableItems"
                  :fields="basicFields"
                  responsive
                  hover
                  :busy="isBusy"
                  bordered
                >
                  <template v-slot:table-busy>
                    <div class="text-center text-primary my-2">
                      <b-spinner
                        class="align-middle"
                        type="grow"
                        style="width: 5rem; height: 5rem"
                      ></b-spinner>
                    </div>
                  </template>
                </b-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-footer></app-footer>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import AppHeader from "./Header.vue";
import Visualization from "./Visualization";
import footer from "./Footer.vue";
// const axios = require("axios");
export default {
  name: "IndexPage",
  components: {
    "app-header": AppHeader,
    Visualization: Visualization,
    "app-footer": footer,
  },
  computed: {
    ...mapState(["home_table_obj"]),

    treatmentTableItems: function() {
      let returnObjcectData = [];
      if (
        this.$store.state.home_table_obj &&
        this.$store.state.home_table_obj.locationTable.datatable
      ) {
        this.$store.state.home_table_obj.locationTable.datatable.forEach(
          function(rec) {
            returnObjcectData.push({
              type: rec[0],
              total: rec[1],
              cdc: rec[2],
              kyc: rec[3],
              tdc: rec[4],
              mdc: rec[5],
            });
          }
        );
      } //  end if

      return returnObjcectData;
    },
  },
  // mounted() {
  //   // let listData = this.home_table_obj.locationTable.datatable;
  //   // //let listData = [[10,1,2,3,4],[11,2,3,4,5],[12,3,4,5,6],[13,4,5,6,7]]
  //   // // console.log("listData ", listData);
  //   // // let labeles = this.home_table_obj.locationTable.labels;
  //   // for (let i = 0; i < listData.length; i++) {
  //   //   let pushObject = {
  //   //     type: listData[i][0],
  //   //     total: listData[i][1],
  //   //     cdc: listData[i][2],
  //   //     kyc: listData[i][3],
  //   //     tdc: listData[i][4],
  //   //     mdc: listData[i][5],
  //   //   };
  //   //   this.treatmentTableItems.push(pushObject);
  //   // }
  // },
  created() {
    this.listHomeTable();
  },

  data() {
    return {
      settingsgraph: "settingsgraph",
      isBusy: false,
      basicFields: [
        { key: "type", label: "", tdClass: "font-weight-bold text-center" },
        { key: "total", label: "TOTAL" },
        { key: "cdc", label: "CDC" },
        { key: "kyc", label: "KYC" },
        { key: "tdc", label: "TDC" },
        { key: "mdc", label: "MDC" },
      ],
      // treatmentTableItems: [],
    };
  },
  methods: {
    ...mapActions(["listHomeTable"]),
    // jsonArrayTo2D(arrayOfObjects) {
    //   let header = [];
    //   let AoA = [];
    //   arrayOfObjects.forEach((obj) => {
    //     Object.keys(obj).forEach(
    //       (key) => header.includes(key) || header.push(key)
    //     );
    //     let thisRow = new Array(header.length);
    //     header.forEach((col, i) => (thisRow[i] = obj[col] || ""));
    //     AoA.push(thisRow);
    //   });
    //   AoA.unshift(header);
    //   return AoA;
    // },
  },
};
</script>

<style lang="scss" scoped>
@import "../css/index.scss";
.pk {
  color: red;
}
</style>
