<template>
  <div id="app">
    <app-header></app-header>
    <div class="contents">
      <div class="row">
        <div class="col-12">
          <h1>Settings</h1>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col-sm-12 col-md-12 col-lg-6">
          <b-form class="card shadow">
            <h3 class="pb-3">Change Your Password</h3>
            <b-form-input
              type="password"
              class="mb-3"
              v-model="old_password"
              placeholder="Old Password"
            ></b-form-input>
            <b-form-input
              type="password"
              class="mb-3"
              v-model="new_password"
              placeholder="New Password (min. 8 characters)"
            ></b-form-input>
            <b-form-input
              type="password"
              class="mb-3"
              v-model="confirm_password"
              placeholder="Confirm Password (min. 8 characters)"
            ></b-form-input>

            <b-button variant="custom" @click="changePassword"
              >Change Password</b-button
            >
          </b-form>
        </div>

        <div
          class="col-sm-12 col-md-12 col-lg-6"
          v-if="userrole == 'Admin User' || userrole == 'System Admin'"
        >
          <b-form class="card shadow">
            <h3 class="pb-3">Reset User's Password</h3>

            <!-- <b-form-input type="password" class="mb-3" placeholder="Old Password"></b-form-input> -->
            <b-form-select
              v-model="user1"
              placeholder="Select a User"
              class="mb-3"
            >
              <option disabled value="">Select a User</option>
              <option
                v-for="(user, index) in filterUserObj"
                :key="index"
                :value="user.username"
              >
                {{ user.full_name }}
              </option>
            </b-form-select>

            <b-form-input
              type="password"
              class="mb-3"
              v-model="new_password1"
              placeholder="New Password (min. 8 characters)"
            ></b-form-input>
            <b-form-input
              type="password"
              class="mb-3"
              v-model="confirm_password1"
              placeholder="Confirm Password (min. 8 characters)"
            ></b-form-input>

            <b-button variant="custom" centered @click="resetPassword"
              >Reset Password</b-button
            >
          </b-form>
        </div>
      </div>

      <b-toast
        id="error-toast"
        variant="warning"
        solid
        append-toast
        toaster="b-toaster-bottom-full"
      >
        <div slot="toast-title" class="d-flex flex-grow-1 align-items-baseline">
          <strong class="mr-auto">Error!</strong>
        </div>
        <div v-if="message">
          <p v-if="message.length > 0">{{ message }}</p>
        </div>
        <div v-else-if="errors.old_password">
          <p>{{ errors.old_password }}</p>
        </div>
        <div v-else-if="errors.new_password">
          <p>{{ errors.new_password }}</p>
        </div>
        <div v-else-if="errors.confirm_password">
          <p>{{ errors.confirm_password }}</p>
        </div>
        <div v-else-if="errors.new_password1">
          <p>{{ errors.new_password1 }}</p>
        </div>
        <div v-else-if="errors.confirm_password1">
          <p>{{ errors.confirm_password1 }}</p>
        </div>
        <div v-else-if="errors.new_password_length">
          <p>{{ errors.new_password_length }}</p>
        </div>
        <div v-else-if="errors.new_password1_length">
          <p>{{ errors.new_password1_length }}</p>
        </div>
        <div v-else-if="errors.password_error">
          <p>{{ errors.password_error }}</p>
        </div>
        <div v-else-if="errors.password1_error">
          <p>{{ errors.password1_error }}</p>
        </div>
        <div v-else-if="errors.user1">
          <p>{{ errors.user1 }}</p>
        </div>
      </b-toast>

      <b-toast
        id="success-toast"
        variant="custom-success"
        solid
        append-toast
        toaster="b-toaster-bottom-full"
      >
        <div slot="toast-title" class="d-flex flex-grow-1 align-items-baseline">
          <strong class="mr-auto">Success !</strong>
        </div>
        Your password is successfully changed.
      </b-toast>

      <!-- <b-modal
        id="reset-password-modal"
        centered
        title="Enter system password to confirm"
      >
        <b-form>
          <b-form-input
            type="password"
            class="mb-3"
            placeholder="Password"
          ></b-form-input>

          <b-form-input
            type="password"
            class="mb-3"
            placeholder="Confirm Password"
          ></b-form-input>

          <multiselect
            class="mb-3"
            :options="options"
            :multiple="true"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            placeholder="Select Location"
            label="name"
            track-by="name"
            :preselect-first="true"
          >
          </multiselect>
        </b-form>
        <template #modal-footer="{ ok, cancel }">
          <b-button size="sm" variant="success" @click="ok()">
            Confirm
          </b-button>
          <b-button size="sm" variant="danger" @click="cancel()">
            Cancel
          </b-button>
        </template>
      </b-modal> -->
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import AppHeader from "./Header.vue";

// const axios = require('axios');
export default {
  name: "Settings",
  components: {
    // "AuthenticationForm": AuthenticationForm
    "app-header": AppHeader,
  },
  computed: {
    ...mapState([
      "token",
      "message",
      "successmessage",
      "errormessage",
      "profile",
      "users_obj",
    ]),
    filterUserObj: function () {
      let allUserObj = this.users_obj;

      // filter inactive users
      allUserObj = allUserObj.filter((row) => {
        return (
          row.active == true &&
          row.role != "D1D16697DC6F4BA7B563E853388B7A0C" &&
          row.role != "ABD4EA83732844109EA479C613B3E59E"
        );
      });

      // skip logged in user from list
      if (this.profile && this.profile.id) {
        allUserObj = allUserObj.filter((row) => {
          return row.id != this.profile.id;
        });
      } // end if

      return allUserObj;
    },
    userrole: function () {
      let profileDetail = this.profile;
      let userrole =
        profileDetail && profileDetail.role ? profileDetail.role : "Admin User";

      return userrole;
    },
  },

  created() {
    this.listProfile();
    this.listUsers();
    // var token = window.localStorage.getItem("token");
    // if (token != undefined && token != ""){
    //   this.$router.replace("/app");
    // }
  },

  data() {
    return {
      old_password: "",
      new_password: "",
      confirm_password: "",
      new_password1: "",
      confirm_password1: "",
      user1: "",
      password_error: "",
      // new_password_length: '',
      // password1_error: '',
      // new_password1_length: '',
      username: "",
      errors: [],
      filter: "",
      valid_email:
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      valid_password:
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,24}$/,
    };
  },

  methods: {
    ...mapActions(["listProfile", "listUsers"]),
    changePassword() {
      this.errors = [];
      if (this.old_password == "") {
        this.errors["old_password"] = "Old Password required.";
        this.$bvToast.show("error-toast");
      } else if (this.new_password == "") {
        this.errors["new_password"] = "New Password required.";
        this.$bvToast.show("error-toast");
      } else if (!this.valid_password.test(this.new_password)) {
        this.errors["new_password"] =
          "Password must contain 8 characters with a capital & small letter, number & special character.";
        this.$bvToast.show("error-toast");
      } else if (this.confirm_password == "") {
        this.errors["confirm_password"] = "Confirm Password required.";
        this.$bvToast.show("error-toast");
      } else if (this.confirm_password != this.new_password) {
        this.errors["password_error"] = "Confirm Passwords does not matched.";
        this.$bvToast.show("error-toast");
      } else
        this.$store
          .dispatch("changePassword", {
            old_password: this.old_password,
            new_password: this.new_password,
            confirm_password: this.confirm_password,
          })
          .then(() => {
            if (this.successmessage == "success") {
              this.old_password = "";
              this.new_password = "";
              this.confirm_password = "";
              this.$bvToast.show("success-toast");
            } else if (this.errormessage == "errormessage") {
              this.$bvToast.show("error-toast");
            }
          });
    },

    resetPassword() {
      this.errors = [];
      if (this.user1 == "") {
        this.errors["user1"] = "User required.";
        this.$bvToast.show("error-toast");
      } else if (this.new_password1 == "") {
        this.errors["new_password1"] = "New Password required.";
        this.$bvToast.show("error-toast");
      } else if (!this.valid_password.test(this.new_password1)) {
        this.errors["confirm_password1"] =
          "Newe Password must contain 8 characters with a capital & small letter, number & special character.";
        this.$bvToast.show("error-toast");
      } else if (this.confirm_password1 != this.new_password1) {
        this.errors["password1_error"] = "Confirm Passwords do not match.";
        this.$bvToast.show("error-toast");
      } else {
        let resetObj = {
          username: this.user1,
          new_password: this.new_password1,
          confirm_password: this.confirm_password1,
        };

        this.$store.dispatch("resetUserPassword", resetObj).then(() => {
          if (this.successmessage == "success") {
            this.confirm_password1 = "";
            this.new_password1 = "";
            this.user1 = "";
            this.$bvToast.show("success-toast");
          } else if (this.errormessage == "errormessage") {
            this.$bvToast.show("error-toast");
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../css/settings.scss";
</style>
