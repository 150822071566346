import { render, staticRenderFns } from "./PrivacyPolicyPage.vue?vue&type=template&id=60fdf1d7&scoped=true&"
import script from "./PrivacyPolicyPage.vue?vue&type=script&lang=js&"
export * from "./PrivacyPolicyPage.vue?vue&type=script&lang=js&"
import style0 from "./PrivacyPolicyPage.vue?vue&type=style&index=0&id=60fdf1d7&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60fdf1d7",
  null
  
)

export default component.exports