<template>
  <div class="treatment-section">
    <form action class="treatment-form">
      <div class="header-buttons">
        <b-button
          pill
          @click="selectType('SDF')"
          class="sdf-button"
          :class="{ sdf: backgroundSdf }"
          >SDF</b-button
        >
        <b-button
          pill
          @click="selectType('SEAL')"
          class="seal-button"
          :class="{ seal: backgroundSeal }"
          >SEAL</b-button
        >
        <b-button
          pill
          @click="selectType('RESTORATION')"
          class="restoration-button"
          :class="{ restoration: backgroundRestoration }"
          v-if="profile.role != 'Dental Assistant'"
          >FILLING</b-button
        >
        <b-button
          pill
          @click="selectType('ART')"
          class="art-button"
          :class="{ art: backgroundArt }"
          v-if="profile.role != 'Dental Assistant'"
          >ART</b-button
        >
        <b-button
          pill
          @click="selectType('EXO')"
          class="exo-button"
          :class="{ exo: backgroundExo }"
          v-if="profile.role != 'Dental Assistant'"
          >EXO</b-button
        >
        <b-button
          pill
          @click="selectType('CROWN')"
          class="crown-button"
          :class="{ crowns: backgroundCrown }"
          v-if="profile.role != 'Dental Assistant'"
          >CROWN</b-button
        >
        <b-button
          pill
          @click="selectType('ROOT')"
          class="root-button"
          :class="{ root: backgroundRoot }"
          v-if="profile.role != 'Dental Assistant'"
          >ROOT</b-button
        >
        <b-button
          pill
          @click="selectType('UNTR')"
          class="untr-button"
          :class="{ untr: backgroundUntr }"
          v-if="profile.role != 'Dental Assistant'"
          >UNTR</b-button
        >
      </div>
      <div class="treatment-body--wrapper">
        <div class="right-indicator same-style">
          <span class="number">10</span>
          <span class="text">R</span>
          <span class="number">40</span>
        </div>
        <div class="left-indicator same-style">
          <span class="number">20</span>
          <span class="text">L</span>
          <span class="number">30</span>
        </div>
        <div class="middle-indicator">
          <button type="button" class="pills">00</button>
        </div>
        <div class="indicator--header">01</div>
        <div class="teeth-number-selection">
          <div class="buttons-group-one">
            <div class="top-button-group">
              <div class="top-button">
                <div class="horizontal-line"></div>
                <div class="round-buttons justify-content-end">
                  <div v-for="(teeth, index) in teeth_11_18" :key="index">
                    <button
                      type="button"
                      class="round"
                      :id="teeth.id"
                      @click="setTreatmentInfo(teeth.name)"
                    >
                      {{ teeth.id + 10 }}
                    </button>
                  </div>
                </div>
              </div>
              <div class="bottom-button">
                <div class="round-buttons justify-content-end">
                  <div v-for="(teeth, index) in teeth_51_55" :key="index">
                    <button
                      type="button"
                      class="round"
                      :id="teeth.id"
                      @click="setTreatmentInfo(teeth.name)"
                    >
                      {{ teeth.id + 18 }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="bottom-button-group">
              <div class="top-button">
                <div class="round-buttons justify-content-end">
                  <div v-for="(teeth, index) in teeth_81_85" :key="index">
                    <button
                      type="button"
                      class="round"
                      :id="teeth.id"
                      @click="setTreatmentInfo(teeth.name)"
                    >
                      {{ teeth.id + 33 }}
                    </button>
                  </div>
                </div>
              </div>
              <div class="bottom-button">
                <div class="round-buttons justify-content-end">
                  <div v-for="(teeth, index) in teeth_41_48" :key="index">
                    <button
                      type="button"
                      class="round"
                      :id="teeth.id"
                      @click="setTreatmentInfo(teeth.name)"
                    >
                      {{ teeth.id + 16 }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="buttons-group-two">
            <div class="top-button-group">
              <div class="top-button">
                <div class="round-buttons justify-content-start">
                  <div v-for="(teeth, index) in teeth_21_28" :key="index">
                    <button
                      type="button"
                      class="round"
                      :id="teeth.id"
                      @click="setTreatmentInfo(teeth.name)"
                    >
                      {{ teeth.id + 12 }}
                    </button>
                  </div>
                </div>
              </div>
              <div class="bottom-button">
                <div class="round-buttons justify-content-start">
                  <div v-for="(teeth, index) in teeth_61_65" :key="index">
                    <button
                      class="round"
                      type="button"
                      :id="teeth.id"
                      @click="setTreatmentInfo(teeth.name)"
                    >
                      {{ teeth.id + 23 }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="bottom-button-group">
              <div class="top-button">
                <div class="round-buttons justify-content-start">
                  <div v-for="(teeth, index) in teeth_71_75" :key="index">
                    <button
                      type="button"
                      class="round"
                      :id="teeth.id"
                      @click="setTreatmentInfo(teeth.name)"
                    >
                      {{ teeth.id + 28 }}
                    </button>
                  </div>
                </div>
              </div>
              <div class="bottom-button">
                <div class="round-buttons justify-content-start">
                  <div v-for="(teeth, index) in teeth_31_38" :key="index">
                    <button
                      type="button"
                      class="round"
                      :id="teeth.id"
                      @click="setTreatmentInfo(teeth.name)"
                    >
                      {{ teeth.id + 14 }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="indicator--footer">02</div>
      </div>
      <div class="treatment--footer">
        <label class="space-between">
          <span>SDF Whole Mouth</span>
          <input type="checkbox" size="lg" v-model="sdf_whole_mouth" />
        </label>
        <label class="space-between">
          <span>FV Applied</span>
          <input type="checkbox" size="lg" v-model="fv_applied" />
        </label>
        <label class="space-between">
          <span>OHE Given</span>
          <input type="checkbox" size="lg" v-model="OHE_given" />
        </label>
        <label class="space-between">
          <span>Treatment Plan Complete</span>
          <input type="checkbox" size="lg" v-model="treatment_plan_complete" />
        </label>
        <label class="note">
          <p>Notes</p>
          <input type="text" v-model="notes" />
        </label>
        <div class="button">
          <b-button variant="outline-success" @click="backToDiagnostic"
            >Back</b-button
          >
          <b-button variant="outline-success" @click="saveTreatment"
            >Next</b-button
          >
        </div>
      </div>
      <!-- Restoration Modal -->
      <div>
        <b-modal
          ref="restoration-modal"
          hide-footer
          centered
          title="Filling"
          no-close-on-backdrop
          no-stacking
        >
          <b-form>
            <div class="filling">
              <label class="space-between">
                <span>Composite</span>
                <input
                  type="checkbox"
                  v-model="restoration_content_object.composite"
                  class="red"
                  size="lg"
                  :disabled="disabledComposite"
                  @change="disableComposite"
                />
              </label>
              <label class="space-between">
                <span>GIC</span>
                <input
                  type="checkbox"
                  v-model="restoration_content_object.GIC"
                  :disabled="disabledGic"
                  size="lg"
                  @change="disableGic"
                />
              </label>
            </div>
            <div class="composite-filling" v-if="OptionSelected">
              <h2>Surface</h2>
              <label class="space-between">
                <span>Mesial</span>
                <input
                  type="checkbox"
                  v-model="restoration_content_object.mesial"
                  size="lg"
                />
              </label>
              <label class="space-between">
                <span>Occlusal</span>
                <input
                  type="checkbox"
                  v-model="restoration_content_object.occlusal"
                  size="lg"
                />
              </label>
              <label class="space-between">
                <span>Distal</span>
                <input
                  type="checkbox"
                  v-model="restoration_content_object.distal"
                  size="lg"
                />
              </label>
              <label class="space-between">
                <span>Buccal</span>
                <input
                  type="checkbox"
                  v-model="restoration_content_object.buccal"
                  size="lg"
                />
              </label>
              <label class="space-between">
                <span>Palatal</span>
                <input
                  type="checkbox"
                  v-model="restoration_content_object.palatal"
                  size="lg"
                />
              </label>
              <label class="details floating-label">
                <span>Details</span>
                <input
                  type="text"
                  v-model="restoration_content_object.detail"
                />
              </label>
            </div>
            <div class="continue-button">
              <b-button class="close" @click="close()">CLOSE</b-button>
              <b-button class="ok" @click="saveRestorationinfo()"
                >SAVE</b-button
              >
            </div>
          </b-form>
        </b-modal>
      </div>
      <!-- Crown Modal -->
      <div>
        <b-modal
          ref="crown-modal"
          hide-footer
          centered
          title="Step of the procedure"
          no-close-on-backdrop
          no-stacking
        >
          <b-form>
            <label class="space-between">
              <span>Preparation</span>
              <input
                type="checkbox"
                :disabled="disabledPreparation"
                @change="disablePreparation"
                v-model="crown_content_object.preparation"
                size="lg"
              />
            </label>
            <label class="space-between">
              <span>Cementation</span>
              <input
                type="checkbox"
                :disabled="disabledCementation"
                @change="disableCementation"
                v-model="crown_content_object.cementation"
                size="lg"
              />
            </label>
            <label class="details floating-label">
              <span>Other</span>
              <input type="text" v-model="crown_content_object.other" />
            </label>
            <div class="select-crown">
              <h2>Types of Crown</h2>
              <label
                class="
                  d-flex
                  justify-content-flex-start
                  align-items-center
                  gap-between
                "
              >
                <input
                  id="cementation"
                  type="radio"
                  v-model="crown_content_object.crown_type"
                  value="A"
                  size="lg"
                />
                <span>Stainless Steel Crown</span>
              </label>
              <label
                class="
                  gap-between
                  d-flex
                  justify-content-flex-start
                  align-items-center
                "
              >
                <input
                  type="radio"
                  size="lg"
                  v-model="crown_content_object.crown_type"
                  value="B"
                />
                <span>Porcelain fused to metal</span>
              </label>
              <label
                class="
                  gap-between
                  d-flex
                  justify-content-flex-start
                  align-items-center
                "
              >
                <input
                  type="radio"
                  size="lg"
                  v-model="crown_content_object.crown_type"
                  value="C"
                />
                <span>Full Porcelain</span>
              </label>
              <label
                class="
                  gap-between
                  d-flex
                  justify-content-flex-start
                  align-items-center
                "
              >
                <input
                  type="radio"
                  size="lg"
                  v-model="crown_content_object.crown_type"
                  value="D"
                />
                <span>Precious metal</span>
              </label>
              <label
                class="
                  gap-between
                  d-flex
                  justify-content-flex-start
                  align-items-center
                "
              >
                <input
                  type="radio"
                  size="lg"
                  v-model="crown_content_object.crown_type"
                  value="E"
                />
                <span>Non-precious metal</span>
              </label>
              <label
                class="
                  gap-between
                  d-flex
                  justify-content-flex-start
                  align-items-center
                "
              >
                <input
                  type="radio"
                  size="lg"
                  v-model="crown_content_object.crown_type"
                  value="F"
                />
                <span>None</span>
              </label>
            </div>
            <label class="addtional-detail">
              <h2>Additional Detail</h2>
              <input
                type="text"
                v-model="crown_content_object.detail"
                placeholder
              />
            </label>

            <div class="continue-button">
              <b-button class="close" @click="close()">CLOSE</b-button>
              <b-button class="ok" @click="saveCrowninfo()">SAVE</b-button>
            </div>
          </b-form>
        </b-modal>
      </div>
      <!-- Root Modal -->
      <div>
        <b-modal
          ref="root-modal"
          hide-footer
          centered
          title="Steps of the procedure"
          no-stacking
          no-close-on-backdrop
        >
          <b-form>
            <div class="root-modal">
              <label class="space-between">
                <span>Pulpectomy</span>
                <input
                  type="checkbox"
                  v-model="root_content_object.pulpectomy"
                  size="lg"
                />
              </label>
              <label class="space-between">
                <span>Pulpotomy</span>
                <input
                  type="checkbox"
                  v-model="root_content_object.pulpotomy"
                  size="lg"
                />
              </label>
              <label class="space-between">
                <span>Dressing</span>
                <input
                  type="checkbox"
                  v-model="root_content_object.dressing"
                  size="lg"
                />
              </label>
              <label class="space-between">
                <span>Preparation</span>
                <input
                  type="checkbox"
                  v-model="root_content_object.root_preparation"
                  size="lg"
                />
              </label>
              <label class="space-between">
                <span>Root Filling(obturation)</span>
                <input
                  type="checkbox"
                  v-model="root_content_object.root_filling"
                  size="lg"
                />
              </label>
            </div>
            <div class="additional-details">
              <span>Additional Details</span>
              <label class="space-between">
                <input
                  type="text"
                  v-model="root_content_object.detail"
                  size="lg"
                />
              </label>
            </div>
            <div class="continue-button">
              <b-button class="close" @click="close()">CLOSE</b-button>
              <b-button class="ok" @click="saveRootinfo()">SAVE</b-button>
            </div>
          </b-form>
        </b-modal>
      </div>
    </form>
    <b-toast
      id="treatment-error-toast"
      variant="danger"
      solid
      append-toast
      toaster="b-toaster-top-center"
    >
      <div slot="toast-title" class="d-flex flex-grow-1 align-items-baseline">
        <strong class="mr-auto">Error!</strong>
      </div>
      <p>
        Before choosing another treatment Type for a teeth,Remove the selected
        treatment TYPE
      </p>
    </b-toast>
    <b-toast
      id="select-toast"
      variant="danger"
      solid
      append-toast
      toaster="b-toaster-top-center"
    >
      <div slot="toast-title" class="d-flex flex-grow-1 align-items-baseline">
        <strong class="mr-auto"> Error!</strong>
      </div>
      <p>Please Select Treatment TYPE</p>
    </b-toast>
    <b-toast
      id="treatmentInfo-surface-toast"
      variant="danger"
      solid
      append-toast
      toaster="b-toaster-top-center"
    >
      <div slot="toast-title" class="d-flex flex-grow-1 align-items-baseline">
        <strong class="mr-auto"> Error!</strong>
      </div>
      <p>Please Select a Surface to Continue.</p>
    </b-toast>
    <b-toast
      id="treatmentInfo-crown-toast"
      variant="danger"
      solid
      append-toast
      toaster="b-toaster-top-center"
    >
      <div slot="toast-title" class="d-flex flex-grow-1 align-items-baseline">
        <strong class="mr-auto">Error!</strong>
      </div>
      <p>Please Select a Crown Type to Continue.</p>
    </b-toast>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import axios from "axios";
import { uuid } from "vue-uuid";

export default {
  name: "app",
  components: {},
  data() {
    return {
      encounter_id: this.$route.params.id,
      treatment_id: [],
      selectedType: "",
      selectedTeethName: "",
      disabledGic: false,
      disabledComposite: false,
      disabledCementation: false,
      disabledPreparation: false,
      restoration_content_object: {
        GIC: false,
        buccal: false,
        composite: false,
        detail: "",
        distal: false,
        mesial: false,
        occlusal: false,
        palatal: false,
      },
      crown_content_object: {
        cementation: false,
        detail: "",
        crown_type: "F",
        other: "",
        preparation: false,
      },
      root_content_object: {
        detail: "",
        preparation: false,
        dressing: false,
        root_filling: false,
        root_preparation: false,
        pulpectomy: false,
        pulpotomy: false,
      },

      fv_applied: false,
      OHE_given: false,
      treatment_plan_complete: false,
      sdf_whole_mouth: false,
      notes: "",
      treatmentInfo: [],
      backgroundSdf: false,
      backgroundSeal: false,
      backgroundRestoration: false,
      backgroundArt: false,
      backgroundExo: false,
      backgroundCrown: false,
      backgroundRoot: false,
      backgroundUntr: false,
      OptionSelected: false,
    };
  },

  beforeUpdate() {
    this.teeth_obj.forEach((element) => {
      this.treatmentInfo.forEach((treatment) => {
        if (element.id == treatment.teeth.id) {
          var elem = document.getElementById(element.id);
          if (treatment.treatment_types == "SDF") {
            elem.classList.add("sdf");
          }
          if (treatment.treatment_types == "SEAL") {
            elem.classList.add("seal");
          }
          if (treatment.treatment_types == "RESTORATION") {
            elem.classList.add("restoration");
          }
          if (treatment.treatment_types == "EXO") {
            elem.classList.add("exo");
          }
          if (treatment.treatment_types == "ART") {
            elem.classList.add("art");
          }
          if (treatment.treatment_types == "CROWN") {
            elem.classList.add("crowns");
          }
          if (treatment.treatment_types == "ROOT") {
            elem.classList.add("root");
          }
          if (treatment.treatment_types == "UNTR") {
            elem.classList.add("untr");
          }
        }
      });
    });
  },

  computed: {
    ...mapState([
      "treatment",
      "teeth_obj",
      "successmessage",
      "errormessage",
      "message",
      "profile",
    ]),

    storeId() {
      console.log(this.treatment);
      this.treatment_id = this.treatment;
      console.log(this.treatment_id);
    },

    teeth_11_18: function() {
      let teethNumber = [];
      this.teeth_obj.forEach((teeth) => {
        if (teeth.id < 9) {
          teethNumber.push(teeth);
        }
      });
      return teethNumber.reverse();
    },
    teeth_21_28: function() {
      let teethNumber = [];
      this.teeth_obj.forEach((teeth) => {
        if (teeth.id > 8 && teeth.id < 17) {
          teethNumber.push(teeth);
        }
      });
      return teethNumber;
    },
    teeth_31_38: function() {
      let teethNumber = [];
      this.teeth_obj.forEach((teeth) => {
        if (teeth.id > 16 && teeth.id < 25) {
          teethNumber.push(teeth);
        }
      });
      return teethNumber;
    },
    teeth_41_48: function() {
      let teethNumber = [];
      this.teeth_obj.forEach((teeth) => {
        if (teeth.id > 24 && teeth.id < 33) {
          teethNumber.push(teeth);
        }
      });
      return teethNumber.reverse();
    },
    teeth_51_55: function() {
      let teethNumber = [];
      this.teeth_obj.forEach((teeth) => {
        if (teeth.id > 32 && teeth.id < 38) {
          teethNumber.push(teeth);
        }
      });
      return teethNumber.reverse();
    },
    teeth_61_65: function() {
      let teethNumber = [];
      this.teeth_obj.forEach((teeth) => {
        if (teeth.id > 37 && teeth.id < 43) {
          teethNumber.push(teeth);
        }
      });
      return teethNumber;
    },
    teeth_71_75: function() {
      let teethNumber = [];
      this.teeth_obj.forEach((teeth) => {
        if (teeth.id > 42 && teeth.id < 48) {
          teethNumber.push(teeth);
        }
      });
      return teethNumber;
    },
    teeth_81_85: function() {
      let teethNumber = [];
      this.teeth_obj.forEach((teeth) => {
        if (teeth.id > 47 && teeth.id < 53) {
          teethNumber.push(teeth);
        }
      });
      return teethNumber.reverse();
    },
  },

  watch: {
    treatment: function() {
      if (Object.keys(this.treatment).length !== 0) {
        this.fv_applied = this.treatment.fv_applied;
        this.OHE_given = this.treatment.OHE_given;
        this.treatment_plan_complete = this.treatment.treatment_plan_complete;
        this.sdf_whole_mouth = this.treatment.sdf_whole_mouth;
        this.notes = this.treatment.notes;
        this.treatmentInfo = this.treatment.treatment_info;
      } else {
        this.fv_applied = false;
        this.OHE_given = false;
        this.treatment_plan_complete = false;
        this.sdf_whole_mouth = false;
        this.notes = "";
        this.treatmentInfo = [];
      }
    },
  },
  created() {
    this.listEncounterTreatment(this.encounter_id);
    this.listTeeth();
  },
  methods: {
    ...mapActions(["listEncounterTreatment", "listTeeth", "listProfile"]),
    saveRestorationinfo() {
      if (
        this.restoration_content_object.GIC == false &&
        this.restoration_content_object.buccal == false &&
        this.restoration_content_object.composite == false &&
        this.restoration_content_object.detail == "" &&
        this.restoration_content_object.distal == false &&
        this.restoration_content_object.mesial == false &&
        this.restoration_content_object.occlusal == false &&
        this.restoration_content_object.palatal == false
      ) {
        const result = this.treatmentInfo.filter(
          (i) => i.teeth.name == this.selectedTeethName
        );
        console.log(result);
        if (result.length > 0) {
          let i = this.treatmentInfo
            .map((item) => item.id)
            .indexOf(result[0].id);

          this.treatmentInfo.splice(i, 1);

          if (typeof result[0].id == "number") {
            this.$store
              .dispatch("removeTreatmentInfo", result[0].id)
              .then(() => {
                if (this.successmessage == "success") {
                }
              });
          }
          var elem = document.getElementById(result[0].teeth.id);
          let treatmentType = result[0].treatment_types;
          treatmentType = treatmentType.toLowerCase();
          elem.classList.remove(treatmentType);
          this.$refs["restoration-modal"].hide();
        } else {
          console.log(this.treatmentInfo);
          this.$refs["restoration-modal"].hide();
        }
      } else if (
        this.restoration_content_object.composite == true &&
        this.restoration_content_object.buccal == false &&
        this.restoration_content_object.detail == "" &&
        this.restoration_content_object.distal == false &&
        this.restoration_content_object.mesial == false &&
        this.restoration_content_object.occlusal == false &&
        this.restoration_content_object.palatal == false
      ) {
        this.$bvToast.show("treatmentInfo-surface-toast");
      } else if (
        this.restoration_content_object.GIC == true &&
        this.restoration_content_object.buccal == false &&
        this.restoration_content_object.detail == "" &&
        this.restoration_content_object.distal == false &&
        this.restoration_content_object.mesial == false &&
        this.restoration_content_object.occlusal == false &&
        this.restoration_content_object.palatal == false
      ) {
        this.$bvToast.show("treatmentInfo-surface-toast");
      } else {
        const result = this.treatmentInfo.filter(
          (i) => i.teeth.name == this.selectedTeethName
        );
        console.table(this.treatmentInfo);
        console.log(result);
        if (result.length > 0) {
          let i = this.treatmentInfo
            .map((item) => item.id)
            .indexOf(result[0].id);
          console.log(this.treatmentInfo[i]);
          this.treatmentInfo[
            i
          ].content_object.GIC = this.restoration_content_object.GIC;
          this.treatmentInfo[
            i
          ].content_object.buccal = this.restoration_content_object.buccal;
          this.treatmentInfo[
            i
          ].content_object.composite = this.restoration_content_object.composite;
          this.treatmentInfo[
            i
          ].content_object.distal = this.restoration_content_object.distal;
          this.treatmentInfo[
            i
          ].content_object.mesial = this.restoration_content_object.mesial;
          this.treatmentInfo[
            i
          ].content_object.occlusal = this.restoration_content_object.occlusal;
          this.treatmentInfo[
            i
          ].content_object.palatal = this.restoration_content_object.palatal;
          this.treatmentInfo[
            i
          ].content_object.detail = this.restoration_content_object.detail;
          // console.log(this.treatmentInfo[i]);

          this.$refs["restoration-modal"].hide();
        } else {
          let teth = this.teeth_obj.filter((t) => {
            if (t.name == this.selectedTeethName) {
              return t;
            }
          });
          if (Object.keys(this.treatment).length !== 0) {
            let treatment_id = this.treatment.id;
            let teethTreatmentInfo = {
              id: uuid.v1(),
              treatment: treatment_id,
              treatment_types: this.selectedType,
              teeth: teth[0],
              content_object: {
                GIC: this.restoration_content_object.GIC,
                composite: this.restoration_content_object.composite,
                mesial: this.restoration_content_object.mesial,
                occlusal: this.restoration_content_object.occlusal,
                distal: this.restoration_content_object.distal,
                buccal: this.restoration_content_object.buccal,
                palatal: this.restoration_content_object.palatal,
                detail: this.restoration_content_object.detail,
              },
            };
            let elem = document.getElementById(teethTreatmentInfo.teeth.id);
            let treatmentType = this.selectedType;
            treatmentType = treatmentType.toLowerCase();
            elem.classList.add(treatmentType);
            this.treatmentInfo.push(teethTreatmentInfo);
            console.log(teethTreatmentInfo);

            console.table(this.treatmentInfo);
            this.$refs["restoration-modal"].hide();
          } else {
            let teethTreatmentInfo = {
              id: uuid.v1(),
              treatment: "",
              treatment_types: this.selectedType,
              teeth: teth[0],
              content_object: {
                GIC: this.restoration_content_object.GIC,
                composite: this.restoration_content_object.composite,
                mesial: this.restoration_content_object.mesial,
                occlusal: this.restoration_content_object.occlusal,
                distal: this.restoration_content_object.distal,
                buccal: this.restoration_content_object.buccal,
                palatal: this.restoration_content_object.palatal,
                detail: this.restoration_content_object.detail,
              },
            };
            let elem = document.getElementById(teethTreatmentInfo.teeth.id);
            let treatmentType = this.selectedType;
            treatmentType = treatmentType.toLowerCase();
            elem.classList.add(treatmentType);
            console.log(teethTreatmentInfo);

            this.treatmentInfo.push(teethTreatmentInfo);
            console.log(this.treatmentInfo);

            this.$refs["restoration-modal"].hide();
          }
        }
      }
    },
    saveCrowninfo() {
      if (
        this.crown_content_object.cementation == false &&
        this.crown_content_object.crown_type == "F" &&
        this.crown_content_object.other == "" &&
        this.crown_content_object.detail == "" &&
        this.crown_content_object.preparation == false
      ) {
        const result = this.treatmentInfo.filter(
          (i) => i.teeth.name == this.selectedTeethName
        );
        if (result.length > 0) {
          let i = this.treatmentInfo
            .map((item) => item.id)
            .indexOf(result[0].id);
          this.treatmentInfo.splice(i, 1);

          if (typeof result[0].id == "number") {
            this.$store
              .dispatch("removeTreatmentInfo", result[0].id)
              .then(() => {
                if (this.successmessage == "success") {
                }
              });
          }
          var elem = document.getElementById(result[0].teeth.id);
          let treatmentType = result[0].treatment_types;
          treatmentType = treatmentType.toLowerCase();
          elem.classList.remove(treatmentType + "s");
          this.$refs["crown-modal"].hide();
        } else {
          this.$refs["crown-modal"].hide();
        }
      } else if (this.crown_content_object.crown_type == "F") {
        this.$bvToast.show("treatmentInfo-crown-toast");
      } else {
        const result = this.treatmentInfo.filter(
          (i) => i.teeth.name == this.selectedTeethName
        );
        if (result.length > 0) {
          let i = this.treatmentInfo
            .map((item) => item.id)
            .indexOf(result[0].id);
          this.treatmentInfo[
            i
          ].content_object.cementation = this.crown_content_object.cementation;
          this.treatmentInfo[
            i
          ].content_object.crown_type = this.crown_content_object.crown_type;
          this.treatmentInfo[
            i
          ].content_object.other = this.crown_content_object.other;
          this.treatmentInfo[
            i
          ].content_object.detail = this.crown_content_object.detail;
          this.treatmentInfo[
            i
          ].content_object.preparation = this.crown_content_object.preparation;
          console.log(this.treatmentInfo[i]);
          this.$refs["crown-modal"].hide();
        } else {
          let teth = this.teeth_obj.filter((t) => {
            if (t.name == this.selectedTeethName) {
              return t;
            }
          });
          if (Object.keys(this.treatment).length !== 0) {
            let treatment_id = this.treatment.id;
            let teethTreatmentInfo = {
              id: uuid.v1(),

              treatment: treatment_id,
              treatment_types: this.selectedType,
              teeth: teth[0],
              content_object: {
                preparation: this.crown_content_object.preparation,
                cementation: this.crown_content_object.cementation,
                other: this.crown_content_object.other,
                crown_type: this.crown_content_object.crown_type,
                detail: this.crown_content_object.detail,
              },
            };
            let elem = document.getElementById(teethTreatmentInfo.teeth.id);
            let treatmentType = this.selectedType;
            treatmentType = treatmentType.toLowerCase();
            treatmentType = treatmentType + "s";
            elem.classList.add(treatmentType);

            this.treatmentInfo.push(teethTreatmentInfo);
            this.$refs["crown-modal"].hide();
          } else {
            let teethTreatmentInfo = {
              id: uuid.v1(),

              treatment: "",
              treatment_types: this.selectedType,
              teeth: teth[0],
              content_object: {
                preparation: this.crown_content_object.preparation,
                cementation: this.crown_content_object.cementation,
                other: this.crown_content_object.other,
                crown_type: this.crown_content_object.crown_type,
                detail: this.crown_content_object.detail,
              },
            };
            let elem = document.getElementById(teethTreatmentInfo.teeth.id);
            let treatmentType = this.selectedType;
            treatmentType = treatmentType.toLowerCase();
            treatmentType = treatmentType + "s";
            elem.classList.add(treatmentType);
            this.treatmentInfo.push(teethTreatmentInfo);
            console.log(this.treatmentInfo);
            this.$refs["crown-modal"].hide();
          }
        }
      }
    },
    saveRootinfo() {
      if (
        this.root_content_object.root_preparation == false &&
        this.root_content_object.pulpectomy == false &&
        this.root_content_object.pulpotomy == false &&
        this.root_content_object.dressing == "" &&
        this.root_content_object.detail == "" &&
        this.root_content_object.root_filling == false
      ) {
        const result = this.treatmentInfo.filter(
          (i) => i.teeth.name == this.selectedTeethName
        );
        if (result.length > 0) {
          let i = this.treatmentInfo
            .map((item) => item.id)
            .indexOf(result[0].id);

          this.treatmentInfo.splice(i, 1);

          if (typeof result[0].id == "number") {
            this.$store
              .dispatch("removeTreatmentInfo", result[0].id)
              .then(() => {
                if (this.successmessage == "success") {
                }
              });
          }
          var elem = document.getElementById(result[0].teeth.id);
          let treatmentType = result[0].treatment_types;
          treatmentType = treatmentType.toLowerCase();
          elem.classList.remove(treatmentType);
          console.log(this.treatmentInfo);
          this.$refs["root-modal"].hide();
        } else {
          this.$refs["root-modal"].hide();
        }
      } else {
        const result = this.treatmentInfo.filter(
          (i) => i.teeth.name == this.selectedTeethName
        );
        if (result.length > 0) {
          let i = this.treatmentInfo
            .map((item) => item.id)
            .indexOf(result[0].id);
          this.treatmentInfo[
            i
          ].content_object.pulpectomy = this.root_content_object.pulpectomy;
          this.treatmentInfo[
            i
          ].content_object.pulpotomy = this.root_content_object.pulpotomy;
          this.treatmentInfo[
            i
          ].content_object.root_preparation = this.root_content_object.root_preparation;
          this.treatmentInfo[
            i
          ].content_object.dressing = this.root_content_object.dressing;
          this.treatmentInfo[
            i
          ].content_object.detail = this.root_content_object.detail;
          this.treatmentInfo[
            i
          ].content_object.root_filling = this.root_content_object.root_filling;
          this.$refs["root-modal"].hide();
        } else {
          let teth = this.teeth_obj.filter((t) => {
            if (t.name == this.selectedTeethName) {
              return t;
            }
          });
          if (Object.keys(this.treatment).length !== 0) {
            let treatment_id = this.treatment.id;
            // console.log(teth);
            let teethTreatmentInfo = {
              id: uuid.v1(),

              treatment: treatment_id,
              treatment_types: this.selectedType,
              teeth: teth[0],
              content_object: {
                pulpectomy: this.root_content_object.pulpectomy,
                pulpotomy: this.root_content_object.pulpotomy,
                dressing: this.root_content_object.dressing,
                root_preparation: this.root_content_object.root_preparation,
                root_filling: this.root_content_object.root_filling,
                detail: this.root_content_object.detail,
              },
            };
            let elem = document.getElementById(teethTreatmentInfo.teeth.id);
            let treatmentType = this.selectedType;
            treatmentType = treatmentType.toLowerCase();
            elem.classList.add(treatmentType);
            this.treatmentInfo.push(teethTreatmentInfo);
            this.$refs["root-modal"].hide();
          } else {
            let teethTreatmentInfo = {
              id: uuid.v1(),
              treatment: "",
              treatment_types: this.selectedType,
              teeth: teth[0],
              content_object: {
                pulpectomy: this.root_content_object.pulpectomy,
                pulpotomy: this.root_content_object.pulpotomy,
                dressing: this.root_content_object.dressing,
                root_preparation: this.root_content_object.root_preparation,
                root_filling: this.root_content_object.root_filling,
                detail: this.root_content_object.detail,
              },
            };
            let elem = document.getElementById(teethTreatmentInfo.teeth.id);
            let treatmentType = this.selectedType;
            treatmentType = treatmentType.toLowerCase();
            elem.classList.add(treatmentType);
            this.treatmentInfo.push(teethTreatmentInfo);
            console.log(this.treatmentInfo);
            this.$refs["root-modal"].hide();
          }
        }
      }
    },
    selectType(type) {
      if (this.selectedType == type) {
        this.selectedType = "";
        this.backgroundSdf = false;
        this.backgroundSeal = false;
        this.backgroundArt = false;
        this.backgroundRestoration = false;
        this.backgroundExo = false;
        this.backgroundCrown = false;
        this.backgroundRoot = false;
        this.backgroundUntr = false;
      } else {
        // alert("selected type is empty")
        this.selectedType = type;
        if (this.selectedType == "SDF") {
          this.backgroundSdf = true;
          this.backgroundSeal = false;
          this.backgroundArt = false;
          this.backgroundRestoration = false;
          this.backgroundExo = false;
          this.backgroundCrown = false;
          this.backgroundRoot = false;
          this.backgroundUntr = false;
        } else if (this.selectedType == "SEAL") {
          this.backgroundSeal = true;
          this.backgroundSdf = false;
          this.backgroundArt = false;
          this.backgroundRestoration = false;
          this.backgroundExo = false;
          this.backgroundCrown = false;
          this.backgroundRoot = false;
          this.backgroundUntr = false;
        } else if (this.selectedType == "RESTORATION") {
          this.backgroundSeal = false;
          this.backgroundSdf = false;
          this.backgroundArt = false;
          this.backgroundRestoration = true;
          this.backgroundExo = false;
          this.backgroundCrown = false;
          this.backgroundRoot = false;
          this.backgroundUntr = false;
        } else if (this.selectedType == "ART") {
          this.backgroundSeal = false;
          this.backgroundSdf = false;
          this.backgroundArt = true;
          this.backgroundRestoration = false;
          this.backgroundExo = false;
          this.backgroundCrown = false;
          this.backgroundRoot = false;
          this.backgroundUntr = false;
        } else if (this.selectedType == "EXO") {
          this.backgroundSeal = false;
          this.backgroundSdf = false;
          this.backgroundArt = false;
          this.backgroundRestoration = false;
          this.backgroundExo = true;
          this.backgroundCrown = false;
          this.backgroundRoot = false;
          this.backgroundUntr = false;
        } else if (this.selectedType == "EXO") {
          this.backgroundSeal = false;
          this.backgroundSdf = false;
          this.backgroundArt = false;
          this.backgroundRestoration = false;
          this.backgroundExo = true;
          this.backgroundCrown = false;
          this.backgroundRoot = false;
          this.backgroundUntr = false;
        } else if (this.selectedType == "CROWN") {
          this.backgroundSeal = false;
          this.backgroundSdf = false;
          this.backgroundArt = false;
          this.backgroundRestoration = false;
          this.backgroundExo = false;
          this.backgroundCrown = true;
          this.backgroundRoot = false;
          this.backgroundUntr = false;
        } else if (this.selectedType == "ROOT") {
          this.backgroundSeal = false;
          this.backgroundSdf = false;
          this.backgroundArt = false;
          this.backgroundRestoration = false;
          this.backgroundExo = false;
          this.backgroundCrown = false;
          this.backgroundRoot = true;
          this.backgroundUntr = false;
        } else if (this.selectedType == "UNTR") {
          this.backgroundSeal = false;
          this.backgroundSdf = false;
          this.backgroundArt = false;
          this.backgroundRestoration = false;
          this.backgroundExo = false;
          this.backgroundCrown = false;
          this.backgroundRoot = false;
          this.backgroundUntr = true;
        }
      }
    },
    setTreatmentInfo(name) {
      this.selectedTeethName = name;
      if (
        this.selectedType == "SDF" ||
        this.selectedType == "SEAL" ||
        this.selectedType == "ART" ||
        this.selectedType == "EXO" ||
        this.selectedType == "UNTR"
      ) {
        if (Object.keys(this.treatment).length !== 0) {
          const result = this.treatmentInfo.filter((i) => i.teeth.name == name);

          if (result.length > 0) {
            if (result[0].treatment_types == this.selectedType) {
              let i = this.treatmentInfo
                .map((item) => item.id)
                .indexOf(result[0].id);
              if (typeof result[0].id == "number") {
                this.$store
                  .dispatch("removeTreatmentInfo", result[0].id)
                  .then(() => {
                    if (this.successmessage == "success") {
                    }
                  });
              }
              this.treatmentInfo.splice(i, 1);
              var elem = document.getElementById(result[0].teeth.id);
              let treatmentType = result[0].treatment_types;
              treatmentType = treatmentType.toLowerCase();
              elem.classList.remove(treatmentType);
            } else {
              this.$bvToast.show("treatment-error-toast");
            }
          } else {
            let treatment_id = this.treatment.id;
            let teth = this.teeth_obj.filter((t) => {
              if (t.name == name) {
                return t;
              }
            });
            let teethTreatmentInfo = {
              id: uuid.v1(),
              treatment: treatment_id,
              treatment_types: this.selectedType,
              teeth: teth[0],
            };
            let elem = document.getElementById(teethTreatmentInfo.teeth.id);
            let treatmentType = this.selectedType;
            treatmentType = treatmentType.toLowerCase();
            elem.classList.add(treatmentType);
            this.treatmentInfo.push(teethTreatmentInfo);
          }
        } else {
          const result = this.treatmentInfo.filter((i) => i.teeth.name == name);
          if (result.length > 0) {
            if (result[0].treatment_types == this.selectedType) {
              let i = this.treatmentInfo
                .map((item) => item.id)
                .indexOf(result[0].id);
              this.treatmentInfo.splice(i, 1);
              var elem = document.getElementById(result[0].teeth.id);
              let treatmentType = result[0].treatment_types;
              treatmentType = treatmentType.toLowerCase();
              elem.classList.remove(treatmentType);
            } else {
              this.$bvToast.show("treatment-error-toast");
            }
          } else {
            let teth = this.teeth_obj.filter((t) => {
              if (t.name == name) {
                return t;
              }
            });
            let teethTreatmentInfo = {
              id: uuid.v1(),
              treatment: "",
              treatment_types: this.selectedType,
              teeth: teth[0],
            };
            let elem = document.getElementById(teethTreatmentInfo.teeth.id);
            let treatmentType = this.selectedType;
            treatmentType = treatmentType.toLowerCase();
            elem.classList.add(treatmentType);
            this.treatmentInfo.push(teethTreatmentInfo);
          }
        }
      } else if (this.selectedType == "RESTORATION") {
        const result = this.treatmentInfo.filter((i) => i.teeth.name == name);
        if (result.length > 0) {
          console.log(result);
          if (result[0].treatment_types == this.selectedType) {
            this.restoration_content_object = result[0].content_object;
            if (this.restoration_content_object.composite == true) {
              this.OptionSelected = true;
              this.disabledGic = true;
            } else {
              // this.OptionSelected = false;
              this.disabledGic = false;
            }
            if (this.restoration_content_object.GIC == true) {
              this.OptionSelected = true;
              this.disabledComposite = true;
            } else {
              // this.OptionSelected = false;
              this.disabledComposite = false;
            }
            this.$refs["restoration-modal"].show();
          } else {
            this.$bvToast.show("treatment-error-toast");
          }
        } else {
          this.restoration_content_object.GIC = false;
          this.restoration_content_object.buccal = false;
          this.restoration_content_object.composite = false;
          this.restoration_content_object.detail = "";
          this.restoration_content_object.distal = false;
          this.restoration_content_object.mesial = false;
          this.restoration_content_object.occlusal = false;
          this.restoration_content_object.palatal = false;
          this.disabledComposite = false;
          this.disabledGic = false;
          this.OptionSelected = false;

          this.$refs["restoration-modal"].show();
        }
      } else if (this.selectedType == "CROWN") {
        const result = this.treatmentInfo.filter((i) => i.teeth.name == name);
        if (result.length > 0) {
          if (result[0].treatment_types == this.selectedType) {
            this.crown_content_object = result[0].content_object;
            if (
              this.crown_content_object.crown_type == "Stainless Steel Crown"
            ) {
              this.crown_content_object.crown_type = "A";
            } else if (
              this.crown_content_object.crown_type == "Porcelain Fused to Metal"
            ) {
              this.crown_content_object.crown_type = "B";
            } else if (
              this.crown_content_object.crown_type == "Full Porcelain"
            ) {
              this.crown_content_object.crown_type = "C";
            } else if (
              this.crown_content_object.crown_type == "Precious Metal"
            ) {
              this.crown_content_object.crown_type = "D";
            } else if (
              this.crown_content_object.crown_type == "Non-precious Metal"
            ) {
              this.crown_content_object.crown_type = "E";
            }
            if (this.crown_content_object.preparation == true) {
              this.disabledCementation = true;
            } else {
              this.disabledCementation = false;
            }
            if (this.crown_content_object.cementation == true) {
              this.disabledPreparation = true;
            } else {
              this.disabledPreparation = false;
            }
            this.$refs["crown-modal"].show();
          } else {
            this.$bvToast.show("treatment-error-toast");
          }
        } else {
          this.crown_content_object.cementation = false;
          this.crown_content_object.crown_type = "F";
          this.crown_content_object.other = "";
          this.crown_content_object.detail = "";
          this.crown_content_object.preparation = false;
          this.disabledCementation = false;
          this.disabledPreparation = false;
          this.$refs["crown-modal"].show();
        }
      } else if (this.selectedType == "ROOT") {
        const result = this.treatmentInfo.filter((i) => i.teeth.name == name);
        console.log(result);

        if (result.length > 0) {
          if (result[0].treatment_types == this.selectedType) {
            this.root_content_object = result[0].content_object;
            this.$refs["root-modal"].show();
          } else {
            this.$bvToast.show("treatment-error-toast");
          }
        } else {
          this.root_content_object.pulpectomy = false;
          this.root_content_object.pulpotomy = false;
          this.root_content_object.root_preparation = false;
          this.root_content_object.dressing = false;
          this.root_content_object.detail = "";
          this.root_content_object.root_filling = false;
          this.$refs["root-modal"].show();
        }
      } else {
        this.$bvToast.show("select-toast");
      }
      //
    },
    backToDiagnostic() {
      this.$store.state.tabIndex--;
      this.$store.state.disabledDiagnostic = false;
      this.$store.state.disabledTreatment = true;
    },
    saveTreatment() {
      //Encounter Treatment
      let treatmentObj = {
        fv_applied: !this.fv_applied ? false : true,
        OHE_given: !this.OHE_given ? false : true,
        treatment_plan_complete: !this.treatment_plan_complete ? false : true,
        sdf_whole_mouth: !this.sdf_whole_mouth ? false : true,
        notes: this.notes == "" ? "N/A" : this.notes,
        encounter_id: this.encounter_id,
      };

      if (Object.keys(this.treatment).length !== 0) {
        this.$store.dispatch("updateTreatment", treatmentObj).then(() => {
          if (this.successmessage == "success") {
            this.success = "Treatment saved successfully.";
            this.$store.state.tabIndex++;
          } else if (this.errormessage == "errormessage") {
            alert("Failed to update Treatment").fadeOut();
          }
        });

        this.treatmentInfo.forEach((element) => {
          if (
            element.treatment_types == "SDF" ||
            element.treatment_types == "SEAL" ||
            element.treatment_types == "ART" ||
            element.treatment_types == "EXO" ||
            element.treatment_types == "UNTR"
          ) {
            let teethTreatmentObj = {
              treatment: element.treatment,
              teeth: element.teeth.id,
              treatment_types: element.treatment_types,
            };
            if (typeof element.id == "number") {
              console.log("old SDF,SEAL,ART,EXO,UNTR");
            } else {
              this.$store
                .dispatch("saveTreatmentInfo", teethTreatmentObj)
                .then(() => {
                  if (this.successmessage == "success") {
                    this.success = "Teeth Treatment Info saved successfully.";
                  } else if (this.errormessage == "errormessage") {
                    alert("Failed to update teet Treatment info").fadeOut();
                  }
                });
            }
          } else if (element.treatment_types == "RESTORATION") {
            if (typeof element.id == "number") {
              let teethTreatmentObj = {
                treatment: element.treatment,
                id: element.id,
                teeth: element.teeth.id,
                treatment_types: element.treatment_types,
                composite: element.content_object.composite,
                GIC: element.content_object.GIC,
                mesial: element.content_object.mesial,
                occlusal: element.content_object.occlusal,
                distal: element.content_object.distal,
                buccal: element.content_object.buccal,
                palatal: element.content_object.palatal,
                detail: element.content_object.detail,
              };
              this.$store
                .dispatch("updateTreatmentInfo", teethTreatmentObj)
                .then(() => {
                  if (this.successmessage == "success") {
                    this.success = "Teeth Treatment Info saved successfully.";
                  } else if (this.errormessage == "errormessage") {
                    alert("Failed to update teeth Treatment info").fadeOut();
                  }
                });
            } else {
              let teethTreatmentObj = {
                treatment: element.treatment,
                teeth: element.teeth.id,
                treatment_types: element.treatment_types,
                composite: element.content_object.composite,
                GIC: element.content_object.GIC,
                mesial: element.content_object.mesial,
                occlusal: element.content_object.occlusal,
                distal: element.content_object.distal,
                buccal: element.content_object.buccal,
                palatal: element.content_object.palatal,
                detail: element.content_object.detail,
              };
              this.$store
                .dispatch("saveTreatmentInfo", teethTreatmentObj)
                .then(() => {
                  if (this.successmessage == "success") {
                    this.success = "Teeth Treatment Info saved successfully.";
                  } else if (this.errormessage == "errormessage") {
                    alert("Failed to update teeth Treatment info").fadeOut();
                  }
                });
            }
          } else if (element.treatment_types == "CROWN") {
            if (element.content_object.crown_type == "A") {
              element.content_object.crown_type = "Stainless Steel Crown";
            } else if (element.content_object.crown_type == "B") {
              element.content_object.crown_type = "Porcelain Fused to Metal";
            } else if (element.content_object.crown_type == "C") {
              element.content_object.crown_type = "Full Porcelain";
            } else if (element.content_object.crown_type == "D") {
              element.content_object.crown_type = "Precious Metal";
            } else if (element.content_object.crown_type == "E") {
              element.content_object.crown_type = "Non-precious Metal";
            }

            if (typeof element.id == "number") {
              let teethTreatmentObj = {
                id: element.id,
                treatment: element.treatment,
                teeth: element.teeth.id,
                treatment_types: element.treatment_types,
                preparation: element.content_object.preparation,
                cementation: element.content_object.cementation,
                other: element.content_object.other,
                detail: element.content_object.detail,
                crown_type: element.content_object.crown_type,
              };

              this.$store
                .dispatch("updateTreatmentInfo", teethTreatmentObj)
                .then(() => {
                  if (this.successmessage == "success") {
                    this.success = "Teeth Treatment Info saved successfully.";
                  } else if (this.errormessage == "errormessage") {
                    alert("Failed to update teeth Treatment info").fadeOut();
                  }
                });
            } else {
              let teethTreatmentObj = {
                treatment: element.treatment,
                teeth: element.teeth.id,
                treatment_types: element.treatment_types,
                preparation: element.content_object.preparation,
                cementation: element.content_object.cementation,
                other: element.content_object.other,
                detail: element.content_object.detail,
                crown_type: element.content_object.crown_type,
              };

              this.$store
                .dispatch("saveTreatmentInfo", teethTreatmentObj)
                .then(() => {
                  if (this.successmessage == "success") {
                    this.success = "Teeth Treatment Info saved successfully.";
                  } else if (this.errormessage == "errormessage") {
                    alert("Failed to update teet Treatment info").fadeOut();
                  }
                });
            }
          } else if (element.treatment_types == "ROOT") {
            if (typeof element.id == "number") {
              let teethTreatmentObj = {
                id: element.id,
                treatment: element.treatment,
                teeth: element.teeth.id,
                treatment_types: element.treatment_types,
                dressing: element.content_object.dressing,
                root_preparation: element.content_object.root_preparation,
                root_filling: element.content_object.root_filling,
                pulpectomy: element.content_object.pulpectomy,
                pulpotomy: element.content_object.pulpotomy,
                detail: element.content_object.detail,
              };
              this.$store
                .dispatch("updateTreatmentInfo", teethTreatmentObj)
                .then(() => {
                  if (this.successmessage == "success") {
                    this.success = "Teeth Treatment Info saved successfully.";
                  } else if (this.errormessage == "errormessage") {
                    alert("Failed to update teeth Treatment info").fadeOut();
                  }
                });
            } else {
              let teethTreatmentObj = {
                treatment: element.treatment,
                teeth: element.teeth.id,
                treatment_types: element.treatment_types,
                dressing: element.content_object.dressing,
                root_preparation: element.content_object.root_preparation,
                root_filling: element.content_object.root_filling,
                pulpectomy: element.content_object.pulpectomy,
                pulpotomy: element.content_object.pulpotomy,
                detail: element.content_object.detail,
              };
              this.$store
                .dispatch("saveTreatmentInfo", teethTreatmentObj)
                .then(() => {
                  if (this.successmessage == "success") {
                    this.success = "Teeth Treatment Info saved successfully.";
                  } else if (this.errormessage == "errormessage") {
                    alert("Failed to update teeth Treatment info").fadeOut();
                  }
                });
            }
          }
        });

        this.$store.state.tabIndex++;
        this.$store.state.disabledTreatment = true;
        this.$store.state.disabledReferral = false;
      } else {
        axios
          .post(
            "https://blcp.dentalhubproject.org/api/v1/encounter/" +
              treatmentObj.encounter_id +
              "/treatment",
            treatmentObj
          )
          .then((response) => {
            if (response.status == 200) {
              let treatment_id = response.data.id;
              // window.localStorage.setItem("treatment_id", response.data.id);
              this.treatmentInfo.forEach((element) => {
                if (
                  element.treatment_types == "SDF" ||
                  element.treatment_types == "SEAL" ||
                  element.treatment_types == "ART" ||
                  element.treatment_types == "EXO" ||
                  element.treatment_types == "UNTR"
                ) {
                  let teethTreatmentObj = {
                    treatment: treatment_id,
                    teeth: element.teeth.id,
                    treatment_types: element.treatment_types,
                  };
                  this.$store
                    .dispatch("saveTreatmentInfo", teethTreatmentObj)
                    .then(() => {
                      if (this.successmessage == "success") {
                        this.success =
                          "Teeth Treatment Info saved successfully.";
                      } else if (this.errormessage == "errormessage") {
                        alert("Failed to update teet Treatment info");
                      }
                    });
                } else if (element.treatment_types == "RESTORATION") {
                  let teethTreatmentObj = {
                    treatment: treatment_id,
                    teeth: element.teeth.id,
                    treatment_types: element.treatment_types,
                    composite: element.content_object.composite,
                    GIC: element.content_object.GIC,
                    mesial: element.content_object.mesial,
                    occlusal: element.content_object.occlusal,
                    distal: element.content_object.distal,
                    buccal: element.content_object.buccal,
                    palatal: element.content_object.palatal,
                    detail: element.content_object.detail,
                  };
                  this.$store
                    .dispatch("saveTreatmentInfo", teethTreatmentObj)
                    .then(() => {
                      if (this.successmessage == "success") {
                        this.success =
                          "Teeth Treatment Info saved successfully.";
                      } else if (this.errormessage == "errormessage") {
                        alert("Failed to update teet Treatment info").fadeOut();
                      }
                    });
                } else if (element.treatment_types == "CROWN") {
                  if (element.content_object.crown_type == "A") {
                    element.content_object.crown_type = "Stainless Steel Crown";
                  } else if (element.content_object.crown_type == "B") {
                    element.content_object.crown_type =
                      "Porcelain Fused to Metal";
                  } else if (element.content_object.crown_type == "C") {
                    element.content_object.crown_type = "Full Porcelain";
                  } else if (element.content_object.crown_type == "D") {
                    element.content_object.crown_type = "Precious Metal";
                  } else if (element.content_object.crown_type == "E") {
                    element.content_object.crown_type = "Non-precious Metal";
                  }
                  let teethTreatmentObj = {
                    treatment: treatment_id,
                    teeth: element.teeth.id,
                    treatment_types: element.treatment_types,
                    preparation: element.content_object.preparation,
                    cementation: element.content_object.cementation,
                    other: element.content_object.other,
                    detail: element.content_object.detail,
                    crown_type: element.content_object.crown_type,
                  };
                  this.$store
                    .dispatch("saveTreatmentInfo", teethTreatmentObj)
                    .then(() => {
                      if (this.successmessage == "success") {
                        this.success =
                          "Teeth Treatment Info saved successfully.";
                      } else if (this.errormessage == "errormessage") {
                        alert("Failed to update teet Treatment info");
                      }
                    });
                } else if (element.treatment_types == "ROOT") {
                  let teethTreatmentObj = {
                    treatment: treatment_id,
                    teeth: element.teeth.id,
                    treatment_types: element.treatment_types,
                    dressing: element.content_object.dressing,
                    root_preparation: element.content_object.root_preparation,
                    root_filling: element.content_object.root_filling,
                    pulpectomy: element.content_object.pulpectomy,
                    pulpotomy: element.content_object.pulpotomy,
                    detail: element.content_object.detail,
                  };
                  this.$store
                    .dispatch("saveTreatmentInfo", teethTreatmentObj)
                    .then(() => {
                      if (this.successmessage == "success") {
                        this.success =
                          "Teeth Treatment Info saved successfully.";
                      } else if (this.errormessage == "errormessage") {
                        alert("Failed to update teet Treatment info").fadeOut();
                      }
                    });
                }
              });
            }
          })
          .catch((error) => {
            if (error) {
              // commit("setErrorMessage", "errormessage");
              commit("setMessage", error.response.data.message);
            }
          });

        this.$store.state.tabIndex++;
        this.$store.state.disabledTreatment = true;
        this.$store.state.disabledReferral = false;
      }
    },
    close() {
      this.$refs["restoration-modal"].hide();
      this.$refs["crown-modal"].hide();
      this.$refs["root-modal"].hide();
    },
    disableComposite() {
      if (this.restoration_content_object.composite == true) {
        this.OptionSelected = true;
        this.disabledGic = true;
      } else {
        this.OptionSelected = false;

        this.restoration_content_object.buccal = false;
        this.restoration_content_object.detail = "";
        this.restoration_content_object.distal = false;
        this.restoration_content_object.mesial = false;
        this.restoration_content_object.occlusal = false;
        this.restoration_content_object.palatal = false;
        this.disabledGic = false;
      }
    },
    disableGic() {
      if (this.restoration_content_object.GIC == true) {
        this.OptionSelected = true;
        this.disabledComposite = true;
      } else {
        this.OptionSelected = false;

        this.restoration_content_object.buccal = false;
        this.restoration_content_object.detail = "";
        this.restoration_content_object.distal = false;
        this.restoration_content_object.mesial = false;
        this.restoration_content_object.occlusal = false;
        this.restoration_content_object.palatal = false;
        this.disabledComposite = false;
      }
    },
    disablePreparation() {
      if (this.crown_content_object.preparation == true) {
        this.disabledCementation = true;
      } else {
        this.disabledCementation = false;
      }
    },
    disableCementation() {
      if (this.crown_content_object.cementation == true) {
        this.disabledPreparation = true;
      } else {
        this.disabledPreparation = false;
      }
    },
  },
};
</script>

<style lang="scss">
form.treatment-form .teeth-number-selection button.colorSdf {
  background-color: rgb(223, 70, 85);
}

form.treatment-form .teeth-number-selection button.colorSeal {
  background-color: rgb(0, 98, 204);
}

form.treatment-form .teeth-number-selection button.colorArt {
  background-color: rgb(32, 134, 55);
}
</style>
<style lang="scss" scoped>
@import "src/css/main.scss";
</style>
