<template>
  <div class="calllist-page">
    <app-header></app-header>
    <div class="components">
      <shortcuttab></shortcuttab>
      <patient-list></patient-list>
      <div></div>
    </div>
    <Footer />
    <!-- <modalpatient></modalpatient> -->
  </div>
</template>
<script>
import AppHeader from "../Header.vue";
import PatientList from "../components_clinic_page/PatientList.vue";
import ShortcutTab from "../components_clinic_page/ShortcutTab.vue";
import Footer from "../components_clinic_page/FooterClinicSection.vue";
// import ModalPatient from '../components_clinic_page/AddPatientModal.vue';

export default {
  name: "CallList",
  components: {
    "app-header": AppHeader,
    "patient-list": PatientList,
    shortcuttab: ShortcutTab,
    // 'modalpatient': ModalPatient,
    Footer,
  },
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
@import "src/css/main.scss";
</style>
