<template>
  <div id="app">
    <main>
      <router-view></router-view>
    </main>
    <footer></footer>
  </div>
</template>

<script>
import footer from "./components/Footer.vue";

export default {
  name: "app",
  components: {
    footer,
  },
  data() {
    return {
      // 'auth': true
    };
  },
  mounted: function () {
    // if (this.$store.state.token == "") {
    //   // this.$router.replace("/");
    //   // show login page
    //   this.auth;
    // }else{
    //   // show authenticated area
    //   this.auth = !this.auth;
    //   // this.$router.replace({name:"adminpage"});
    // }
  },
};
</script>
