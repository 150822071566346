var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid px-5"},[_c('div',{staticClass:"footer card shadow"},[_c('div',{staticClass:"footer-wrapper"},[_c('div',{staticClass:"copyright"},[_c('span',[_vm._v("© "+_vm._s(this.current_year)+" | Designed, Developed & Maintained by")]),_c('a',{attrs:{"href":"https://abhiyantrik.com","target":"_blank"}},[_vm._v("Abhiyantrik Technology")])]),_c('div',{staticClass:"links"},[_c('ul',[_c('li',[_c('router-link',{attrs:{"to":{
                name: 'staticPage',
                params: { slug: 'about' },
              }}},[_c('span',[_vm._v("About Us")])])],1),_c('li',[_c('router-link',{attrs:{"to":{
                name: 'staticPage',
                params: { slug: 'help' },
              }}},[_c('span',[_vm._v("Help")])])],1),_c('li',[_c('router-link',{attrs:{"to":{
                name: 'staticPage',
                params: { slug: 'privacy-policy' },
              }}},[_c('span',[_vm._v("Privacy Policy")])])],1),_c('li',[_c('router-link',{attrs:{"to":{
                name: 'staticPage',
                params: { slug: 'play-store' },
              }}},[_c('span',[_vm._v("Play Store")])])],1),_c('li',[_c('router-link',{attrs:{"to":{
                name: 'staticPage',
                params: { slug: 'dental-hub-open-source-project' },
              }}},[_c('span',[_vm._v("Dental Hub Open Source Project")])])],1),_c('li',[_c('router-link',{attrs:{"to":"/"}},[_c('b-button',{attrs:{"variant":"primary"}},[_vm._v("Back")])],1)],1)])])])]),_c('div',{staticClass:"static-page card shadow p-5"},[(_vm.static_page_obj)?_c('article',[_c('h1',[_vm._v(_vm._s(_vm.static_page_obj.title))]),_c('p',[_vm._v(_vm._s(_vm.static_page_obj.content))])]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }