<template>
  <form action class="referral-form" @submit.prevent="submitReferral">
    <label class="no-referral space-between">
      <span>No Referral</span>
      <input
        type="checkbox"
        size="lg"
        v-model="no_referral"
        @change="disableReferral"
      />
    </label>
    <label class="health-post space-between">
      <span>Routine Clinic</span>
      <input
        type="checkbox"
        size="lg"
        v-model="routineClinic"
        :disabled="disabled"
        @change="showRecall"
      />
    </label>
    <label class="hygienist space-between">
      <span>Hygienist</span>
      <input
        type="checkbox"
        size="lg"
        v-model="hygienist"
        :disabled="disabled"
      />
    </label>
    <label class="dentist space-between">
      <span>Dentist</span>
      <input type="checkbox" size="lg" v-model="dentist" :disabled="disabled" />
    </label>
    <label class="space-between">
      <span>General Physician</span>
      <input
        type="checkbox"
        size="lg"
        v-model="generalPhysician"
        :disabled="disabled"
      />
    </label>
    <label class="other space-between">
      <span>Other</span>
      <input
        type="checkbox"
        v-model="selectOther"
        :disabled="disabled"
        @change="showOther"
      />
    </label>
    <input
      type="text"
      v-model="others"
      placeholder="Eg."
      v-if="showOtherInput"
    />
    <span class="form-error" v-if="errors.referral">{{ errors.referral }}</span>
    <div class="div" v-if="showRecallDiv">
      <div class="recall-date medication font-bold">
        <p>Recall Date</p>
        <div class="mb-2">
          <b-form-datepicker
            id="datepicker-lg"
            class="recalldates"
            size="lg"
            locale="en"
            menu-class="w-100"
            calendar-width="100%"
            v-model="calendar"
          ></b-form-datepicker>
        </div>
        <span class="form-error" v-if="errors.calender">
          {{ errors.calender }}
        </span>
      </div>
      <div class="recall-time medication font-bold">
        <p>Time</p>
        <div class="mb-2">
          <b-form-timepicker
            id="timepicker-buttons"
            now-button
            reset-button
            no-close-button
            size="lg"
            locale="en"
            menu-class="w-100"
            calendar-width="100%"
            v-model="recall_time"
          ></b-form-timepicker>
        </div>
        <span class="form-error" v-if="errors.recall_time">
          {{ errors.recall_time }}
        </span>
      </div>
      <div class="time-period">
        <label class="centered">
          <input
            type="radio"
            v-model="change_recall_date"
            value="firstweek"
            id="week"
            @change="increaseOneWeek"
          />
          <span>1 week</span>
        </label>
        <label class="centered">
          <input
            type="radio"
            v-model="change_recall_date"
            value="firstmonth"
            id="month"
            @change="increaseOneMonth"
          />
          <span>1 month</span>
        </label>
        <label class="centered">
          <input
            type="radio"
            v-model="change_recall_date"
            value="threemonth"
            id="month"
            @change="increaseThreeMonth"
          />
          <span>3 month</span>
        </label>
        <label class="centered">
          <input
            type="radio"
            v-model="change_recall_date"
            value="halfmonth"
            id="half-month"
            @change="increaseSixMonth"
          />
          <span>6 month</span>
        </label>
        <label class="centered">
          <input
            type="radio"
            v-model="change_recall_date"
            value="fullyear"
            id="full-year"
            @change="increaseOneYear"
          />
          <span>1 year</span>
        </label>
      </div>
    </div>
    <div class="button">
      <b-button variant="outline-success" @click="backToTreatment"
        >Back</b-button
      >
      <b-button type="submit" v-b-modal.report-modal variant="outline-success"
        >Save</b-button
      >
      <!-- <b-button type="submit" variant="outline-success">Save</b-button> -->
    </div>
    <reportmodal></reportmodal>
    <b-toast
      id="referral-success-toast"
      variant="success"
      solid
      append-toast
      toaster="b-toaster-top-center"
    >
      <div slot="toast-title" class="d-flex flex-grow-1 align-items-baseline">
        <strong class="mr-auto">Success !</strong>
      </div>
      <p v-if="this.updated">{{ this.updated }}</p>
      <p v-if="this.save">{{ this.save }}</p>
    </b-toast>
    <b-toast
      id="referral-error-toast"
      variant="danger"
      solid
      append-toast
      toaster="b-toaster-top-center"
    >
      <div slot="toast-title" class="d-flex flex-grow-1 align-items-baseline">
        <strong class="mr-auto">Error !</strong>
      </div>
      <p>Could Not Saved the Referral</p>
    </b-toast>
  </form>
</template>

<script>
import ReportModal from "../components_clinic_page/ReportModal.vue";
import { mapState, mapActions } from "vuex";
export default {
  name: "Referral",
  components: {
    reportmodal: ReportModal,
  },
  data() {
    return {
      encounter_id: this.$route.params.id,
      no_referral: false,
      routineClinic: null,
      hygienist: null,
      dentist: null,
      generalPhysician: null,
      disabled: false,
      // disableReferral: false,
      others: "",
      selectOther: null,
      monthIndex: 0,
      recall_date: "",
      recall_time: "",
      change_recall_date: "",
      updated_at: new Date().toISOString().split("T")[0],
      showRecallDiv: false,
      showOtherInput: false,
      recall: {
        day: "",
        month: "",
        year: "",
      },
      recallTime: "",
      errors: [],
      success: "",
      calendar: new Date(),
      updated: "",
      save: "",
    };
  },
  computed: {
    ...mapState([
      "referral",
      "profile",
      "successmessage",
      "errormessage",
      "message",
      "patients",
    ]),
  },
  watch: {
    referral: function() {
      // console.log("hello");
      if (Object.keys(this.referral).length !== 0) {
        this.no_referral = this.referral.no_referral;
        this.routineClinic = this.referral.clinic;
        this.hygienist = this.referral.hygienist;
        this.dentist = this.referral.dentist;
        this.generalPhysician = this.referral.general_physician;
        this.others = this.referral.other != "N/a" ? this.referral.other : "";

        this.calendar = this.patients.recall_date;
        this.recall_time = this.patients.recall_time;
      } else {
        this.no_referral = null;
        this.healthPost = null;
        this.hygienist = null;
        this.dentist = null;
        this.generalPhysician = null;
        this.others = "";
      }
      if (this.no_referral == true) {
        this.disabled = true;
      } else {
        this.disabled = false;
      }
      if (this.routineClinic == true) {
        this.showRecallDiv = true;
      }
    },
    // referral: function() {
    //   // console.log("hello");

    // }
  },
  created() {
    this.listEncounterReferral(this.encounter_id);
    this.listProfile();
  },
  methods: {
    ...mapActions(["listEncounterReferral", "listProfile"]),
    increaseOneWeek() {
      this.calendar = new Date();
      this.calendar.setDate(this.calendar.getDate() + 7);
      this.calendar = this.calendar.toISOString().split("T")[0];
    },
    increaseOneMonth() {
      this.calendar = new Date();
      this.calendar.setDate(this.calendar.getDate() + 30);
      this.calendar = this.calendar.toISOString().split("T")[0];
    },
    increaseThreeMonth() {
      this.calendar = new Date();
      this.calendar.setDate(this.calendar.getDate() + 90);
      this.calendar = this.calendar.toISOString().split("T")[0];
    },
    increaseSixMonth() {
      this.calendar = new Date();
      this.calendar.setDate(this.calendar.getDate() + 180);
      this.calendar = this.calendar.toISOString().split("T")[0];
    },
    increaseOneYear() {
      this.calendar = new Date();
      this.calendar.setDate(this.calendar.getDate() + 365);
      this.calendar = this.calendar.toISOString().split("T")[0];
    },
    backToTreatment() {
      this.$store.state.tabIndex--;
      this.$store.state.disabledReferral = true;
      this.$store.state.disabledTreatment = false;
    },
    showRecall() {
      // this.routineClinic = true;
      if (this.routineClinic == true) {
        this.showRecallDiv = true;
        this.calendar = "";
        this.recall_time = "";
      } else {
        this.routineClinic = false;
        this.showRecallDiv = false;
      }
    },
    showOther() {
      if (this.selectOther == true) {
        this.showOtherInput = true;
      } else {
        this.selectOther = false;
        this.showOtherInput = false;
      }
    },
    disableReferral() {
      if (this.no_referral == true) {
        this.disabled = true;
        this.routineClinic = false;
        this.hygienist = false;
        this.dentist = false;
        this.generalPhysician = false;
        this.showRecallDiv = false;
      } else {
        this.disabled = false;
      }
    },
    selected() {
      this.monthIndex = this.months.indexOf(this.recall.month) + 1;
    },
    daysInMonth(month, year) {
      return new Date(year, month, 0).getDate();
    },
    submitReferral() {
      this.errors = [];
      if (
        this.no_referral != true &&
        this.routineClinic != true &&
        this.hygienist != true &&
        this.dentist != true &&
        this.generalPhysician != true &&
        this.others == ""
      ) {
        this.errors["referral"] = "Choose a referral or add one to others";
      } else if (
        (this.routineClinic == true && this.calendar == "") ||
        this.calendar == null
      ) {
        this.errors["calender"] = "Pick a Date from the calendar";
      } else if (
        (this.routineClinic == true && this.recall_time == "") ||
        this.recall_time == null
      ) {
        this.errors["recall_time"] = "Pick a time";
      } else {
        if (this.routineClinic == true && this.calendar != "") {
          let formDataRecall = {
            id: this.patients.id,
            activity: this.patients.activity,
            area: this.patients.area,
            first_name: this.patients.first_name,
            last_name: this.patients.last_name,
            middle_name: this.patients.middle_name,
            first_name_khmer: this.patients.first_name_khmer,
            middle_name_khmer: this.patients.middle_name_khmer,
            last_name_khmer: this.patients.last_name_khmer,
            gender: this.patients.gender,
            dob: this.patients.dob,
            phone: this.patients.phone,
            education: this.patients.education,
            grade: this.patients.grade,
            school: this.patients.school,
            province: this.patients.province,
            district: this.patients.district,
            commune: this.patients.commune,
            village: this.patients.village,
            clinic: this.patients.clinic,
            author: this.patients.author,
            latitude: this.patients.latitude,
            longitude: this.patients.longitude,
            updated_by: this.profile.id,
            updated_at: this.updated_at,
            created_at: this.patients.created_at,
            recall_date: this.calendar,
            recall_geography: this.patients.recall_geography,
            recall_time: this.recall_time,
            status: this.patients.status,
          };
          // console.log(formDataRecall.recall_date);
          this.$store.dispatch("updatePatient", formDataRecall).then(() => {
            if (this.successmessage == "success") {
              this.calendar = "";
              this.recall_time = "";
              this.success = "Referral updated succsessfully.";
              this.$store.state.disabledReferral = true;
              this.$store.state.disabledHistory = false;
            } else if (this.errormessage == "errormessage") {
              // console.log(JSON.stringify(this.message));
            }
          });
        }
        let formDataObject = {
          no_referral: !this.no_referral ? false : true,
          clinic: !this.routineClinic ? false : true,
          dentist: !this.dentist ? false : true,
          general_physician: !this.generalPhysician ? false : true,
          hygienist: !this.hygienist ? false : true,
          other: this.others != "" ? this.others : "N/a",
          encounter_id: this.encounter_id,
        };
        if (Object.keys(this.referral).length !== 0) {
          this.$store.dispatch("updateReferral", formDataObject).then(() => {
            if (this.successmessage == "success") {
              setTimeout(() => {
                this.calendar = "";
                this.recall_time = "";
                this.$store.state.disableTab = false;
                this.$store.state.disabledReferral = true;
                this.$store.state.disabledHistory = false;
                this.$router.push({
                  name: "patientdetail",
                  params: { id: this.patients.id },
                });
                window.scrollTo(0, 0);
              }, 1500);
              this.updated = "Referral updated succsessfully.";
              this.$bvToast.show("referral-success-toast");
            } else if (this.errormessage == "errormessage") {
              this.$bvToast.show("referral-error-toast");
              // console.log(JSON.stringify(this.message));
            }
          });
        } else {
          this.$store.dispatch("saveReferral", formDataObject).then(() => {
            if (this.successmessage == "success") {
              setTimeout(() => {
                this.calendar = "";
                this.recall_time = "";
                this.$store.state.disableTab = false;
                this.$store.state.disabledReferral = true;
                this.$store.state.disabledHistory = false;
                this.$router.push({
                  name: "patientdetail",
                  params: { id: this.patients.id },
                });
                window.scrollTo(0, 0);
              }, 1500);
              this.save = "Referral saved succsessfully.";
              this.$bvToast.show("referral-success-toast");
            } else if (this.errormessage == "errormessage") {
              this.$bvToast.show("referral-error-toast");
              // console.log(JSON.stringify(this.message));
            }
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.hidden {
  display: none;
}
// .recalldates{
//   // width: 100% !important;
// }
@import "src/css/main.scss";
</style>
