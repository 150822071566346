<template>
  <div class="csi">
    <div id="app">
      <app-header></app-header>
      <div class="contents">
        <div class="row">
          <div class="col-12">
            <h1>Online data entry (OHE)</h1>
          </div>
        </div>
        <div class="mt-5">
          <b-tabs content-class="mt-5 mb-4" align="center">
            <b-tab title="Data Entry" active>
              <b-card-text>
                <div class="row mt-4">
                  <div class="col-12">
                    <div class="card shadow">
                      <div class="row pr-4 justify-content-center">
                        <div class="col-10">
                          <b-card header=" 6.4.1 Oral Health Education" bg-variant="white"
                            class="pl-0 pr-0 pt-0 header-text text-center">
                            <b-row align-h="space-between" class="mb-4 ml-4 mt-4 pr-3">
                              <div class="col-lg-5 col-md-9 col-sm-12 pl-0 text-left">
                                <h4>Date</h4>
                              </div>
                              <div class="col-lg-3 col-md-6 col-sm-12 pl-0">
                                <b-form-select v-model="selectYear" :options="years">
                                  <template v-slot:first>
                                    <b-form-select-option :value="null" disabled>Select Year</b-form-select-option>
                                  </template>
                                </b-form-select>
                              </div>
                              <div class="col-lg-2 col-md-6 col-sm-12 pl-0">
                                <b-form-select v-model="selectMonth" :options="optionsMonth" value-field="index"
                                  text-field="month">
                                  <template v-slot:first>
                                    <b-form-select-option :value="null" disabled>Select Month</b-form-select-option>
                                  </template>
                                </b-form-select>
                              </div>
                              <div class="col-lg-2 col-md-6 col-sm-12 pl-0">
                                <b-form-select v-model="selectDay" @change="minTwoDigits(selectDay)">
                                  <b-form-select-option :value="null" disabled>Select Day</b-form-select-option>
                                  <b-form-select-option v-for="day in daysInMonth(selectMonth, selectYear)" :key="day.id"
                                    :value="day">
                                    {{ day }}
                                  </b-form-select-option>
                                </b-form-select>
                              </div>
                            </b-row>
                            <b-row align-h="space-between" class="mb-4 ml-4 mt-3 pr-3">
                              <div class="col-lg-5 col-md-9 col-sm-12 pl-0 text-left">
                                <h4>Location</h4>
                              </div>
                              <div class="col-lg-7 col-md-6 col-sm-12 pl-0">
                                <b-form-select v-model="selectLocation" :options="optionsLocation" value-field="id"
                                  text-field="name" placeholder="Select Location" @change="changeAddLocation">
                                  <template v-slot:first>
                                    <b-form-select-option :value="null" disabled>Select Location</b-form-select-option>
                                  </template>
                                </b-form-select>
                              </div>
                            </b-row>
                            <b-row align-h="space-between" class="mb-4 ml-4 mt-3 pr-3">
                              <div class="col-lg-5 col-md-9 col-sm-12 pl-0 text-left">
                                <h4>Select School (use Clinic-Other for In-Clinic OHE)</h4>
                              </div>
                              <div class="col-lg-7 col-md-6 col-sm-12 pl-0">
                                <b-form-select v-model="selectSchool" :options="filter_add_schools" value-field="id"
                                  text-field="name">
                                  <template v-slot:first>
                                    <b-form-select-option :value="null" disabled>Select School</b-form-select-option>
                                  </template>
                                </b-form-select>
                              </div>
                            </b-row>
                            <div class="add-section">
                              <b-row align-h="space-between" class="mb-4 ml-0 mt-3">
                                <div class="col-lg-5 col-md-9 col-sm-12 pl-0 text-left">
                                  <h4>Select Grade (Use PPS for mixed grades for In-Clinic OHE)</h4>
                                </div>
                                <div class="col-lg-7 col-md-6 col-sm-12 pl-0">
                                  <b-form-select v-model="selectGrade" :options="optionsGrade"></b-form-select>
                                </div>
                              </b-row>
                              <b-row align-h="space-between" class="mb-4 ml-0 mt-3">
                                <div class="col-lg-5 col-md-9 col-sm-12 pl-0 text-left">
                                  <h4>
                                    Number of children received OHE
                                  </h4>
                                </div>
                                <div class="col-lg-7 col-md-6 col-sm-12 pl-0">
                                  <b-form-input v-model="childrenReceivedTooth"
                                    placeholder="Enter Number of children received toothbrush and toothpaste"
                                    onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.charCode == 46"></b-form-input>
                                </div>
                              </b-row>
                              <b-row align-h="space-between" class="mb-4 ml-0 mt-3">
                                <div class="col-lg-5 col-md-9 col-sm-12 pl-0 text-left">
                                  <h4>Number of student who brushed teeth regularly</h4>
                                </div>
                                <div class="col-lg-7 col-md-6 col-sm-12 pl-0">
                                  <b-form-input v-model="studenBrushingTeethRegularly"
                                    placeholder="Enter brushed teeth regularly student number"
                                    onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.charCode == 46"></b-form-input>
                                </div>
                              </b-row>
                              <b-row align-h="space-between" class="mb-4 ml-0 mt-3">
                                <div class="col-lg-5 col-md-9 col-sm-12 pl-0 text-left">
                                  <h4>Comment</h4>
                                </div>
                                <div class="col-lg-7 col-md-6 col-sm-12 pl-0">
                                  <b-form-textarea id="textarea" v-model="comment" placeholder="Enter something..."
                                    rows="3" max-rows="6"></b-form-textarea>
                                </div>
                              </b-row>
                            </div>
                            <div class="row mb-4 ml-4 mr-1 justify-content-end">
                              <div class="col-lg-4 col-sm-12 mt-4 mr-1">
                                <b-button variant="custom" block class="mb-4" @click="submitButton">Submit</b-button>
                              </div>
                            </div>
                          </b-card>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </b-card-text>
            </b-tab>
            <b-tab title="Data List" content-class="mt-3">
              <b-card-text>
                <div class="row mt-4 mb-4">
                  <div class="col-12">
                    <div class="card shadow">
                      <h3 class="mb-3 text-center">6.4.2 Oral Health Education</h3>
                      <div class="row pr-4">
                        <div class="col-12">
                          <b-table id="user-table" show-empty :items="ohe_Items" :fields="ohe_Fields" responsive hover
                            :busy="isBusy" bordered>
                            <template v-slot:cell(location)="data">{{
                              locationValue(data.item.location)
                            }}</template>

                            <template v-slot:cell(school)="data">{{
                              schoolValue(data.item.school)
                            }}</template>

                            <template v-slot:cell(actions)="data">
                              <b-button variant="outline-warning" centered @click="openEditForm(data.item)"
                                class="m-2">Edit</b-button>
                              <b-button @click="openDeleteForm(data.item)" variant="danger">Delete</b-button>
                            </template>
                            <template v-slot:table-busy>
                              <div class="text-center text-primary my-2">
                                <b-spinner class="align-middle" type="grow" style="width: 5rem; height: 5rem"></b-spinner>
                              </div>
                            </template>
                          </b-table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </b-card-text>
            </b-tab>
          </b-tabs>
        </div>

        <!-- Form validation Error message   -->
        <b-toast id="error-ohe" variant="warning" solid append-toast toaster="b-toaster-bottom-full">
          <div slot="toast-title" class="d-flex flex-grow-1 align-items-baseline">
            <strong class="mr-auto">Required error</strong>
          </div>
          <div v-if="errors.selectYear">
            <p>{{ errors.selectYear }}</p>
          </div>
          <div v-else-if="errors.selectMonth">
            <p>{{ errors.selectMonth }}</p>
          </div>
          <div v-else-if="errors.selectDay">
            <p>{{ errors.selectDay }}</p>
          </div>
          <div v-else-if="errors.selectLocation">
            <p>{{ errors.selectLocation }}</p>
          </div>
          <div v-else-if="errors.selectSchool">
            <p>{{ errors.selectSchool }}</p>
          </div>
          <div v-else-if="errors.selectGrade">
            <p>{{ errors.selectGrade }}</p>
          </div>
          <div v-else-if="errors.childrenReceivedTooth">
            <p>{{ errors.childrenReceivedTooth }}</p>
          </div>
          <div v-else-if="errors.studenBrushingTeethRegularly">
            <p>{{ errors.studenBrushingTeethRegularly }}</p>
          </div>
          <div v-else-if="errors.childrenBrushingTeethPercentage">
            <p>{{ errors.childrenBrushingTeethPercentage }}</p>
          </div>
          <div v-else-if="errors.comment">
            <p>{{ errors.comment }}</p>
          </div>
          <div v-else-if="errors.error_message">
            <p>{{ errors.error_message }}</p>
          </div>
          <div v-else></div>
        </b-toast>
        <b-toast id="success-ohe" variant="success" solid append-toast toaster="b-toaster-bottom-full">
          <div slot="toast-title" class="d-flex flex-grow-1 align-items-baseline">
            <strong class="mr-auto">OHE Success</strong>
          </div>
          <div>
            <p>Oral Health Education (OHE) Added Successfully!</p>
          </div>
        </b-toast>
        <b-toast id="update-success-ohe" variant="success" solid append-toast toaster="b-toaster-bottom-full">
          <div slot="toast-title" class="d-flex flex-grow-1 align-items-baseline">
            <strong class="mr-auto">OHE Updatae Success</strong>
          </div>
          <div>
            <p>Oral Health Education (OHE) Data Updated Successfully!</p>
          </div>
        </b-toast>
        <b-toast id="delete-success-ohe" variant="success" solid append-toast toaster="b-toaster-bottom-full">
          <div slot="toast-title" class="d-flex flex-grow-1 align-items-baseline">
            <strong class="mr-auto">OHE Delete Success</strong>
          </div>
          <div>
            <p>Oral Health Education (OHE) Data Deleted Successfully!</p>
          </div>
        </b-toast>
        <!-- Form validation Error message -->
      </div>
      <b-modal id="edit-modal" size="lg" centered title="Edit OHE Form">
        <b-form>
          <!-- Date  -->
          <b-row>
            <div class="col-12">
              <h4>Date</h4>
            </div>
          </b-row>
          <b-row align-h="space-between" class="mb-4 ml-2 mt-4 pr-3">
            <div class="col-lg-4 col-md-6 col-sm-12 pl-0">
              <b-form-select v-model="single_ohe_obj.year" :options="years">
                <template v-slot:first>
                  <b-form-select-option :value="null" disabled>Select Year</b-form-select-option>
                </template>
              </b-form-select>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 pl-0">
              <b-form-select v-model="singlemonth" :options="optionsMonth" value-field="index" text-field="month">
                <template v-slot:first>
                  <b-form-select-option :value="null" disabled>Select Month</b-form-select-option>
                </template>
              </b-form-select>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 pl-0">
              <b-form-select v-model="single_ohe_obj.day" @change="minTwoDigits(single_ohe_obj.day)">
                <b-form-select-option :value="null" disabled>Select Day</b-form-select-option>
                <b-form-select-option v-for="day in daysInMonth(selectMonth, selectYear)" :key="day.id"
                  :value="day < 10 ? '0' + day : day">
                  {{ day < 10 ? "0" + day : day }} </b-form-select-option>
              </b-form-select>
            </div>
          </b-row>

          <!-- Location  -->
          <b-row>
            <div class="col-12">
              <h4>Location</h4>
            </div>
          </b-row>
          <b-row class="mb-4 ml-2 mt-4 pr-3">
            <div class="col-12 pl-0">
              <b-form-select v-model="single_ohe_obj.location" :options="optionsLocation" value-field="id"
                text-field="name" placeholder="Select Location" @change="changeEditLocation">
                <template v-slot:first>
                  <b-form-select-option :value="null" disabled>Select Location</b-form-select-option>
                </template>
              </b-form-select>
            </div>
          </b-row>

          <!-- Select School -->
          <b-row>
            <div class="col-12">
              <h4>Select School</h4>
            </div>
          </b-row>
          <b-row class="mb-4 ml-2 mt-4 pr-3">
            <div class="col-12 pl-0">
              <b-form-select v-model="single_ohe_obj.school" :options="filter_edit_schools" value-field="id"
                text-field="name">
                <template v-slot:first>
                  <b-form-select-option :value="null" disabled>Select School</b-form-select-option>
                </template>
              </b-form-select>
            </div>
          </b-row>
          <!-- Grade -->
          <b-row>
            <div class="col-12">
              <h4>Select Grade</h4>
            </div>
          </b-row>
          <b-row class="mb-4 ml-2 mt-4 pr-3">
            <div class="col-12 pl-0">
              <b-form-select v-model="single_ohe_obj.grade" :options="optionsGrade"></b-form-select>
            </div>
          </b-row>

          <!-- Number of children received tooth and toothpaste -->
          <b-row>
            <div class="col-12">
              <h4>Number of children received tooth and toothpaste</h4>
            </div>
          </b-row>
          <b-row class="mb-4 ml-2 mt-4 pr-3">
            <div class="col-12 pl-0">
              <b-form-input v-model="single_ohe_obj.students_received_tb_by_tp"
                placeholder="Enter Number of children received toothbrush and toothpaste"
                onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.charCode == 46"></b-form-input>
            </div>
          </b-row>

          <!-- Number of student who brushed teeth regularly -->
          <b-row>
            <div class="col-12">
              <h4>Number of student who brushed teeth regularly</h4>
            </div>
          </b-row>
          <b-row class="mb-4 ml-2 mt-4 pr-3">
            <div class="col-12 pl-0">
              <b-form-input v-model="single_ohe_obj.students_brushing_teeth_regular"
                placeholder="Enter brushed teeth regularly student number"
                onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.charCode == 46"></b-form-input>
            </div>
          </b-row>

          <!-- Proportion of children Brush Teeth in percentage (%) -->
          <!-- <b-row>
            <div class="col-12">
              <h4>Proportion of children Brush Teeth in percentage (%)</h4>
            </div>
          </b-row>

          <b-row class="mb-4 ml-2 mt-4 pr-3">
            <div class="col-12 pl-0">
              <b-form-input
                v-model="single_ohe_obj.percent_regular_teeth_brushing"
                placeholder="Enter children Brush Teeth Proportion % value"
                onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.charCode == 46"
              ></b-form-input>
            </div>
          </b-row> -->

          <!-- Commnents -->
          <b-row>
            <div class="col-12">
              <h4>Comments</h4>
            </div>
          </b-row>
          <b-row class="mb-4 ml-2 mt-4 pr-3">
            <div class="col-12 pl-0">
              <b-form-textarea id="textarea" v-model="single_ohe_obj.comments" placeholder="Enter something..." rows="3"
                max-rows="6"></b-form-textarea>
            </div>
          </b-row>
        </b-form>
        <template #modal-footer="{ cancel }">
          <!-- Emulate built in modal footer ok and cancel button actions -->
          <b-button size="sm" variant="success" @click="updateOHEData">
            Confirm
          </b-button>
          <b-button size="sm" variant="danger" @click="cancel()"> Cancel </b-button>
        </template>
      </b-modal>
      <b-modal id="delete-modal" centered title="Are you sure you want to delete">
        <template #modal-footer="{ cancel }">
          <!-- Emulate built in modal footer ok and cancel button actions -->
          <b-button size="sm" variant="success" @click="deleteOHEData">
            Confirm
          </b-button>
          <b-button size="sm" variant="danger" @click="cancel()"> Cancel </b-button>
        </template>
      </b-modal>
      <app-footer></app-footer>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import AppHeader from "./Header.vue";
import footer from "./Footer.vue";

export default {
  name: "Longitudinal",
  components: {
    "app-header": AppHeader,
    "app-footer": footer,
  },
  data() {
    return {
      errors: [],
      isBusy: false,
      selectYear: null,
      optionsYear: [
        { value: null, text: "Select Year" },
        { value: "a", text: "2019" },
        { value: "b", text: "2020" },
      ],
      selectMonth: null,
      monthIndex: "",
      optionsMonth: [
        {
          month: "January",
          index: "01",
        },
        {
          month: "February",
          index: "02",
        },
        {
          month: "March",
          index: "03",
        },
        {
          month: "April",
          index: "04",
        },
        {
          month: "May",
          index: "05",
        },
        {
          month: "June",
          index: "06",
        },
        {
          month: "July",
          index: "07",
        },
        {
          month: "August",
          index: "08",
        },
        {
          month: "September",
          index: "09",
        },
        {
          month: "October",
          index: "10",
        },
        {
          month: "November",
          index: "11",
        },
        {
          month: "December",
          index: "12",
        },
      ],
      formattedDay: "",
      selectDay: null,
      selectLocation: null,
      selectSchool: null,
      childrenReceivedTooth: "",
      studenBrushingTeethRegularly: "",
      childrenBrushingTeethPercentage: "",
      comment: "",
      selectGrade: null,
      optionsGrade: [
        { text: "Select Grade", value: null, disabled: true },
        { text: "KG", value: "KG" },
        { text: "Grade 1", value: "1" },
        { text: "Grade 2", value: "2" },
        { text: "Grade 3", value: "3" },
        { text: "Grade 4", value: "4" },
        { text: "Grade 5", value: "5" },
        { text: "Grade 6", value: "6" },
        { text: "PPS", value: "PPS" },
      ],
      ohe_Fields: [
        { key: "location", label: "Location", tdClass: "font-weight-bold" },
        { key: "school", label: "School" },
        { key: "year", label: "Year" },
        { key: "month", label: "Month" },
        { key: "grade", label: "Grade" },
        {
          key: "students_received_tb_by_tp",
          label: "# Students received OHE",
        },
        {
          key: "students_brushing_teeth_regular",
          label: "Student brushing teeth regular",
        },
        {
          key: "percent_regular_tooth_brushing",
          label: "% Regular tooth brushing",
        },
        { key: "comments", label: "Comments" },
        { key: "actions", label: "Action" },
      ],
      single_ohe_obj: "",
      singlemonth: "",
      filter_add_schools: [],
      filter_edit_schools: [],
    };
  },
  computed: {
    ...mapState([
      "locations",
      "schools",
      "oral_health_obj",
      "successmessage",
      "errormessage",
      "message",
    ]),
    optionsLocation: function () {
      return this.locations;
    },
    optionsSchool: function () {
      return this.schools;
    },
    years() {
      const year = new Date().getFullYear();
      return Array.from(
        {
          length: 110,
        },
        (value, index) => year - index
      );
    },
    ohe_Items: function () {
      let allData = this.oral_health_obj;
      let singleData = null;
      if (allData && allData.data && allData.data.length > 0) {
        singleData = allData.data;
      }
      return singleData;
    },
  },
  created() {
    this.listLocations();
    this.listSchools();
    this.listEffectsizeoralhealthViewBarGraph();
  },
  methods: {
    ...mapActions([
      "listLocations",
      "listSchools",
      "listEffectsizeoralhealthViewBarGraph",
    ]),
    monthChange() {
      let monthIndex = this.optionsMonth.indexOf(this.selectMonth) + 1;
      if (monthIndex.toString().length < 2) {
        this.monthIndex = "0" + monthIndex;
      }
    },
    daysInMonth(month, year) {
      return new Date(year, month, 0).getDate();
    },
    minTwoDigits(value) {
      if (value.toString().length < 2) {
        this.formattedDay = "0" + value;
      } else {
        this.formattedDay = value;
      }
    },
    changeAddLocation: function (event) {
      // get province array from geograpy/location list
      let allLocations = this.locations.find((x) => x.id == event);
      // filter school list
      let filterSchool = this.schools.filter((school) => {
        return allLocations.province.includes(school.province);
      });

      this.filter_add_schools = filterSchool;
    },
    changeEditLocation: function (event) {
      // get province array from geograpy/location list
      let allLocations = this.locations.find((x) => x.id == event);
      // filter school list
      let filterSchool = this.schools.filter((school) => {
        return allLocations.province.includes(school.province);
      });

      this.filter_edit_schools = filterSchool;
    },
    locationValue(locId) {
      let allLocation = this.locations;
      let singleLocation = null;
      if (allLocation && allLocation.length > 0) {
        singleLocation = allLocation.find((row) => {
          return row.id == locId;
        });
      }
      return singleLocation.location;
    },
    schoolValue(schoolId) {
      let allSchools = this.schools;
      let singleSchool = null;
      if (allSchools && allSchools.length > 0) {
        singleSchool = allSchools.find((row) => {
          return row.id == schoolId;
        });
      }
      return singleSchool.name;
    },
    submitButton() {
      this.errors = [];
      if (this.selectYear == "" || this.selectYear == null) {
        this.errors["selectYear"] = "Please select the Year";
        this.$bvToast.show("error-ohe");
      } else if (this.selectMonth == "" || this.selectMonth == null) {
        this.errors["selectMonth"] = "Please select the Month";
        this.$bvToast.show("error-ohe");
      } else if (this.selectDay == "" || this.selectDay == null) {
        this.errors["selectDay"] = "Please select the Day";
        this.$bvToast.show("error-ohe");
      } else if (this.selectLocation == "" || this.selectLocation == null) {
        this.errors["selectLocation"] = "Please select the Location";
        this.$bvToast.show("error-ohe");
      } else if (this.selectSchool == "" || this.selectSchool == null) {
        this.errors["selectSchool"] = "Please select the school";
        this.$bvToast.show("error-ohe");
      } else if (this.selectGrade == "" || this.selectGrade == null) {
        this.errors["selectGrade"] = "Please select the Grade";
        this.$bvToast.show("error-ohe");
      } else if (this.childrenReceivedTooth == "" || this.childrenReceivedTooth == null) {
        this.errors["childrenReceivedTooth"] =
          "Please enter the number of children receiving tooth and toothpaste";
        this.$bvToast.show("error-ohe");
      } else if (
        this.studenBrushingTeethRegularly == "" ||
        this.studenBrushingTeethRegularly == null
      ) {
        this.errors["studenBrushingTeethRegularly"] =
          "Please enter the number of student brushing teeth regularly";
        this.$bvToast.show("error-ohe");
      } else if (this.comment == "" || this.comment == null) {
        this.errors["comment"] = "Please enter the comment";
        this.$bvToast.show("error-ohe");
      } else {
        // start to store
        let monthName = this.optionsMonth.find((row) => {
          return row.index == this.selectMonth;
        });
        let dayName = this.selectDay.toString();

        let formData = new FormData();
        formData.append("year", this.selectYear);
        formData.append(
          "month",
          monthName && monthName.month ? monthName.month : "January"
        );
        formData.append("day", dayName.length <= 1 ? "0" + dayName : dayName);
        formData.append("location", this.selectLocation);
        formData.append("school", this.selectSchool);
        formData.append("grade", this.selectGrade);
        formData.append(
          "number_of_children_received_tooth_and_toothpaste",
          this.childrenReceivedTooth
        );
        formData.append(
          "students_who_brushed_teeth_regularly",
          this.studenBrushingTeethRegularly
        );
        formData.append("comment", this.comment);
        // for (var pair of formData.entries()) {
        //   console.log(pair[0]+ ', ' + pair[1]);
        // }
        this.$store
          .dispatch("postOralhealthEducationData", formData)
          .then(() => {
            if (this.successmessage == "success") {
              this.$bvToast.show("success-ohe");
              this.selectGrade = "";
              this.childrenReceivedTooth = "";
              this.studenBrushingTeethRegularly = "";
              this.comment = "";
              this.listEffectsizeoralhealthViewBarGraph();
              // setTimeout(() => {
              //   location.reload();
              // }, 1000);
            }
          })
          .catch((error) => {
            if (error) {
              this.errors["error_message"] = error.response;
              this.$bvToast.show("error-csi");
            }
          });
      }
    },
    openEditForm(oheObject) {
      this.single_ohe_obj = oheObject;
      let monthValue = this.optionsMonth.find((item) => {
        return (item.month = this.single_ohe_obj.month);
      });
      this.singlemonth = monthValue.index;

      // get province array from geograpy/location list
      let allLocations = this.locations.find((x) => x.id == this.single_ohe_obj.location);
      // filter school list
      let filterSchool = this.schools.filter((school) => {
        return allLocations.province.includes(school.province);
      });

      this.filter_edit_schools = filterSchool;

      //console.log(JSON.stringify(oheObject));

      // this.selectYear = this.single_ohe_obj.year;
      // this.selectMonth = this.single_ohe_obj.month;
      // this.selectDay = this.single_ohe_obj.day;
      // this.selectLocation = this.single_ohe_obj.location;
      // this.selectSchool = this.single_ohe_obj.school;
      // this.selectGrade = this.single_ohe_obj.grade;
      // this.childrenReceivedTooth = this.single_ohe_obj.students_received_tb_by_tp;
      // this.studenBrushingTeethRegularly = this.single_ohe_obj.students_brushing_teeth_regular;
      // this.childrenBrushingTeethPercentage = this.single_ohe_obj.percent_regular_teeth_brushing;
      // this.comment = this.single_ohe_obj.comments;

      this.$bvModal.show("edit-modal");
    },
    openDeleteForm(oheObject) {
      this.single_ohe_obj = oheObject;

      this.$bvModal.show("delete-modal");
    },
    updateOHEData() {
      this.errors = [];
      if (this.single_ohe_obj.year == "" || this.single_ohe_obj.year == null) {
        this.errors["selectYear"] = "Please select the Year";
        this.$bvToast.show("error-ohe");
      } else if (this.singlemonth == "" || this.singlemonth == null) {
        this.errors["selectMonth"] = "Please select the Month";
        this.$bvToast.show("error-ohe");
      } else if (this.single_ohe_obj.day == "" || this.single_ohe_obj.day == null) {
        this.errors["selectDay"] = "Please select the Day";
        this.$bvToast.show("error-ohe");
      } else if (
        this.single_ohe_obj.location == "" ||
        this.single_ohe_obj.location == null
      ) {
        this.errors["selectLocation"] = "Please select the Location";
        this.$bvToast.show("error-ohe");
      } else if (this.single_ohe_obj.school == "" || this.single_ohe_obj.school == null) {
        this.errors["selectSchool"] = "Please select the school";
        this.$bvToast.show("error-ohe");
      } else if (this.single_ohe_obj.grade == "" || this.single_ohe_obj.grade == null) {
        this.errors["selectGrade"] = "Please select the Grade";
        this.$bvToast.show("error-ohe");
      } else if (
        this.single_ohe_obj.students_received_tb_by_tp == "" ||
        this.single_ohe_obj.students_received_tb_by_tp == null
      ) {
        this.errors["childrenReceivedTooth"] =
          "Please enter the number of children receiving tooth and toothpaste";
        this.$bvToast.show("error-ohe");
      } else if (
        this.single_ohe_obj.students_brushing_teeth_regular == "" ||
        this.single_ohe_obj.students_brushing_teeth_regular == null
      ) {
        this.errors["studenBrushingTeethRegularly"] =
          "Please enter the number of student brushing teeth regularly";
        this.$bvToast.show("error-ohe");
      } else {
        //
        let formData = {
          location: this.single_ohe_obj.location,
          school: this.single_ohe_obj.school,
          grade: this.single_ohe_obj.grade,
          number_of_children_received_tooth_and_toothpaste: this.single_ohe_obj
            .students_received_tb_by_tp,
          students_who_brushed_teeth_regularly: this.single_ohe_obj
            .students_brushing_teeth_regular,
          comment: this.single_ohe_obj.comments,
          id: this.single_ohe_obj.id,
        };

        this.$store
          .dispatch("updateOralhealthEducationData", formData)
          .then(() => {
            if (this.successmessage == "success") {
              this.$bvToast.show("update-success-ohe");
              setTimeout(() => {
                location.reload();
              }, 1000);
            }
          })
          .catch((error) => {
            if (error) {
              this.errors["error_message"] = error.response;
              this.$bvToast.show("error-ohe");
            }
          });
      }
    },
    deleteOHEData() {
      let oheId = this.single_ohe_obj.id;
      this.$store
        .dispatch("removeOralhealthEducationData", oheId)
        .then(() => {
          if (this.successmessage == "success") {
            this.$bvToast.show("delete-success-ohe");
            setTimeout(() => {
              location.reload();
            }, 1000);
          }
        })
        .catch((error) => {
          if (error) {
            this.errors["error_message"] = error.response;
            this.$bvToast.show("error-ohe");
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.csi {
  display: grid;
  height: 100vh;

  .card-body {
    padding: 0px;
  }

  input,
  select {
    border: 1px solid #cccccc;
    border-radius: 8px;
  }
}

.add-section {
  h4 {
    font-size: 20px;
  }

  border-top: 1px solid #e4e4e4;
  border-bottom: 1px solid #e4e4e4;
  padding: 20px;
}

@import "../css/index.scss";
@import "../css/bulkupload.scss";
</style>
