// Test API: https://blcp.dentalhubproject.org/api/v1/
// Production API: https://blcp.dentalhubproject.org/api/v1/

const axios = require("axios");
export default {
  listReturnDate({ commit }) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .get("https://blcp.dentalhubproject.org/api/v1/returndate")
      .then((response) => {
        if (response.status == 200) {
          commit("setReturnDate", response.data);
        }
      });
  },

  listTeeth({ commit }) {
    // axios.defaults.headers.common['authorization'] = 'JWT ' + this.state.token;
    return axios
      .get("https://blcp.dentalhubproject.org/api/v1/teeth")
      .then((response) => {
        if (response.status == 200) {
          commit("setTeeth", response.data);
        }
      });
  },

  listProfile({ commit }) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .get("https://blcp.dentalhubproject.org/api/v1/profile")
      .then((response) => {
        if (response.status == 200) {
          commit("setProfile", response.data);
        }
      });
  },

  updateProfile({ commit }, updateprofile) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .put(
        "https://blcp.dentalhubproject.org/api/v1/profile/update",
        updateprofile,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((response) => {
        if (response.status == 200) {
          commit("setSuccessMessage", "success");
        }
      })
      .catch((error) => {
        if (error) {
          commit("setErrorMessage", "errormessage");
          commit("setMessage", error.response.data.message.image[0]);
        }
      });
  },

  changePassword({ commit }, password_obj) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .post(
        "https://blcp.dentalhubproject.org/api/v1/users/changepassword",
        password_obj
      )
      .then((response) => {
        if (response.status == 200) {
          commit("setSuccessMessage", "success");
        }
      })
      .catch((error) => {
        if (error) {
          commit("setErrorMessage", "errormessage");
          commit("setMessage", error.response.data.message);
        }
      });
  },

  resetUserPassword({ commit }, password_obj) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .post(
        "https://blcp.dentalhubproject.org/api/v1/adminresetpassword",
        password_obj
      )
      .then((response) => {
        if (response.status == 200) {
          commit("setSuccessMessage", "success");
        }
      })
      .catch((error) => {
        if (error) {
          commit("setErrorMessage", "errormessage");
          commit("setMessage", error.response.data.message);
        }
      });
  },

  listLoginVisualization({ commit }) {
    return axios
      .get("https://blcp.dentalhubproject.org/api/v1/doughnutgraph")
      .then((response) => {
        if (response.status == 200) {
          commit("setLoginVisualization", response.data);
          // console.log(response.data);
        }
      });
  },

  listLoginVisualization1({ commit }) {
    return axios
      .get("https://blcp.dentalhubproject.org/api/v1/numberdisplay")
      .then((response) => {
        if (response.status == 200) {
          commit("setLoginVisualization1", response.data);
        }
      });
  },

  // Graph of home section
  listHomePageBarGraph({ commit }) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .get("https://blcp.dentalhubproject.org/api/v1/glancegraph")
      .then((response) => {
        if (response.status == 200) {
          commit("setHomePageBarGraph", response.data);
        }
      });
  },

  listHomeTable({ commit }) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .get("https://blcp.dentalhubproject.org/api/v1/glancegraph")
      .then((response) => {
        if (response.status == 200) {
          commit("setHomeTable", response.data);
        }
      });
  },
  // Graph of home section ends
  // Graph of All Location: Treatment By Type
  listTreatmentByTypeBarGraph({ commit }) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .get("https://blcp.dentalhubproject.org/api/v1/treatmentgraph")
      .then((response) => {
        if (response.status == 200) {
          commit("setTreatmentByTypeBarGraph", response.data);
        }
      });
  },
  postTreatmentByTypeBarGraph({ commit }, treatmentObj) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .post(
        "https://blcp.dentalhubproject.org/api/v1/treatmentgraph",
        treatmentObj
      )
      .then((response) => {
        if (response.status == 200) {
          commit("setTreatmentByTypeBarGraph", response.data);
          commit("setSuccessMessage", "success");
        }
      })
      .catch((error) => {
        if (error) {
          commit("setErrorMessage", "errormessage");
        }
      });
  },
  // Graph of All Location: Treatment Basic
  listTreatmentByTreatmentBasic({ commit }) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .get("https://blcp.dentalhubproject.org/api/v1/treatmentbasic")
      .then((response) => {
        if (response.status == 200) {
          commit("setTreatmentByTreatmentBasic", response.data);
        }
      });
  },

  postTreatmentByTreatmentBasic({ commit }, treatmentObj) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .post(
        "https://blcp.dentalhubproject.org/api/v1/treatmentbasic",
        treatmentObj
      )
      .then((response) => {
        if (response.status == 200) {
          commit("setTreatmentByTreatmentBasic", response.data);
          commit("setSuccessMessage", "success");
        }
      })
      .catch((error) => {
        if (error) {
          commit("setErrorMessage", "errormessage");
        }
      });
  },
  // Graph of All Location: Treatment Basic
  // Graph of All Location: Treatment By Type
  listTreatmentByTreatmentTreatment({ commit }) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .get("https://blcp.dentalhubproject.org/api/v1/treatmenttreatments")
      .then((response) => {
        if (response.status == 200) {
          commit("setTreatmentByTreatmentTreatment", response.data);
        }
      });
  },

  postTreatmentByTreatmentTreatment({ commit }, treatmentObj) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .post(
        "https://blcp.dentalhubproject.org/api/v1/treatmenttreatments",
        treatmentObj
      )
      .then((response) => {
        if (response.status == 200) {
          commit("setTreatmentByTreatmentTreatment", response.data);
          commit("setSuccessMessage", "success");
        }
      })
      .catch((error) => {
        if (error) {
          commit("setErrorMessage", "errormessage");
        }
      });
  },
  // Graph of All Location: Treatment By Type

  // Graph of Screeing Achievement vs Target Graph
  listScreeningAchievementTargetBarGraph({ commit }) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .get("https://blcp.dentalhubproject.org/api/v1/screeningvstarget")
      .then((response) => {
        if (response.status == 200) {
          commit("setScreeningAchievementTargetBarGraph", response.data);
        }
      });
  },
  // Graph of Screeing Achievement vs Target Graph

  postScreeningAchievementTargetTable({ commit }, treatmentObj) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .post(
        "https://blcp.dentalhubproject.org/api/v1/screeningvstarget",
        treatmentObj
      )
      .then((response) => {
        if (response.status == 200) {
          commit("setScreeningAchievementTargetBarGraph", response.data);
          commit("setSuccessMessage", "success");
        }
      })
      .catch((error) => {
        if (error) {
          commit("setErrorMessage", "errormessage");
        }
      });
  },
  //Screeing Achievement vs Target Table

  // Graph of Treatment progress of screened
  listTreatmentScreenedViewGraph({ commit }) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .get("https://blcp.dentalhubproject.org/api/v1/treatmentprogress")
      .then((response) => {
        if (response.status == 200) {
          commit("setTreatmentProgressScreenedGraph", response.data);
        }
      });
  },
  // For Table no 2.4.2 Post Data
  postTreatmentProgressScreenedTable({ commit }, treatmentObj) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .post(
        "https://blcp.dentalhubproject.org/api/v1/treatmentprogress",
        treatmentObj
      )
      .then((response) => {
        if (response.status == 200) {
          commit("setTreatmentProgressScreenedGraph", response.data);
          commit("setSuccessMessage", "success");
        }
      })
      .catch((error) => {
        if (error) {
          commit("setErrorMessage", "errormessage");
        }
      });
  },

  // Graph of All Location
  listAllLocationViewGraph({ commit }) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .get("https://blcp.dentalhubproject.org/api/v1/progressreferral")
      .then((response) => {
        if (response.status == 200) {
          commit("setAllLocation", response.data);
        }
      });
  },
  // For Table no 2.5.2 Post Data
  postAllLocationViewTable({ commit }, treatmentObj) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .post(
        "https://blcp.dentalhubproject.org/api/v1/progressreferral",
        treatmentObj
      )
      .then((response) => {
        if (response.status == 200) {
          commit("setAllLocation", response.data);
          commit("setSuccessMessage", "success");
        }
      })
      .catch((error) => {
        if (error) {
          commit("setErrorMessage", "errormessage");
        }
      });
  },

  //Table of 2.6 Raw Treatment Data by location of Basic
  listTreatmentLocationBasicTable({ commit }) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .get("https://blcp.dentalhubproject.org/api/v1/rawtreatmentbasic")
      .then((response) => {
        if (response.status == 200) {
          commit("setTreatmentLocationBasicTable", response.data);
        }
      });
  },

  postTreatmentLocationBasicTable({ commit }, treatmentObj) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .post(
        "https://blcp.dentalhubproject.org/api/v1/rawtreatmentbasic",
        treatmentObj
      )
      .then((response) => {
        if (response.status == 200) {
          commit("setTreatmentLocationBasicTable", response.data);
          commit("setSuccessMessage", "success");
        }
      })
      .catch((error) => {
        if (error) {
          commit("setErrorMessage", "errormessage");
        }
      });
  },
  //Table of 2.6 Raw Treatment Data  by location of Treatment
  listTreatmentLocationTreatmentTable({ commit }) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .get(
        "https://blcp.dentalhubproject.org/api/v1/rawtreatmenttreatments"
      )
      .then((response) => {
        if (response.status == 200) {
          commit("setTreatmentLocationTreatmentTable", response.data);
        }
      });
  },

  postTreatmentLocationTreatmentTable({ commit }, treatmentObj) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .post(
        "https://blcp.dentalhubproject.org/api/v1/rawtreatmenttreatments",
        treatmentObj
      )
      .then((response) => {
        if (response.status == 200) {
          commit("setTreatmentLocationTreatmentTable", response.data);
          commit("setSuccessMessage", "success");
        }
      })
      .catch((error) => {
        if (error) {
          commit("setErrorMessage", "errormessage");
        }
      });
  },
  // Graph of combodia smile intervention
  listCombodiaSmileInterventionViewBarGraph({ commit }) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .get("https://blcp.dentalhubproject.org/api/v1/csigraph")
      .then((response) => {
        if (response.status == 200) {
          commit("setCsiGraph", response.data);
        }
      });
  },
  // Graph on combodia smile intervention ends
  postCsiTable({ commit }, treatmentObj) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .post(
        "https://blcp.dentalhubproject.org/api/v1/csigraph",
        treatmentObj
      )
      .then((response) => {
        if (response.status == 200) {
          commit("setCsiGraph", response.data);
          commit("setSuccessMessage", "success");
        }
      })
      .catch((error) => {
        if (error) {
          commit("setErrorMessage", "errormessage");
        }
      });
  },

  // Table of combodia smile intervention
  listCsiBasic({ commit }) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .get("https://blcp.dentalhubproject.org/api/v1/csilist")
      .then((response) => {
        if (response.status == 200) {
          commit("setCsiBasic", response.data);
        }
      });
  },

  postCsiTableBasics({ commit }, csiObj) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .post("https://blcp.dentalhubproject.org/api/v1/csilist", csiObj)
      .then((response) => {
        if (response.status == 200) {
          commit("setCsiBasic", response.data);
          commit("setSuccessMessage", "success");
        }
      })
      .catch((error) => {
        if (error) {
          commit("setErrorMessage", "errormessage");
        }
      });
  },

  listOheTableBasic({ commit }) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .get("https://blcp.dentalhubproject.org/api/v1/ohelist")
      .then((response) => {
        if (response.status == 200) {
          commit("setOheTableBasic", response.data);
        }
      });
  },

  postOheTableBasics({ commit }, oheObj) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .post("https://blcp.dentalhubproject.org/api/v1/ohelist", oheObj)
      .then((response) => {
        if (response.status == 200) {
          commit("setOheTableBasic", response.data);
          commit("setSuccessMessage", "success");
        }
      })
      .catch((error) => {
        if (error) {
          commit("setErrorMessage", "errormessage");
        }
      });
  },
  // Table on combodia smile intervention ends
  // Graph of Children OHE
  listChildrenOheViewBarGraph({ commit }) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .get("https://blcp.dentalhubproject.org/api/v1/ohegraph")
      .then((response) => {
        if (response.status == 200) {
          commit("setOheGraph", response.data);
        }
      });
  },
  postChildrenOheViewBarGraph({ commit }, treatmentObj) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .post(
        "https://blcp.dentalhubproject.org/api/v1/ohegraph",
        treatmentObj
      )
      .then((response) => {
        if (response.status == 200) {
          commit("setOheGraph", response.data);
          commit("setSuccessMessage", "success");
        }
      })
      .catch((error) => {
        if (error) {
          commit("setErrorMessage", "errormessage");
        }
      });
  },
  // Graph on Children OHE ends
  // Graph of Epideomiological data
  // listEpidemiologicalViewBarGraph({ commit }) {
  //   axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
  //   return axios
  //     .get("https://blcp.dentalhubproject.org/api/v1/epidemiologicalgraph")
  //     .then((response) => {
  //       if (response.status == 200) {
  //         commit("setEpidemiologicalSumGraph", response.data);
  //       }
  //     });
  // },
  // Graph of Epideomiological data
  // Graph of care patterns data in service delivery section
  // listCarepatternsViewBarGraph({ commit }) {
  //   axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
  //   return axios
  //     .get("https://blcp.dentalhubproject.org/api/v1/carepatterns")
  //     .then((response) => {
  //       if (response.status == 200) {
  //         commit("setCarePatterns", response.data);
  //       }
  //     });
  // },
  // Graph of care patterns graph in service delivery section
  // Graph of Treatment vs surgical in service delivery section
  // listTreatmentsurgicalViewBarGraph({ commit }) {
  //   axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
  //   return axios
  //     .get("https://blcp.dentalhubproject.org/api/v1/preventivevssurgical")
  //     .then((response) => {
  //       if (response.status == 200) {
  //         commit("setPreventiveSurgical", response.data);
  //       }
  //     });
  // },
  // Graph of Treatment vs surgical in service delivery section
  // Graph of Treatment vs surgical in service delivery section

  // listInterventionextractionViewBarGraph({ commit }) {
  //   axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
  //   return axios
  //     .get("https://blcp.dentalhubproject.org/api/v1/interventionvsextraction")
  //     .then((response) => {
  //       if (response.status == 200) {
  //         commit("setInterventionExtraction", response.data);
  //       }
  //     });
  // },
  // Graph of Treatment vs surgical in service delivery section

  listEffectsizeoralhealthViewBarGraph({ commit }) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .get("https://blcp.dentalhubproject.org/api/v1/oralhealtheducation")
      .then((response) => {
        if (response.status == 200) {
          commit("setOralHealth", response.data);
        }
      });
  },

  postOralhealthEducationData({ commit }, ohe_obj) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .post(
        "https://blcp.dentalhubproject.org/api/v1/oralhealtheducation",
        ohe_obj
      )
      .then((response) => {
        if (response.status == 200) {
          commit("setSuccessMessage", "success");
        }
      })
      .catch((error) => {
        if (error) {
          commit("setErrorMessage", "errormessage");
          commit("setMessage", error.response.data.message);
        }
      });
  },

  updateOralhealthEducationData({ commit }, ohe_obj) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .put(
        "https://blcp.dentalhubproject.org/api/v1/oralhealtheducation/" +
        ohe_obj.id,
        ohe_obj
      )
      .then((response) => {
        if (response.status == 200) {
          commit("setSuccessMessage", "success");
        }
      })
      .catch((error) => {
        if (error) {
          commit("setErrorMessage", "errormessage");
          commit("setMessage", error.response.data.message);
        }
      });
  },

  removeOralhealthEducationData({ commit }, oheID) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .delete(
        "https://blcp.dentalhubproject.org/api/v1/oralhealtheducation/" +
        oheID
      )
      .then((response) => {
        if (response.status == 200) {
          commit("setSuccessMessage", "success");
        }
      })
      .catch((error) => {
        if (error) {
          commit("setErrorMessage", "errormessage");
          commit("setMessage", error.response.data.message);
        }
      });
  },

  // CSI
  listCsiData({ commit }) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .get("https://blcp.dentalhubproject.org/api/v1/csi")
      .then((response) => {
        if (response.status == 200) {
          commit("setCsiListData", response.data);
        }
      });
  },

  postCsiData({ commit }, csi_obj) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .post("https://blcp.dentalhubproject.org/api/v1/csi", csi_obj)
      .then((response) => {
        if (response.status == 200) {
          commit("setSuccessMessage", "success");
          // commit("setCsiListData", response.data);
        }
      })
      .catch((error) => {
        if (error) {
          commit("setErrorMessage", "errormessage");
          commit("setMessage", error.response.data.message);
        }
      });
  },

  updateCsiData({ commit }, csi_obj) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .put(
        "https://blcp.dentalhubproject.org/api/v1/csi/" + csi_obj.id,
        csi_obj
      )
      .then((response) => {
        if (response.status == 200) {
          commit("setSuccessMessage", "success");
        }
      })
      .catch((error) => {
        if (error) {
          commit("setErrorMessage", "errormessage");
          commit("setMessage", error.response.data.message);
        }
      });
  },

  removeCsiData({ commit }, csiID) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .delete("https://blcp.dentalhubproject.org/api/v1/csi/" + csiID)
      .then((response) => {
        if (response.status == 200) {
          commit("setSuccessMessage", "success");
        }
      })
      .catch((error) => {
        if (error) {
          commit("setErrorMessage", "errormessage");
          commit("setMessage", error.response.data.message);
        }
      });
  },

  // Graph of Treatment vs surgical in service delivery section

  listSchools({ commit }) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .get("https://blcp.dentalhubproject.org/api/v1/school")
      .then((response) => {
        if (response.status == 200) {
          commit("setSchool", response.data);
        }
      });
  },

  patientBulkUpload({ commit }, patient_bulk_obj) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .post(
        "https://blcp.dentalhubproject.org/api/v1/patientbulk",
        patient_bulk_obj
      )
      .then((response) => {
        if (response.status == 200) {
          commit("setSuccessMessage", "success");
        }
      })
      .catch((error) => {
        if (error) {
          // commit("setErrorMessage", "errormessage");
          // console.log(error.response.data);
          commit("setMessage", error.response.data.message);
          commit("setDuplicateMessage", error.response.data);
          commit("setErrorMessage", "duplicate");
        }
      });
  },

  schoolBulkUpload({ commit }, shcool_bulk_obj) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .post(
        "https://blcp.dentalhubproject.org/api/v1/schoolbulk",
        shcool_bulk_obj
      )
      .then((response) => {
        if (response.status == 200) {
          commit("setSuccessMessage", "success");
          commit("setBulkUploadResponse", response.data);
        }
      })
      .catch((error) => {
        if (error) {
          commit("setErrorMessage", "errormessage");
          commit("setMessage", error.response.data.message);
        }
      });
  },

  referralPostData({ commit }, referral_list_obj) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .post(
        "https://blcp.dentalhubproject.org/api/v1/referraldashboard",
        referral_list_obj
      )
      .then((response) => {
        if (response.status == 200) {
          // console.log(response.data.data)
          commit("setPrintReferral", response.data.data);
          commit("setSuccessMessage", "success");
        }
      })
      .catch((error) => {
        if (error) {
          commit("setErrorMessage", "errormessage");
          commit("setMessage", error.response.data.message);
        }
      });
  },

  recallPostData({ commit }, recall_list_obj) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .post(
        "https://blcp.dentalhubproject.org/api/v1/recalldashboard",
        recall_list_obj
      )
      .then((response) => {
        if (response.status == 200) {
          commit("setPrintRecall", response.data.data);
          commit("setSuccessMessage", "success");
        }
      })
      .catch((error) => {
        if (error) {
          commit("setErrorMessage", "errormessage");
          commit("setMessage", error.response.data.message);
        }
      });
  },

  sampleFileList({ commit }) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .get("https://blcp.dentalhubproject.org/api/v1/samplefile", {})
      .then((response) => {
        commit("setSetFile", response.data);
      });
  },

  // Clinic
  listLocations({ commit }) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .get("https://blcp.dentalhubproject.org/api/v1/geography", {})
      .then((response) => {
        commit("setLocation", response.data);
      });
  },

  listActivityArea({ commit }) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .get("https://blcp.dentalhubproject.org/api/v1/activityarea", {})
      .then((response) => {
        commit("setActivityArea", response.data);
      });
  },

  listActivity({ commit }) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .get("https://blcp.dentalhubproject.org/api/v1/activity", {})
      .then((response) => {
        commit("setActivity", response.data);
      });
  },

  listClinic({ commit }) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .get("https://blcp.dentalhubproject.org/api/v1/clinic", {})
      .then((response) => {
        commit("setClinic", response.data);
      });
  },

  searchPatients({ commit }, search_obj) {
    return axios
      .get(
        "https://blcp.dentalhubproject.org/api/v1/patientsearch/?search=" +
        search_obj,
        {}
      )
      .then((response) => {
        commit("setSearchPatientList", response.data);
      });
  },

  listPatients({ commit }) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .get("https://blcp.dentalhubproject.org/api/v1/patients", {})
      .then((response) => {
        commit("setPatient", response.data);
      });
  },

  listPagePatients({ commit }, pageNumber) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .get(
        "https://blcp.dentalhubproject.org/api/v1/patients?page=" +
        pageNumber,
        {}
      )
      .then((response) => {
        commit("setPatient", response.data);
      });
  },
  listAllPatients({ commit }, object) {
    return axios
      .get(
        "https://blcp.dentalhubproject.org/api/v1/patientlist/" + object
      )
      .then((response) => {
        commit("setPatient", response.data);
      });
  },

  listRecallList({ commit }) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .get("https://blcp.dentalhubproject.org/api/v1/recalls")
      .then((response) => {
        commit("setRecall", response.data);
        commit("setSuccessMessage", "success");
      })
      .catch((error) => {
        if (error) {
          commit("setErrorMessage", "errormessage");
          commit("setMessage", error.response.data.message);
        }
      });
  },

  patientDetails({ commit }, patientId) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .get(`https://blcp.dentalhubproject.org/api/v1/patient/${patientId}`)
      .then((response) => {
        commit("setPatient", response.data);
      });
  },

  listPatientEncounters({ commit }, encounterId) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .get(
        `https://blcp.dentalhubproject.org/api/v1/patients/${encounterId}/encounters`
      )
      .then((response) => {
        commit("setEncounters", response.data);
      });
  },

  addPatient({ commit }, addPatient) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .post(
        "https://blcp.dentalhubproject.org/api/v1/patients",
        addPatient
      )
      .then((response) => {
        if (response.status == 200) {
          commit("setSuccessMessage", "success");
          // state.patients.push(response.data);
        }
      })
      .catch((error) => {
        if (error) {
          commit("setErrorMessage", "errormessage");
          commit("setMessage", error.response.data.message);
        }
      });
  },

  updatePatient({ commit }, patientData) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .put(
        "https://blcp.dentalhubproject.org/api/v1/patient/" +
        patientData.id,
        patientData
      )
      .then((response) => {
        if (response.status == 200) {
          commit("setSuccessMessage", "success");
          // state.patients.push(response.data);
        }
      })
      .catch((error) => {
        if (error) {
          commit("setErrorMessage", "errormessage");
          commit("setMessage", error.response.data.message);
        }
      });
  },

  addEncounterReason({ commit }, encounter_obj) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .post(
        "https://blcp.dentalhubproject.org/api/v1/patients/" +
        encounter_obj.get("id") +
        "/encounters",
        encounter_obj
      )
      .then((response) => {
        if (response.status == 200) {
          commit("setSuccessMessage", "success");
          commit("setEncounterId", response.data.id);
          // state.patients.push(response.data);
        }
      })
      .catch((error) => {
        if (error) {
          commit("setErrorMessage", "errormessage");
          commit("setMessage", error.response.data.message);
        }
      });
  },
  listEncounterHistoryAndScreening({ commit }, thePatientId) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .get(
        "https://blcp.dentalhubproject.org/api/v1/historyscreening/" +
        thePatientId,
        {}
      )
      .then((response) => {
        if (response.status == 200) {
          commit("setEncounterHistoryAndScreening", response.data);
        }
      })
      .catch((error) => {
        if (error) {
          commit("setEncounterHistory", {});
          commit("setErrorMessage", "errormessage");
          commit("setMessage", error.response.data.message);
        }
      });
  },
  listPatientEncounter({ commit }, patient_obj) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .get(
        `https://blcp.dentalhubproject.org/api/v1/patients/${patient_obj.patientId}/encounters/${patient_obj.encounterId}`
      )
      .then((response) => {
        commit("setEncounter", response.data);
      });
  },

  removeEncounterReason({ commit }, encounter_id) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .get(
        "https://blcp.dentalhubproject.org/api/v1/encounterdelete/" +
        encounter_id
      )
      .then((response) => {
        if (response.status == 200) {
          commit("setSuccessMessage", "success");
        }
      })
      .catch((error) => {
        if (error) {
          commit("setErrorMessage", "errormessage");
          commit("setMessage", error.response.data.message);
        }
      });
  },

  listEncounterHistory({ commit }, theEncounterId) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .get(
        "https://blcp.dentalhubproject.org/api/v1/encounter/" +
        theEncounterId +
        "/history",
        {}
      )
      .then((response) => {
        if (response.status == 200) {
          commit("setEncounterHistory", response.data);
          commit("setErrorMessage", "noerroronhistory");
        }
      })
      .catch((error) => {
        if (error) {
          commit("setEncounterHistory", {});
          commit("setErrorMessage", "errormessage");
          commit("setMessage", error.response.data.message);
        }
      });
  },

  saveHistory({ commit }, history_obj) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .post(
        "https://blcp.dentalhubproject.org/api/v1/encounter/" +
        history_obj.encounter_id +
        "/history",
        history_obj
      )
      .then((response) => {
        if (response.status == 200) {
          commit("setSuccessMessage", "success");
          // state.patients.push(response.data);
        }
      })
      .catch((error) => {
        if (error) {
          commit("setErrorMessage", "errormessage");
          commit("setMessage", error.response.data.message);
        }
      });
  },

  updateHistory({ commit }, history_obj) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .put(
        "https://blcp.dentalhubproject.org/api/v1/encounter/" +
        history_obj.encounter_id +
        "/history/update",
        history_obj
      )
      .then((response) => {
        if (response.status == 200) {
          commit("setSuccessMessage", "success");
        }
      })
      .catch((error) => {
        if (error) {
          commit("setErrorMessage", "errormessage");
          commit("setMessage", error.response.data.message);
        }
      });
  },

  listEncounterScreening({ commit }, theEncounterId) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .get(
        "https://blcp.dentalhubproject.org/api/v1/encounter/" +
        theEncounterId +
        "/screening",
        {}
      )
      .then((response) => {
        if (response.status == 200) {
          commit("setEncounterScreening", response.data);
          commit("setErrorMessage", "noerroronscreening");
        }
      })
      .catch((error) => {
        if (error) {
          commit("setEncounterScreening", {});
          commit("setErrorMessage", "errormessage");
          commit("setMessage", error.response.data.message);
        }
      });
  },

  saveScreening({ commit }, screeiningObj) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .post(
        "https://blcp.dentalhubproject.org/api/v1/encounter/" +
        screeiningObj.encounter_id +
        "/screening",
        screeiningObj
      )
      .then((response) => {
        if (response.status == 200) {
          commit("setSuccessMessage", "success");
        }
      })
      .catch((error) => {
        if (error) {
          commit("setErrorMessage", "errormessage");
          commit("setMessage", error.response.data.message);
        }
      });
  },

  updateScreening({ commit }, screeiningObj) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .put(
        "https://blcp.dentalhubproject.org/api/v1/encounter/" +
        screeiningObj.encounter_id +
        "/screening/update",
        screeiningObj
      )
      .then((response) => {
        if (response.status == 200) {
          commit("setSuccessMessage", "success");
        }
      })
      .catch((error) => {
        if (error) {
          commit("setErrorMessage", "errormessage");
          commit("setMessage", error.response.data.message);
        }
      });
  },

  listEncounterPeriodontal({ commit }, theEncounterId) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .get(
        "https://blcp.dentalhubproject.org/api/v1/encounter/" +
        theEncounterId +
        "/periodontal",
        {}
      )
      .then((response) => {
        if (response.status == 200) {
          commit("setEncounterPeriodontal", response.data);
        }
      })
      .catch((error) => {
        if (error) {
          commit("setEncounterPeriodontal", {});
          commit("setErrorMessage", "errormessage");
          commit("setMessage", error.response.data.message);
        }
      });
  },

  savePeriodontal({ commit }, periodontalObj) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .post(
        "https://blcp.dentalhubproject.org/api/v1/encounter/" +
        periodontalObj.encounter_id +
        "/periodontal",
        periodontalObj
      )
      .then((response) => {
        if (response.status == 200) {
          commit("setSuccessMessage", "success");
        }
      })
      .catch((error) => {
        if (error) {
          commit("setErrorMessage", "errormessage");
          commit("setMessage", error.response.data.message);
        }
      });
  },

  updatePeriodontal({ commit }, periodontalObj) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .put(
        "https://blcp.dentalhubproject.org/api/v1/encounter/" +
        periodontalObj.encounter_id +
        "/periodontal/update",
        periodontalObj
      )
      .then((response) => {
        if (response.status == 200) {
          commit("setSuccessMessage", "success");
        }
      })
      .catch((error) => {
        if (error) {
          commit("setErrorMessage", "errormessage");
          commit("setMessage", error.response.data.message);
        }
      });
  },

  listEncounterDiagnostic({ commit }, theEncounterId) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .get(
        "https://blcp.dentalhubproject.org/api/v1/encounter/" +
        theEncounterId +
        "/diagnostic",
        {}
      )
      .then((response) => {
        if (response.status == 200) {
          commit("setEncounterDiagnostic", response.data);
        }
      })
      .catch((error) => {
        if (error) {
          commit("setEncounterDiagnostic", {});
          commit("setErrorMessage", "errormessage");
          commit("setMessage", error.response.data.message);
        }
      });
  },

  saveDiagnostic({ commit }, diagnosticObj) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .post(
        "https://blcp.dentalhubproject.org/api/v1/encounter/" +
        diagnosticObj.encounter_id +
        "/diagnostic",
        diagnosticObj
      )
      .then((response) => {
        if (response.status == 200) {
          commit("setSuccessMessage", "success");
          window.localStorage.setItem("diagnostic_id", response.data.id);
          commit("setEncounterDiagnostic", response.data);
        }
      })
      .catch((error) => {
        if (error) {
          commit("setErrorMessage", "errormessage");
          commit("setMessage", error.response.data.message);
        }
      });
  },

  updateDiagnostic({ commit }, diagnosticObj) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .put(
        "https://blcp.dentalhubproject.org/api/v1/encounter/" +
        diagnosticObj.encounter_id +
        "/diagnostic/update",
        diagnosticObj
      )
      .then((response) => {
        if (response.status == 200) {
          commit("setSuccessMessage", "success");
        }
      })
      .catch((error) => {
        if (error) {
          commit("setErrorMessage", "errormessage");
          commit("setMessage", error.response.data.message);
        }
      });
  },

  listEncounterTreatment({ commit }, theEncounterId) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .get(
        "https://blcp.dentalhubproject.org/api/v1/encounter/" +
        theEncounterId +
        "/treatment",
        {}
      )
      .then((response) => {
        if (response.status == 200) {
          commit("setEncounterTreatment", response.data);
        }
      })
      .catch((error) => {
        if (error) {
          commit("setEncounterTreatment", {});
          commit("setErrorMessage", "errormessage");
          commit("setMessage", error.response.data.message);
        }
      });
  },

  saveTreatment({ commit }, treatmentObj) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .post(
        "https://blcp.dentalhubproject.org/api/v1/encounter/" +
        treatmentObj.encounter_id +
        "/treatment",
        treatmentObj
      )
      .then((response) => {
        if (response.status == 200) {
          commit("setSuccessMessage", "success");
          commit("setEncounterTreatment", response.data);
          window.localStorage.setItem("treatment_id", response.data.id);
        }
      })
      .catch((error) => {
        if (error) {
          commit("setErrorMessage", "errormessage");
          commit("setMessage", error.response.data.message);
        }
      });
  },

  saveTreatmentInfo({ commit }, treatmentInfoObj) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .post(
        "https://blcp.dentalhubproject.org/api/v1/treatmentinfo",
        treatmentInfoObj
      )
      .then((response) => {
        if (response.status == 200) {
          commit("setSuccessMessage", "success");
          // commit('setEncounterTreatment', response.data);
        }
      })
      .catch((error) => {
        if (error) {
          commit("setErrorMessage", "errormessage");
          commit("setMessage", error.response.data.message);
        }
      });
  },

  updateTreatmentInfo({ commit }, treatmentInfoObj) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .put(
        "https://blcp.dentalhubproject.org/api/v1/treatmentinfo/" +
        treatmentInfoObj.id,
        treatmentInfoObj
      )
      .then((response) => {
        if (response.status == 200) {
          commit("setSuccessMessage", "success");
          // commit('setEncounterTreatment', response.data);
        }
      })
      .catch((error) => {
        if (error) {
          commit("setErrorMessage", "errormessage");
          commit("setMessage", error.response.data.message);
        }
      });
  },

  removeTreatmentInfo({ commit }, treatmentInfoId) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .delete(
        "https://blcp.dentalhubproject.org/api/v1/treatmentinfo/" +
        treatmentInfoId
      )
      .then((response) => {
        if (response.status == 200) {
          commit("setSuccessMessage", "success");
        }
      })
      .catch((error) => {
        if (error) {
          commit("setErrorMessage", "errormessage");
          commit("setMessage", error.response.data.message);
        }
      });
  },

  updateTreatment({ commit }, treatmentObj) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .put(
        "https://blcp.dentalhubproject.org/api/v1/encounter/" +
        treatmentObj.encounter_id +
        "/treatment/update",
        treatmentObj
      )
      .then((response) => {
        if (response.status == 200) {
          commit("setSuccessMessage", "success");
        }
      })
      .catch((error) => {
        if (error) {
          commit("setErrorMessage", "errormessage");
          commit("setMessage", error.response.data.message);
        }
      });
  },

  listEncounterReferral({ commit }, theEncounterId) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .get(
        "https://blcp.dentalhubproject.org/api/v1/encounter/" +
        theEncounterId +
        "/refer",
        {}
      )
      .then((response) => {
        if (response.status == 200) {
          commit("setEncounterReferral", response.data);
        }
      })
      .catch((error) => {
        if (error) {
          commit("setEncounterReferral", {});
          commit("setErrorMessage", "errormessage");
          commit("setMessage", error.response.data.message);
        }
      });
  },

  saveReferral({ commit }, referralObj) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .post(
        "https://blcp.dentalhubproject.org/api/v1/encounter/" +
        referralObj.encounter_id +
        "/refer",
        referralObj
      )
      .then((response) => {
        if (response.status == 200) {
          commit("setSuccessMessage", "success");
        }
      })
      .catch((error) => {
        if (error) {
          commit("setErrorMessage", "errormessage");
          commit("setMessage", error.response.data.message);
        }
      });
  },

  updateReferral({ commit }, referralObj) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .put(
        "https://blcp.dentalhubproject.org/api/v1/encounter/" +
        referralObj.encounter_id +
        "/refer/update",
        referralObj
      )
      .then((response) => {
        if (response.status == 200) {
          commit("setSuccessMessage", "success");
        }
      })
      .catch((error) => {
        if (error) {
          commit("setErrorMessage", "errormessage");
          commit("setMessage", error.response.data.message);
        }
      });
  },

  saveXrayImage({ commit }, xrayObj) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .post("https://blcp.dentalhubproject.org/api/v1/xray", xrayObj, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response) => {
        if (response.status == 200) {
          commit("setSuccessMessage", "success");
        }
      })
      .catch((error) => {
        if (error) {
          commit("setErrorMessage", "errormessage");
          commit("setMessage", error.response.data.message);
        }
      });
  },

  updateXrayImage({ commit }, xrayObj) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .put(
        "https://blcp.dentalhubproject.org/api/v1/xray/" + xrayObj.id,
        xrayObj
      )
      .then((response) => {
        if (response.status == 200) {
          commit("setSuccessMessage", "success");
        }
      })
      .catch((error) => {
        if (error) {
          commit("setErrorMessage", "errormessage");
          commit("setMessage", error.response.data.message);
        }
      });
  },

  removeXrayImage({ commit }, xrayId) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .delete("https://blcp.dentalhubproject.org/api/v1/xray/" + xrayId)
      .then((response) => {
        if (response.status == 200) {
          commit("setSuccessMessage", "success");
        }
      })
      .catch((error) => {
        if (error) {
          commit("setErrorMessage", "errormessage");
          commit("setMessage", error.response.data.message);
        }
      });
  },

  removeEncounter({ commit }) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .get("https://blcp.dentalhubproject.org/api/v1/emptyencounterdelete")
      .then((response) => {
        // console.log(response.data.message)
        if (response.status == 200) {
          commit("setSuccessMessage", "success");
          commit("setMessage", response.data.message)
        }
      })
      .catch((error) => {
        if (error) {
          commit("setErrorMessage", "errormessage");
          commit("setMessage", error.response.data.message);
        }
      });
  },

  listAddresses({ commit }) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .get("https://blcp.dentalhubproject.org/api/v1/addresses", {})
      .then((response) => {
        commit("setAddress", response.data);
      });
  },

  listAllAddresses({ commit }) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .get("https://blcp.dentalhubproject.org/api/v1/alladdress", {})
      .then((response) => {
        commit("setAllAddress", response.data);
      });
  },

  listProvince({ commit }) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .get("https://blcp.dentalhubproject.org/api/v1/province", {})
      .then((response) => {
        commit("setProvince", response.data);
      });
  },
  /**
   * Update Patients Grade
   */
  updatePatientsGrade({ commit }) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .get("https://blcp.dentalhubproject.org/api/v1/patientgradeupdate")
      .then((response) => {
        if (response.status == 200) {
          commit("setSuccessMessage", "success");
        }
      })
      .catch((error) => {
        if (error) {
          commit("setErrorMessage", "errormessage");
          commit("setMessage", error.response.data.message);
        }
      });
  },

  /**
   * User Management
   */
  listUsers({ commit }) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .get("https://blcp.dentalhubproject.org/api/v1/users")
      .then((response) => {
        if (response.status == 200) {
          commit("setUsersList", response.data);
        }
      });
  },

  postUsers({ commit }, user_obj) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .post("https://blcp.dentalhubproject.org/api/v1/users", user_obj)
      .then((response) => {
        if (response.status == 200) {
          commit("setSuccessMessage", "success");
        }
      })
      .catch((error) => {
        if (error) {
          commit("setErrorMessage", "errormessage");
          commit("setMessage", error.response.data.message);
        }
      });
  },

  updateUsers({ commit }, user_obj) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .put(
        "https://blcp.dentalhubproject.org/api/v1/users/" + user_obj.id,
        user_obj
      )
      .then((response) => {
        if (response.status == 200) {
          commit("setSuccessMessage", "success");
        }
      })
      .catch((error) => {
        if (error) {
          commit("setErrorMessage", "errormessage");
          commit("setMessage", error.response.data.message);
        }
      });
  },

  deleteUsers({ commit }, user_obj) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .delete(
        "https://blcp.dentalhubproject.org/api/v1/users/" + user_obj.id,
        user_obj
      )
      .then((response) => {
        if (response.status == 200) {
          commit("setSuccessMessage", "success");
        }
      })
      .catch((error) => {
        if (error) {
          commit("setErrorMessage", "errormessage");
          commit("setMessage", error.response.data.message);
        }
      });
  },

  updateUserStatus({ commit }, user_obj) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .post(
        "https://blcp.dentalhubproject.org/api/v1/userstatus/" +
        user_obj.id,
        user_obj
      )
      .then((response) => {
        if (response.status == 200) {
          commit("setSuccessMessage", "success");
        }
      })
      .catch((error) => {
        if (error) {
          commit("setErrorMessage", "errormessage");
          commit("setMessage", error.response.data.message);
        }
      });
  },

  listRole({ commit }) {
    axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
    return axios
      .get("https://blcp.dentalhubproject.org/api/v1/roles")
      .then((response) => {
        if (response.status == 200) {
          commit("setRole", response.data);
        }
      });
  },

  // listProfile({ commit }) {
  //   axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
  //   return axios
  //     .get("https://blcp.dentalhubproject.org/api/v1/profile")
  //     .then((response) => {
  //       if (response.status == 200) {
  //         commit("setProfile", response.data);
  //       }
  //     });
  // },

  listStaticPageData({ commit }, pageSlug) {
    return axios
      .get(
        "https://blcp.dentalhubproject.org/api/v1/flatpage/" + pageSlug,
        {}
      )
      .then((response) => {
        commit("setStaticPageList", response.data);
      });
  },

  //  listUserWards({ commit }) {
  //   axios.defaults.headers.common["authorization"] = "JWT " + this.state.token;
  //   return axios
  //     .get("https://blcp.dentalhubproject.org/api/v1/userwards")
  //     .then((response) => {
  //       if (response.status == 200) {
  //         commit("setUserWards", response.data);
  //       }
  //     });
  // },
};
