<template>
  <div class="recall-list">
    <app-header></app-header>
    <div class="components">
      <shortcuttab></shortcuttab>
      <div>
        <div class="container-bg-white p-2">
          <div class="wrapper">
            <!-- <div class="add-new-patient">
              <h1>Patient Recall List</h1>
              <i class="fa fa-plus"></i> Add new Patient
            </button>
              <form class="search-form">
                <i class="fa fa-search search"></i>
                <input
                  placeholder="Search Recall Patient"
                  v-model="searchPatient"
                />
              </form>
            </div> -->

            <!-- <div class="patient-info-wrapper">
              <div
                class="patient-info"
                v-for="(recall, index) in filteredRecallList"
                :key="index"
              >
                <div class="patient-name">
                  <span class="date-time"
                    >{{ recall.recall_date }} {{ recall.recall_time }}</span
                  >
                  <span>{{ recall.full_name }}</span>
                  <span>{{ recall.phone }}</span>
                </div>
                <div class="button">
                  <router-link
                    :to="{ name: 'patientdetail', params: { id: recall.id } }"
                  >
                    <button class="detail-button">View Detail</button>
                  </router-link>
                </div>
              </div>
            </div> -->
            <div class="col-12 p-0">
              <div class="data-list">
                <h3 class="pb-3">Recall List</h3>
                <div class="d-grid mb-4">
                  <b-input-group-prepend>
                    <b-button disabled variant="success" class="search-button"
                      ><i class="fas fa-search"></i
                    ></b-button>
                  </b-input-group-prepend>
                  <b-form-input
                    v-model="filter"
                    placeholder="Search for Users"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-button
                      variant="danger"
                      :disabled="!filter"
                      @click="filter = ''"
                      class="search-button"
                      >Clear</b-button
                    >
                  </b-input-group-append>
                </div>
                <div class="filter">
                  <label for="day">Recall</label>
                  <div class="select-box">
                    <select v-model="recall_date">
                      <option disabled value="">Select Recall</option>
                      <option
                        v-for="(recalls, index) in recall"
                        :key="index"
                        :value="recalls.id"
                      >
                        {{ recalls.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div>
                  <b-table
                    id="patients-table"
                    show-empty
                    :items="filteredRecallList"
                    :fields="fields"
                    bordered
                    :filter="filter"
                    @filtered="onFiltered"
                    responsive
                    hover
                    :current-page="currentPage"
                    :per-page="perPage"
                    :busy="isBusy"
                  >
                    <template v-slot:table-busy>
                      <div class="text-center text-primary my-2">
                        <b-spinner
                          class="align-middle"
                          type="grow"
                          style="width: 5rem; height: 5rem"
                        ></b-spinner>
                      </div>
                    </template>
                    <template v-slot:cell(serial_number)="data">
                      {{ data.index + 1 + "." }}
                    </template>

                    <template v-slot:cell(full_name)="row">
                      {{ row.item.full_name }}
                    </template>
                    <template v-slot:cell(school)="row">
                      {{ row.item.school }}
                    </template>

                    <template v-slot:cell(actions)="row">
                      <b-button
                        variant="success"
                        centered
                        @click="getuserdetail(row.item.id)"
                        class=""
                        >View Detail</b-button
                      >
                    </template>
                  </b-table>
                  <div class="row my-3">
                    <div class="col-12">
                      <b-pagination
                        v-model="currentPage"
                        :total-rows="rows"
                        :per-page="perPage"
                        aria-controls="patients-table"
                        pills
                      ></b-pagination>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div v-if="message.length > 0">{{message}}</div> -->
            <!-- {{recall_list}} -->
            <!-- {{message}} -->
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import AppHeader from "../Header.vue";
import ShortcutTab from "../components_clinic_page/ShortcutTab.vue";
import Footer from "../components_clinic_page/FooterClinicSection.vue";
// import ModalPatient from "../components_clinic_page/AddPatientModal.vue";
import { mapState, mapActions, mapMutations } from "vuex";

export default {
  name: "RecallList",
  components: {
    "app-header": AppHeader,
    shortcuttab: ShortcutTab,
    Footer,
    // ModalPatient,
  },
  data() {
    return {
      searchPatient: "",
      location_item: JSON.parse(localStorage.getItem("location_item")),
      recall_date: "",
      recall: [
        { id: "1", name: "Today" },
        { id: "2", name: "Next Week" },
        { id: "3", name: "Next Month" },
      ],
      recallList: [],
      recallArray: [],
      filter: null,
      fields: [
        { key: "serial_number", label: "S.N." },
        { key: "full_name", label: "Full Name", sortable: true },
        { key: "recall_date", label: "Recall Date", sortable: true },
        { key: "recall_time", label: "Recall Time" },
        { key: "actions", label: "Action" },
      ],
      perPage: 10,
      currentPage: 1,
      isBusy: true,
    };
  },
  computed: {
    ...mapState([
      "successmessage",
      "recall_list",
      "schools",
      "province",
      "errormessage",
      "message",
    ]),

    filteredRecallList: function () {
      let patient_array = [];
      // this.recall_list = patient_array;
      let location_item = JSON.parse(localStorage.getItem("location_item"));
      if (location_item.name !== "MDC") {
        this.recallList.forEach((patient) => {
          if (patient.province == location_item.province) {
            patient_array.push(patient);
          }
        });
      }
      let recall_array = [];
      if (this.recall_date == 1) {
        let today_date = new Date().toISOString().split("T")[0];
        // let patient_array = this.recallList;
        patient_array.forEach((element) => {
          if (element.recall_date == today_date) {
            recall_array.push(element);
          }
        });
        return recall_array;
      } else if (this.recall_date == 2) {
        let recall_array = [];
        let from = new Date().toISOString().split("T")[0];
        let calendar = new Date();
        calendar.setDate(calendar.getDate() + 7);
        let to = calendar.toISOString().split("T")[0];
        patient_array.forEach((element) => {
          if (element.recall_date >= from && element.recall_date <= to) {
            recall_array.push(element);
          }
        });
        return recall_array;
      } else if (this.recall_date == 3) {
        let recall_array = [];
        let from = new Date().toISOString().split("T")[0];
        let calendar = new Date();
        calendar.setDate(calendar.getDate() + 30);
        let to = calendar.toISOString().split("T")[0];
        patient_array.forEach((element) => {
          if (element.recall_date >= from && element.recall_date <= to) {
            recall_array.push(element);
          }
        });
        return recall_array;
      } else {
        return patient_array;
      }
    },
    rows() {
      return this.recallArray.length;
    },
  },

  watch: {
    schools: function () {
      if (this.recall_list != []) {
        this.recallList = this.recall_list;
        this.isBusy = false;
      } else {
        this.isBusy = false;
      }
    },
  },

  created() {
    this.listRecallList();
    this.listSchools();
    this.listProvince();
  },

  methods: {
    ...mapActions(["listRecallList", "listSchools", "listProvince"]),
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.recall_date = "";
      this.currentPage = 1;
    },

    getuserdetail(id) {
      this.$router.push({ name: "patientdetail", params: { id: id } });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/css/main.scss";
.form-error {
  color: #e23f3f;
}

.form-success {
  color: #1fd01f;
}
.d-grid {
  display: grid;
  grid-template-columns: 50px auto 50px;
}
.phone-number {
  -moz-appearance: textfield;
  &input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
</style>
