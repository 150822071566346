import { render, staticRenderFns } from "./Treatment.vue?vue&type=template&id=250d6e06&scoped=true&"
import script from "./Treatment.vue?vue&type=script&lang=js&"
export * from "./Treatment.vue?vue&type=script&lang=js&"
import style0 from "./Treatment.vue?vue&type=style&index=0&id=250d6e06&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "250d6e06",
  null
  
)

export default component.exports