<template>
  <div id="app" class="header">
    <b-navbar toggleable="lg" type="light" fixed="top">
      <b-navbar-brand to="/"
        ><b-img
          src="/dentallogo.jpg"
          fluid
          class="mr-3 mr-lg-0 header-logo"
        ></b-img
        ><span class="d-sm-inline d-md-inline d-lg-none"
          >Dental Hub</span
        ></b-navbar-brand
      >
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item to="/dashboard">Home</b-nav-item>
          <b-nav-item to="/treatment">Treatment</b-nav-item>
          <!-- <b-nav-item to="/screening">Screening</b-nav-item> -->
          <!-- <b-nav-item to="/servicedelivery">Service Delivery</b-nav-item> -->
          <!-- <b-nav-item to="/research">Research</b-nav-item> -->
          <b-nav-item-dropdown>
            <template slot="button-content">Administration</template>
            <b-dropdown-item to="/bulkupload" v-if="userrole != 'Dentist'"
              >Bulk Upload</b-dropdown-item
            >
            <b-dropdown-item to="/csi" v-if="userrole != 'Dentist'"
              >Online Data Entry (CSI)</b-dropdown-item
            >
            <b-dropdown-item to="/ohe" v-if="userrole != 'Dentist'"
              >Online Data Entry (OHE)</b-dropdown-item
            >
            <b-dropdown-item to="/printlist">Print List</b-dropdown-item>
            <b-dropdown-item
              to="/userlist"
              v-if="userrole == 'Admin User' || userrole == 'System Admin'"
              >User Management</b-dropdown-item
            >
            <b-dropdown-item
              @click="update_grade()"
              v-if="userrole == 'System Admin'"
              >Update the Grade</b-dropdown-item
            >
            <b-dropdown-item
              @click="deleteEncounter"
              v-if="userrole == 'Admin User' || userrole == 'System Admin'"
              >Delete Empty Encounter</b-dropdown-item
            >
          </b-nav-item-dropdown>
          <b-nav-item to="/clinic" v-if="userrole != 'Clinic Officer'"
            >Clinic</b-nav-item
          >
          <!-- <b-nav-item to="/activity">Activity</b-nav-item> -->
        </b-navbar-nav>

        <b-navbar-nav class="ml-auto">
          <b-nav-item-dropdown>
            <template slot="button-content"
              ><i class="fas fa-cog"></i
            ></template>
            <b-nav-item class="settings" to="settings"
              ><span class="d-none d-sm-none d-md-none d-lg-inline"></span
              ><span class="icon-label d-sm-inline d-md-inline"
                >Settings</span
              ></b-nav-item
            >
            <b-nav-item to="/logout"
              ><span class="d-none d-sm-none d-md-none d-lg-inline"></span
              ><span class="icon-label d-sm-inline d-md-inline"
                >Logout</span
              ></b-nav-item
            >
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>

    <b-toast
      id="delete-success-toast"
      variant="success"
      solid
      append-toast
      toaster="b-toaster-top-center"
    >
      <div slot="toast-title" class="d-flex flex-grow-1 align-items-baseline">
        <strong class="mr-auto">Success !</strong>
      </div>
      <p>{{ this.showMessage }}</p>
    </b-toast>
    <b-toast
      id="delete-error-toast"
      variant="success"
      solid
      append-toast
      toaster="b-toaster-top-center"
    >
      <div slot="toast-title" class="d-flex flex-grow-1 align-items-baseline">
        <strong class="mr-auto">Success !</strong>
      </div>
      <p>Encounter Could not be deleted</p>
    </b-toast>
    <b-toast
      id="grade-update-success-toast"
      variant="success"
      solid
      append-toast
      toaster="b-toaster-top-center"
    >
      <div slot="toast-title" class="d-flex flex-grow-1 align-items-baseline">
        <strong class="mr-auto">Success !</strong>
      </div>
      <p>Student's grade updated successfully.</p>
    </b-toast>
    <b-toast
      id="error-toast"
      variant="warning"
      solid
      append-toast
      toaster="b-toaster-top-center"
      v-if="this.message"
    >
      <div slot="toast-title" class="d-flex flex-grow-1 align-items-baseline">
        <strong class="mr-auto">Error !</strong>
      </div>
      <p>{{ this.message }}</p>
    </b-toast>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "AppHeader",
  components: {},
  data() {
    return {
      showMessage: "",
    };
  },
  computed: {
    ...mapState(["profile", "successmessage", "message"]),
    userrole: function() {
      let profileDetail = this.profile;
      let userrole =
        profileDetail && profileDetail.role ? profileDetail.role : "Admin User";

      return userrole;
    },
  },
  created() {
    this.listProfile();
  },
  methods: {
    ...mapActions(["listProfile", "updatePatientsGrade"]),
    update_grade() {
      this.$store.dispatch("updatePatientsGrade").then(() => {
        if (this.successmessage == "success") {
          this.$bvToast.show("grade-update-success-toast");
        } else {
          this.$bvToast.show("error-toast");
        }
      });
    },
    deleteEncounter() {
      this.$store.dispatch("removeEncounter").then(() => {
        if (this.successmessage == "success") {
          this.showMessage = this.message;
          // console.log(this.showMessage);
          this.$bvToast.show("delete-success-toast");
          window.location.reload();
        } else {
          this.$bvToast.show("delete-error-toast");
          window.location.reload();
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../css/header";
</style>
