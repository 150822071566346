<template>
  <form action class="diagnostic-form" @submit.prevent="saveDiagnostic">
    <div class="border-box">
      <div class="title">
        <p>
          <strong>X-ray</strong>
        </p>
      </div>
      <label class="pbw space-between">
        <span>PBW</span>
        <input type="checkbox" v-model="pbw" />
      </label>
      <label class="opg space-between">
        <span>OPG</span>
        <input type="checkbox" size="lg" v-model="opg" />
      </label>
      <label class="pa space-between">
        <span>PA</span>
        <input type="checkbox" size="lg" v-model="pa" />
      </label>
      <label class="other space-between">
        <span>Occlusal View</span>
        <input type="checkbox" size="lg" v-model="osslusalView" />
      </label>
    </div>
    <div class="border-box">
      <div class="title space-between direction-column">
        <p>Diagnostic Finding on X-Ray</p>

        <b-button
          v-b-modal.upload-image
          variant="link"
          v-if="this.xrayImage.length < 6"
          >Upload Image</b-button
        >
        <b-modal id="upload-image" hide-footer centered title="Upload Image">
          <b-form
            @submit.prevent="addImage"
            class="upload-image-modal"
            enctype="multipart/form-data"
          >
            <div class="image-name medication">
              <label class="floating-label">
                <span>Image Title</span>
                <input type="text" v-model="imageTitle" />
              </label>
              <span class="text-danger float-right" v-if="errors.imageTitle">
                {{ errors.imageTitle }}
              </span>
            </div>
            <div class="choose-image">
              <label id="upload-label" for="image-upload">
                <input
                  type="file"
                  ref="xRayFile"
                  accept="image/png, image/jpeg"
                  id="image-upload"
                  @change="upload"
                />
                <span>Choose Image</span>
              </label>
              <span class="text-danger float-right" v-if="errors.image">
                {{ errors.image }}
              </span>
            </div>
            <div class="image-preview">
              <img
                alt
                v-if="image == ''"
                src="https://www.clipartmax.com/png/middle/92-925246_window-cleaning-placeholder-icon-png.png"
              />
              <img alt v-else :src="image" />
            </div>
            <div class="save-button">
              <b-button
                variant="secondary"
                @click="$bvModal.hide('upload-image')"
                >Cancel</b-button
              >
              <b-button type="submit" variant="success">Save Image</b-button>
            </div>
          </b-form>
        </b-modal>
      </div>

      <div class="column-2 image-container" id="diagnosticImageContainer">
        <div v-for="(item, index) in this.xrayImage" :key="index">
          <div class="row-1" v-if="index < 6">
            <p v-html="item.image_title" class="m-0"></p>
            <img :src="item.image" width height="150" :alt="item.image_title" />
            <button
              type="button"
              @click="clearImage(item.id)"
              class="delete-button"
            >
              Delete
            </button>
          </div>
          <div class="row" v-else-if="index < 7">
            <span class="delete-button">Six Images Upload Limit !</span>
          </div>
          <div v-else></div>
        </div>
      </div>
    </div>
    <div class="border-box">
      <div class="title">
        <p>Oral Mucosal Lesions</p>
      </div>
      <label class="section-of-mouth medication floating-label">
        <span>Section of the mouth</span>
        <input type="text" name placeholder v-model="oralMucosalLesion1" />
      </label>
      <label class="description-of-lesson medication floating-label">
        <span>Description of lesion</span>
        <input type="text" name placeholder v-model="oralMucosalLesion2" />
      </label>
    </div>
    <label class="comments">
      <p>Other Special Test</p>
      <input
        type="text"
        placeholder="Special Tests if any"
        v-model="otherSpecialTest"
      />
    </label>
    <div class="button">
      <b-button variant="outline-success" @click="backToPeriodontal"
        >Back</b-button
      >
      <b-button variant="outline-success" type="submit">Next</b-button>
    </div>
  </form>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { uuid } from "vue-uuid";
import axios from "axios";
export default {
  name: "Diagnostic",
  data() {
    return {
      encounter_id: this.$route.params.id,
      pbw: false,
      opg: false,
      pa: false,
      osslusalView: false,
      oralMucosalLesion1: "",
      oralMucosalLesion2: "",
      otherSpecialTest: "",
      image: "",
      targetImg: "",
      file: null,
      imageTitle: "",
      xrayImage: [],
      errors: [],
    };
  },
  computed: {
    ...mapState(["diagnostic", "successmessage", "errormessage", "message"]),
  },

  watch: {
    diagnostic: function() {
      if (Object.keys(this.diagnostic).length !== 0) {
        this.pbw = this.diagnostic.PBW;
        this.opg = this.diagnostic.OPG;
        this.pa = this.diagnostic.PA;
        this.osslusalView = this.diagnostic.occlusal_view;
        this.oralMucosalLesion1 =
          this.diagnostic.section_of_mouth != "N/a"
            ? this.diagnostic.section_of_mouth
            : "";
        this.oralMucosalLesion2 =
          this.diagnostic.description_of_lesion != "N/a"
            ? this.diagnostic.description_of_lesion
            : "";
        this.otherSpecialTest =
          this.diagnostic.other_special_test != "N/a"
            ? this.diagnostic.other_special_test
            : "";
        this.xrayImage = this.diagnostic.xraygallery;
      } else {
        this.pbw = false;
        this.opg = false;
        this.pa = false;
        this.osslusalView = false;
        this.oralMucosalLesion1 = "";
        this.oralMucosalLesion2 = "";
        this.otherSpecialTest = "";
        this.xrayImage = [];
      }
    },
  },
  created() {
    this.listEncounterDiagnostic(this.encounter_id);
  },
  methods: {
    ...mapActions(["listEncounterDiagnostic"]),
    upload(e) {
      var files = e.target.files || e.dataTransfer.files;
      this.targetImg = this.$refs.xRayFile.files[0];
      //xRayFile
      // this.targetImg = e.target.files[0];
      this.image = URL.createObjectURL(this.targetImg);
    },
    addImage() {
      this.errors = [];
      if (this.imageTitle == "") {
        this.errors["imageTitle"] = "Image title required.";
      } else if (this.image == "") {
        this.errors["image"] = "X-ray Image required.";
      } else {
        this.xrayImage.push({
          id: uuid.v1(),
          image_title: this.imageTitle,
          image: this.image,
          file: this.targetImg,
        });
        this.imageTitle = "";
        this.image = "";
        this.$bvModal.hide("upload-image");
      }
    },
    clearImage(id) {
      if (typeof id == "number") {
        this.$store.dispatch("removeXrayImage", id).then(() => {
          if (this.successmessage == "success") {
          }
        });
      }
      this.xrayImage.forEach((element) => {
        if (element.id == id) {
          this.xrayImage.splice(this.xrayImage.indexOf(element), 1);
        }
      });
    },
    backToPeriodontal() {
      this.$store.state.tabIndex--;
      this.$store.state.disabledPeriodontal = false;
      this.$store.state.disabledDiagnostic = true;
    },
    saveDiagnostic() {
      this.$store.state.disabledDiagnostic = true;
      this.$store.state.disabledTreatment = false;
      let formDataObject = {
        PBW: !this.pbw ? false : true,
        OPG: !this.opg ? false : true,
        PA: !this.pa ? false : true,
        occlusal_view: !this.osslusalView ? false : true,
        section_of_mouth:
          this.oralMucosalLesion1 != "" ? this.oralMucosalLesion1 : "N/a",
        description_of_lesion:
          this.oralMucosalLesion2 != "" ? this.oralMucosalLesion2 : "N/a",
        other_special_test:
          this.otherSpecialTest != "" ? this.otherSpecialTest : "N/a",
        encounter_id: this.encounter_id,
      };
      if (Object.keys(this.diagnostic).length !== 0) {
        this.$store.dispatch("updateDiagnostic", formDataObject).then(() => {
          if (this.successmessage == "success") {
            if (Object.keys(this.diagnostic).length !== 0) {
              this.xrayImage.forEach((element) => {
                if (typeof element.id != "number") {
                  let xrayImgObj = new FormData();
                  xrayImgObj.append("diagnostic", this.diagnostic.id);
                  xrayImgObj.append("image", element.file);
                  xrayImgObj.append("image_title", element.image_title);
                  this.$store.dispatch("saveXrayImage", xrayImgObj).then(() => {
                    if (this.successmessage == "success") {
                      this.success = "Diagnostic updated succsessfully.";
                      this.$store.state.tabIndex++;
                    } else if (this.errormessage == "errormessage") {
                      console.log(JSON.stringify(this.message));
                    }
                  });
                }
              });
            }
          }
        });
      } else {
        axios
          .post(
            "https://blcp.dentalhubproject.org/api/v1/encounter/" +
              formDataObject.encounter_id +
              "/diagnostic",
            formDataObject
          )
          .then((response) => {
            if (response.status == 200) {
              let diagnostic_id = response.data.id;
              this.xrayImage.forEach((element) => {
                console.log(element);
                let xrayImgObj = new FormData();
                xrayImgObj.append("diagnostic", diagnostic_id);
                xrayImgObj.append("image", element.file);
                xrayImgObj.append("image_title", element.image_title);
                this.$store.dispatch("saveXrayImage", xrayImgObj).then(() => {
                  if (this.successmessage == "success") {
                    this.success = "Diagnostic saved succsessfully.";
                    this.$store.state.tabIndex++;
                  } else if (this.errormessage == "errormessage") {
                    console.log(JSON.stringify(this.message));
                  }
                });
              });
            }
          })
          .catch((error) => {
            if (error) {
              commit("setErrorMessage", "errormessage");
              commit("setMessage", error.response.data.message);
            }
          });
        // this.$store.dispatch("saveDiagnostic", formDataObject).then(() => {
        //   if (this.successmessage == "success") {
        //     console.log(window.localStorage.getItem("diagnostic_id"));
        //   }
        // });
      }
    },
  },
};
</script>

<style lang="scss">
.image-preview {
  img {
    height: 90%;
    width: 90%;
  }
}
</style>
<style lang="scss" scoped>
@import "src/css/main.scss";
</style>
