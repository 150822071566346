<template>
  <div class="tab">
    <ul>
      <b-navbar toggleable="xl" class="shortcut-tab">
        <h1>Shortcuts</h1>
        <b-navbar-toggle class="toggle-icon" target="shortcut-list"
          ><i class="fas fa-caret-down"></i
        ></b-navbar-toggle>
        <b-collapse id="shortcut-list" is-nav>
          <b-navbar-nav class="list-item">
            <b-nav-item to="/clinic/calllist" :disabled="this.$store.state.disableTab">Patient List</b-nav-item>
            <b-nav-item to="/clinic/recalllist" :disabled="this.$store.state.disableTab">Recall List</b-nav-item>
            <b-nav-item 
              ><button v-b-modal.add-patient-modal>
                Create New Patient
              </button></b-nav-item
            >
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </ul>
    <AddPatientModal />
  </div>
</template>
<script>
import AddPatientModal from "../components_clinic_page/AddPatientModal.vue";
export default {
  name: "ShortcutTab",
  components: {
    AddPatientModal,
  },
  data() {
    return {
      // first_name: "",
      // middle_name: "",
      // last_name: "",
      // first_name_khamer: "",
      // middle_name_khamer: "",
      // last_name_khamer: "",
      // gender: "",
      // dob: {
      //   month: "",
      //   day: "",
      //   year: "",
      // },
      // provinceName: "",
      // districtName: "",
      // commune: "",
      // education: "",
      // grade:'',
      // school:'',
    };
  },
  methods: {

  }
};
</script>
<style lang="scss" scoped>
@import "src/css/main.scss";
</style>
