<template>
  <div id="app">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
    ></loading>
    <app-header></app-header>
    <div class="bulk-upload">
      <div class="contents">
        <div class="row">
          <div class="col-12">
            <h1>Bulk Upload</h1>
          </div>
        </div>
        <div class="row mt-4 text-center">
          <div class="col-12">
            <div class="card shadow">
              <b-row align-h="center" class="pl-0 pr-0">
                <div class="col-lg-5 col-md-11 col-sm-12 mb-4 pl-0 pr-0">
                  <b-card
                    header="6.1 School Bulk Upload"
                    bg-variant="white"
                    class="pl-0 pr-0 pt-0 header-text"
                  >
                    <b-row align-h="center" class="mb-4">
                      <div class="col-lg-6 col-md-9 col-sm-12 pl-0">
                        <span>Download sample excel(.xlsx file)</span>
                      </div>
                      <div class="col-lg-6 col-md-6 col-sm-12 pl-0">
                        <a
                          :href="
                            targetSchoolFile
                              ? targetSchoolFile.upload_file
                              : '#'
                          "
                          target="_blank"
                          style="text-decoration: none"
                        >
                          <b-button variant="outline-primary" pill block>
                            <i class="fas fa-arrow-down pr-2"></i>Target School
                          </b-button>
                        </a>
                      </div>
                    </b-row>
                    <b-row align-h="center" class="mb-4">
                      <div class="col-lg-6 col-md-9 col-sm-12 pl-0">
                        <span>Download sample excel(.xlsx file)</span>
                      </div>
                      <div class="col-lg-6 col-md-6 col-sm-12 pl-0">
                        <a
                          :href="
                            nonTargetSchoolFile
                              ? nonTargetSchoolFile.upload_file
                              : '#'
                          "
                          target="_blank"
                          style="text-decoration: none"
                        >
                          <b-button variant="outline-primary" pill block>
                            <i class="fas fa-arrow-down pr-2"></i>Non-Target
                            School
                          </b-button>
                        </a>
                      </div>
                    </b-row>
                    <b-row align-h="center" class="mb-4">
                      <div class="col-lg-4 col-md-10 col-sm-12 pl-0 pr-0">
                        <h4>School Type</h4>
                      </div>
                      <div class="col-lg-8 col-md-12 col-sm-12">
                        <b-form-select
                          v-model="selectedSchoolType"
                          :options="optionSchoolType"
                        ></b-form-select>
                      </div>
                    </b-row>
                    <b-row align-h="center">
                      <div class="col-lg-4 col-md-10 col-sm-12 pl-0 pr-0">
                        <h4>Upload File</h4>
                      </div>
                      <div class="col-lg-8 col-md-12 col-sm-12">
                        <input
                          type="file"
                          ref="file2"
                          @change="onChangeFileTwoUpload()"
                          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        />
                      </div>
                    </b-row>
                    <b-row class="mt-5" align-h="center">
                      <div class="col-lg-7 col-md-9 col-sm-12 pr-0 pl-0">
                        <b-button
                          variant="custom"
                          @click="submitSchoolFile()"
                          block
                          >Submit School</b-button
                        >
                      </div>
                    </b-row>
                  </b-card>
                </div>

                <div class="col-lg-5 col-md-11 col-sm-12 mb-4 ml-4 pl-0 pr-0">
                  <b-card
                    header="6.2 Patient Bulk Upload"
                    bg-variant="white"
                    class="pl-0 pr-0 pt-0 header-text"
                  >
                    <b-row align-h="center" class="mb-4">
                      <div class="col-lg-6 col-md-9 col-sm-12 pl-0">
                        <span>Download sample excel(.xlsx file)</span>
                      </div>
                      <div class="col-lg-6 col-md-6 col-sm-12 pl-0">
                        <a
                          :href="patientFile ? patientFile.upload_file : '#'"
                          target="_blank"
                          style="text-decoration: none"
                        >
                          <b-button variant="outline-primary" pill block>
                            <i class="fas fa-arrow-down pr-2"></i>Patient Sample
                          </b-button>
                        </a>
                      </div>
                    </b-row>
                    <b-row align-h="center" class="mb-4">
                      <div class="col-lg-6 col-md-9 col-sm-12 pl-0">
                        <span></span>
                      </div>
                      <div class="col-lg-6 col-md-6 col-sm-12 pl-0">
                        <div class="blank"></div>
                      </div>
                    </b-row>
                    <b-row align-h="center" class="mb-4">
                      <div class="col-lg-4 col-md-10 col-sm-12 pl-0 pr-0">
                        <h4>School</h4>
                      </div>
                      <div class="col-lg-8 col-md-10 col-sm-12">
                        <b-form-select
                          v-model="selectedSchoolName"
                          :options="schools"
                          value-field="id"
                          text-field="name"
                        >
                          <b-form-select-option
                            :value="null"
                            disabled="disabled"
                            >Select School Name</b-form-select-option
                          >
                        </b-form-select>
                      </div>
                    </b-row>
                    <b-row align-h="center">
                      <div class="col-lg-4 col-md-10 col-sm-12 pl-0 pr-0">
                        <h4>Upload File</h4>
                      </div>
                      <div class="col-lg-8 col-md-10 col-sm-12">
                        <input
                          type="file"
                          ref="file"
                          @change="onChangeFileUpload()"
                          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        />
                      </div>
                    </b-row>
                    <b-row class="mt-5" align-h="center">
                      <div class="col-lg-7 col-md-9 col-sm-12 pr-0">
                        <b-button
                          variant="custom"
                          @click="submitPatientFile"
                          block
                          >Submit Patient</b-button
                        >
                      </div>
                    </b-row>
                  </b-card>
                </div>
              </b-row>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      ref="modal-center"
      id="modal-center"
      hide-footer
      centered
      title="Error ! School Bulk Error"
    >
      <p v-if="require_error && require_error.schooluploaderror">
        {{ require_error.schooluploaderror }}
      </p>
    </b-modal>
    <b-modal
      ref="duplicate-patient"
      id="duplicate-patient"
      hide-footer
      centered
      title="Error ! Patient Bulk Error"
      size="xl"
    >
      <p v-if="require_error && require_error.patientuploaderror">
        {{ require_error.patientuploaderror }}
      </p>
      <!-- <p v-if="require_error && require_error.notsuccess">
        {{ require_error.notsuccess }}
      </p> -->
      <div v-if="this.duplicate_message.length > 0 && this.duplicate_message">
        <div class="col-12 p-0">
          <div class="data-list">
            <h3 class="pb-3">Duplicate List Found Please Correct it</h3>
            <div class="d-grid mb-4">
              <b-input-group-prepend>
                <b-button disabled variant="success" class="search-button"
                  ><i class="fas fa-search"></i
                ></b-button>
              </b-input-group-prepend>
              <b-form-input
                v-model="filter"
                placeholder="Search for Users"
              ></b-form-input>
              <b-input-group-append>
                <b-button
                  variant="danger"
                  :disabled="!filter"
                  @click="filter = ''"
                  class="search-button"
                  >Clear</b-button
                >
              </b-input-group-append>
            </div>
            <div>
              <b-table
                id="duplicate-patient-table"
                show-empty
                :items="filteredDuplicateList"
                :fields="fields"
                bordered
                :filter="filter"
                responsive
                hover
                :current-page="currentPage"
                :per-page="perPage"
              >
                <template v-slot:table-busy>
                  <div class="text-center text-primary my-2">
                    <b-spinner
                      class="align-middle"
                      type="grow"
                      style="width: 5rem; height: 5rem"
                    ></b-spinner>
                  </div>
                </template>
                <template v-slot:cell(serial_number)="data">
                  {{ data.index + 1 + "." }}
                </template>
                <template v-slot:cell(first_name)="row">
                  {{ row.item.first_name }}
                </template>
                <template v-slot:cell(school)="row">
                  {{ row.item.school }}
                </template>
              </b-table>
              <div class="row my-3">
                <div class="col-10">
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="rows"
                    :per-page="perPage"
                    aria-controls="patients-table"
                    pills
                  ></b-pagination>
                </div>
                <div class="col-2">
                  <small class="ml-auto">
                    <vue-excel-xlsx
                      :data="filteredDuplicateList"
                      :columns="fields"
                      :filename="'Recall PrintList'"
                      :sheetname="'Recall PrintList'"
                      class="export-btn"
                    >
                      <a href="#"
                        ><i class="fas fa-file-export mr-1"></i>Export Now</a
                      >
                    </vue-excel-xlsx>
                  </small>
                </div>
              </div>
              <div class="row justify-content-end">
                <div class="col-2">
                  <button class="btn btn-primary w-100" @click="closeModal">
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p v-if="message">{{ message }}</p>
    </b-modal>
    <b-toast
      id="success-toast"
      variant="info"
      solid
      append-toast
      toaster="b-toaster-bottom-full"
      v-if="!this.bulk_upload_response"
    >
      <!-- <div slot="toast-title" class="d-flex flex-grow-1 align-items-baseline">
        <strong
          class="mr-auto"
          v-if="this.duplicate_message && this.duplicate_message.length > 0"
        >
          Duplicate Patient
        </strong>
      </div> -->
      <p v-if="this.success">{{ this.success }}</p>
    </b-toast>
    <b-toast
      id="success-toast"
      variant="info"
      solid
      append-toast
      toaster="b-toaster-bottom-full"
      v-if="this.bulk_upload_response"
    >
      <p>{{ this.bulk_upload_response }}</p>
    </b-toast>
    <app-footer></app-footer>
    <input type="hidden" v-model="onStateChangeValue" />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import AppHeader from "./Header";
import Appfooter from "./Footer";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
// import func from 'vue-editor-bridge';
export default {
  components: {
    "app-header": AppHeader,
    "app-footer": Appfooter,
  },
  data() {
    return {
      isLoading: false,
      file: "",
      file2: "",
      selectedSchoolType: null,
      selectedSchoolName: null,
      optionSchoolType: [
        { value: null, disabled: "disabled", text: "Select School Type" },
        { value: "target", text: "Target" },
        { value: "non target", text: "Non Target" },
      ],
      optionSchoolName: [
        { value: null, disabled: "disabled", text: "Select School" },
        { value: "20", text: "School Name One" },
      ],
      onStateChangeValue: "",
      success: "",
      require_error: [],
      duplicate_items: [],
      filter: null,
      perPage: 10,
      currentPage: 1,
      searchPatient: "",
      fields: [
        { field: "serial_number", label: "S.N." },
        { field: "dob", label: "Date of Birth" },
        { field: "first_name", label: "First Name" },
        { field: "first_name_khmer", label: "First Name" },
        { field: "gender", label: "Gender" },
        { field: "last_name", label: "Last Name" },
        { field: "last_name_khmer", label: "Last Name" },
        { field: "phone", label: "Phone" },
      ],
      errors: [],
    };
  },
  components: {
    Loading,
    "app-header": AppHeader,
    "app-footer": Appfooter,
  },
  computed: {
    ...mapState([
      "schools",
      "sample_file_obj",
      "successmessage",
      "errormessage",
      "message",
      "bulk_upload_response",
      "duplicate_message",
    ]),
    targetSchoolFile: function() {
      let allSampleFile = this.sample_file_obj;
      let newfileData = null;
      if (allSampleFile && allSampleFile.length > 0) {
        newfileData = allSampleFile.find(
          (x) => x.file_type === "Target School"
        );
      }
      return newfileData;
    },
    nonTargetSchoolFile: function() {
      let allSampleFile = this.sample_file_obj;
      let newfileData = null;
      if (allSampleFile && allSampleFile.length > 0) {
        newfileData = allSampleFile.find(
          (x) => x.file_type === "Non Target School"
        );
      }
      return newfileData;
    },
    patientFile: function() {
      let allSampleFile = this.sample_file_obj;
      let newfileData = null;
      if (allSampleFile && allSampleFile.length > 0) {
        newfileData = allSampleFile.find((x) => x.file_type === "Patient");
      }
      return newfileData;
    },
    // filteredDuplicateList: function() {
    //   let duplicate_array = [];
    //   if (this.duplicate_message.length > 0) {
    //     this.duplicate_message.forEach((element) => {
    //       duplicate_array.push(element);
    //     });
    //   }
    //   return duplicate_array;
    // },
    rows() {
      return this.duplicate_message.length;
    },

    filteredDuplicateList: function() {
      let returnObjectData = [];
      if (this.duplicate_message && this.duplicate_message.length > 0) {
        this.duplicate_message.forEach(function(rec, index) {
          returnObjectData.push({
            serial_number: index + 1,
            dob: rec.dob,
            first_name: rec.first_name,
            first_name_khmer: rec.first_name_khmer,
            gender: rec.gender,
            last_name: rec.last_name,
            last_name_khmer: rec.last_name_khmer,
            phone: rec.phone,
          });
        });
      }
      return returnObjectData;
    },
  },
  created() {
    this.listSchools();
    this.sampleFileList();
  },
  methods: {
    ...mapActions(["listSchools", "sampleFileList"]),
    onChangeFileUpload() {
      this.file = this.$refs.file.files[0];
    },
    onChangeFileTwoUpload() {
      this.file2 = this.$refs.file2.files[0];
    },
    submitSchoolFile() {
      this.require_error = [];
      this.success = "";
      if (this.selectedSchoolType == "" || this.selectedSchoolType == null) {
        this.require_error["schooluploaderror"] = "School Type required.";
        this.$refs["modal-center"].show();
      } else if (this.file2 == "") {
        this.require_error["schooluploaderror"] = "School File required.";
        this.$refs["modal-center"].show();
      } else if (
        this.file2.type != "application/vnd.ms-excel" &&
        this.file2.type !=
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        this.require_error["schooluploaderror"] =
          "Invalid School File uploaded. Accept only Excel or CSV file.";
        this.$refs["modal-center"].show();
      } else {
        this.isLoading = true;
        let formData = new FormData();
        formData.append("school_type", this.selectedSchoolType);
        formData.append("upload_file", this.file2);

        // for (var pair of formData.entries()) {
        //   console.log(pair[0]+ ', ' + pair[1]);
        // }

        this.$store.dispatch("schoolBulkUpload", formData).then(() => {
          if (this.successmessage == "success") {
            this.onStateChangeValue = new Date();
            this.isLoading = false;
            this.success =
              "School Bulk Uplaod Successfully. Please check on list.";
            this.file = null;
            this.selectedSchoolType = null;
            this.$bvToast.show("success-toast");
          } else if (this.errormessage == "errormessage") {
            this.onStateChangeValue = new Date();
            this.isLoading = false;
            this.require_error["schooluploaderror"] = this.message;
            this.file = null;
            this.selectedSchoolType = null;
            this.$refs["modal-center"].show();
          } else if ((this.message = "duplicate")) {
            this.$refs["modal-center"].show();
          }
        });
      }
    },
    submitPatientFile() {
      this.require_error = [];
      this.success = "";
      this.errors = [];
      this.$store.state.duplicate_message = [];
      // this.duplicate_items = [];
      if (this.selectedSchoolName == "" || this.selectedSchoolName == null) {
        this.require_error["patientuploaderror"] = "School Name required.";
        this.$refs["duplicate-patient"].show();
      } else if (this.file == "") {
        this.require_error["patientuploaderror"] = "Patient File required.";
        this.$refs["duplicate-patient"].show();
      } else if (
        this.file.type != "application/vnd.ms-excel" &&
        this.file.type !=
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        this.require_error["patientuploaderror"] =
          "Invalid Patient File uploaded. Accept only Excel or CSV file.";
        this.$refs["duplicate-patient"].show();
      } else {
        // this.isLoading = true;
        let formData = new FormData();
        formData.append("school", this.selectedSchoolName);
        formData.append("upload_file", this.file);
        this.$store.dispatch("patientBulkUpload", formData).then(() => {
          if (this.successmessage == "success") {
            this.isLoading = false;
            this.onStateChangeValue = new Date();
            // this.file2 = null;
            this.selectedSchoolName = null;
            this.file = "";
            this.success =
              "Patient Bulk Uplaod Successfully. Please check on list.";
            this.$bvToast.show("success-toast");
            window.location.reload();
          } else if (this.errormessage == "duplicate") {
            this.isLoading = false;
            // this.require_error["notsuccess"] = "Could not Upload the patient";
            this.selectedSchoolName = null;
            this.file = "";
            this.onStateChangeValue = new Date();
            // this.require_error["patientuploaderror"] = this.message;
            // this.file2 = null;
            this.$refs["duplicate-patient"].show();
            // this.$bvModal.show("duplicate-patient");
            // setTimeout(() => {
            //   window.location.reload();
            // }, 3000);
          }
        });
      }
    },
    closeModal() {
      this.$refs["duplicate-patient"].hide();
      window.location.reload();

      // window.location.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../css/bulkupload.scss";
.d-grid {
  display: grid;
  grid-template-columns: 50px auto 50px;
}

.export-btn {
  background: #fff;
  border: none;
  color: blue;
}
</style>
