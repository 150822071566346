<template>
  <div id="app">
    <app-header></app-header>
    <div class="contents">
      <div class="row">
        <div class="col-12">
          <h1>Screening</h1>
        </div>
      </div>
      <!-- Filter data Section -->
      <div class="row mt-4 text-center">
        <div class="col-12">
          <div class="card shadow">
            <h3 class="my-4">3 Filter Data</h3>

            <div class="row mt-4">
              <div class="col-lg-4 col-sm-12 mb-4">
                <h6>Select Start Date:</h6>
                <b-input v-model="start_date" type="date" />
              </div>

              <div class="col-lg-4 col-sm-12 mb-4">
                <h6>Select End Date:</h6>
                <b-input v-model="today_date" type="date" />
              </div>

              <div class="col-lg-4 col-sm-12 mb-4">
                <h6>Location:</h6>
                <multiselect
                  v-model="location_filter_data"
                  :options="options_location_filter_data"
                  :multiple="true"
                  :preserve-search="true"
                  placeholder="Select Location"
                  label="name"
                  track-by="name"
                  open-direction="bottom"
                  :preselect-first="true"
                >
                </multiselect>
              </div>
            </div>

            <div class="row">
              <!-- <div class="col-lg-10 col-sm-12">
                <h6>Select Activities:</h6>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="checkbox_selected"
                    :options="checkbox_options"
                    switches
                    size="md"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div> -->
              <div class="col-lg-2 col-sm-12 ml-auto">
                <b-button variant="custom" block class="mb-4" @click="filterDataTable">Submit</b-button>
              </div>
            </div>
            <div class="row pr-4 mt-5">
              <small class="ml-auto"
                ><a href=""
                  ><i class="fas fa-file-export mr-1"></i>Export Now</a
                ></small
              >
            </div>
          </div>
        </div>
      </div>
      <!-- Filter data Section -->

      <!--Summary Epidemiological Bargraph -->
      <div class="row my-4 text-center">
        <div class="col-12">
          <div class="card shadow">
            <h3 class="my-4">3.1.1 Summary Epidemiological Data</h3>
            <div class="row justify-content-center mt-5">
              <div class="col-lg-11">
                <Visualization :tag="epidemiologicaldata"></Visualization>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--Summary Epidemiological Bargraph -->

      <!--Summary Epidemiological Table -->
      <div class="row mt-4 text-center">
        <div class="col-12">
          <div class="card shadow">
            <h3 class="mb-4">3.1.2 Summary Epidemiological Data</h3>
            <div class="row justify-content-center mt-5">
              <div class="col-lg-12">
                <b-table
                  id="epidemiological-table"
                  show-empty
                  :items="epidemiologicalItems"
                  :fields="epidemiologicalData"
                  responsive
                  hover
                  bordered
                >
                </b-table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Summary Epidemiological Table -->
      <!--Summary Screeing Table -->
      <div class="row mt-4 text-center">
        <div class="col-12">
          <div class="card shadow">
            <h3 class="my-4">3.2 Summary Screening data</h3>
            <div class="row justify-content-center">
              <div class="col-lg-12">
                <h4 class="my-4">Basic Data</h4>
                <b-table
                  id="epidemiological-table"
                  show-empty
                  :items="basicScreeningItems"
                  :fields="basicScreeningData"
                  responsive
                  hover
                  bordered
                >
                </b-table>
              </div>
            </div>
            <div class="row justify-content-center mt-5">
              <div class="col-lg-12">
                <h4 class="my-4">Treatment Data</h4>
                <b-table
                  id="epidemiological-table"
                  show-empty
                  :items="treatmentScreeningItems"
                  :fields="treatmentScreeningData"
                  responsive
                  hover
                  bordered
                >
                </b-table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Summary Screening Table -->
         <!-- Form validation Error message for 2 Filter Table Data  -->
      <b-toast
        id="error-screening"
        variant="warning"
        solid
        append-toast
        toaster="b-toaster-bottom-full"
      >
        <div slot="toast-title" class="d-flex flex-grow-1 align-items-baseline">
          <strong class="mr-auto">Required error</strong>
        </div>

        <div v-if="errors.location_filter_data">
          <p>{{ errors.location_filter_data}}</p>
        </div>
      </b-toast>
      <!-- Form validation Error message for 2 Filter Table Data  -->

    </div>
    <app-footer></app-footer>
  </div>
</template>


<script>
import { mapState, mapActions } from "vuex";
import Visualization from "./Visualization";
import AppHeader from "./Header.vue";
import Appfooter from "./Footer.vue";
// import userChart from "../js/userchart.js";
// import locationChart from "../js/locationchart.js";
// import years from "../js/year_array.js";

// const axios = require('axios');
export default {
  name: "screening",
  components: {
    // "AuthenticationForm": AuthenticationForm
    "app-header": AppHeader,
    Visualization: Visualization,
    "app-footer": Appfooter,
  },

  data() {
    return {
      start_date: "",
      today_date: "",
      errors: [],
      location_filter_data: [],
      options_location_filter_data: [{ name: "All location", language: null }],
      checkbox_selected: "",
      checkbox_options: ["Routine Clinic", "School", "Other", "All"],
      epidemiologicaldata: "epidemiologicaldata",
      epidemiologicalData: [
        { key: "type", label: "", tdClass: "font-weight-bold" },
        { key: "totalscreening", label: "Total Screening" },
        { key: "anycaries", label: "AnyCaries" },
        { key: "cavityfreepd", label: "Cavity free permanent dentition" },
        { key: "symptomfree", label: "Symptom Free" },
        { key: "activeinfection", label: "Active Infection" },
        { key: "cavitymolar", label: "Cavity Molar" },
        { key: "goodoralhygine", label: "Good Oral Hygine" },
      ],
      epidemiologicalItems: [
        {
          type: "Number",
          totalscreening: "20,000",
          anycaries: "",
          cavityfreepd: "",
          symptomfree: "",
          activeinfection: "",
        },
        {
          type: "Number",
          totalscreening: "20,000",
          anycaries: "",
          cavityfreepd: "",
          symptomfree: "",
          activeinfection: "",
        },
      ],
      basicScreeningData: [
        { key: "type", label: "", tdClass: "font-weight-bold text-left" },
        { key: "male", label: "Male" },
        { key: "female", label: "Female" },
        { key: "greater_twelve", label: "Child < 13Y" },
        { key: "lesser_thirteen", label: "Child ≥ 13Y" },
        { key: "threeyears", label: "3Y" },
        { key: "sixyears", label: "6Y" },
        { key: "twoyears", label: "12Y" },
        { key: "fifteenyears", label: "15Y" },
        { key: "targetchildren", label: "Target Children" },
        { key: "total", label: "Total" },
      ],
      basicScreeningItems: [
        {
          type: "Referred to Clinic",
          male: "20,000",
          female: "20,000",
          greater_twelve: "",
          lesser_thirteen: "",
          threeyears: "",
          sixyears: "",
          twoyears: "",
          sixyears: "",
          fifteenyears: "",
          targetchildren: "",
          total: "",
        },
      ],
      treatmentScreeningData: [
        { key: "type", label: "", tdClass: "font-weight-bold text-left" },
        { key: "male", label: "Male" },
        { key: "female", label: "Female" },
        { key: "greater_twelve", label: "Child < 13Y" },
        { key: "lesser_thirteen", label: "Child ≥ 13Y" },
        { key: "threeyears", label: "3Y" },
        { key: "sixyears", label: "6Y" },
        { key: "twoyears", label: "12Y" },
        { key: "fifteenyears", label: "15Y" },
        { key: "targetchildren", label: "Target Children" },
        { key: "total", label: "Total" },
      ],
      treatmentScreeningItems: [
        {
          type: "Presence of Untreated Caries",
          totalscreening: "20,000",
          anycaries: "",
          cavityfreepd: "",
          symptomfree: "",
          activeinfection: "",
        },
        {
          type: "Mean Number of Decayed Primary Teeth",
          totalscreening: "20,000",
          anycaries: "",
          cavityfreepd: "",
          symptomfree: "",
          activeinfection: "",
        },

        {
          type: "Mean Number of Decayed Permanent Teeth",
          totalscreening: "20,000",
          anycaries: "",
          cavityfreepd: "",
          symptomfree: "",
          activeinfection: "",
        },

        { type: "Presence of Cavity in Permanent Molar" },
        { type: "Presence of Cavity in Permanent anterior zone" },
        { type: "Presence of acute infection" },
        { type: "Presence of Oral Symptoms" },
        { type: "Mean Symptoms Score" },
        { type: "Proportion of children with good oral hygiene" },
      ],
    };
  },

  computed: {
    ...mapState([
      "errormessage",
      "successmessage",
      "message",
      "returndate_obj",
      "treatment_tablebasicdata_obj",
      "treatmentstrategicdata_obj",
      "geography",
      "activities_obj",
    ]),
  },

  methods: {
    ...mapActions([
    ]),
    filterDataTable() {
      this.errors=[];
      if (this.location_filter_data == ''){
        this.errors['location_filter_data']="Selection of location is required.";
        this.$bvToast.show("error-screening")
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../css/encounter.scss";
</style>
