<template>
  <div class="footer">
    <div class="footer-wrapper">
      <div class="copyright">
        <span
          >© {{ this.current_year }} | Designed, Developed & Maintained by</span
        >
        <a href="https://abhiyantrik.com" target="_blank"
          >Abhiyantrik Technology</a
        >
      </div>
      <div class="links">
        <ul>
          <li
            v-for="(list, index) in this.footer"
            :key="index"
            @click="redirectUser(list.slug)"
          >
            <span>{{ list.title }}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      current_year: new Date().getFullYear(),
      footer: {
        about: {
          title: "About Us",
          slug: "about",
        },
        help: {
          title: "Help",
          slug: "help",
        },
        privacy_policy: {
          title: "Privacy Policy",
          slug: "privacy-policy",
        },
        play_store: {
          title: "Play Store",
          slug: "play-store",
        },
        dental_hub_open_source_project: {
          title: "Dental Hub Open Source Project",
          slug: "dental-hub-open-source-project",
        },
      },
    };
  },
  methods: {
    redirectUser(slug) {
      this.$router.push({
        name: "staticPage",
        params: { slug: slug },
      });
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../css/footer";
</style>
