<template>
  <div class="container-fluid px-5">
    <div class="footer card shadow">
      <div class="footer-wrapper">
        <div class="copyright">
          <span
            >© {{ this.current_year }} | Designed, Developed & Maintained
            by</span
          >
          <a href="https://abhiyantrik.com" target="_blank"
            >Abhiyantrik Technology</a
          >
        </div>
        <div class="links">
          <ul>
            <li>
              <router-link
                :to="{
                  name: 'staticPage',
                  params: { slug: 'about' },
                }"
              >
                <span>About Us</span>
              </router-link>
            </li>
            <li>
              <router-link
                :to="{
                  name: 'staticPage',
                  params: { slug: 'help' },
                }"
              >
                <span>Help</span>
              </router-link>
            </li>
            <li>
              <router-link
                :to="{
                  name: 'staticPage',
                  params: { slug: 'privacy-policy' },
                }"
              >
                <span>Privacy Policy</span>
              </router-link>
            </li>
            <li>
              <router-link
                :to="{
                  name: 'staticPage',
                  params: { slug: 'play-store' },
                }"
              >
                <span>Play Store</span>
              </router-link>
            </li>
            <li>
              <router-link
                :to="{
                  name: 'staticPage',
                  params: { slug: 'dental-hub-open-source-project' },
                }"
              >
                <span>Dental Hub Open Source Project</span>
              </router-link>
            </li>
            <li>
              <router-link to="/">
                <b-button variant="primary">Back</b-button>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="static-page card shadow p-5">
      <!-- <div class="image-section">
      <img src="../assets/images/about-us.png" alt="">
    </div> -->
      <article v-if="static_page_obj">
        <!-- content is placed here -->
        <h1>{{ static_page_obj.title }}</h1>
        <p>{{ static_page_obj.content }}</p>
        <!-- <div v-html="static_page_obj.content"></div> -->
      </article>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "staticPage",
  data() {
    return {
      page_slug: this.$route.params.slug,
      current_year: new Date().getFullYear(),
    };
  },
  computed: {
    ...mapState(["static_page_obj", "error_message", "response_message"]),
  },
  created() {
    this.listStaticPageData(this.page_slug);
  },
  methods: {
    ...mapActions(["listStaticPageData"]),
  },
  watch: {
    "$route.params.slug"() {
      this.listStaticPageData(this.$route.params.slug);
    },
  },
};
</script>

<style>
.static-page {
  height: 100vh;
}
.static-page p,
.help-page p,
.contact-page p {
  font-size: 24px;
  line-height: 30px;
}
</style>
<style lang="scss" scoped>
@import "../css/footer";
</style>
