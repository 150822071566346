<template>
  <div class="csi">
    <div id="app">
      <app-header></app-header>
      <div class="contents">
        <div class="row">
          <div class="col-12">
            <h1>Print List</h1>
          </div>
        </div>
        <!-- {{print_referral}} -->
        <div class="mt-5">
          <b-tabs content-class="mt-5 mb-4" align="center">
            <b-tab title="Print List - Referral" active>
              <b-card-text>
                <div class="row mt-4">
                  <div class="col-12">
                    <!-- Filter data Section -->
                    <div class="row mt-4 text-center">
                      <div class="col-12">
                        <div class="card shadow">
                          <h3 class="mb-4">6.7.1 Filter Data</h3>
                          <div class="row">
                            <div class="col-lg-4 col-sm-12 mb-4">
                              <h6>Select Start Date:</h6>
                              <b-input
                                v-model="select_start_date_referral"
                                type="date"
                              />
                            </div>
                            <div class="col-lg-4 col-sm-12 mb-4">
                              <h6>Select End Date:</h6>
                              <b-input
                                v-model="select_end_date_referral"
                                type="date"
                              />
                            </div>
                            <div class="col-lg-4 col-sm-12 mb-4">
                              <h6>Location:</h6>
                              <b-form-select
                                v-model="location_referral"
                                :options="locations"
                                @change="changeReferralLocation"
                                value-field="id"
                                text-field="name"
                              >
                                <template v-slot:first>
                                  <b-form-select-option :value="null" disabled
                                    >Select Location</b-form-select-option
                                  >
                                </template>
                              </b-form-select>
                              <!-- <multiselect
                                v-model="location_referral"
                                :options="options_location_referral"
                                :multiple="true"
                                :preserve-search="true"
                                placeholder="Select Location"
                                label="name"
                                track-by="name"
                                open-direction="bottom"
                                :preselect-first="true"
                              >
                              </multiselect> -->
                            </div>
                          </div>
                          <div
                            class="
                              row
                              justify-content-between
                              align-items-center
                            "
                          >
                            <div class="col-lg-4 col-sm-12 mb-4">
                              <h6>Select School:</h6>
                              <b-form-select
                                v-model="school_referral"
                                :options="referral_schools"
                                value-field="id"
                                text-field="name"
                              >
                                <template v-slot:first>
                                  <b-form-select-option :value="null" disabled
                                    >Select School</b-form-select-option
                                  >
                                </template>
                              </b-form-select>
                            </div>
                            <div class="col-lg-4 col-sm-12 mb-4">
                              <h6>Select Grade:</h6>
                              <multiselect
                                v-model="grade_referral"
                                :options="options_grade_referral"
                                :multiple="true"
                                :preserve-search="true"
                                placeholder="Select Grade"
                                label="name"
                                track-by="name"
                                open-direction="bottom"
                                id="grade_referral"
                                :preselect-first="false"
                              >
                              </multiselect>
                              <!-- <b-form-select
                                v-model="grade_referral"
                                :options="options_grade_referral"
                                value-field="name"
                                text-field="name"
                                multiple
                              >
                                <template v-slot:first>
                                  <b-form-select-option :value="null" disabled
                                    >Select Grade</b-form-select-option
                                  >
                                </template>
                              </b-form-select> -->
                              <!-- <multiselect
                                v-model="grade_referral"
                                :options="options_grade_referral"
                                :multiple="true"
                                :preserve-search="true"
                                placeholder="Select grade"
                                label="name"
                                track-by="name"
                                open-direction="bottom"
                                :preselect-first="true"
                              >
                              </multiselect> -->
                            </div>
                            <div class="col-lg-2 col-sm-12 mt-lg-4">
                              <!-- <h6>Click Here:</h6> -->
                              <b-button
                                variant="custom"
                                block
                                class="mb-4"
                                @click="filterDataReferral"
                                >Submit</b-button
                              >
                            </div>
                          </div>
                          <div class="row pr-4 mt-2 mb-2">
                            <small class="ml-auto">
                              <vue-excel-xlsx
                                :data="referral_list_Items"
                                :columns="referral_list_Fields"
                                :filename="'Referral PrintList'"
                                :sheetname="'Referral PrintList'"
                                class="export-btn"
                              >
                                <a href="#"
                                  ><i class="fas fa-file-export mr-1"></i>Export
                                  Now</a
                                >
                              </vue-excel-xlsx>
                            </small>
                          </div>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="card shadow">
                          <h3 class="mb-4">
                            6.7.2 Referral List by Clinic and School
                          </h3>
                          <div class="row">
                            <div class="col-12">
                              <b-table
                                id="user-table"
                                show-empty
                                :items="referral_list_Items"
                                :fields="referral_list_Fields"
                                responsive
                                hover
                                :busy="isBusy"
                                bordered
                              >
                                <template v-slot:table-busy>
                                  <div class="text-center text-primary my-2">
                                    <b-spinner
                                      class="align-middle"
                                      type="grow"
                                      style="width: 5rem; height: 5rem"
                                    ></b-spinner>
                                  </div>
                                </template>
                              </b-table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- Filter data Section -->
                  </div>
                </div>
              </b-card-text>
            </b-tab>
            <b-tab title="Print List - Recall">
              <b-card-text>
                <div class="row mt-4">
                  <div class="col-12">
                    <!-- Filter data Section -->
                    <div class="row mt-4 text-center">
                      <div class="col-12">
                        <div class="card shadow">
                          <h3 class="mb-4">6.8.1 Filter Data</h3>
                          <div class="row">
                            <div class="col-lg-4 col-sm-12 mb-4">
                              <h6>Select Start Date:</h6>
                              <b-input
                                v-model="select_start_date_recall"
                                type="date"
                              />
                            </div>

                            <div class="col-lg-4 col-sm-12 mb-4">
                              <h6>Select End Date:</h6>
                              <b-input
                                v-model="select_end_date_recall"
                                type="date"
                              />
                            </div>

                            <div class="col-lg-4 col-sm-12 mb-4">
                              <h6>Location:</h6>
                              <b-form-select
                                v-model="location_recall"
                                :options="locations"
                                @change="changeRecallLocation"
                                value-field="id"
                                text-field="name"
                              >
                                <template v-slot:first>
                                  <b-form-select-option :value="null" disabled
                                    >Select Location</b-form-select-option
                                  >
                                </template>
                              </b-form-select>
                              <!-- <multiselect
                                v-model="location_recall"
                                :options="options_location_recall"
                                :multiple="true"
                                :preserve-search="true"
                                placeholder="Select Location"
                                label="name"
                                track-by="name"
                                open-direction="bottom"
                                :preselect-first="true"
                              >
                              </multiselect> -->
                            </div>
                          </div>

                          <div
                            class="
                              row
                              justify-content-between
                              align-items-center
                            "
                          >
                            <div class="col-lg-4 col-sm-12 mb-4">
                              <h6>Select School:</h6>
                              <b-form-select
                                v-model="school_recall"
                                :options="recall_schools"
                                value-field="id"
                                text-field="name"
                              >
                                <template v-slot:first>
                                  <b-form-select-option :value="null" disabled
                                    >Select School</b-form-select-option
                                  >
                                </template>
                              </b-form-select>
                            </div>
                            <div class="col-lg-4 col-sm-12 mb-4">
                              <h6>Select Grade:</h6>
                              <multiselect
                                v-model="grade_recall"
                                :options="options_grade_recall"
                                :multiple="true"
                                :preserve-search="true"
                                placeholder="Select Grade"
                                label="name"
                                track-by="name"
                                open-direction="bottom"
                                id="grade_recall"
                                :preselect-first="false"
                              >
                              </multiselect>
                              <!-- <b-form-select
                                v-model="grade_recall"
                                :options="options_grade_recall"
                                value-field="name"
                                text-field="name"
                              >
                                <template v-slot:first>
                                  <b-form-select-option :value="null" disabled
                                    >Select Grade</b-form-select-option
                                  >
                                </template>
                              </b-form-select> -->
                              <!-- <multiselect
                                v-model="grade_recall"
                                :options="options_grade_recall"
                                :multiple="true"
                                :preserve-search="true"
                                placeholder="Select grade"
                                label="name"
                                track-by="name"
                                open-direction="bottom"
                                :preselect-first="true"
                              >
                              </multiselect> -->
                            </div>
                            <div class="col-lg-2 col-sm-12 mt-lg-4">
                              <!-- <h6>Click Here:</h6> -->
                              <b-button
                                variant="custom"
                                block
                                class="mb-4"
                                @click="filterDataRecall"
                                >Submit</b-button
                              >
                            </div>
                          </div>
                          <div class="row pr-4 mt-2 mb-2">
                            <small class="ml-auto">
                              <vue-excel-xlsx
                                :data="recall_list_Items"
                                :columns="recall_list_Fields"
                                :filename="'Recall PrintList'"
                                :sheetname="'Recall PrintList'"
                                class="export-btn"
                              >
                                <a href="#"
                                  ><i class="fas fa-file-export mr-1"></i>Export
                                  Now</a
                                >
                              </vue-excel-xlsx>
                            </small>
                          </div>
                          <!-- <div class="row pr-4 mt-5">
                            <small class="ml-auto"
                              ><a href=""
                                ><i class="fas fa-file-export mr-1"></i>Export
                                Now</a
                              ></small
                            >
                          </div> -->
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="card shadow">
                          <h3 class="mb-4">
                            6.8.2 Recall list by clinic and school
                          </h3>

                          <div class="row">
                            <div class="col-12">
                              <b-table
                                id="user-table"
                                show-empty
                                :items="recall_list_Items"
                                :fields="recall_list_Fields"
                                responsive
                                hover
                                :busy="isBusy"
                                bordered
                              >
                                <template v-slot:table-busy>
                                  <div class="text-center text-primary my-2">
                                    <b-spinner
                                      class="align-middle"
                                      type="grow"
                                      style="width: 5rem; height: 5rem"
                                    ></b-spinner>
                                  </div>
                                </template>
                              </b-table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- Filter data Section -->
                  </div>
                </div>
              </b-card-text>
            </b-tab>
          </b-tabs>
        </div>
      </div>
      <!-- Form validation Error message   -->
      <b-toast
        id="error-referral"
        variant="warning"
        solid
        append-toast
        toaster="b-toaster-bottom-full"
      >
        <div slot="toast-title" class="d-flex flex-grow-1 align-items-baseline">
          <strong class="mr-auto">Referral Required error</strong>
        </div>
        <div v-if="errorsReferral.select_start_date_referral">
          <p>{{ errorsReferral.select_start_date_referral }}</p>
        </div>
        <div v-else-if="errorsReferral.select_end_date_referral">
          <p>{{ errorsReferral.select_end_date_referral }}</p>
        </div>
        <div v-else-if="errorsReferral.location_referral">
          <p>{{ errorsReferral.location_referral }}</p>
        </div>
        <div v-else-if="errorsReferral.school_referral">
          <p>{{ errorsReferral.school_referral }}</p>
        </div>
        <div v-else-if="errorsReferral.grade_referral">
          <p>{{ errorsReferral.grade_referral }}</p>
        </div>
      </b-toast>
      <!-- Form validation Error message -->
      <!-- Form validation Error message   -->
      <b-toast
        id="error-recall"
        variant="warning"
        solid
        append-toast
        toaster="b-toaster-bottom-full"
      >
        <div slot="toast-title" class="d-flex flex-grow-1 align-items-baseline">
          <strong class="mr-auto">Recall Required error</strong>
        </div>

        <div v-if="errorsRecall.select_start_date_recall">
          <p>{{ errorsRecall.select_start_date_recall }}</p>
        </div>
        <div v-else-if="errorsRecall.select_end_date_recall">
          <p>{{ errorsRecall.select_end_date_recall }}</p>
        </div>
        <div v-else-if="errorsRecall.location_recall">
          <p>{{ errorsRecall.location_recall }}</p>
        </div>
        <div v-else-if="errorsRecall.school_recall">
          <p>{{ errorsRecall.school_recall }}</p>
        </div>
        <div v-else-if="errorsRecall.grade_recall">
          <p>{{ errorsRecall.grade_recall }}</p>
        </div>
      </b-toast>
      <!-- Form validation Error message -->
      <app-footer></app-footer>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import AppHeader from "./Header.vue";
import footer from "./Footer.vue";

export default {
  name: "Longitudinal",
  components: {
    "app-header": AppHeader,
    "app-footer": footer,
  },
  data() {
    return {
      select_start_date_referral: "",
      select_start_date_recall: "",
      select_end_date_referral: "",
      select_end_date_recall: "",
      location_referral: null,
      location_recall: null,
      errorsReferral: [],
      errorsRecall: [],
      isBusy: false,
      school_referral: null,
      school_recall: null,
      grade_referral: null,
      grade_recall: null,
      options_grade_referral: [
        { name: "All", language: null },
        { name: "KG", language: null },
        { name: "1", language: null },
        { name: "2", language: null },
        { name: "3", language: null },
        { name: "4", language: null },
        { name: "5", language: null },
        { name: "6", language: null },
        { name: "PPS", language: null },
      ],
      options_grade_recall: [
        { name: "All", language: null },
        { name: "KG", language: null },
        { name: "1", language: null },
        { name: "2", language: null },
        { name: "3", language: null },
        { name: "4", language: null },
        { name: "5", language: null },
        { name: "6", language: null },
        { name: "PPS", language: null },
      ],
      referral_list_Fields: [
        { field: "location", label: "Location", tdClass: "font-weight-bold" },
        { field: "school", label: "School" },
        { field: "grade", label: "Grade" },
        { field: "name", label: "Name" },
        { field: "khmer_name", label: "Khmer/Name" },
        { field: "phone", label: "Phone" },
        { field: "age", label: "Age" },
        { field: "sex", label: "Sex" },
        { field: "active_infection", label: "Active Infection" },
        { field: "cavity_perm_molar", label: "Cavity Premolar" },
        { field: "incisor_canine", label: "Incisor/Canine" },
      ],
      recall_list_Fields: [
        { field: "location", label: "Location", tdClass: "font-weight-bold" },
        { field: "school", label: "School" },
        { field: "recall_date", label: "Date" },
        { field: "recall_time", label: "Time" },
        { field: "recall_list_name", label: "Name" },
        { field: "grade", label: "Grade" },
        { field: "recall_list_khmer_name", label: "Khmer/Name" },
        { field: "recall_list_phone", label: "Phone" },
        { field: "recall_list_age", label: "Age" },
        { field: "recall_list_sex", label: "Sex" },
      ],
      referral_schools: [],
      recall_schools: [],
    };
  },
  computed: {
    ...mapState([
      "locations",
      "schools",
      "oral_health_obj",
      "print_referral",
      "print_recall",
      "successmessage",
      "errormessage",
      "message",
    ]),

    referral_list_Items: function() {
      let returnObjectData = [];
      if (this.print_referral && this.print_referral.length > 0) {
        this.print_referral.forEach(function(rec) {
          returnObjectData.push({
            location: rec[0],
            school: rec[1],
            grade: rec[2],
            name: rec[3],
            khmer_name: rec[4],
            phone: rec[5],
            age: rec[6],
            sex: rec[7],
            active_infection: rec[8],
            cavity_perm_molar: rec[9],
            incisor_canine: rec[10],
          });
        });
      }
      return returnObjectData;
    },

    recall_list_Items: function() {
      let returnObjectData = [];
      if (this.print_recall && this.print_recall.length > 0) {
        this.print_recall.forEach(function(rec) {
          returnObjectData.push({
            location: rec[0],
            school: rec[1],
            recall_date: rec[2],
            recall_time: rec[3],
            recall_list_name: rec[4],
            grade: rec[5],
            recall_list_khmer_name: rec[6],
            recall_list_phone: rec[7],
            recall_list_age: rec[8],
            recall_list_sex: rec[9],
          });
        });
      }
      return returnObjectData;
    },

    optionsSchool: function() {
      return this.schools;
    },
  },
  created() {
    this.listLocations();
    this.listSchools();
  },
  methods: {
    ...mapActions(["listLocations", "listSchools"]),
    changeReferralLocation: function(event) {
      // get province array from geograpy/location list
      let allLocations = this.locations.find((x) => x.id == event);
      // filter school list
      let filterSchool = this.schools.filter((school) => {
        return allLocations.province.includes(school.province);
      });

      this.referral_schools = filterSchool;
    },
    changeRecallLocation: function(event) {
      // get province array from geograpy/location list
      let allLocations = this.locations.find((x) => x.id == event);
      // filter school list
      let filterSchool = this.schools.filter((school) => {
        return allLocations.province.includes(school.province);
      });

      this.recall_schools = filterSchool;
    },
    filterDataReferral() {
      this.errorsReferral = [];
      //Error message of the filter data table 1
      if (this.select_start_date_referral == "") {
        this.errorsReferral["select_start_date_referral"] =
          "Referral starat date required.";
        this.$bvToast.show("error-referral");
      } else if (this.select_end_date_referral == "") {
        this.errorsReferral["select_end_date_referral"] =
          "Referral end date required.";
        this.$bvToast.show("error-referral");
      } else if (!this.location_referral || this.location_referral == "") {
        this.errorsReferral["location_referral"] =
          "Referral Location is required.";
        this.$bvToast.show("error-referral");
      } else if (!this.school_referral || this.school_referral == "") {
        this.errorsReferral["school_referral"] = "Referral School is required.";
        this.$bvToast.show("error-referral");
      } else if (!this.grade_referral || this.grade_referral == "") {
        this.errorsReferral["grade_referral"] = "Referral Grade is required.";
        this.$bvToast.show("error-referral");
      } else {
        this.isBusy = true;
        let grade_name = [];
        this.grade_referral.forEach((element) => {
          if (element.name == "All") {
            let KG = "KG";
            grade_name = [KG, 1, 2, 3, 4, 5, 6];
          } else {
            // let grade_name = [];
            // console.log(element.name);
            grade_name.push(element.name);
          }
        });
        console.log(grade_name);
        let formData = new FormData();
        formData.append("start_date", this.select_start_date_referral);
        formData.append("end_date", this.select_end_date_referral);
        formData.append("clinic", this.location_referral);
        formData.append("school", this.school_referral);
        grade_name.forEach(function(grade) {
          formData.append("grade", grade);
        });
        this.$store.dispatch("referralPostData", formData).then(() => {
          if (this.successmessage == "success") {
            this.isBusy = false;
          }
        });
      }
    },

    filterDataRecall() {
      this.errorsRecall = [];
      if (this.select_start_date_recall == "") {
        this.errorsRecall["select_start_date_recall"] =
          "Recall starat date required.";
        this.$bvToast.show("error-recall");
      } else if (this.select_end_date_recall == "") {
        this.errorsRecall["select_end_date_recall"] =
          "Recall end date required.";
        this.$bvToast.show("error-recall");
      } else if (!this.location_recall || this.location_recall == "") {
        this.errorsRecall["location_recall"] = "Recall Location is required.";
        this.$bvToast.show("error-recall");
      } else if (!this.school_recall || this.school_recall == "") {
        this.errorsRecall["school_recall"] = "Recall School is required.";
        this.$bvToast.show("error-recall");
      } else if (!this.grade_recall || this.grade_recall == "") {
        this.errorsRecall["grade_recall"] = "Recall Grade is required.";
        this.$bvToast.show("error-recall");
      } else {
        let grade_name = [];
        this.grade_recall.forEach((element) => {
          if (element.name == "All") {
            let KG = "KG";
            grade_name = [KG, 1, 2, 3, 4, 5, 6];
          } else {
            grade_name.push(element.name);
          }
        });
        this.isBusy = true;
        let formData = new FormData();
        formData.append("recall_start_date", this.select_start_date_recall);
        formData.append("recall_end_date", this.select_end_date_recall);
        formData.append("clinic", this.location_recall);
        formData.append("school", this.school_recall);
        grade_name.forEach(function(grade) {
          formData.append("grade", grade);
        });
        this.$store.dispatch("recallPostData", formData).then(() => {
          if (this.successmessage == "success") {
            this.isBusy = false;
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.csi {
  display: grid;
  height: 100vh;
}
.export-btn {
  background: #fff;
  border: none;
  color: blue;
}
@import "../css/index.scss";
</style>
