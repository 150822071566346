var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex align-content-center flex-wrap",attrs:{"id":"app"}},[_c('div',{staticClass:"card shadow text-center login-card",attrs:{"data-aos":"fade-right","data-aos-duration":"3200"}},[_c('div',{staticClass:"logo"},[_c('b-img',{staticClass:"align-self-center mb-4",attrs:{"src":"/dentallogo.jpg","fluid":""}}),_vm._m(0)],1),_c('h4',{staticClass:"mb-4 text-primary"},[_vm._v("Login to continue")]),_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('b-form-group',{staticClass:"pb-3",attrs:{"label-for":"email"}},[_c('b-form-input',{attrs:{"id":"email","type":"text","placeholder":"Username"},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}})],1),_c('b-form-group',{staticClass:"pb-3",attrs:{"label-for":"password"}},[_c('div',{staticClass:"toggle-password"},[_c('b-form-input',{attrs:{"id":"password","type":_vm.passwordFieldType,"placeholder":"Password"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('div',{staticClass:"input-group-append"},[_c('span',{staticClass:"input-span",on:{"click":_vm.showPassword}},[_c('i',{class:'fas ' + _vm.password_eye + ' move_to_up',attrs:{"aria-hidden":"true"}})])])],1)]),_c('div',{staticClass:"col-md-12 submit-button text-center"},[_c('b-button',{attrs:{"block":"","variant":"custom","type":"submit"},on:{"click":_vm.processLogin}},[_vm._v("Sign in")])],1)],1),_c('div',{staticClass:"row mt-4 justify-content-center"},[_c('b-nav',{attrs:{"pills":""}},[_c('b-nav-item',{attrs:{"to":"/","active-class":_vm.toggleClass('loginpage')}},[_vm._v("Overview")]),_c('b-nav-item',{attrs:{"to":{
          name: 'staticPage',
          params: { slug: 'about' },
        },"active-class":_vm.toggleClass('about')}},[_vm._v("About")]),_c('b-nav-item',{attrs:{"to":{
          name: 'staticPage',
          params: { slug: 'help' },
        },"active-class":_vm.toggleClass('help')}},[_vm._v("Help")]),_c('b-nav-item',{attrs:{"to":{
          name: 'staticPage',
          params: { slug: 'privacy-policy' },
        },"active-class":_vm.toggleClass('privacypolicy')}},[_vm._v("Privacy Policy")])],1)],1),_c('small',{staticClass:"mt-4"},[_vm._v("© "+_vm._s(this.current_year)+" | Designed, Developed & Maintained by "),_c('a',{attrs:{"href":"https://abhiyantrik.com","target":"_blank"}},[_vm._v("Abhiyantrik Technology")])])],1),_c('b-toast',{attrs:{"id":"error-toast","variant":"danger","solid":"","append-toast":"","toaster":"b-toaster-bottom-full"}},[_c('div',{staticClass:"d-flex flex-grow-1 align-items-baseline",attrs:{"slot":"toast-title"},slot:"toast-title"},[_c('strong',{staticClass:"mr-auto"},[_vm._v("Login Error")])]),(_vm.errors.auth)?_c('div',[_c('p',[_vm._v(_vm._s(_vm.errors.auth))])]):_vm._e(),(_vm.require_error.username)?_c('div',[_c('p',[_vm._v(_vm._s(_vm.require_error.username))])]):_vm._e(),(_vm.require_error.password)?_c('div',[_c('p',[_vm._v(_vm._s(_vm.require_error.password))])]):_vm._e()])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"logo-text"},[_c('h1',[_vm._v("Dental Hub Blcp")]),_c('span',[_vm._v("By dentalhubproject.org")])])}]

export { render, staticRenderFns }