<template>
  <div id="app" class="d-flex align-content-center flex-wrap">
    <div class="card shadow text-center login-card" data-aos="fade-right" data-aos-duration="3200">
      <div class="logo">
        <b-img src="/dentallogo.jpg" fluid class="align-self-center mb-4"></b-img>
        <div class="logo-text">
          <h1>Dental Hub Blcp</h1>
          <span>By dentalhubproject.org</span>
        </div>
      </div>
      <h4 class="mb-4 text-primary">Login to continue</h4>
      <b-form @submit.prevent>
        <b-form-group class="pb-3" label-for="email">
          <b-form-input id="email" type="text" v-model="username" placeholder="Username"></b-form-input>
        </b-form-group>

        <b-form-group class="pb-3" label-for="password">
          <div class="toggle-password">
            <b-form-input id="password" :type="passwordFieldType" v-model="password" placeholder="Password">
            </b-form-input>
            <div class="input-group-append">
              <span class="input-span" @click="showPassword"><i :class="'fas ' + password_eye + ' move_to_up'"
                  aria-hidden="true"></i></span>
            </div>
          </div>
        </b-form-group>

        <div class="col-md-12 submit-button text-center">
          <b-button block variant="custom" type="submit" @click="processLogin">Sign in</b-button>
        </div>
      </b-form>
      <div class="row mt-4 justify-content-center">
        <b-nav pills>
          <b-nav-item to="/" :active-class="toggleClass('loginpage')">Overview</b-nav-item>
          <b-nav-item :to="{
            name: 'staticPage',
            params: { slug: 'about' },
          }" :active-class="toggleClass('about')">About</b-nav-item>
          <b-nav-item :to="{
            name: 'staticPage',
            params: { slug: 'help' },
          }" :active-class="toggleClass('help')">Help</b-nav-item>
          <b-nav-item :to="{
            name: 'staticPage',
            params: { slug: 'privacy-policy' },
          }" :active-class="toggleClass('privacypolicy')">Privacy Policy</b-nav-item>
        </b-nav>
      </div>
      <small class="mt-4">&copy; {{ this.current_year }} | Designed, Developed & Maintained by
        <a href="https://abhiyantrik.com" target="_blank">Abhiyantrik Technology</a></small>
    </div>

    <b-toast id="error-toast" variant="danger" solid append-toast toaster="b-toaster-bottom-full">
      <div slot="toast-title" class="d-flex flex-grow-1 align-items-baseline">
        <strong class="mr-auto">Login Error</strong>
      </div>
      <div v-if="errors.auth">
        <p>{{ errors.auth }}</p>
      </div>
      <div v-if="require_error.username">
        <p>{{ require_error.username }}</p>
      </div>
      <div v-if="require_error.password">
        <p>{{ require_error.password }}</p>
      </div>
    </b-toast>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";

const axios = require("axios");
export default {
  name: "LoginForm",
  components: {},
  computed: {
    ...mapState(["token", "adminusername"]),
  },
  data() {
    return {
      username: "",
      password: "",
      errors: { auth: "" },
      require_error: [],
      passwordFieldType: "password",
      password_eye: "fa-eye-slash",
      current_year: new Date().getFullYear(),
    };
  },

  created() {
    var token = window.localStorage.getItem("token");
    if (token != undefined && token != "") {
      this.$router.replace("/welcome");
    }
  },

  methods: {
    ...mapMutations(["setToken", "setAdminUsername"]),

    showPassword() {
      if (this.passwordFieldType == "password") {
        this.password_eye = "fa-eye";
        this.passwordFieldType = "text";
      } else {
        this.password_eye = "fa-eye-slash";
        this.passwordFieldType = "password";
      }
    },

    toggleClass(current_route) {
      if (current_route == this.$route.name) {
        return "active";
      }
    },

    processLogin() {
      this.require_error = [];
      if (this.$data.username == "") {
        this.require_error["username"] = "Email required.";
        this.$bvToast.show("error-toast");
      } else if (this.$data.password == "") {
        this.require_error["password"] = "Password required.";
        this.$bvToast.show("error-toast");
      } else {
        //axios.post('https://blcp.dentalhubproject.orgcheckuser',{'email':this.$data.username}).then(response=>{
        // this.setAdminUsername(response.data.username)
        //const formData = {'username': response.data.username, 'password': this.$data.password}
        const formData = {
          username: this.$data.username.toLowerCase(),
          password: this.$data.password,
        };
        axios
          .post("https://blcp.dentalhubproject.org/api/v1/token", formData)
          .then((response) => {
            window.localStorage.setItem("token", response.data.token);
            this.setToken(response.data.token);
            axios.defaults.headers.common["authorization"] =
              "JWT " + response.data.token;
            this.$router.replace("/welcome");
            // if (this.userrole != "Clinic Officer") {
            //   this.$router.replace("/clinic");
            // } else {
            //   this.$router.replace("/dashboard");
            // }
          })
          .catch((error) => {
            if (error) {
              this.errors["auth"] = "Invalid username/password.";
              this.$bvToast.show("error-toast");
            }
            this.errored = true;
          });
        // })
        // .catch(error => {
        //   if(error){
        //     this.errors['auth'] =  "Invalid username/password."
        //     this.$bvToast.show('error-toast');
        //   }this.errored = true;

        // })
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../css/loginpage.scss";

.move_to_up {
  z-index: 9999;
}
</style>
